import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';
import { getSelectedGroupFromStorage } from '../components/findUserType';
import { Resolution } from '../models/commonEnums';
import { DeviceResponse } from '../models/commonTypeScript';
import fetch from './fetch';
import getFetchHeaders from './getFetchHeaders';

export default async (
    serialNumber: string,
    params: { from: string; to: string; resolution?: Resolution; sensors?: string; parent?: string }
): Promise<DeviceResponse> => {
    const uri = Object.keys(params)
        .filter(p => !!params[p as keyof typeof params])
        .map(p => `${p}=${params[p as keyof typeof params]}`)
        .join('&');
    return fetch<DeviceResponse>(`/devices/${serialNumber}/segments/latest/samples?${uri}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const fetchSegmentWithId = async (
    serialNumber: string,
    segmentId: string,
    from: string,
    to: string,
    resolution?: Resolution
): Promise<DeviceResponse> => {
    const resolutionAttr = resolution ? `&resolution=${resolution}` : '';
    return fetch<DeviceResponse>(
        `/devices/${serialNumber}/segments/${segmentId}/samples?from=${from}&to=${to}${resolutionAttr}`,
        {
            method: 'GET',
            headers: await getFetchHeaders(),
        }
    );
};

export const fetchVirtualDeviceData = async (
    serialNumber: string,
    params: { from: string; to: string; resolution?: Resolution; sensors?: string; parent?: string },
    segmentId?: string
): Promise<DeviceResponse> => {
    const fromDate = params.from;
    const toDate = params.to;
    const uri = Object.keys(params)
        .filter(p => !!params[p as keyof typeof params])
        .map(p => `${p}=${params[p as keyof typeof params]}`)
        .join('&');

    if (toDate === fromDate || dayjs(fromDate).isAfter(toDate)) {
        if (Sentry) {
            Sentry.captureEvent({
                message: 'Virtual sensor not fetched, From date must be before to date',
                level: 'warning',
                tags: {
                    queryParams: uri,
                    toAndFromAreEqual: toDate === fromDate,
                    userGroupId: getSelectedGroupFromStorage()?.userGroupId,
                },
            });
        }
        throw new Error('From date must be before to date');
    }
    return fetch<DeviceResponse>(`/devices/${serialNumber}/segments/${segmentId || 'latest'}/virtual-samples?${uri}`, {
        method: 'GET',
        headers: await getFetchHeaders(),
    });
};

export const updateSegment = async (
    serialNumber: string,
    segmentId: string,
    name: string,
    locationId?: string
): Promise<void> =>
    fetch<void>(`/devices/${serialNumber}/segments/${segmentId}`, {
        method: 'PATCH',
        headers: await getFetchHeaders(),
        body: JSON.stringify({ name, locationId }),
    });

export const deleteEndedSegment = async (serialNumber: string, segmentId: string): Promise<void> =>
    fetch<void>(`/devices/${serialNumber}/segments/${segmentId}`, {
        method: 'DELETE',
        headers: await getFetchHeaders(),
    });
