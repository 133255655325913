import React, { ReactElement, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment, { unitOfTime } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { virtualSensors, colors } from 'commons/src/constants';
import { highChartsTimeZoneOffset } from 'commons/src/features/devicePage/SensorGraph';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import { Resolution, SensorTypes } from 'commons/src/models/commonEnums';
import { SelectedPeriod, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../../reducers';
import { buildingSelector } from '../../../../reducers/selectors/buildingSelector';
import { generatePlotBands, addNullPointsToChartData } from '../../../reports/insightFunctions';
import { getSensorPlotBands } from '../spaceFunctions';
import { spaceSensorData } from '../spaceSelectors';
import spaceGraphConfig, { SpaceGraphData } from './spaceGraphConfig';
import getSpaceGraphData from './spaceGraphFunctions';
import styles from './SpaceMainGraph.module.scss';

type Props = {
    spaceId: string;
    sensor: SensorTypes;
    customThresholds: SensorThresholds;
    selectedInterval: SelectedPeriod;
    locationId: string;
    graphStartAndEnd: { max: number; min: number };
    compareSensor?: SensorTypes;
};

const SpaceMainGraph = ({
    sensor,
    selectedInterval,
    spaceId,
    locationId,
    customThresholds,
    graphStartAndEnd,
    compareSensor,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const {
        spaceData: { sensorData, deviceData, extraSeries },
        requests: spaceDataRequests,
        virtualSensorRequest,
    } = useSelector((state: Store) => spaceSensorData(state, spaceId));
    const { outdoorData, locationId: outdoorLocationId } = useSelector((state: Store) => state.outdoorData);
    const { thresholds } = useSelector((state: Store) => state.config);
    const { usageHours, timezone } = useSelector((state: Store) => buildingSelector(state, locationId));
    const thresholdsLoaded = Object.keys(thresholds).length > 0;
    const compareSensorThresholds: SensorThresholds | undefined = compareSensor && thresholds[compareSensor];

    useEffect((): void => {
        if (timezone) highChartsTimeZoneOffset(timezone, selectedInterval.resolution === Resolution.hour);
    }, [timezone, selectedInterval]);

    const { sensorLevelPlotBands, plotLines } = useMemo(
        () => getSensorPlotBands(customThresholds?.ranges || [], sensor),
        [customThresholds]
    );

    const dateFormat = useSelector((state: Store) => state.userSettings.dateFormat);
    const data: SpaceGraphData[] = useMemo(() => {
        return getSpaceGraphData(sensorData, extraSeries, sensor, selectedInterval, deviceData);
    }, [sensorData, deviceData, sensor, extraSeries, selectedInterval]);

    const compareSensorData = useMemo(() => {
        if (!compareSensor) {
            return [];
        }
        return getSpaceGraphData(sensorData, {}, compareSensor, selectedInterval, deviceData, true);
    }, [compareSensor, sensorData, deviceData, sensor, selectedInterval]);

    const calculatedFromDate =
        selectedInterval.period &&
        moment().subtract(selectedInterval.number, selectedInterval.period as unitOfTime.DurationConstructor);
    const fromDate = selectedInterval.startDate || calculatedFromDate;

    const usageHoursPlotBands = useMemo(
        // TODO - update generatePlotBands to use DayJS
        () => (timezone && fromDate && generatePlotBands(usageHours, fromDate, timezone)) || [],
        [timezone, fromDate, usageHours]
    );

    const outdoorSensorData =
        outdoorLocationId === locationId ? outdoorData[selectedInterval.name || '']?.[sensor]?.measurements : [];

    const outdoorGraph = useMemo(
        () => ({
            data: addNullPointsToChartData(
                outdoorSensorData || [],
                selectedInterval.startDate || null,
                selectedInterval.endDate || null
            ),
            name: `${txt('Space.OutdoorSensor', { sensor: txt(sensor) })}`,
            id: `${sensor}-outdoor`,
            color: colors.blueBondi,
        }),
        [outdoorSensorData, selectedInterval]
    );

    const graphConfig = spaceGraphConfig({
        data:
            outdoorGraph.data.length > 0
                ? [...data, outdoorGraph, ...compareSensorData]
                : [...data, ...compareSensorData],
        sensorLevelPlotBands,
        selectedInterval,
        usageHoursPlotBands,
        dateFormat,
        sensorUnit: customThresholds,
        graphStartAndEnd,
        plotLines,
        compareSensorUnit: compareSensorThresholds,
    });

    const isVirtual = virtualSensors.includes(sensor);
    const dataLoading = isVirtual ? virtualSensorRequest.loading : spaceDataRequests.loading;
    const dataError = isVirtual ? virtualSensorRequest.error : spaceDataRequests.error;

    if (dataError) {
        return <ResponseBox text={txt(`ErrorCodes.${dataError.error}`)} />;
    }

    return (
        <div className={styles.graphWrapper}>
            {dataLoading || !thresholdsLoaded ? (
                <div className={styles.spinnerBlock}>
                    <StandAloneSpinner />
                </div>
            ) : (
                <HighchartsReact highcharts={Highcharts} options={graphConfig} />
            )}
            {!dataLoading && data.length === 0 && (
                <div className={styles.noData}>{txt('Space.NoDataForTimeFrame')}</div>
            )}
        </div>
    );
};

export default SpaceMainGraph;
