import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import {
    BUILDINGS_FOCUS_SELECT_OPENING_HOURS,
    BUILDINGS_FOCUS_SELECT_THRESHOLD,
    BUILDINGS_FOCUS_SELECT_TIME,
    BUILDINGS_FOCUS_SET_THRESHOLD_AS_DEFAULT,
} from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import spinner from 'commons/src/img/spinner';
import { Resolution, Role, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import {
    getBuildingsSensorData,
    setBuildingsFocusedSensorDefault,
} from '../../../actions/buildingsTimeOverThresholdActions';
import { BreachType } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import BuildingInsightTimeSelector from '../../buildings/buildingInsight/BuildingInsightTimeSelector';
import ThresholdInsightRule from '../../buildings/ThresholdInsightRule';
import styles from './BuildingFocusSelector.module.scss';

type SelectedPeriod = {
    toDate: string;
    fromDate: string;
    resolution: Resolution;
    name: TimePeriod;
};

type ParentProps = {
    selectedSensor: SensorTypes;
    clearSelection: () => void;
    aboveThresholds: { id: string; inputValue: string }[];
    belowThresholds: { id: string; inputValue: string }[];
};

type StateProps = {
    getDataLoading: boolean;
    loadingSetDefault: boolean;
    error?: ErrorType;
    thresholds: { value: number; type: BreachType }[];
    userRole?: Role;
};

type Props = StateProps & ParentProps;
const BuildingFocusedSensorOptionsComponent = ({
    thresholds,
    userRole,
    aboveThresholds,
    belowThresholds,
    selectedSensor,
    clearSelection,
    getDataLoading,
    loadingSetDefault,
    error,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const defaultTimeSpanSelected: TimePeriod = TimePeriod.week;
    const defaultResolution: Resolution = Resolution.day;

    const requiredUserRoleLevelForEdit =
        userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editBuilding);

    const [isInLoadingState, setIsInLoadingState] = useState(false);
    const [initialThresholds, setInitialThresholds] = useState(thresholds);
    const [withOpeningHours, setWithOpeningHours] = useState(true);
    const [initialStatesSet, setInitialStatesSet] = useState(false);
    const upperThreshold = thresholds?.find(threshold => threshold.type === BreachType.over);
    const lowerThreshold = thresholds?.find(threshold => threshold.type === BreachType.under);
    const [selectedHighThreshold, setSelectedHighThreshold] = useState<Option>(
        aboveThresholds.find(threshold => upperThreshold && threshold.id === upperThreshold.value.toString()) ||
            aboveThresholds[0]
    );

    const [selectedLowThreshold, setSelectedLowThreshold] = useState<Option | undefined>(
        thresholds.length > 1
            ? belowThresholds.find(threshold => lowerThreshold && threshold.id === lowerThreshold.value.toString()) ||
                  belowThresholds[0]
            : undefined
    );

    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>({
        toDate: moment().subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
        fromDate: moment().subtract(1, defaultTimeSpanSelected).format('YYYY-MM-DD'),
        resolution: defaultResolution,
        name: defaultTimeSpanSelected,
    });

    const updateOpeningHours = (openingHoursOn: boolean): void => {
        setWithOpeningHours(openingHoursOn);
        dispatch(
            getBuildingsSensorData({
                thresholds,
                sensor: selectedSensor,
                resolution: selectedPeriod.resolution,
                from: selectedPeriod.fromDate,
                to: selectedPeriod.toDate,
                useOpeningHours: openingHoursOn,
            })
        );
        analyticsLogger(BUILDINGS_FOCUS_SELECT_OPENING_HOURS, { useOpeningHours: openingHoursOn });
    };

    const selectPeriod = (period: TimePeriod, from: string, to: string, res: Resolution): void => {
        setSelectedPeriod({
            fromDate: from,
            toDate: to,
            resolution: res,
            name: period,
        });
        const payload = {
            thresholds,
            sensor: selectedSensor,
            resolution: res,
            from,
            to,
            useOpeningHours: withOpeningHours,
        };
        dispatch(getBuildingsSensorData(payload));
        analyticsLogger(BUILDINGS_FOCUS_SELECT_TIME, payload);
    };

    const getNewThresholds = (): { value: number; type: BreachType }[] => {
        return thresholds.map(threshold => {
            let newThresholdValue = threshold.value;
            if (threshold.type === BreachType.over) {
                newThresholdValue = parseInt(selectedHighThreshold.id, 10);
            } else if (threshold.type === BreachType.under && selectedLowThreshold) {
                newThresholdValue = parseInt(selectedLowThreshold.id, 10);
            }
            return { type: threshold.type, value: newThresholdValue };
        });
    };
    const thresholdsChanged = thresholds.find(
        threshold =>
            !initialThresholds.find(
                initThreshold => initThreshold.value === threshold.value && initThreshold.type === threshold.type
            )
    );

    const setThresholdAsDefault = (): void => {
        if (!requiredUserRoleLevelForEdit) return;
        const newThresholdValues = getNewThresholds();
        setInitialThresholds(newThresholdValues);
        setIsInLoadingState(true);
        dispatch(setBuildingsFocusedSensorDefault(newThresholdValues, selectedSensor));
        analyticsLogger(BUILDINGS_FOCUS_SET_THRESHOLD_AS_DEFAULT, {
            thresholds: newThresholdValues,
            sensor: selectedSensor,
        });
    };

    useEffect(() => {
        if (initialStatesSet) {
            const newThresholds = getNewThresholds();
            setIsInLoadingState(true);
            const payload = {
                thresholds: newThresholds,
                sensor: selectedSensor,
                resolution: selectedPeriod.resolution,
                from: selectedPeriod.fromDate,
                to: selectedPeriod.toDate,
                useOpeningHours: withOpeningHours,
            };
            dispatch(getBuildingsSensorData(payload));
            analyticsLogger(BUILDINGS_FOCUS_SELECT_THRESHOLD, { ...payload, timeFrame: selectedPeriod.name });
        } else {
            setInitialStatesSet(true);
        }
    }, [selectedHighThreshold, selectedLowThreshold]);

    if (error) {
        return (
            <div className={styles.errorWrapper}>
                <ResponseBox text={error.error} />
            </div>
        );
    }

    return (
        <div className={styles.focusSelector}>
            <div className={styles.leftSection}>
                <ThresholdInsightRule
                    sensor={selectedSensor}
                    selectedHighThreshold={selectedHighThreshold}
                    selectedLowThreshold={selectedLowThreshold}
                    setSelectedHighThreshold={setSelectedHighThreshold}
                    setSelectedLowThreshold={setSelectedLowThreshold}
                    aboveThresholds={aboveThresholds}
                    belowThresholds={belowThresholds}
                    boldText
                    noPadding
                />
                {!(getDataLoading && isInLoadingState) && thresholdsChanged && requiredUserRoleLevelForEdit && (
                    <PrimaryButton
                        onClick={setThresholdAsDefault}
                        title="BuildingInsight.SetAsDefault"
                        filled
                        loading={loadingSetDefault && isInLoadingState}
                    />
                )}
                {getDataLoading && isInLoadingState && <div>{spinner}</div>}
                {!(getDataLoading && isInLoadingState) && !thresholdsChanged && requiredUserRoleLevelForEdit && (
                    <TertiaryButton onClick={clearSelection} title="BuildingSensorFocus.ClearSelection" />
                )}
            </div>
            <BuildingInsightTimeSelector
                buildingHasUsageHours
                setWithOpeningHours={updateOpeningHours}
                withOpeningHours={withOpeningHours}
                selectedPeriod={selectedPeriod.name}
                setSelectedPeriod={selectPeriod}
            />
        </div>
    );
};

const mapsStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        buildingsOverview: { thresholds },
        requests: {
            [RequestType.GetBuildingsFocusedSensorData]: { loading: getDataLoading, error: getDataError },
            [RequestType.BuildingsThresholdsUpdateConfig]: { loading: loadingSetDefault, error: errorSetDefault },
            [RequestType.BuildingsThresholdsSetFocusedSensor]: { error: focusedSensorError },
        },
    } = store;
    return {
        thresholds,
        getDataLoading,
        loadingSetDefault,
        error: getDataError || errorSetDefault || focusedSensorError,
        userRole: selectedGroup && selectedGroup.role,
    };
};

export default connect(mapsStateToProps)(BuildingFocusedSensorOptionsComponent);
