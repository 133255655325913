import mixpanel from 'mixpanel-browser';
import { isProd } from '../commonFunctions';
import { mixpanelProdToken, mixpanelDevToken } from '../constants';
import { MixpanelChatUserType } from '../models/commonTypeScript';

if (isProd) {
    mixpanel.init(mixpanelProdToken);
} else {
    mixpanel.init(mixpanelDevToken);
}

export const analyticsLogger = <T>(eventName: string, eventData: T): void => {
    mixpanel.track(eventName, eventData);
};

export const mixpanelRegisterSuperProperties = <T>(eventData: T): void => {
    mixpanel.register(eventData);
};

export const mixpanelChatInformation = (user: MixpanelChatUserType, groups: string[], userIsHbs: boolean): void => {
    mixpanel.people.set({
        $email: user.email,
        organizations: groups,
        $name: user.name,
        isBusinessUser: userIsHbs,
    });

    mixpanel.identify(user.user_id);
};

export enum PageType {
    Insight = 'Insight',
    Device = 'Device',
    Account = 'Account',
    PublicDashboard = 'Public Dashboard',
    Building = 'Building',
    Floorplan = 'Floorplan',
    Dashboard = 'Dashboard',
    Alert = 'Alert',
    Integration = 'Integration',
    Status = 'Status',
}

export enum ReportType {
    Outdoor = 'Outdoor',
    Pressure = 'Pressure',
    Radon = 'Radon',
    VirusRisk = 'Virus Risk',
    IAQCertificate = 'IAQ certificate',
    EnergyWastage = 'Energy Wastage',
    IAQInsight = 'IAQ Insight',
    Calibration = ' Calibration report',
}

export enum FeatureType {
    MoldRisk = 'Mold risk',
    Occupancy = 'Occupancy',
    VirusRisk = 'Virus risk',
}

export enum IntegrationType {
    ThirdParty = 'Third Party',
    API = 'API',
    Webhooks = 'Webhooks',
    MQTT = 'MQTT',
}
