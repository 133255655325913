import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, NavLink, useNavigate } from 'react-router-dom';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import SpaceSensorValue from 'commons/src/components/sensors/SpaceSensorValue';
import { DeviceTypeNames, SensorTypes } from 'commons/src/models/commonEnums';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { paths } from '../../../constants';
import { IndoorSpace, SpaceDevice, SpaceHub } from '../../../models/spaceModels';
import styles from './SpacesRow.module.scss';

export type Props = {
    space: IndoorSpace;
    selectedSensors: SensorTypes[];
};

const SpacesRow = ({ space, selectedSensors }: Props): ReactElement => {
    const { t: txt } = useTranslation();

    const link: string = generatePath(`/${paths.spacePage}`, {
        buildingId: space.locationId,
        spaceId: space.id,
    });
    const navigate = useNavigate();
    const addDevice = (): void => {
        navigate({ pathname: link });
    };

    const devices: SpaceDevice[] = space.devices
        .filter(device => device.deviceType !== DeviceTypeNames.hub)
        .map(device => device as SpaceDevice);
    const hubs: SpaceHub[] = space.devices
        .filter(device => device.deviceType === DeviceTypeNames.hub)
        .map(device => device as SpaceHub);

    const addButton: ReactElement = (
        <TertiaryButton title={`+ ${txt('AddDevice.AddDevice')}`} onClick={addDevice} testId="add-device" />
    );

    const currentSensorValues: ReactElement = (
        <div className={styles.sensorsWrapper}>
            {hubs.map(hub => (
                <div className={styles.status} key={hub.serialNumber}>
                    <DeviceStatusPill status={hub.healthStatus} deviceType="hub" />
                </div>
            ))}
            {devices.map(device =>
                [DeviceHealthStatus.notSynced, DeviceHealthStatus.offline].includes(device.healthStatus) ? (
                    <div className={styles.status} key={device.serialNumber}>
                        <DeviceStatusPill status={device.healthStatus} deviceType="device" />
                    </div>
                ) : (
                    <div key={device.serialNumber} className={styles.sensors}>
                        {selectedSensors.map(sensor => {
                            const currentValue = device.currentSensorValues.find(cv => cv.type === sensor);
                            return (
                                <div key={`sensor-${sensor}`} className={styles.sensor}>
                                    {currentValue === undefined ? (
                                        ''
                                    ) : (
                                        <SpaceSensorValue
                                            value={currentValue.value}
                                            unit={currentValue.unit}
                                            type={currentValue.type}
                                            thresholds={currentValue.thresholds}
                                            showSensorType={false}
                                            key={sensor}
                                            healthStatus={device.healthStatus}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )
            )}
        </div>
    );

    return (
        <NavLink to={link} className={styles.element}>
            <div className={styles.name}>{space.name}</div>
            <div className={styles.body}>{space.devices.length === 0 ? addButton : currentSensorValues}</div>
        </NavLink>
    );
};

export default SpacesRow;
