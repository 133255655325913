import React from 'react';
import { createRoot } from 'react-dom/client';
import BusinessDashboard from 'business-dashboard/src/index';
import { getSelectedGroupFromStorage, userIsHbs } from 'commons/src/components/findUserType';
import 'commons/src/style/index.scss';
import { GroupType } from 'commons/src/models/commonEnums';
import ConsumerDashboard from 'consumer-dashboard/src/index';

const App = (): React.ReactElement => {
    let renderBusinessDashboard: string | boolean = true;

    const userGroupType = localStorage && getSelectedGroupFromStorage()?.groupType;

    const consumerUser = userGroupType === GroupType.consumer;
    const hbsIdSet = userIsHbs();
    if (consumerUser && !hbsIdSet) {
        renderBusinessDashboard = false;
    }
    return renderBusinessDashboard ? <BusinessDashboard /> : <ConsumerDashboard />;
};

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

export default App;
