import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkButton from 'commons/src/components/buttons/LinkButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import { rssiThresholds } from 'commons/src/constants';
import i18n from 'commons/src/i18n';
import { getBusinessIntercomArticleLink } from '../../../../constants';
import { BuildingHealthStats } from '../../../../models/buildingHealthModels';
import { IntercomArticleId } from '../../../../models/commonEnums';
import styles from './BuildingHealtStatusSummaryBar.module.scss';
import HealthStatusSummary from './HealthStatusSummary';

type Props = {
    hubs?: BuildingHealthStats;
    devices?: BuildingHealthStats;
};

type TrobleshootLink = {
    articleId: IntercomArticleId;
    helperText: string;
    helperIcon: JSX.Element;
};

const troubleShootLinks: TrobleshootLink[] = [
    {
        articleId: IntercomArticleId.TROUBLESHOOTING_OFFLINE_HUB,
        helperText: i18n.t('BuildingHealth.TroubleshootOfflineHub'),
        helperIcon: <MaterialIcon name="cloud_off" />,
    },
    {
        articleId: IntercomArticleId.TROUBLESHOOTING_OFFLINE_DEVICE,
        helperText: i18n.t('BuildingHealth.TroubleshootOfflineDevice'),
        helperIcon: <MaterialIcon name="wifi_off" />,
    },
    {
        articleId: IntercomArticleId.TROUBLESHOOTING_CONNECTION_ISSUES,
        helperText: i18n.t('BuildingHealth.TroubleshootPoorConnection'),
        helperIcon: <RssiSignalStrength color="blue" rssi={rssiThresholds.threeBars} height={16} />,
    },
];

const BuildingHealthStatusSummaryBar = ({ hubs, devices }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    return (
        <section className={styles.statusBarWrapper}>
            <HealthStatusSummary hubs={hubs} devices={devices} />
            <div className={styles.statusBarTroubleshootWrapper}>
                {txt('BuildingHealth.TroubleshootLabel')}
                <div className={styles.statusBarTroubleShootLinks}>
                    {troubleShootLinks.map(({ helperText, helperIcon, articleId }) => (
                        <LinkButton
                            key={articleId}
                            link={getBusinessIntercomArticleLink(articleId)}
                            title={helperText}
                            icon={helperIcon}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BuildingHealthStatusSummaryBar;
