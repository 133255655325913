import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { Rating } from 'commons/src/models/commonEnums';
import { BuildingType, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import ThresholdRanges from '../iaqInsight/ThresholdRanges';
import BuildingInfo from '../reportSelector/BuildingInfo';

type ParentProps = {
    building: BuildingType;
    devicesInEachLevel: { [Rating.GOOD]: number; [Rating.FAIR]: number; [Rating.POOR]: number };
    setDownloadModalOpen: (open: boolean) => void;
    downloadButtonEnabled: boolean;
    optionalBuildingProps: string[];
};

type StateProps = {
    numberOfDevicesMissingData: number;
    thresholds: { [sensor: string]: SensorThresholds };
};

type Props = ParentProps & StateProps;

export const VirusResultSummaryComponent = (props: Props): React.ReactElement => {
    const {
        thresholds,
        numberOfDevicesMissingData,
        building,
        devicesInEachLevel,
        setDownloadModalOpen,
        downloadButtonEnabled,
        optionalBuildingProps,
    } = props;
    const { t: txt } = useTranslation();
    const virusThresholds = thresholds.virusRisk;

    const allDevicesGood = devicesInEachLevel[Rating.POOR] + devicesInEachLevel[Rating.FAIR] === 0;
    const numberOfDevicesWithHighestAlert =
        devicesInEachLevel[Rating.POOR] > 0 ? devicesInEachLevel[Rating.POOR] : devicesInEachLevel[Rating.FAIR];
    const warningTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtWarningLevel'
            : 'RadonInsight.DevicesAtWarningLevel';
    const alertTextKey =
        numberOfDevicesWithHighestAlert === 1
            ? 'RadonInsight.SingleDeviceAtHighRisk'
            : 'RadonInsight.DevicesAtHighRisk';
    const numberOfDevicesWithAlertTextKey = devicesInEachLevel[Rating.POOR] > 0 ? alertTextKey : warningTextKey;

    return (
        <div id="virusResultSummary" className="form__wide-container insight-tile insight-tile--full-width">
            <div className="insight-tile__header insight-tile__header--no-border">
                <h3 className="insight-tile__header__name insight-tile__header__name--full-text">
                    {txt('VirusInsight.VirusSummaryHeader')}
                </h3>
                <div className="form__button-container">
                    <PrimaryButton
                        onClick={(): void => setDownloadModalOpen(true)}
                        disabled={!downloadButtonEnabled}
                        title="Download"
                        filled
                    />
                </div>
            </div>
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <BuildingInfo building={building} validate={false} optionalBuildingProps={optionalBuildingProps} />
            <div className="inline-header-lined inline-header-lined--no-margin-top" />
            <div className="insight-tile__content insight-tile__content--wrap">
                <div className="insight-tile__content__text">
                    <div
                        className={classNames('sensor__line', {
                            'sensor__line--ok':
                                devicesInEachLevel[Rating.POOR] === 0 && devicesInEachLevel[Rating.FAIR] === 0,
                            'sensor__line--alert': devicesInEachLevel[Rating.POOR] > 0,
                            'sensor__line--warning':
                                devicesInEachLevel[Rating.POOR] === 0 && devicesInEachLevel[Rating.FAIR] > 0,
                        })}
                    />
                    <span className="text-large text-bold">
                        {allDevicesGood
                            ? txt('RadonInsight.AllDevicesWithinRecommendedLevel')
                            : txt(numberOfDevicesWithAlertTextKey, {
                                  numberOfDevices: numberOfDevicesWithHighestAlert.toString(),
                              })}
                    </span>
                </div>
                <ThresholdRanges
                    ranges={virusThresholds.ranges}
                    unit={virusThresholds.unit}
                    sensorType={virusThresholds.type}
                    devicesInEachLevel={devicesInEachLevel}
                />
                {numberOfDevicesMissingData > 0 && (
                    <div className="device-insight__error-response device-insight__error-response--slim text-small">
                        <MaterialIcon extraClass="icon-color--red" name="error_outline" />
                        {txt('RadonInsight.DevicesMissingDataForReport', {
                            numberOfDevices: numberOfDevicesMissingData.toString(),
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        virusRiskInsight: { thresholds, errors },
    } = state;
    return {
        numberOfDevicesMissingData: errors.length,
        thresholds,
    };
};

export default connect(mapStateToProps)(VirusResultSummaryComponent);
