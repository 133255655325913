import { colors } from 'commons/src/constants';
import { Rating, SensorTypes } from 'commons/src/models/commonEnums';
import { ThresholdRange } from 'commons/src/models/commonTypeScript';

type PlotLines = {
    zIndex: number;
    color: string;
    dashStyle: string;
    value: number;
    width: number;
};

const createPlotLine = (value: number, rating: Rating): PlotLines => ({
    zIndex: rating === Rating.FAIR ? 1 : 2,
    color: rating === Rating.FAIR ? colors.yellowSunlight : colors.redAuburn,
    dashStyle: 'LongDash',
    value,
    width: 1,
});

const filteredRanges = (rangesForSensor: ThresholdRange[], sensor: SensorTypes): ThresholdRange[] => {
    return (rangesForSensor || []).filter(
        range => range.rating !== Rating.GOOD && !(sensor === SensorTypes.humidity && range.rating === Rating.FAIR)
    );
};

// eslint-disable-next-line import/prefer-default-export
export const getSensorPlotBands = (
    rangesForSensor: ThresholdRange[],
    sensor: SensorTypes
): { sensorLevelPlotBands: { from: number; to: number; color: string }[]; plotLines: PlotLines[] } => {
    const plotLines: PlotLines[] = [];
    const ranges = filteredRanges(rangesForSensor, sensor).map(range => {
        let { from, to } = range;
        if (from !== undefined) {
            plotLines.push(createPlotLine(from, range.rating));
        }
        if (to !== undefined) {
            plotLines.push(createPlotLine(to, range.rating));
        }
        if (to === undefined && from !== undefined) to = from * 100; // multiplying by 100 to cover the max value.
        if (from === undefined && to !== undefined) from = to - 100; // This covers the case of temp and humidity
        return {
            from: from as number,
            to: to as number,
            color: range.rating === Rating.FAIR ? 'rgba(255, 217, 102, 0.35)' : 'rgba(211, 46, 47, 0.25)',
        };
    });
    return { sensorLevelPlotBands: ranges, plotLines };
};

export const getThresholdRanges = (
    rangesForSensor: ThresholdRange[],
    sensor: SensorTypes
): { modifiedRanges: ThresholdRange[]; lowest: number; highest: number } => {
    const ranges = filteredRanges(rangesForSensor, sensor);

    const allValues = ranges.flatMap(range => [range.from, range.to]).filter(value => value !== undefined) as number[];
    const lowest = Math.min(...allValues);
    const highest = Math.max(...allValues);

    return {
        modifiedRanges: ranges,
        lowest,
        highest,
    };
};
