import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CircleButton from '../../../components/buttons/CircleButton';
import { dateFormats } from '../../../constants';
import { EndedSegment } from '../../../models/commonTypeScript';
import EndedMeasurementsTileRow from './EndedMeasurementsTileRow';

export type Props = {
    showLocation?: boolean;
    dateFormat: keyof typeof dateFormats;
    listElements: EndedSegment[];
    headerText: string;
    sortElement: string;
    sortByDate: boolean;
    width: number;
};

export const EndedMeasurementsTileComponent = ({
    showLocation,
    dateFormat,
    listElements,
    headerText,
    sortElement,
    sortByDate,
    width,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const onResize = (): void => {
        setIsExpanded(!isExpanded);
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Enter') {
            onResize();
        }
    };

    const numberInList = listElements.length;
    const sortedList = listElements.sort((element1, element2) => {
        if (sortByDate) {
            const date1 = moment(element1[sortElement]).valueOf();
            const date2 = moment(element2[sortElement]).valueOf();
            return date2 - date1;
        }
        return element1.name.toUpperCase().localeCompare(element2.name.toUpperCase());
    });

    const renderEndedMeasurements = (): React.ReactElement[] => {
        let list = sortedList;
        if (!isExpanded && numberInList > 4) {
            list = sortedList.slice(0, 4);
        }
        return list.map((row, index) => (
            <EndedMeasurementsTileRow
                key={`archived-${row.serialNumber}-${row.segmentId}`}
                serialNumber={row.serialNumber}
                showLocation={showLocation}
                segmentStart={row.segmentStart}
                segmentEnd={row.segmentEnd}
                segmentId={row.segmentId}
                name={row.name}
                locationName={row.locationName}
                type={row.deviceType}
                dateFormat={dateFormat}
                isLastRow={index === sortedList.length - 1}
            />
        ));
    };

    return (
        <div
            className={classNames('page-wrapper__column', {
                'page-wrapper__column--two-cols': width === 2,
                'page-wrapper__column--three-cols': width === 3,
            })}
        >
            <div
                className={classNames('tile-wrapper', 'tile-wrapper--no-hover', {
                    'tile-wrapper--full-height': isExpanded,
                })}
            >
                <div className="tile-header">
                    <div className={classNames({ 'tile-header__space-between': width > 1 })}>
                        <h2 className="tile-header__title--name">
                            {numberInList} {txt(headerText)}
                        </h2>
                        {numberInList > 3 && (
                            <CircleButton
                                onClick={onResize}
                                testAttr="expand-section-public-devices"
                                iconName={isExpanded ? 'expand_less' : 'expand_more'}
                                color="secondary"
                                loading={false}
                            />
                        )}
                    </div>
                </div>
                <div>
                    <table
                        className={classNames('tile-body', 'tile-body--table', {
                            'tile-body--full-height': isExpanded,
                        })}
                    >
                        <tbody className="table">{renderEndedMeasurements()}</tbody>
                    </table>
                    {!isExpanded && (
                            <div // eslint-disable-line
                            tabIndex={0}
                            role="button"
                            onClick={onResize}
                            onKeyUp={onKeyUp}
                            className="table table__overlay"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EndedMeasurementsTileComponent;
