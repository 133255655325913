import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { deletePropertyValue, updatePropertyValue } from 'commons/src/actions/SettingsActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { Store } from '../../../reducers';
import styles from './SpacePropertyEditOption.module.scss';

export type Props = {
    propertyId: string;
    option: { name: string; id: string };
    options: { name: string; id: string }[];
    setSelectedOptionId: (id: string) => void;
    activeOptionId?: string;
};

const SpacePropertyEditOption = ({
    option,
    options,
    setSelectedOptionId,
    activeOptionId,
    propertyId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch: Dispatch = useDispatch();

    enum ComponentState {
        DEFAULT,
        EDIT,
        DELETE,
    }

    const [name, setName] = useState<string>(option.name);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.DEFAULT);

    const deleteActionState: RequestState = useSelector((store: Store) => store.commonRequests.DELETE_PROPERTY_VALUE);
    const updateActionState: RequestState = useSelector((store: Store) => store.commonRequests.UPDATE_PROPERTY_VALUE);

    useEffect(() => {
        if (activeOptionId !== option.id) {
            setComponentState(ComponentState.DEFAULT);
            setName(option.name);
            setDisplayValidation(false);
        }
    }, [activeOptionId]);

    const prevUpdateLoading: React.MutableRefObject<boolean> = useRef(updateActionState.loading);
    useEffect((): void => {
        if (
            activeOptionId === option.id &&
            prevUpdateLoading.current &&
            !updateActionState.loading &&
            updateActionState.error === undefined
        ) {
            setComponentState(ComponentState.DEFAULT);
        }
        prevUpdateLoading.current = updateActionState.loading;
    }, [updateActionState.loading]);

    const onUpdateComponentState = (state: ComponentState): void => {
        setSelectedOptionId(option.id);
        setName(option.name);
        setComponentState(state);
    };

    const validInput = (): boolean => name.length > 0 && !options.some(prop => prop.name === name);

    const onUpdate = (): void => {
        if (name === option.name) {
            setDisplayValidation(false);
            setComponentState(ComponentState.DEFAULT);
        } else if (!validInput()) {
            setDisplayValidation(true);
        } else {
            setDisplayValidation(false);
            dispatch(updatePropertyValue(propertyId, option.id, name.trim()));
        }
    };

    const onDelete = (): void => {
        dispatch(deletePropertyValue(propertyId, option.id));
    };

    return (
        <div className={styles.body}>
            {componentState === ComponentState.DEFAULT && (
                <>
                    <div className={styles.name}>{option.name}</div>
                    <div className={styles.buttons}>
                        <TertiaryButton
                            size="medium"
                            noColor
                            onClick={(): void => onUpdateComponentState(ComponentState.EDIT)}
                            icon={<MaterialIcon name="edit" />}
                            testId="edit-button"
                        />
                        <TertiaryButton
                            size="medium"
                            alert
                            onClick={(): void => onUpdateComponentState(ComponentState.DELETE)}
                            icon={<MaterialIcon name="delete" />}
                            testId="delete-button"
                        />
                    </div>
                </>
            )}
            {componentState === ComponentState.EDIT && (
                <>
                    <div className={styles.input}>
                        <Input
                            type="text"
                            id={`option-${option.name}`}
                            isValid={validInput()}
                            validate={displayValidation && !validInput()}
                            maxLength={30}
                            autoComplete="off"
                            onChange={(e): void => setName(e.currentTarget.value)}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') onUpdate();
                            }}
                            currentValue={name}
                            noBottomMargin
                            hideLabel
                            autoFocus
                            testId="option-input"
                        />
                    </div>
                    <div className={styles.buttons}>
                        <PrimaryButton
                            onClick={onUpdate}
                            icon={<MaterialIcon name="check" />}
                            testId="confirm-update-button"
                            loading={updateActionState.loading && activeOptionId === option.id}
                        />
                        <TertiaryButton
                            onClick={(): void => onUpdateComponentState(ComponentState.DEFAULT)}
                            icon={<MaterialIcon name="close" />}
                            noColor
                        />
                    </div>
                </>
            )}
            {componentState === ComponentState.DELETE && (
                <>
                    <div className={styles.name}>
                        {txt('CustomProperty.DeletePropertyValue', { value: option.name })}
                    </div>
                    <div className={styles.buttons}>
                        <PrimaryButton
                            onClick={(): void => onDelete()}
                            alert
                            small
                            icon={<MaterialIcon name="delete" />}
                            testId="confirm-delete-button"
                            loading={deleteActionState.loading && activeOptionId === option.id}
                        />
                        <TertiaryButton
                            onClick={(): void => onUpdateComponentState(ComponentState.DEFAULT)}
                            icon={<MaterialIcon name="close" />}
                            noColor
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default SpacePropertyEditOption;
