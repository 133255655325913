import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { DeleteFloorplan, deleteFloorplan } from 'commons/src/actions/FloorPlanActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { FLOORPLAN_DELETED_FLOORPLAN, FLOORPLAN_SELECTED_FLOORPLAN } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import { IntercomAPI } from 'commons/src/components/Intercom';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { Role } from 'commons/src/models/commonEnums';
import { FloorPlanType } from 'commons/src/models/commonTypeScript';
import {
    setFloorplanModalVisible,
    SetFloorplanModalVisible,
    UpdateFloorName,
    updateFloorName,
} from '../../../actions/floorplanActions';
import { Store } from '../../../reducers';

type StateProps = {
    deleteFloorError: boolean;
    deleteFloorLoading: boolean;
    editNameLoading: boolean;
    loadingImage: boolean;
    userRole?: Role;
};

type ActionProps = {
    onDeleteFloorplan: (floorplanId: string, locationId: string) => void;
    editFloorName: (floorplanId: string, name: string, locationId: string) => void;
    setModalVisible: (visible: boolean) => void;
};

type ParentProps = {
    locationId: string;
    locationFloorplans: FloorPlanType[];
    floorId: string | undefined;
    onFloorChange: (floorplanId: string) => void;
};

type Props = StateProps & ActionProps & ParentProps;

export const FloorListComponent = (props: Props): React.ReactElement => {
    const {
        locationFloorplans,
        floorId,
        onFloorChange,
        onDeleteFloorplan,
        deleteFloorError,
        deleteFloorLoading,
        editFloorName,
        editNameLoading,
        locationId,
        userRole,
        loadingImage,
        setModalVisible,
    } = props;

    const allowedToEdit = userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editDeviceOrBuilding);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editingFloor, setEditingFloor] = useState<string | undefined>(undefined);
    const [editLoading, setEditLoading] = useState(false);
    const { t: txt } = useTranslation();

    const selectFloor = (floor: { id: string; inputValue: string }): void => {
        onFloorChange(floor.id);
        analyticsLogger(FLOORPLAN_SELECTED_FLOORPLAN, { pageType: PageType.Floorplan, floorplan: floor.inputValue });
    };

    const floors = locationFloorplans.map(floor => ({ id: floor.id, inputValue: floor.name }));
    const selectedFloor = floorId && locationFloorplans.find(floor => floor.id === floorId);
    const floorName = selectedFloor ? selectedFloor.name : 'UnknownFloor';
    const [newFloorName, setNewFloorName] = useState(floorName);

    const deleteFloor = (): void => {
        if (allowedToEdit) {
            IntercomAPI('trackEvent', 'delete-floorplan', {
                locationId,
                floorplanName: floorName,
                floorplanId: floorId,
            });
            if (floorId) onDeleteFloorplan(floorId, locationId);
            analyticsLogger(FLOORPLAN_DELETED_FLOORPLAN, { pageType: PageType.Floorplan });
        }
    };

    const editFloor = (): void => {
        if (allowedToEdit) {
            setEditingFloor(floorId);
            setNewFloorName(floorName);
        }
    };

    const onChangeFloorName = (e: SyntheticEvent<HTMLInputElement>): void => {
        if (allowedToEdit) {
            const { value } = e.currentTarget;
            setNewFloorName(value);
        }
    };

    const saveFloorName = (): void => {
        const updatedFloorName = newFloorName.trim();
        if (allowedToEdit && floorId && updatedFloorName.length > 0) {
            editFloorName(floorId, updatedFloorName, locationId);
            setEditLoading(true);
        }
    };

    useEffect((): void => {
        if (editLoading && !editNameLoading) {
            setEditLoading(false);
            setEditingFloor(undefined);
        }
    }, [editLoading, editNameLoading]);

    useEffect(() => {
        const editingFloorIsSelectedFloor = editingFloor === floorId;
        if (deleteModalOpen && (!editingFloor || !editingFloorIsSelectedFloor)) {
            setDeleteModalOpen(false);
            setEditingFloor(undefined);
        }
    }, [editingFloor, floorId]);

    const addFloorInit = (): void => setModalVisible(true);
    const userAllowedToAddFloorPlan =
        (userRole && userRoleAboveRequiredLevel(userRole, roleRestrictions.editDeviceOrBuilding)) || false;

    const description = (
        <p className="modal__content__description">
            {txt('FloorPlan.DeleteFloorPlanDescription', { name: floorName })}
        </p>
    );
    return (
        <div className="floorlist">
            {editingFloor ? (
                <div className="floorlist__list">
                    <Input
                        label="EditName"
                        id="EditNameInput"
                        type="text"
                        isValid={false}
                        onChange={onChangeFloorName}
                        validate={newFloorName.length === 0}
                        currentValue={newFloorName}
                        maxLength={50}
                    />
                    <PrimaryButton
                        title="Save"
                        onClick={saveFloorName}
                        loading={editNameLoading}
                        testAttr="edit-floor"
                    />
                    <PrimaryButton
                        title="Delete"
                        onClick={(): void => setDeleteModalOpen(true)}
                        testAttr="delete-floor"
                        alert
                    />
                </div>
            ) : (
                <div className="floorlist__list">
                    <Dropdown
                        id="FloorPlanSelector"
                        title="FloorPlan.SelectFloorPlan"
                        options={floors}
                        loading={false}
                        onSelect={selectFloor}
                        defaultOption="FloorPlan.SelectFloorPlan"
                        value={selectedFloor ? selectedFloor.name : null}
                        testAttr="floor-selector"
                        disabled={loadingImage}
                    />
                    {allowedToEdit && floorId && (
                        <PrimaryButton
                            disabled={loadingImage}
                            title="Edit"
                            onClick={editFloor}
                            testAttr="edit-floor"
                            testId="edit-floor"
                        />
                    )}
                </div>
            )}
            {deleteModalOpen && (
                <DeleteConfirmModal
                    title="FloorPlan.DeleteFloorPlan"
                    description={description}
                    onSubmit={deleteFloor}
                    onCancel={(): void => setDeleteModalOpen(false)}
                    onSubmitText="Delete"
                    onCancelText="Cancel"
                    loading={deleteFloorLoading}
                    error={deleteFloorError}
                    errorText="SomethingWentWrong"
                />
            )}
            {userAllowedToAddFloorPlan && (
                <div className="floorlist__button">
                    <PrimaryButton onClick={addFloorInit} title="FloorPlan.AddFloorplan" filled />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        floorplans: { deleteFloorLoading, deleteFloorError, loadingImage },
        requests: {
            UPDATE_FLOOR_NAME: { loading: editNameLoading },
        },
    } = store;
    return {
        deleteFloorError,
        deleteFloorLoading,
        editNameLoading,
        userRole: selectedGroup && selectedGroup.role,
        loadingImage,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onDeleteFloorplan: (floorplanId: string, locationId: string): DeleteFloorplan =>
        dispatch(deleteFloorplan(floorplanId, locationId)),
    editFloorName: (floorplanId: string, name: string, locationId: string): UpdateFloorName =>
        dispatch(updateFloorName(floorplanId, name, locationId)),
    setModalVisible: (visible: boolean): SetFloorplanModalVisible => dispatch(setFloorplanModalVisible(visible)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorListComponent);
