import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { copyToClipboard } from 'commons/src/commonFunctions';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ChangeSelectedSearchHeader from 'commons/src/components/menu/ChangeSelectedSearchHeader';
import { ResetConnection } from '../../../models/common';
import { Store } from '../../../reducers';

type StateProps = {
    resetConnections: ResetConnection[];
};

type PassedProps = {
    onClose: () => void;
};

export type Props = PassedProps & StateProps;

export const ResetConnectionsModalComponent = ({ onClose, resetConnections }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const [searchText, setSearchText] = useState<string>('');
    const [copiedConnectionId, setCopiedConnectionId] = useState<string>('');

    const filteredResetConnections: ResetConnection[] = resetConnections.filter(
        device =>
            device.serialNumber.includes(searchText.toLowerCase()) ||
            device.deviceName.toLowerCase().includes(searchText.toLowerCase())
    );

    const onCopyToClipboard = (e: SyntheticEvent<HTMLButtonElement>): void => {
        const { id } = e.currentTarget;
        copyToClipboard(id);
        setCopiedConnectionId(id);
    };

    const renderRows = (): React.ReactElement[] =>
        filteredResetConnections.map(device => {
            const isCopiedConnectionId = copiedConnectionId === device.resetConnectionId;
            return (
                <div key={device.resetConnectionId}>
                    <div className="flex modal__content--reset-connections__row">
                        <div className="modal__content--reset-connections__column-1">
                            <div className="text-paragraph text-bold">{txt('ResetConnection.SerialNumber')}</div>
                            <div className="text-paragraph text-medium">{txt(`${device.serialNumber}`)}</div>
                        </div>
                        <div className="modal__content--reset-connections__column-2">
                            <div className="text-paragraph text-bold">{txt('ResetConnection.DeviceName')}</div>
                            <div className="text-paragraph text-medium">{device.deviceName}</div>
                        </div>
                    </div>
                    <div className="modal__content--reset-connections__row">
                        <div className="text-paragraph text-bold">{txt('ResetConnection.ModelName')}</div>
                        <div className="text-paragraph text-medium">{txt(`${device.deviceType}`)}</div>
                    </div>
                    <div className="modal__content--reset-connections__row">
                        <div className="text-paragraph text-bold">{txt('ResetConnection.ConnectionId')}</div>
                        <div className="flex flex--align-center flex--spaced">
                            <span className="text-paragraph text-medium">{device.resetConnectionId}</span>
                            <span className="modal__content--reset-connections__clipboard">
                                <CircleButton
                                    id={device.resetConnectionId}
                                    onClick={onCopyToClipboard}
                                    testAttr="copy-connection-id"
                                    iconName="content_copy"
                                    color={isCopiedConnectionId ? 'confirmed' : 'tertiary'}
                                />
                                {isCopiedConnectionId && (
                                    <div className="text-small text-paragraph modal__content__body__fab-button-wrapper__text">
                                        {txt('Copied')}
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="inline-header-lined inline-header-lined--less-margin" />
                </div>
            );
        });

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--size-medium modal__content--reset-connections"
            overlayClassName="modal modal__overlay"
        >
            <div className="modal__content__sticky">
                <ChangeSelectedSearchHeader
                    setSearchText={setSearchText}
                    headerText="ResetConnection.ConnectionIds"
                    headerTextWithIcon={false}
                    displaySearch={resetConnections.length > 3}
                />
                <div className="modal__content__body">
                    {renderRows()}
                    {resetConnections.length === 0 && (
                        <p className="text-paragraph modal__content--reset-connections__center-text">
                            {txt('ResetConnection.NoConnections')}
                        </p>
                    )}
                </div>
                <div className="modal__content__buttons modal__content__buttons--sticky">
                    <PrimaryButton title="Cancel" onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildings: { resetConnections },
    } = state;

    return {
        resetConnections,
    };
};

export default connect(mapStateToProps)(ResetConnectionsModalComponent);
