import delay from '@redux-saga/delay-p';
import * as Sentry from '@sentry/browser';
import moment, { DurationInputArg1, Moment, DurationInputArg2 } from 'moment';
import {
    all,
    AllEffect,
    call,
    CallEffect,
    put,
    PutEffect,
    race,
    RaceEffect,
    select,
    SelectEffect,
    take,
    takeEvery,
    TakeEffect,
} from 'redux-saga/effects';
import {
    DeviceActionType,
    fetchDeviceSegmentError,
    fetchDeviceSegmentSuccess,
    getVirtualSensorDataSuccess,
    POLL_DEVICE_DATA,
    PollDeviceData,
    pollDeviceData,
    PollDeviceDataPayload,
    pollVirtualSensorData,
    STOP_POLL_DEVICE_DATA,
} from '../../actions/DeviceActions';
import { AddLocationSuccess } from '../../actions/LocationActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import fetchSegment, { fetchVirtualDeviceData } from '../../api/segment';
import { getSelectedGroupFromStorage } from '../../components/findUserType';
import { drawGraphTime, pollingDelayMs } from '../../constants';
import {
    DashboardSensorData,
    DeviceDataType,
    DeviceResponse,
    FullDeviceData,
    SelectedPeriod,
} from '../../models/commonTypeScript';
import {
    deviceData,
    getDevice,
    isLoggedIn,
    pollingDeviceInterval,
    virtualDeviceData,
} from '../../reducers/reducerShortcuts';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';
import createGraphArrays from './createGraphArrays';

export const setFetchInterval = (
    selectedInterval: SelectedPeriod,
    prevDeviceData: DashboardSensorData,
    isoTime: Moment = moment()
): { from: string; to: string; lastDataOlderThanResolutionDuration: boolean } => {
    const { number, period, resolutionDuration } = selectedInterval;

    const lastSegmentTime = prevDeviceData.sensorData
        ? Object.values(prevDeviceData.sensorData).reduce((fetchFromTime: number | undefined, sensorData) => {
              const sensorDataLength =
                  sensorData && sensorData[selectedInterval.name] && sensorData[selectedInterval.name].length;
              if (!sensorDataLength) return 0;
              const sensorDataEndTime = sensorData[selectedInterval.name][sensorDataLength - 1][0];
              return fetchFromTime === undefined || fetchFromTime > sensorDataEndTime
                  ? sensorDataEndTime
                  : fetchFromTime;
          }, 0)
        : 0;

    const lastSegmentTimeFormatted = moment.utc(lastSegmentTime).toISOString();

    const startOfPeriodISOTime = isoTime
        .clone()
        .subtract(number as DurationInputArg1, period as DurationInputArg2)
        .toISOString();
    const startOfPeriod = moment(startOfPeriodISOTime).subtract(resolutionDuration).toISOString();

    const fromISO = moment(lastSegmentTimeFormatted).isAfter(startOfPeriod) ? lastSegmentTimeFormatted : startOfPeriod;
    const from = fromISO.slice(0, fromISO.lastIndexOf('.'));
    const toISO = isoTime.clone().toISOString();
    const to = toISO.slice(0, toISO.lastIndexOf('.'));

    const fetchIfBeforeTime = selectedInterval.resolution
        ? moment(to).subtract(selectedInterval.resolutionDuration)
        : moment(to).subtract(5, 'minutes');

    const lastDataOlderThanResolutionDuration = moment.utc(from).isBefore(fetchIfBeforeTime);
    return { from, to, lastDataOlderThanResolutionDuration };
};

type PollDeviceDataSagaType = Generator<
    | CallEffect
    | CallEffect<DashboardSensorData>
    | SelectEffect
    | PutEffect<AddLocationSuccess>
    | PutEffect
    | void
    | string
    | undefined,
    void,
    DashboardSensorData &
        DeviceResponse & { device: FullDeviceData } & DeviceDataType &
        string & { from: string; to: string; lastDataOlderThanResolutionDuration: boolean }
>;
export function* pollDeviceDataSaga({
    payload,
}: PollDeviceData | { payload: PollDeviceDataPayload }): PollDeviceDataSagaType {
    const { serialNumber, selectedInterval, parent, extraParams } = payload;

    const selectedGroup = yield getSelectedGroupFromStorage()?.userGroupId;
    let loggedIn = yield select(isLoggedIn);
    let isInIntervalToFetch = true;
    let numberOfErrors = 0;
    while (loggedIn && isInIntervalToFetch && selectedGroup) {
        try {
            yield call(delay, pollingDelayMs);
            const prevDeviceData = yield select(deviceData, serialNumber);
            const prevDeviceInfo = yield select(getDevice, serialNumber);
            const fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData);
            if (fetchAttrs.lastDataOlderThanResolutionDuration) {
                const response = yield call(fetchSegment, serialNumber, {
                    from: fetchAttrs.from,
                    to: fetchAttrs.to,
                    resolution: selectedInterval.resolution,
                    parent,
                    ...extraParams,
                });

                yield put(
                    fetchDeviceSegmentSuccess(
                        createGraphArrays(response, selectedInterval, prevDeviceData, prevDeviceInfo),
                        serialNumber
                    )
                );
            }
            numberOfErrors = 0;
        } catch (error) {
            numberOfErrors += 1;
            const errorAsErrorType = toErrorType(error);
            yield put(fetchDeviceSegmentError(serialNumber, errorAsErrorType));
        } finally {
            if (numberOfErrors < 2) {
                const intervalToFetch = yield select(pollingDeviceInterval);
                isInIntervalToFetch = intervalToFetch === selectedInterval.name;
                loggedIn = yield select(isLoggedIn);
            } else {
                isInIntervalToFetch = false;
                if (Sentry) {
                    Sentry.captureEvent({
                        message: 'Abort polling of sensor data',
                        level: 'warning',
                        tags: {
                            serialNumber,
                            userGroupId: selectedGroup,
                        },
                    });
                }
            }
        }
    }
}

type FetchDeviceDataSagaType = Generator<
    | CallEffect
    | AllEffect<PutEffect>
    | CallEffect<DashboardSensorData>
    | SelectEffect
    | RaceEffect<CallEffect<PollDeviceDataSagaType> | TakeEffect>
    | PutEffect<AddLocationSuccess>
    | PutEffect
    | void,
    void,
    DashboardSensorData &
        DeviceResponse &
        DeviceDataType & { from: string; to: string; lastDataOlderThanResolutionDuration: boolean }
>;
export function* fetchDeviceDataSaga({ payload, withPolling }: PollDeviceData): FetchDeviceDataSagaType {
    const { serialNumber, selectedInterval, parent, extraParams } = payload;

    try {
        yield put(pollVirtualSensorData(payload));
        const prevDeviceData = yield select(deviceData, serialNumber);
        const fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData);
        const loggedIn = yield select(isLoggedIn);

        if (loggedIn && fetchAttrs.lastDataOlderThanResolutionDuration) {
            const prevDeviceInfo = yield select(getDevice, serialNumber);
            if (
                prevDeviceData.sensorData &&
                prevDeviceData.sensorData[Object.keys(prevDeviceData.sensorData)[0]][selectedInterval.name]
            ) {
                yield call(delay, drawGraphTime);
            }
            const response = yield call(fetchSegment, serialNumber, {
                from: fetchAttrs.from,
                to: fetchAttrs.to,
                resolution: selectedInterval.resolution,
                parent,
                ...extraParams,
            });

            if (response && response.childGroups) {
                yield all(
                    response.childGroups.map(childGroup =>
                        put(pollDeviceData({ serialNumber: childGroup, selectedInterval, parent: serialNumber }))
                    )
                );
            }
            yield put(
                fetchDeviceSegmentSuccess(
                    createGraphArrays(response, selectedInterval, prevDeviceData, prevDeviceInfo),
                    serialNumber
                )
            );
        }
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(fetchDeviceSegmentError(serialNumber, errorAsErrorType));
    } finally {
        if (withPolling) {
            yield race({
                task: call(pollDeviceDataSaga, { payload }),
                cancel: take(STOP_POLL_DEVICE_DATA),
            });
        }
    }
}

/*
export function* pollVirtualDeviceDataSaga({
    payload,
}: PollDeviceData | { payload: PollDeviceDataPayload }): PollDeviceDataSagaType {
    const { serialNumber, selectedInterval, parent, extraParams } = payload;

    const selectedGroup = yield getSelectedGroupFromStorage()?.userGroupId;
    let loggedIn = yield select(isLoggedIn);
    let isInIntervalToFetch = true;
    let numberOfErrors = 0;
    while (loggedIn && isInIntervalToFetch && selectedGroup) {
        try {
            yield call(delay, pollingDelayMs);
            const prevDeviceData = yield select(virtualDeviceData, serialNumber);
            const prevDeviceInfo = yield select(getDevice, serialNumber);
            const fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData);
            if (fetchAttrs.lastDataOlderThanResolutionDuration) {
                const response = yield call(fetchVirtualDeviceData, serialNumber, {
                    from: fetchAttrs.from,
                    to: fetchAttrs.to,
                    resolution: selectedInterval.resolution,
                    parent,
                    ...extraParams,
                });

                yield put(
                    getVirtualSensorDataSuccess(
                        createGraphArrays(response, selectedInterval, prevDeviceData, prevDeviceInfo),
                        serialNumber
                    )
                );
                yield put(requestSuccess(CommonRequestType.PollVirtualDeviceSensorData, RequestActionType.Success));
                numberOfErrors = 0;
            }
        } catch (error) {
            numberOfErrors += 1;
            const errorAsErrorType = toErrorType(error);
            yield put(
                requestError(errorAsErrorType, CommonRequestType.PollVirtualDeviceSensorData, RequestActionType.Error)
            );
        } finally {
            if (numberOfErrors < 2) {
                const intervalToFetch = yield select(pollingDeviceInterval);
                isInIntervalToFetch = intervalToFetch === selectedInterval.name;
                loggedIn = yield select(isLoggedIn);
            } else if (Sentry) {
                Sentry.captureEvent({
                    message: 'Abort polling of virtual sensor data',
                    level: 'warning',
                    tags: {
                        serialNumber,
                        userGroupId: selectedGroup,
                    },
                });
            }
        }
    }
} */

type FetchVirtualDeviceDataSagaType = Generator<
    | CallEffect
    | AllEffect<PutEffect>
    | CallEffect<DashboardSensorData>
    | SelectEffect
    | RaceEffect<CallEffect<PollDeviceDataSagaType> | TakeEffect>
    | PutEffect<AddLocationSuccess>
    | PutEffect
    | void,
    void,
    DashboardSensorData &
        DeviceResponse &
        DeviceDataType & { from: string; to: string; lastDataOlderThanResolutionDuration: boolean }
>;
export function* fetchVirtualDeviceDataSaga({ payload }: PollDeviceData): FetchVirtualDeviceDataSagaType {
    const { serialNumber, selectedInterval, parent, extraParams } = payload;

    try {
        const prevDeviceData = yield select(virtualDeviceData, serialNumber);
        const fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData);
        const loggedIn = yield select(isLoggedIn);

        if (loggedIn && fetchAttrs.lastDataOlderThanResolutionDuration) {
            const prevDeviceInfo = yield select(getDevice, serialNumber);
            if (
                prevDeviceData.sensorData &&
                prevDeviceData.sensorData[Object.keys(prevDeviceData.sensorData)[0]][selectedInterval.name]
            ) {
                yield call(delay, drawGraphTime);
            }
            const response = yield call(fetchVirtualDeviceData, serialNumber, {
                from: fetchAttrs.from,
                to: fetchAttrs.to,
                resolution: selectedInterval.resolution,
                parent,
                ...extraParams,
            });

            if (response && response.childGroups) {
                yield all(
                    response.childGroups.map(childGroup =>
                        put(pollVirtualSensorData({ serialNumber: childGroup, selectedInterval, parent: serialNumber }))
                    )
                );
            }
            yield put(
                getVirtualSensorDataSuccess(
                    createGraphArrays(response, selectedInterval, prevDeviceData, prevDeviceInfo),
                    serialNumber
                )
            );
        }
        yield put(requestSuccess(CommonRequestType.PollVirtualDeviceSensorData, RequestActionType.Success));
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(
            requestError(errorAsErrorType, CommonRequestType.PollVirtualDeviceSensorData, RequestActionType.Error)
        );
    } /* finally {
        yield race({
            task: call(pollVirtualDeviceDataSaga, { payload }),
            cancel: take(STOP_POLL_DEVICE_DATA),
        });
    } */
}

export default function* PollDeviceDataSaga(): Generator {
    yield all([
        takeEvery(POLL_DEVICE_DATA, fetchDeviceDataSaga),
        takeEvery(DeviceActionType.PollVirtualDeviceSensorData, fetchVirtualDeviceDataSaga),
    ]);
}
