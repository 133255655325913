import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useTranslation } from 'react-i18next';
import { PaginationMetadata } from './context/usePagination';
import useMaterialTableContext from './hooks/useMaterialTableContext';
import styles from './MaterialTablePagination.module.scss';

type PaginationProps = {
    type?: string;
};

const MaterialTablePagination = ({ type }: PaginationProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { paginationMetadata, handlePaginationChange, selectedPage, totalLength, isReady, pagination } =
        useMaterialTableContext();

    const defaultPaginationMetadata: PaginationMetadata = {
        pagesCount: pagination ? Math.ceil(totalLength / pagination) : 1,
        pageFrom: 0,
        pageTo: pagination ?? 0,
    };

    const { pagesCount, pageFrom, pageTo } = isReady ? paginationMetadata : defaultPaginationMetadata;

    if (pagesCount <= 1) return <div />;
    return (
        <footer className={styles.paginationFooter} data-testid="materialTable-pagination-wrapper">
            <span className={styles.paginationSummary} data-testid="materialTable-summary">
                {`${pageFrom + 1}-${pageTo} of ${totalLength} ${type ?? txt('DevicesLowerCase')}`}
            </span>
            <Pagination
                disabled={!isReady}
                className={styles.paginationWrapper}
                count={pagesCount}
                page={selectedPage}
                onChange={(_, page): void => {
                    handlePaginationChange(page);
                }}
                renderItem={(item): React.ReactNode => <PaginationItem {...item} />}
            />
        </footer>
    );
};

export default MaterialTablePagination;
