import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapDeviceType } from '../../../commonFunctions';
import BatteryIcon from '../../../components/device/BatteryIcon';
import Calibrating from '../../../components/sensors/Calibrating';
import RssiSignalStrength from '../../../components/sensors/RssiSignalStrength';
import { RelayDevice } from '../../../constants';
import { DeviceTypeNames } from '../../../models/commonEnums';
import { AnyDeviceType } from '../../../models/commonTypeScript';

export type Props = {
    batteryPercentage?: number;
    rssi?: number;
    deviceType: string;
    segmentStart: string;
    relayDevice: RelayDevice;
    isHubConnectionLost?: boolean;
};

const DeviceListStatusBlock = ({
    segmentStart,
    deviceType,
    rssi,
    batteryPercentage,
    relayDevice,
    isHubConnectionLost,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const displayBatteryPercentage = batteryPercentage !== undefined && batteryPercentage > 0;
    const displayRssi = relayDevice === DeviceTypeNames.hub && !Number.isNaN(Number(rssi));

    return (
        <div className="tile__status">
            <div className="tile-header__info">
                <div className="tile-header__info--chip">{txt(mapDeviceType(deviceType as AnyDeviceType))}</div>
                <div className="battery-container">
                    {displayBatteryPercentage && (
                        <span>
                            <BatteryIcon
                                batteryPercentage={batteryPercentage}
                                isHubConnectionLost={isHubConnectionLost}
                            />
                        </span>
                    )}
                    {displayRssi && (
                        <RssiSignalStrength
                            isHubConnectionLost={isHubConnectionLost}
                            rssi={rssi}
                            inverted={false}
                            displayDescription={false}
                        />
                    )}
                </div>
                <Calibrating segmentStart={segmentStart} />
            </div>
        </div>
    );
};

DeviceListStatusBlock.defaultProps = {
    batteryPercentage: undefined,
    rssi: undefined,
    isHubConnectionLost: undefined,
};

export default DeviceListStatusBlock;
