import React, { SyntheticEvent, useEffect, useState } from 'react';
import { RadioGroup } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import { RadioButton } from 'commons/src/components/input/Radio';
import LabeledText from 'commons/src/components/LabeledText';
import { getOrganizationMembers } from '../../../actions/organizationMemberActions';
import { integrationTypes, thirdPartyChannels } from '../../../constants';
import { ChannelDetails, Member, ThirdPartyIntegration } from '../../../models/common';
import { Store } from '../../../reducers';
import IntegrationLogo from '../IntegrationLogo';
import EmailSelector from './EmailSelector';

type OptionSelector = { id: string; inputValue: string };

type StateProps = {
    thirdPartyIntegrations: ThirdPartyIntegration[];
    members: Member[];
};

type ParentProps = {
    onNext: () => void;
    channel: OptionSelector;
    selectedIntegration: string;
    name: string;
    onUpdate: (channelDetails: ChannelDetails) => void;
    editingAlert: boolean;
    emails: string[];
};
export type Props = ParentProps & StateProps;

export const SetChannelComponent = (props: Props): React.ReactElement => {
    const {
        thirdPartyIntegrations,
        channel,
        selectedIntegration,
        name,
        onNext,
        onUpdate,
        editingAlert,
        emails,
        members,
    } = props;

    const [alertName, setAlertName] = useState(name);
    const [selectedIntegrationType, setSelectedIntegrationType] = useState(selectedIntegration);
    const [selectedChannel, setSelectedChannel] = useState(channel);
    const [selectedEmails, setSelectedEmails] = useState<string[]>(emails);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedIntegrationType === thirdPartyChannels.EMAIL && members.length === 0) {
            dispatch(getOrganizationMembers());
        }
    }, [selectedIntegrationType]);

    const { t: txt } = useTranslation();

    useEffect(() => {
        onUpdate({
            name: alertName,
            integrationChannel: selectedChannel,
            integrationType: selectedIntegrationType,
            emails: selectedEmails,
        });
    }, [alertName, selectedIntegrationType, selectedChannel, selectedEmails]);

    const filteredChannel = (integrationType: string): { id: string; inputValue: string }[] =>
        thirdPartyIntegrations
            .filter(integration => integration.type === integrationType)
            .map(integration => ({ id: integration.id, inputValue: integration.name }));

    const selectIntegrationType = (e: SyntheticEvent<HTMLInputElement>): void => {
        const integrationType = e.currentTarget.value;
        setSelectedIntegrationType(integrationType);
        const sortedChannels = filteredChannel(integrationType).sort((a: OptionSelector, b: OptionSelector) =>
            a.inputValue.localeCompare(b.inputValue)
        );
        if (sortedChannels.length === 0) {
            setSelectedChannel({ id: 'email', inputValue: 'Email' });
        } else setSelectedChannel(sortedChannels[0]);
    };

    const onSelectChannelDropdown = (selected: OptionSelector): void => {
        setSelectedChannel(selected);
    };

    const hasInvalidFields =
        selectedIntegrationType === thirdPartyChannels.EMAIL
            ? alertName === '' || selectedEmails.length === 0
            : selectedChannel.id === '' || selectedIntegrationType === '' || alertName === '';

    const saveChannel = (): void => {
        const valid = !hasInvalidFields;
        if (valid) {
            onNext();
        }
    };

    const updateNameInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setAlertName(value);
    };

    const onUpdateEmails = ({ id: email, changeAll }: { id: string; changeAll: boolean }): void => {
        if (changeAll) {
            if (members.length === selectedEmails.length) {
                setSelectedEmails([]);
            } else {
                setSelectedEmails(members.map(m => m.email || ''));
            }
        } else {
            const newSelectedRecipients = selectedEmails.includes(email)
                ? selectedEmails.filter(m => m !== email)
                : [...selectedEmails, email];
            setSelectedEmails(newSelectedRecipients);
        }
    };

    const renderEmailCheckbox = (): React.ReactElement => (
        <div
            key={thirdPartyChannels.EMAIL}
            className={classNames('form__row--padded-small', {
                'form__row--padded-medium': selectedIntegrationType !== thirdPartyChannels.EMAIL,
            })}
        >
            <div className="form__field form__field--single-width notification-alert__checkbox-field notification-alert__email">
                <RadioButton
                    value={thirdPartyChannels.EMAIL}
                    onChange={selectIntegrationType}
                    testId={`checkbox-${thirdPartyChannels.EMAIL.toLowerCase()}`}
                />
                <IntegrationLogo integrationType={thirdPartyChannels.EMAIL} />
            </div>
            <div className="notification-alert__indented-row">
                <p className="notification-alert__indented-row__paragraph">
                    {txt('NotificationAlerts.EmailTriggerDescription')}
                </p>
                {selectedIntegrationType === thirdPartyChannels.EMAIL && (
                    <EmailSelector onUpdateEmails={onUpdateEmails} emails={selectedEmails} members={members} />
                )}
            </div>
        </div>
    );

    const renderServiceCheckbox = (): (React.ReactElement | null)[] =>
        Object.keys(integrationTypes).map(type => {
            if (thirdPartyIntegrations.some(e => e.type === type)) {
                return (
                    <div key={type} className="form__row--padded-medium">
                        <div className="form__field form__field--single-width notification-alert__checkbox-field">
                            <RadioButton
                                value={type}
                                onChange={selectIntegrationType}
                                testId={`checkbox-${type.toLowerCase()}`}
                            />
                            <span className="notification-alert">
                                <IntegrationLogo integrationType={type} />
                            </span>
                        </div>
                        <div className="notification-alert__indented-row">
                            <p className="notification-alert__indented-row__paragraph">
                                {txt('NotificationAlerts.SelectAIntegration', {
                                    ThirdParty: txt(`ThirdParty.${type}`),
                                })}
                            </p>
                            {selectedIntegrationType === type && (
                                <div className="notification-alert__indented-row__dropdown">
                                    <Dropdown
                                        id="channelOptionsSelector"
                                        title=""
                                        options={filteredChannel(type)}
                                        loading={false}
                                        validate={false}
                                        defaultOption="Select"
                                        value={selectedChannel.inputValue}
                                        onSelect={onSelectChannelDropdown}
                                        testAttr="integration-dropdown"
                                        testId="integration-dropdown"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
            return null;
        });

    return (
        <div className="settings-details-container">
            <div className="form notification-alert__form">
                {!editingAlert && (
                    <h3 className="form__row--padded-medium">{txt('NotificationAlerts.ChannelSectionDescription')}</h3>
                )}
                <div className="form__row">
                    <div className="form__field form__field--single-width third-party__padding--medium">
                        <Input
                            type="text"
                            id="alert-name"
                            testAttr="alert-name"
                            label="NotificationAlerts.AlertName"
                            validate={false}
                            maxLength={25}
                            currentValue={alertName}
                            onChange={updateNameInput}
                            testId="input-alert-name"
                        />
                    </div>
                </div>
                {editingAlert ? (
                    <>
                        <div className="form__row">
                            <div className="form__field form__field--single-width">
                                <div className="full-width">
                                    <IntegrationLogo integrationType={selectedIntegrationType} />
                                    {selectedIntegrationType !== thirdPartyChannels.EMAIL && (
                                        <LabeledText
                                            label="NotificationAlerts.SelectedChannel"
                                            value={selectedChannel.inputValue}
                                            id="SelectedChannel"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        {selectedIntegrationType === thirdPartyChannels.EMAIL && (
                            <div className="form__row">
                                <EmailSelector
                                    onUpdateEmails={onUpdateEmails}
                                    emails={selectedEmails}
                                    members={members}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <RadioGroup row={false} name="integrationType" value={selectedIntegrationType}>
                            {renderEmailCheckbox()}
                            {renderServiceCheckbox()}
                        </RadioGroup>
                        <div className="form__button-container">
                            <PrimaryButton
                                id="submit"
                                type="button"
                                title="Next"
                                filled
                                disabled={hasInvalidFields}
                                onClick={saveChannel}
                                testAttr="save-integration-channel"
                                testId="set-channel-next"
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { thirdPartyIntegrations },
        settings: { members },
    } = state;

    return { thirdPartyIntegrations, members };
};

export default connect(mapStateToProps)(SetChannelComponent);
