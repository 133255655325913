import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSegmentDetails } from '../../../../api/devices';
import Error from '../../../../components/errorComponents/Error';
import { pageNotFoundUrl } from '../../../../components/errorComponents/PageNotFound';
import PageHeader from '../../../../components/headers/PageHeader';
import SubHeader from '../../../../components/headers/SubHeader';
import { mediumFormLoader } from '../../../../components/placeholder';
import { paths } from '../../../../constants';
import { ButtonColor, RequiredRoleLevel } from '../../../../models/commonEnums';
import { DeviceType, DeviceWithKeyInfo } from '../../../../models/commonTypeScript';
import { ActionButton } from '../../../../models/menuModels';
import { Store } from '../../../../reducers';
import RadonReportForm from './RadonReportForm';

type StateProps = {
    settingsLoading: boolean;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    error: boolean;
};

type Props = StateProps;

export const GenerateRadonReportPageComponent = ({
    error,
    settingsLoading,
    devicesWithKeyInfo,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { serialNumber, ...params } = useParams() as { serialNumber: string; segmentId?: string };
    const navigate = useNavigate();
    const deviceKeyInfo = devicesWithKeyInfo[serialNumber];
    const ongoingSegmentId = (deviceKeyInfo && deviceKeyInfo.segmentId) || 'latest';
    const isEndedSegment = !!params.segmentId;
    const segmentId = params.segmentId || ongoingSegmentId;
    const [selectedSegment, setSelectedSegment] = useState<DeviceType | undefined>(undefined);
    const [selectedSegmentError, setSelectedSegmentError] = useState(false);

    const getMeasurements = useCallback(async () => {
        const response = await getSegmentDetails(serialNumber, segmentId).catch(err => {
            setSelectedSegmentError(err);
        });
        if (response) {
            setSelectedSegment(response);
        }
    }, []);

    useEffect(() => {
        getMeasurements().catch(err => {
            setSelectedSegmentError(err);
        });
        if (!segmentId || !serialNumber)
            navigate(pageNotFoundUrl('segmentIdOrSerialNumberNotDefined', { serialNumber, segmentId }));
    }, []);

    const goBackToDevice = (): void => {
        if (isEndedSegment) {
            navigate(`/${paths.devices}/${serialNumber}/${segmentId}`);
        } else navigate(`/${paths.devices}/${serialNumber}`);
    };

    if (error || selectedSegmentError) {
        return <Error />;
    }
    const actionButtons: ActionButton[] = [
        {
            onClick: goBackToDevice,
            testAttr: 'back-to-device',
            id: 'backToDevice',
            color: ButtonColor.secondary,
            title: txt('Close'),
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
        },
    ];

    return (
        <div>
            <PageHeader title={txt('RadonReport')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!settingsLoading && !!selectedSegment} customPlaceholder={mediumFormLoader}>
                <RadonReportForm serialNumber={serialNumber} segmentId={segmentId} selectedSegment={selectedSegment} />
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { loading: settingsLoading },
        locations: { error: locationError },
        devices: { devicesWithKeyInfo, loading },
        app: { error: appError },
    } = state;
    return {
        settingsLoading: settingsLoading || loading,
        devicesWithKeyInfo,
        error: locationError || appError,
    };
};

export default connect(mapStateToProps)(GenerateRadonReportPageComponent);
