import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import { BreachType } from '../../../models/buildingModels';
import { SensorBreachThresholds } from '../../../models/common';
import { EmailSummarySensor, EmailSummarySensorRule } from '../../../models/emailSummaryModels';
import ThresholdInsightRuleWithCheckbox from './ThresholdInsightRuleWithCheckbox';

type EmailSummaryRulesSectionProps = {
    thresholds: SensorBreachThresholds;
    setRules: (sensor: string, options: EmailSummarySensorRule[], enabled: boolean) => void;
    selectUnselectSensor: (sensor: string, checked: boolean) => void;
    rules: { [sensorType: string]: EmailSummarySensor };
    onSave: () => void;
    buttonLoading: boolean;
    buttonDisabled: boolean;
};

const EmailSummaryRulesSectionComponent = (props: EmailSummaryRulesSectionProps): React.ReactElement => {
    const { thresholds, buttonLoading, onSave, rules, buttonDisabled, selectUnselectSensor, setRules } = props;

    const { t: txt } = useTranslation();

    const renderThresholds = (): React.ReactElement => (
        <>
            {Object.entries(thresholds).map(([sensorType, sensorThresholds]) => {
                const selectedRule = Object.values(rules).find(rule => rule.sensor === sensorType);

                const selectedHighThreshold = selectedRule?.rules?.find(rule => rule.type === BreachType.over) || {
                    type: BreachType.over,
                    value: sensorThresholds.over[0],
                };
                const selectedLowThreshold = selectedRule?.rules?.find(rule => rule.type === BreachType.under);

                const onSetHighThresholdRule = (option: Option): void => {
                    const optionAsRule = { type: BreachType.over, value: parseInt(option.id, 10) };
                    setRules(
                        sensorType,
                        selectedLowThreshold ? [optionAsRule, selectedLowThreshold] : [optionAsRule],
                        true
                    );
                };
                const onSetLowThresholdRule = (option: Option): void => {
                    if (selectedHighThreshold) {
                        setRules(
                            sensorType,
                            [selectedHighThreshold, { type: BreachType.under, value: parseInt(option.id, 10) }],
                            true
                        );
                    }
                };

                return (
                    <div className="form__row margin-20-bottom" key={sensorType}>
                        <ThresholdInsightRuleWithCheckbox
                            sensor={sensorType}
                            unit={sensorThresholds.unit as string}
                            thresholds={sensorThresholds}
                            selectedHighThreshold={selectedHighThreshold}
                            selectedLowThreshold={selectedLowThreshold}
                            setSelectedHighThreshold={onSetHighThresholdRule}
                            setSelectedLowThreshold={onSetLowThresholdRule}
                            selectUnselectSensor={selectUnselectSensor}
                            enabled={selectedRule?.checked || false}
                        />
                    </div>
                );
            })}
        </>
    );

    return (
        <div className="settings-details-container">
            <div className="form small-padding-bottom">
                <p className="text-medium text-paragraph">{txt('EmailSummary.RulesDescription')}</p>
                <h3 className="text-large text-paragraph text-bold">{txt('EmailSummary.SelectSensors')}</h3>
                {renderThresholds()}
                <div className="form__button-container form__button-container--margin-top-50">
                    <PrimaryButton
                        id="submit"
                        type="button"
                        title="Save"
                        filled
                        loading={buttonLoading}
                        disabled={buttonDisabled}
                        onClick={onSave}
                        testId="save-email-summary"
                    />
                </div>
            </div>
        </div>
    );
};

export default EmailSummaryRulesSectionComponent;
