import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ErrorType, FloorPlanType } from 'commons/src/models/commonTypeScript';
import {
    FetchFloorplanImage,
    fetchFloorplanImage,
    fetchLocationFloorplans,
    SetActiveFloorPlan,
    setActiveFloorPlan,
} from '../../actions/floorplanActions';
import { Store } from '../../reducers';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';
import { FloorplanErrors } from '../../reducers/Floorplans';
import FloorList from './floorList/FloorList';
import FloorMap from './floorMap/FloorMap';

type ParentProps = {
    locationId: string;
};

type ActionProps = {
    onFetchFloorplanImage: (locationId: string, floorplanId: string) => void;
    setActiveFloor: (floorId: string | undefined) => void;
};

type StateProps = {
    floorplans: { [locationId: string]: FloorPlanType[] };
    error: boolean | string;
    images: { [floorId: string]: string };
    activeFloorPlanId: string | undefined;
    loadingImage: boolean;
    fetchFloorPlansError?: ErrorType;
    loadingFloorPlans: boolean;
};

type Props = StateProps & ParentProps & ActionProps;

const LoadingPlaceholder = (
    <div>
        <div className="floorplan__map">
            <div className="floormap">
                <div className="toolbar" />
                <div className="floormap__map floormap__map--border" />
            </div>
        </div>
    </div>
);

export const FloorplanFeature = ({
    onFetchFloorplanImage,
    floorplans,
    locationId,
    activeFloorPlanId,
    setActiveFloor,
    error,
    images,
    loadingImage,
    fetchFloorPlansError,
    loadingFloorPlans,
}: Props): React.ReactElement => {
    const [locationFloorplans, setLocationFloorplans] = useState<FloorPlanType[]>([]);
    const { t: txt } = useTranslation();
    const dispatch: Dispatch = useDispatch();

    useEffect(() => {
        if (activeFloorPlanId && !images[activeFloorPlanId] && !loadingImage) {
            onFetchFloorplanImage(locationId, activeFloorPlanId);
        }
    }, [activeFloorPlanId, loadingImage]);

    const setActiveFloorId = (activeFloorId: string | undefined): void => {
        setActiveFloor(activeFloorId);
    };

    useEffect(() => {
        dispatch(fetchLocationFloorplans(locationId));
    }, []);

    useEffect(() => {
        const floorplansForLocation = floorplans[locationId] || [];
        setLocationFloorplans(floorplansForLocation);
        const activeFloorExists =
            activeFloorPlanId && floorplansForLocation.find(floor => floor.id === activeFloorPlanId);
        if (activeFloorPlanId === undefined || activeFloorExists === undefined) {
            const sortedFloorPlans = floorplansForLocation.sort((a, b) => a.name.localeCompare(b.name));
            const activeFloorId = sortedFloorPlans.length > 0 ? sortedFloorPlans[0].id : undefined;
            setActiveFloor(activeFloorId);
        }
    }, [floorplans, locationId]);

    if (error === FloorplanErrors.FETCH_FLOORPLAN_IMAGE_ERROR || fetchFloorPlansError) {
        return (
            <div className="floorplan container">
                <h2 className="settings__header">{txt('FloorPlan.Floorplans')}</h2>
                <ResponseBox text={fetchFloorPlansError?.error || 'SomethingWentWrong'} />{' '}
            </div>
        );
    }

    const noFloorMapView = (): React.ReactElement => (
        <div className="floorplan__map">
            <div className="floormap">
                <div className="toolbar" />
                <div className="floormap__map floormap__map--border">
                    <div className="floorplan__border-box__no-content">
                        <MaterialIcon name="map" />
                        <h2 className="floorplan__border-box__no-content__header">{txt('FloorPlan.NoFloorAdded')}</h2>
                    </div>
                </div>
            </div>
        </div>
    );

    const activeFloor = locationFloorplans.find(floor => floor.id === activeFloorPlanId);
    const image = activeFloor ? images[activeFloor.id] : undefined;
    return (
        <ReactPlaceholder
            ready={!(loadingFloorPlans && floorplans[locationId]?.length === 0)}
            customPlaceholder={mediumFormLoader}
        >
            <div className="floorplan container">
                <h2 className="settings__header">{txt('FloorPlan.Floorplans')}</h2>
                <div className="building-card building-card__groups building-card--no-max-height">
                    <div className="container small-padding-top">
                        <FloorList
                            locationId={locationId}
                            locationFloorplans={locationFloorplans}
                            onFloorChange={setActiveFloorId}
                            floorId={activeFloor && activeFloor.id}
                        />
                    </div>
                    <ReactPlaceholder ready={!loadingImage} customPlaceholder={LoadingPlaceholder}>
                        {activeFloor && image ? (
                            <div className="floorplan__map">
                                <FloorMap floorplanImage={image} floor={activeFloor} locationId={locationId} />
                            </div>
                        ) : (
                            noFloorMapView()
                        )}
                    </ReactPlaceholder>
                </div>
            </div>
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        floorplans: { loadingImage, error, images, activeFloorPlanId, floorplans },
        requests: {
            [RequestType.FetchLocationFloorplans]: { error: floorplansError, loading: loadingFloorPlans },
        },
    } = state;
    return {
        fetchFloorPlansError: floorplansError,
        error,
        images,
        activeFloorPlanId,
        floorplans,
        loadingImage,
        loadingFloorPlans,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchFloorplanImage: (locationId: string, floorplanId: string): FetchFloorplanImage =>
        dispatch(fetchFloorplanImage(locationId, floorplanId)),
    setActiveFloor: (floorId: string | undefined): SetActiveFloorPlan => dispatch(setActiveFloorPlan(floorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorplanFeature);
