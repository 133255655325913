import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createPropertyValue } from 'commons/src/actions/SettingsActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { PredefinedProperty, PropertyType } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { Store } from '../../../reducers';
import { SpacePropertyEditState } from './SpacePropertyEditCommon';
import styles from './SpacePropertyEditNewOption.module.scss';

type Props = {
    property: { id: string; data: SpaceProperty };
    activeSection?: SpacePropertyEditState;
    setActiveSection: (section: SpacePropertyEditState) => void;
};

const SpacePropertyEditNewOption = ({ property, activeSection, setActiveSection }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch: Dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [displayValidation, setDisplayValidation] = useState(false);

    const validInput = (): boolean =>
        name.length > 0 && !(property.data.value?.options ?? []).some(prop => prop.name === name);

    const createActionState: RequestState = useSelector((store: Store) => store.commonRequests.CREATE_PROPERTY_VALUE);

    const prevLoading: React.MutableRefObject<boolean> = useRef(createActionState.loading);
    useEffect((): void => {
        if (prevLoading.current && !createActionState.loading && createActionState.error === undefined) {
            setIsEditing(false);
            setName('');
        }
        prevLoading.current = createActionState.loading;
    }, [createActionState.loading]);

    const onCreate = (): void => {
        const isValid: boolean = validInput();
        setDisplayValidation(!isValid);
        if (isValid) {
            dispatch(createPropertyValue(property.id, name.trim(), PropertyType.Selection));
        }
    };

    useEffect(() => {
        if (activeSection !== SpacePropertyEditState.CREATE_OPTION) {
            setIsEditing(false);
            setName('');
            setDisplayValidation(false);
        }
    }, [activeSection]);

    const onSetIsEditing = (): void => {
        setIsEditing(true);
        setActiveSection(SpacePropertyEditState.CREATE_OPTION);
    };

    return (
        <div className={styles.body}>
            {isEditing ? (
                <>
                    <div className={styles.input}>
                        <Input
                            type="text"
                            id="option-input"
                            isValid={validInput()}
                            validate={displayValidation && !validInput()}
                            maxLength={30}
                            autoComplete="off"
                            onChange={(e): void => setName(e.currentTarget.value)}
                            onKeyUp={(e): void => {
                                if (e.key === 'Enter') onCreate();
                            }}
                            currentValue={name}
                            noBottomMargin
                            hideLabel
                            autoFocus
                            testId="option-input"
                        />
                    </div>
                    <PrimaryButton
                        onClick={onCreate}
                        icon={<MaterialIcon name="check" />}
                        loading={createActionState.loading}
                        testId="create-button"
                    />
                    <TertiaryButton
                        onClick={(): void => setIsEditing(false)}
                        icon={<MaterialIcon name="close" />}
                        noColor
                    />
                </>
            ) : (
                <TertiaryButton
                    onClick={(): void => onSetIsEditing()}
                    size="medium"
                    testId="edit-button"
                    icon={<MaterialIcon name="add" />}
                    title={txt('CustomProperty.AddType', {
                        param:
                            property.data.predefinedType === PredefinedProperty.Custom
                                ? property.data.propertyName
                                : txt(`PredefinedProperties.${property.data.predefinedType}`),
                    })}
                />
            )}
        </div>
    );
};

export default SpacePropertyEditNewOption;
