import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { paths, integrationTypeLogos } from '../../../constants';
import { ThirdPartyAddedIntegration, ThirdPartyMappedLocations } from '../../../models/common';
import MappedTile from './MappedTile';

export type Props = {
    integration: ThirdPartyAddedIntegration | undefined;
    onClose: () => void;
    mappedLocations: ThirdPartyMappedLocations[] | undefined;
};

export const AddThirdPartyIntegrationFinishedViewComponent = (props: Props): React.ReactElement | null => {
    const { t: txt } = useTranslation();
    const { integration, onClose, mappedLocations } = props;
    if (!integration) return null;

    const viewDescription = txt('ThirdParty.AddIntegrationCompleteDescription', {
        alerts: txt('NotificationAlerts.Alerts'),
    });
    const notificationTranslation = txt('NotificationAlerts.Alerts');
    const [textPart1, textPart2] = viewDescription.split(notificationTranslation);

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <div className="form form__wide-container">
                <h2>
                    {txt('ThirdParty.AddIntegrationComplete', { integration: txt(`ThirdParty.${integration.type}`) })}
                </h2>
                <p className="text-large third-party__padding--large">
                    <span>{textPart1}</span>
                    <Link className="third-party--link" to={`/${paths.alerts}`}>
                        {notificationTranslation}
                    </Link>
                    <span>{textPart2}</span>
                </p>
                <div className="third-party__img-container centered">
                    <img
                        src={integrationTypeLogos(integration.type)}
                        className="third-party__img-container__img"
                        alt={txt('ThirdParty.IntegrationTypeLogo', {
                            type: txt(`ThirdParty.${integration.type}`),
                        })}
                    />
                </div>
                <div className="centered third-party__padding--medium">
                    <span>{[txt('Name'), ': ', integration.name]}</span>
                </div>
                {mappedLocations && mappedLocations.length > 0 && (
                    <>
                        <h3>{txt('ThirdParty.LocationMappings')}</h3>
                        {mappedLocations.map(option => (
                            <MappedTile mapping={option} displayCircle={false} key={option.id} />
                        ))}
                    </>
                )}
                <div
                    className={classNames('form__row form__button-container third-party__button-margin--medium', {
                        'third-party__button-margin--large': mappedLocations && mappedLocations.length === 0,
                    })}
                >
                    <PrimaryButton
                        type="submit"
                        title="Close"
                        filled
                        onClick={onClose}
                        loading={false}
                        testAttr="close-view"
                    />
                </div>
            </div>
        </div>
    );
};

export default AddThirdPartyIntegrationFinishedViewComponent;
