import { takeEvery, call, put } from 'redux-saga/effects';
import { fetchDashboard } from '../../actions/DashboardActions';
import { renameSegmentSuccess, RenameSegment, DeviceActionType } from '../../actions/DeviceActions';
import { RequestActionType, requestError, requestSuccess } from '../../actions/requestActions';
import { updateSegment } from '../../api/segment';
import { CommonRequestType } from '../../reducers/requestReducer';
import { toErrorType } from '../isErrorType';

export function* renameSegmentSaga({ segmentId, serialNumber, name }: RenameSegment): Generator {
    try {
        yield call(updateSegment, serialNumber, segmentId, name);
        yield put(renameSegmentSuccess(serialNumber, segmentId, name));
        yield put(requestSuccess(CommonRequestType.RenameSegment, RequestActionType.Success));
        yield put(fetchDashboard());
    } catch (error) {
        const errorAsErrorType = toErrorType(error);
        yield put(requestError(errorAsErrorType, CommonRequestType.RenameSegment, RequestActionType.Error));
    }
}

export default function* RenameSegmentSaga(): Generator {
    yield takeEvery(DeviceActionType.RenameSegmentInit, renameSegmentSaga);
}
