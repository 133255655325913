import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { analyticsLogger, PageType } from '../../../analytics';
import { DASHBOARD_CLICKED_TILE } from '../../../analytics/AnalyticsEvents';
import {
    getDotColor,
    getValidLanguageTagForMoment,
    maxTileSensors,
    sortSensors,
    tileSensorOrder,
} from '../../../commonFunctions';
import { userIsHbs } from '../../../components/findUserType';
import { SensorComponent } from '../../../components/sensors/SensorComponent';
import NoConnection from '../../../components/tile/NoConnection';
import NoDataTileBody from '../../../components/tile/NoDataTileBody';
import { DashboardVisibility, SensorTypes } from '../../../models/commonEnums';
import { CurrentSensorValuesType, DeviceType } from '../../../models/commonTypeScript';
import TileHeader from './TileHeader';

export type ParentProps = {
    showLocation?: boolean;
    visibility?: DashboardVisibility;
    menuProps?: { tileId: string; tileRef: string; removeTile: (tileId: string, tileRef: string) => void };
    isDragging?: boolean;
    device: DeviceType;
    url?: string;
};

type Props = ParentProps;

export const DeviceTileComponent = ({
    menuProps,
    showLocation,
    isDragging,
    device,
    visibility,
    url,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { type, serialNumber, currentSensorValues, isHubConnectionLost, latestSample } = device;
    const displayStatusBar = false;
    const link = url ? `${url}/devices/${serialNumber}` : `/devices/${serialNumber}`;

    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        } else {
            analyticsLogger(DASHBOARD_CLICKED_TILE, { pageType: PageType.Dashboard, tileType: 'Device' });
        }
    };

    let tileBody;
    if (isHubConnectionLost) {
        const validLanguageKey = getValidLanguageTagForMoment();
        const offlineText = latestSample
            ? txt('DeviceSettings.OfflineForTime', {
                  time: moment.utc(latestSample).local().locale(validLanguageKey).fromNow(true),
              })
            : txt('NoHubConnection');
        const noSycText =
            userIsHbs() && device.segmentStart && moment(device.segmentStart).isAfter(moment().subtract(24, 'hours'))
                ? txt('WaitForHubSync')
                : offlineText;
        tileBody = <NoConnection text={noSycText} translated />;
    } else if (!currentSensorValues || !currentSensorValues.length) {
        tileBody = <NoDataTileBody message="NotEnoughData" />;
    } else if (currentSensorValues.length) {
        const orderedSensors = (): CurrentSensorValuesType[] =>
            sortSensors(currentSensorValues, tileSensorOrder[type], maxTileSensors);
        tileBody = orderedSensors().map(sensor => {
            const dotColor = getDotColor(sensor.thresholds, sensor.type, sensor.value);
            return (
                <SensorComponent
                    dotColor={dotColor}
                    value={sensor.value}
                    sensorType={sensor.type}
                    isRadonAverage={sensor.type === SensorTypes.radonShortTermAvg}
                    unit={sensor.unit}
                    displayStatusBar={displayStatusBar}
                    key={`sensor-component-${serialNumber}-${sensor.type}`}
                />
            );
        });
    }

    return (
        <NavLink to={link} onClick={preventLink} className="tile-wrapper__link page-wrapper__column" data-tile>
            <div className="tile-wrapper">
                <TileHeader device={device} menuProps={menuProps} showLocation={showLocation} visibility={visibility} />
                <div
                    className={classNames('tile-body', {
                        'tile-body--devices':
                            !isHubConnectionLost && currentSensorValues && currentSensorValues.length > 0,
                    })}
                >
                    {tileBody}
                </div>
            </div>
        </NavLink>
    );
};
DeviceTileComponent.defaultProps = {
    showLocation: false,
    menuProps: undefined,
    isDragging: false,
    visibility: undefined,
};

export default DeviceTileComponent;
