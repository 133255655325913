import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import DeviceAndSeatCount from '../../../../components/subscriptionComponents/DeviceAndSeatCount';
import { getBusinessIntercomArticleLink } from '../../../../constants';
import { IntercomArticleId } from '../../../../models/commonEnums';
import { EstimatedSubscription, Subscription, SubscriptionSeats } from '../../../../models/subscriptionModels';
import { getDeviceAndSeatCount, getServicePeriodName } from '../StartSubscription/subscriptionFunctions';
import AdditionalSeats from './AdditionalSeatsView';
import styles from './AddSeatsFormView.module.scss';

export type ParentProps = {
    subscription: Subscription;
    customerSubscriptions?: Subscription[];
    poNumber: string;
    updatePONumber: (poNumber: string) => void;
    setSeatsPerDevice: (seats: SubscriptionSeats[]) => void;
    seatsPerDevice: SubscriptionSeats[];
    setStepNumber: (step: number) => void;
    dateFormat: keyof typeof dateFormats;
    costPreview?: EstimatedSubscription;
    costPreviewLoading: boolean;
    costPreviewError?: ErrorType;
    ratePlanError?: ErrorType;
    deviceCounts?: { [device: string]: number };
};

type Props = ParentProps;

const AddSeatsFormView = ({
    subscription,
    poNumber,
    updatePONumber,
    seatsPerDevice,
    setSeatsPerDevice,
    setStepNumber,
    dateFormat,
    customerSubscriptions,
    costPreview,
    costPreviewLoading,
    costPreviewError,
    ratePlanError,
    deviceCounts,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const { durationInYears, billingType } = subscription;

    const deviceSeatsAlreadyPayedFor =
        customerSubscriptions?.reduce((acc: { [deviceType: string]: number }, sub) => {
            const updatedAcc = { ...acc };
            sub.deviceSeats.forEach(device => {
                updatedAcc[device.deviceType] = (updatedAcc[device.deviceType] || 0) + device.seats;
            });
            return updatedAcc;
        }, {}) || {};

    const onUpdatePO = (event: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = event.currentTarget;
        updatePONumber(value);
    };

    const cancel = (): void => {
        navigate(-1);
    };

    return (
        <div className="page-wrapper__medium">
            <h2>{txt('AddSeats.AddToSubscription')}</h2>
            <div className="page-wrapper__medium page-wrapper__medium--no-margin-top page-wrapper__medium--white">
                <div className={styles.summaryOfAllSubscriptions}>
                    <div className={styles.sectionHeader}>{txt('AddSeats.CurrentSubscriptionSummary')}</div>
                    <div className={styles.smallText}>{txt('AddSeats.SubscriptionSummaryInfo')}</div>
                    <div className={styles.seatsWrapper}>
                        <DeviceAndSeatCount
                            deviceCounts={
                                deviceCounts ? getDeviceAndSeatCount(deviceCounts, deviceSeatsAlreadyPayedFor) : []
                            }
                            displaySeatCount
                        />
                    </div>
                </div>
            </div>
            <div className="page-wrapper__medium page-wrapper__medium--no-margin-top page-wrapper__medium--white">
                <div className={styles.container}>
                    <div className={styles.sectionHeader}>{txt('AddSeats.SubscriptionDetails')}</div>
                    <div className={styles.subscriptionDetails}>
                        <div className={styles.subscriptionParams}>
                            <LabeledText
                                label="Subscription.Subscription"
                                value={subscription.subscriptionNumber}
                                id={subscription.subscriptionNumber}
                                noPadding
                                largeText
                            />
                            <LabeledText
                                label="Subscription.ServicePeriod"
                                value={`${durationInYears} ${txt(
                                    durationInYears === 1 ? 'Zuora.Year' : 'Zuora.Years'
                                )} - ${txt(`Zuora.${billingType.toLowerCase()}Billing`)}`}
                                id="durationInYears"
                                noPadding
                                largeText
                            />
                        </div>
                        <div className={styles.poReferenceWrapper}>
                            <Input
                                required
                                markedMandatory
                                validate={false}
                                type="text"
                                id="poNumber"
                                maxLength={100}
                                label="Subscription.PONumber"
                                currentValue={poNumber}
                                onBlur={onUpdatePO}
                                onChange={onUpdatePO}
                            />
                            <div className={styles.pOText}>
                                {txt('Subscription.ChangingPOWarning')}
                                <a
                                    className={styles.iconWithText}
                                    href={getBusinessIntercomArticleLink(IntercomArticleId.SUBSCRIPTION_MGMT)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {txt('Subscription.ReadAboutPO')}
                                    <MaterialIcon name="launch" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.ratePlanSection}>
                <AdditionalSeats
                    servicePeriod={getServicePeriodName(subscription.durationInYears)}
                    seatsPerDevice={seatsPerDevice}
                    updateSeats={setSeatsPerDevice}
                    costPreview={costPreview}
                    subscription={subscription}
                    dateFormat={dateFormat}
                    loadingPreview={costPreviewLoading}
                    costPreviewError={costPreviewError}
                    ratePlanError={ratePlanError}
                />
            </div>

            <div className="page-wrapper__medium page-wrapper__medium--white">
                <div className={styles.container}>
                    <div className={styles.prorateInfo}>
                        <MaterialIcon name="info" />
                        <div>{txt('AddSeats.ProrateAmountChargeInfo')}</div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <PrimaryButton onClick={cancel} title="Cancel" />
                        <PrimaryButton
                            onClick={(): void => setStepNumber(2)}
                            title="Subscription.ReviewPurchase"
                            disabled={
                                costPreviewLoading ||
                                !!costPreviewError ||
                                !costPreview ||
                                seatsPerDevice.filter(seat => seat.seats > seat.deployedSeats).length === 0
                            }
                            filled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSeatsFormView;
