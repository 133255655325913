import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { clearError } from 'commons/src/actions/requestActions';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_DEVICE_SETTINGS, SPACE_EXPANDED_SENSORS } from 'commons/src/analytics/AnalyticsEvents';
import { getValidLanguageTagForMoment } from 'commons/src/commonFunctions';
import AccordionButton from 'commons/src/components/buttons/AccordionButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import BatteryIcon from 'commons/src/components/device/BatteryIcon';
import DropdownOptions from 'commons/src/components/dropdown/DropdownOptions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import RssiSignalStrength from 'commons/src/components/sensors/RssiSignalStrength';
import SpaceSensorValue from 'commons/src/components/sensors/SpaceSensorValue';
import { roleRestrictions } from 'commons/src/constants';
import { CurrentSensorValuesType } from 'commons/src/models/commonTypeScript';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { MenuItem } from 'commons/src/models/menuModels';
import { paths } from '../../../../constants';
import { IndoorSpace, SpaceDevice } from '../../../../models/spaceModels';
import { BusinessRequestType as RequestType } from '../../../../reducers/BusinessRequestType';
import SpaceMoveDeviceModal from '../moveOrRemoveDevice/SpaceMoveDeviceModal';
import SpaceRemoveDeviceModal from '../moveOrRemoveDevice/SpaceRemoveDeviceModal';
import styles from './SpaceDevicesElement.module.scss';

export type Props = {
    space: IndoorSpace;
    device: SpaceDevice;
};

const SpaceDevicesElement = ({ space, device }: Props): React.ReactElement => {
    const { serialNumber, segmentName } = device;

    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { buildingId: locationId = '' } = useParams();

    const [deleteModalOpened, setDeleteModalOpened] = useState(false);
    const [moveModalOpened, setMoveModalOpened] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const toSettings = generatePath('/devices/:serialNumber/change-location', { serialNumber });

    const onRemoveOptionHandler = (): void => {
        dispatch(clearError(RequestType.RemoveDeviceFromSpace));
        setDeleteModalOpened(true);
    };

    const deviceDropdownOptions: MenuItem[] = [
        {
            onClick: (): void => {
                analyticsLogger(SPACE_DEVICE_SETTINGS, {
                    spaceId: space.id,
                    locationId,
                    serialNumber: device.serialNumber,
                });
                window.open(toSettings, '_blank');
            },
            text: txt('Settings'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
        {
            onClick: () => setMoveModalOpened(true),
            text: txt('Move'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
        {
            onClick: onRemoveOptionHandler,
            text: txt('Remove'),
            requiredRoleLevel: roleRestrictions.editSpace,
            disabled: false,
            requiredGroupTypes: [],
        },
    ];

    const batterySection = (): React.ReactElement => {
        const { batteryPercentage }: SpaceDevice = device;
        const deviceOffline: boolean = device.healthStatus === DeviceHealthStatus.offline;
        const batteryData: boolean = !!batteryPercentage && batteryPercentage >= 0;
        if (batteryData) {
            return (
                <div
                    className={classNames(styles.batteryWrapper, {
                        [styles.batteryGreyed]: deviceOffline,
                    })}
                >
                    <div className={styles.battery}>
                        <BatteryIcon batteryPercentage={batteryPercentage} isHubConnectionLost={deviceOffline} />
                        {`${batteryPercentage}% `} {txt('EstBatteryRemaining')}
                    </div>
                </div>
            );
        }
        return <div />;
    };

    const rssiSection = (): React.ReactElement => {
        const { rssi }: SpaceDevice = device;
        const deviceOffline: boolean = device.healthStatus === DeviceHealthStatus.offline;
        const rssiData = !Number.isNaN(Number(rssi));
        if (deviceOffline) {
            return (
                <div className={styles.offlineRssi}>
                    <MaterialIcon name="signal_cellular_connected_no_internet_4_bar" />
                    {rssiData ? [rssi, ' dBm'] : txt('DeviceSettings.Offline')}
                </div>
            );
        }
        if (rssiData) {
            return (
                <div className={styles.battery}>
                    <RssiSignalStrength inverted={false} rssi={rssi} displayDescription={false} />
                    {[rssi, ' dBm']}
                </div>
            );
        }
        return <div />;
    };

    const navToDevice = (): void => {
        navigate(`/${paths.devices}/${device.serialNumber}`);
    };

    const setAccordionExpanded = (): void => {
        setIsExpanded(!isExpanded);
        analyticsLogger(SPACE_EXPANDED_SENSORS, {
            spaceId: space.id,
            locationId,
            serialNumber: device.serialNumber,
        });
    };

    const neverSyncedSection = (): ReactElement => {
        const language: string = getValidLanguageTagForMoment();
        const lastSynced = device.lastSynced
            ? moment.utc(device.lastSynced).local().locale(language).fromNow()
            : txt('neverSynced');

        return (
            <div className={styles.lastSynced}>
                <span>{txt('LastSyncedNoSemicolon')}</span>
                <span>{lastSynced}</span>
            </div>
        );
    };

    const currentValueBlock = (currentValues: CurrentSensorValuesType[]): ReactElement[] =>
        currentValues.map(currentValue => (
            <div className={styles.currentValueBlock} key={currentValue.type}>
                <SpaceSensorValue
                    value={currentValue.value}
                    unit={currentValue.unit}
                    type={currentValue.type}
                    thresholds={currentValue.thresholds}
                    showSensorType
                    withGreenStatus
                    key={currentValue.type}
                    healthStatus={device.healthStatus}
                />
            </div>
        ));

    const currentValuesSection = (): ReactElement => {
        const mainCurrentValues: CurrentSensorValuesType[] = device.currentSensorValues.slice(0, 5);
        const expandedCurrentValues: CurrentSensorValuesType[] = isExpanded
            ? device.currentSensorValues.slice(5, device.currentSensorValues.length)
            : [];

        return (
            <div className={styles.deviceValuesRow}>
                <div
                    key={device.serialNumber}
                    className={classNames(styles.currentValues, {
                        [styles.currentValuesExpanded]: isExpanded,
                    })}
                >
                    {currentValueBlock(mainCurrentValues)}
                    {currentValueBlock(expandedCurrentValues)}
                    {device.currentSensorValues.length > 5 && (
                        <div className={styles.currentValueBlock}>
                            <AccordionButton
                                title={txt(isExpanded ? 'ViewLess' : 'ViewMore')}
                                onClick={setAccordionExpanded}
                                expanded={isExpanded}
                                testId="expand-button"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <li key={serialNumber} id={serialNumber} className={styles.body} data-space-device={device.serialNumber}>
                <div className={styles.deviceTopBar}>
                    <div className={styles.deviceInfo}>
                        <div className={styles.deviceButton}>
                            <PrimaryButton
                                title={segmentName}
                                onClick={navToDevice}
                                small
                                testId="nav-to-device"
                                type="button"
                            />
                        </div>
                        <div className={styles.serialNumber}>{device.serialNumber}</div>
                        {[DeviceHealthStatus.notSynced, DeviceHealthStatus.offline].includes(device.healthStatus) && (
                            <div className={styles.status}>
                                <DeviceStatusPill status={device.healthStatus} deviceType="device" />
                            </div>
                        )}
                        {device.healthStatus !== DeviceHealthStatus.notSynced && neverSyncedSection()}
                        <div>{batterySection()}</div>
                        <div>{rssiSection()}</div>
                    </div>
                    <DropdownOptions
                        options={deviceDropdownOptions}
                        id={`device_options_${serialNumber}`}
                        dotMenu
                        customButton={<MaterialIcon extraClass={styles.dots} name="more_horiz" />}
                    />
                </div>
                {currentValuesSection()}
            </li>
            <SpaceRemoveDeviceModal
                device={device}
                visible={deleteModalOpened}
                hide={(): void => setDeleteModalOpened(false)}
                payload={{ locationId, spaceId: space.id, serialNumber }}
            />
            <SpaceMoveDeviceModal
                device={device}
                isOpen={moveModalOpened}
                closeModal={(): void => setMoveModalOpened(false)}
                locationId={locationId}
                space={space}
            />
        </>
    );
};

export default SpaceDevicesElement;
