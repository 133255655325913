import { Draft, produce } from 'immer';
import { SettingsActionType, SettingsReducerActions } from 'commons/src/actions/SettingsActions';
import { PlacementType, SensorTypes } from 'commons/src/models/commonEnums';
import { SpaceAction, SpaceActions } from '../actions/spaceActions';
import { IndoorSpace, Space } from '../models/spaceModels';

export interface SpaceStore {
    spaces: {
        [locationId: string]: {
            spaces: Space[];
            availableSensors: SensorTypes[];
        };
    };
}

const initialStore: SpaceStore = {
    spaces: {},
};

const produceSpaceId =
    (state: SpaceStore) =>
    (locationId: string, spaceId: string): number =>
        state.spaces[locationId]?.spaces?.findIndex(({ id }) => id === spaceId);

/* eslint-disable no-param-reassign */
const reducer = (state: SpaceStore = initialStore, action: SpaceAction | SettingsReducerActions): SpaceStore =>
    produce(state, draft => {
        const getSpaceIndex = produceSpaceId(state);
        switch (action.type) {
            case SpaceActions.FetchSpacesSuccess: {
                draft.spaces[action.locationId] = {
                    spaces: action.spaces,
                    availableSensors: action.availableSensors,
                };
                break;
            }
            case SpaceActions.CreateSpaceSuccess: {
                const { locationId } = action.space;
                draft.spaces[locationId].spaces.push(action.space);
                break;
            }
            case SpaceActions.AddDeviceToSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.locationId, action.spaceId);
                if (spaceIndex >= 0) {
                    const space = draft.spaces[action.locationId].spaces[spaceIndex] as Draft<IndoorSpace>;
                    space.devices.push(action.device);
                }
                break;
            }
            case SpaceActions.EditSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.space.locationId, action.space.id);
                if (spaceIndex >= 0) {
                    draft.spaces[action.space.locationId].spaces[spaceIndex] = action.space;
                }
                break;
            }
            case SpaceActions.RemoveDeviceFromSpaceSuccess: {
                const spaceIndex: number = getSpaceIndex(action.locationId, action.spaceId);
                const space: Draft<IndoorSpace> = draft.spaces[action.locationId].spaces[
                    spaceIndex
                ] as Draft<IndoorSpace>;
                const itemToDeleteIndex: number = space.devices.findIndex(
                    device => device.serialNumber === action.serialNumber
                );
                if (itemToDeleteIndex >= 0) {
                    space.devices.splice(itemToDeleteIndex, 1);
                }
                break;
            }
            case SpaceActions.MoveDeviceBetweenSpacesSuccess: {
                const { spaceId, serialNumber, payload, locationId } = action;
                const { toLocationId, toSpaceId } = payload;

                const sourceSpaceIndex: number = getSpaceIndex(locationId, spaceId);
                const deviceToMove = (
                    draft.spaces[locationId].spaces[sourceSpaceIndex] as Draft<IndoorSpace>
                ).devices.find(device => device.serialNumber === serialNumber);

                if (deviceToMove !== undefined) {
                    const destinationSpaceIndex: number = getSpaceIndex(toLocationId, toSpaceId);
                    if (sourceSpaceIndex >= 0 && destinationSpaceIndex >= 0) {
                        // Add device to destination space
                        const destinationSpace: Draft<IndoorSpace> = draft.spaces[toLocationId].spaces[
                            destinationSpaceIndex
                        ] as Draft<IndoorSpace>;
                        destinationSpace.devices.push(deviceToMove);
                        // Remove device serialNumber from source space
                        const spaceToDelete = draft.spaces[locationId].spaces[sourceSpaceIndex] as Draft<IndoorSpace>;

                        const itemToDeleteIndex = spaceToDelete.devices.findIndex(
                            device => device.serialNumber === serialNumber
                        );
                        spaceToDelete.devices.splice(itemToDeleteIndex, 1);
                    }
                }
                break;
            }
            case SettingsActionType.DeletePropertyDefinitionSuccess: {
                Object.keys(draft.spaces).forEach(locationId => {
                    draft.spaces[locationId].spaces.forEach((space, index) => {
                        if (
                            space.placement === PlacementType.ROOM &&
                            (space as IndoorSpace).properties[action.definitionId]
                        ) {
                            delete (draft.spaces[locationId].spaces[index] as IndoorSpace).properties[
                                action.definitionId
                            ];
                        }
                    });
                });
                break;
            }
            case SettingsActionType.DeletePropertyValueSuccess: {
                Object.keys(draft.spaces).forEach(locationId => {
                    draft.spaces[locationId].spaces.forEach((space, spaceIndex) => {
                        if (
                            space.placement === PlacementType.ROOM &&
                            (space as IndoorSpace).properties[action.propertyId]
                        ) {
                            Object.keys((space as IndoorSpace).properties).forEach(propertyKey => {
                                const property = (space as IndoorSpace).properties[propertyKey];
                                if (propertyKey === action.propertyId && property.optionId === action.valueId) {
                                    delete (draft.spaces[locationId].spaces[spaceIndex] as IndoorSpace).properties[
                                        propertyKey
                                    ];
                                }
                            });
                        }
                    });
                });
                break;
            }
            default:
        }
    });
/* eslint-enable no-param-reassign */

export default reducer;
