import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column, Row } from 'react-table';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { BuildingSummaryHealthStatus } from '../../../../../models/buildingModels';
import { LocationLink } from '../common';
import styles from './DesktopTable.module.scss';
import HealthStatusTable from './HealthStatusTable';

type RowData = {
    locationId: string;
    locationName: string;
    hubs: number;
    sensorDevices: number;
    display: {
        sensorDevices: string;
        hubs: string;
    };
};

const toDisplayFraction = (nominator: number, denominator: number): string =>
    nominator === 0 ? '0' : `${nominator}/${denominator}`;

const toPercentage = (nominator: number, denominator: number): number =>
    nominator === 0 ? 0 : nominator / denominator;

const toRowData = ({
    locationId,
    locationName,
    offlineHubs,
    offlineSensorDevices,
    hubs,
    sensorDevices,
}: BuildingSummaryHealthStatus): RowData => ({
    locationId,
    locationName,
    hubs: toPercentage(offlineHubs, hubs),
    sensorDevices: toPercentage(offlineSensorDevices, sensorDevices),
    display: {
        hubs: toDisplayFraction(offlineHubs, hubs),
        sensorDevices: toDisplayFraction(offlineSensorDevices, sensorDevices),
    },
});

export const DisplayValue = ({ column, cell }: { column: Column; cell: Cell }): React.ReactElement => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <span>{column.accessor(cell.row.original.display)}</span>
);

const ExpandRow = ({ row, cell }: { row: Row; cell: Cell }): React.ReactElement => {
    return (
        <span className={styles.alignedCellContent}>
            <span
                role="button"
                tabIndex={0}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') row.toggleRowExpanded();
                }}
                {...row.getToggleRowExpandedProps()}
                className={styles.expandTableElement}
            >
                <MaterialIcon name={row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'} />
            </span>
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <LocationLink locationId={row.original.locationId} locationName={cell.value} />
            }
        </span>
    );
};

type Props = {
    data: BuildingSummaryHealthStatus[];
    numberOfDevicesInOrg: number;
};

export default function DesktopTable({ data, numberOfDevicesInOrg }: Props): React.ReactElement {
    const { t: txt } = useTranslation();
    const columns = useMemo<Column[]>(
        () => [
            {
                Header: txt('Building.Building'),
                accessor: 'locationName',
                className: styles.textCell,
                Cell: ExpandRow,
            },
            {
                Header: txt('BuildingStatus.OfflineHubsHeader'),
                accessor: 'hubs',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
            {
                Header: txt('BuildingStatus.OfflineSensorDevicesHeader'),
                accessor: 'sensorDevices',
                Cell: DisplayValue,
                className: styles.fractionCell,
            },
        ],
        []
    );

    const tableData = useMemo<RowData[]>(() => data.map(toRowData), [data]);

    return (
        <HealthStatusTable
            data={tableData}
            columns={columns}
            styles={styles}
            emptyTableText={
                numberOfDevicesInOrg > 0 ? 'BuildingStatus.NoOfflineBuildings' : 'BuildingStatus.NoDevicesInBuildings'
            }
        />
    );
}
