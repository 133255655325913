import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Store } from 'business-dashboard/src/reducers';
import filterMenuElements from 'commons/src/components/menu/FilterMenuElements';
import NavigationSideBar from 'commons/src/components/menu/NavigationSideBar';
import { roleRestrictions, shopLink } from 'commons/src/constants';
import { FeatureToggleEnum, GroupType, RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { NavigationItem } from 'commons/src/models/menuModels';
import { paths } from '../../constants';
import logo from '../../img/Airthings_Logo_Standard.svg';
import simpleLogo from '../../img/airthings_simple_logo.svg';

type StateProps = {
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
};

type ParentProps = {
    publicPaths: string[];
};
type Props = StateProps & ParentProps;

export const NavigationComponent = ({
    userRole,
    featureToggles,
    publicPaths,
    groupType,
}: Props): React.ReactElement => {
    let currentPath = useLocation().pathname;
    if (currentPath.includes('/app-devices')) {
        currentPath = '/app-devices/:device';
    }
    const isInPublicPath = publicPaths.includes(currentPath);

    if (isInPublicPath) return <div />;

    const navigation: NavigationItem[] = [
        {
            id: 'dashboard',
            key: 'Dashboard',
            path: '',
            title: 'Dashboard',
            iconName: 'dashboard',
            requiredRoleLevel: roleRestrictions.dashboardTiles,
            requiredGroupTypes: [],
        },
        {
            id: 'devices',
            key: 'Devices',
            path: paths.devices,
            title: 'Devices',
            iconName: 'deviceIcon',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
        {
            id: 'settings',
            key: 'Settings',
            path: paths.settings,
            title: 'Account',
            iconName: 'account_circle',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
        {
            id: 'integrations',
            key: 'Integrations',
            path: paths.integrations,
            title: 'Integrations.Integrations',
            iconName: 'widgets',
            requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
            accessWithResourceFilter: false,
            requiredGroupTypes: [],
        },
        {
            id: 'shop',
            key: 'Shop',
            path: shopLink,
            title: 'Shop',
            iconName: 'shopping_cart',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const accessibleNavigationItems = filterMenuElements(navigation, groupType, userRole, undefined, featureToggles);

    return (
        <NavigationSideBar
            navigationItems={accessibleNavigationItems}
            logo={logo}
            slimLogo={simpleLogo}
            styleClass="consumer-navigation"
        />
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const { selectedGroup, featureToggles } = store.userSettings;
    return {
        userRole: selectedGroup?.role,
        groupType: selectedGroup?.groupType,
        featureToggles,
    };
};

NavigationComponent.defaultProps = {
    userRole: undefined,
};

export default connect(mapStateToProps)(NavigationComponent);
