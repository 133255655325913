import React, { FormEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { INTEGRATION_AUTHORIZED_INTEGRATION } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import {
    FetchThirdPartyIntegrationClientID,
    fetchThirdPartyIntegrationClientID,
} from '../../../actions/thirdPartyIntegrationActions';
import { integrationTypeLogos, integrationTypes, grantType, thirdPartyChannels } from '../../../constants';
import { IntegrationExtraAuth, NewThirdPartyIntegration } from '../../../models/common';
import { Store } from '../../../reducers';
import EiendomsappenIntegration from './integrationChannels/EiendomsappenIntegration';

type ActionProps = {
    fetchClientId: (integration: NewThirdPartyIntegration) => void;
};

type PassedProps = {
    selectedIntegrationType: string;
    onAddIntegration: (integration: NewThirdPartyIntegration) => void;
};

type StateProps = {
    addLoading: boolean;
    fetchClientIdLoading: boolean;
};

export type Props = ActionProps & PassedProps & StateProps;

export const AddThirdPartyIntegrationFormViewComponent = ({
    selectedIntegrationType,
    onAddIntegration,
    fetchClientId,
    addLoading,
    fetchClientIdLoading,
}: Props): React.ReactElement => {
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [integrationName, setIntegrationName] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [displayValidation, setDisplayValidation] = useState(false);

    const [extraAuthInputs, setExtraAuthInputs] = useState<{ [key: string]: string }>({});
    const [validExtraInputs, setValidExtraInputs] = useState(false);

    const { t: txt } = useTranslation();

    const flowType = selectedIntegrationType && integrationTypes[selectedIntegrationType].authorizationMethods[0];

    const updateInput = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value, id } = e.currentTarget;

        if (id === 'integration-name') setIntegrationName(value);
        else if (id === 'access-token') setAccessToken(value);
        else if (id === 'client-id') setClientId(value);
        else if (id === 'client-secret') setClientSecret(value);
    };

    const validateIntegration = (): boolean => {
        const isEiendomsappenChannel = selectedIntegrationType === thirdPartyChannels.EIENDOMSAPPEN;

        const validName = integrationName.length > 0;
        const validAccessToken = accessToken.length > 0;
        const validClientId = !isEiendomsappenChannel ? clientId.length > 0 : true;
        const validClientSecret = !isEiendomsappenChannel ? clientSecret.length > 0 : true;

        const validExtraIntegration = isEiendomsappenChannel ? validExtraInputs : true;

        const isOauthCode = flowType === grantType.oauthAuthorizationCode && validName;
        const isAccessToken = flowType === grantType.accessToken && validName && validAccessToken;
        const isClientCredentials =
            flowType === grantType.oauthClientCredentials &&
            validName &&
            validClientId &&
            validClientSecret &&
            validExtraIntegration;
        if (isOauthCode || isAccessToken || isClientCredentials) {
            return true;
        }

        setDisplayValidation(true);
        return false;
    };

    const addIntegration = (e: SyntheticEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const integrationIsValid = validateIntegration();
        if (integrationIsValid) {
            const newIntegration = {
                type: selectedIntegrationType,
                name: integrationName,
                grantType: flowType,
                parameters: {},
            };
            analyticsLogger(INTEGRATION_AUTHORIZED_INTEGRATION, {
                pageType: PageType.Integration,
                selectedIntegrationType,
                integrationName,
            });

            if (flowType === grantType.accessToken) {
                newIntegration.parameters = {
                    accessToken,
                };
                onAddIntegration(newIntegration);
            } else if (flowType === grantType.oauthClientCredentials) {
                newIntegration.parameters = {
                    clientId: clientId.length > 0 ? clientId : undefined,
                    clientSecret: clientSecret.length > 0 ? clientSecret : undefined,
                    extra: extraAuthInputs,
                };
                onAddIntegration(newIntegration);
            } else {
                fetchClientId(newIntegration);
            }
        }
    };

    const onUpdateExtraInputs = (inputValues: IntegrationExtraAuth, valid: boolean): void => {
        setExtraAuthInputs(inputValues);
        setValidExtraInputs(valid);
    };

    const integrationTypeTranslated = txt(`ThirdParty.${selectedIntegrationType}`);
    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            <form className="form__wide-container" onSubmit={addIntegration}>
                <h2 className="form__header--padding-bottom">
                    {txt('ThirdParty.NameAndAuthorize', { integration: integrationTypeTranslated })}
                </h2>
                <div className="third-party__img-container">
                    <img
                        src={integrationTypeLogos(selectedIntegrationType)}
                        className="third-party__img-container__img"
                        alt={txt('ThirdParty.IntegrationTypeLogo', {
                            type: integrationTypeTranslated,
                        })}
                    />
                </div>
                <p className="third-party__padding--large centered">
                    {txt('ThirdParty.AuthorizeConnection', { integration: integrationTypeTranslated })}
                </p>
                <div className="form__row form__row--centered">
                    <div className="form__field form__field--medium-width">
                        <Input
                            type="text"
                            id="integration-name"
                            validate={displayValidation && integrationName.length === 0}
                            isValid={integrationName.length > 0}
                            hint="ThirdParty.NameHint"
                            maxLength={80}
                            onChange={updateInput}
                            placeholder={txt('ThirdParty.NamePlaceholder')}
                            testId="integration-name"
                        />
                    </div>
                </div>
                {flowType === grantType.accessToken && (
                    <div className="form__row form__row--centered">
                        <div className="form__field form__field--medium-width">
                            <Input
                                type="text"
                                id="access-token"
                                validate={displayValidation && accessToken.length === 0}
                                isValid={accessToken.length > 0}
                                hint={txt('ThirdParty.InputHint', { input: txt('ThirdParty.AccessTokenPlaceholder') })}
                                maxLength={350}
                                onChange={updateInput}
                                placeholder={txt('ThirdParty.AccessTokenPlaceholder')}
                                testId="access-token-input"
                            />
                        </div>
                    </div>
                )}
                {flowType === grantType.oauthClientCredentials &&
                    (selectedIntegrationType !== thirdPartyChannels.EIENDOMSAPPEN ? (
                        <>
                            <div className="form__row form__row--centered">
                                <div className="form__field form__field--medium-width">
                                    <Input
                                        type="text"
                                        id="client-id"
                                        validate={displayValidation && clientId.length === 0}
                                        isValid={clientId.length > 0}
                                        hint={txt('ThirdParty.InputHint', {
                                            input: txt('ThirdParty.ClientIdPlaceholder'),
                                        })}
                                        maxLength={80}
                                        onChange={updateInput}
                                        placeholder={txt('ThirdParty.ClientIdPlaceholder')}
                                        testId="client-id-input"
                                    />
                                </div>
                            </div>
                            <div className="form__row form__row--centered">
                                <div className="form__field form__field--medium-width">
                                    <Input
                                        type="text"
                                        id="client-secret"
                                        validate={displayValidation && clientSecret.length === 0}
                                        isValid={clientSecret.length > 0}
                                        hint={txt('ThirdParty.InputHint', {
                                            input: txt('ThirdParty.ClientSecretPlaceholder'),
                                        })}
                                        maxLength={80}
                                        onChange={updateInput}
                                        placeholder={txt('ThirdParty.ClientSecretPlaceholder')}
                                        testId="client-secret-input"
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <EiendomsappenIntegration
                            inputValues={extraAuthInputs}
                            displayValidation={displayValidation}
                            onUpdate={onUpdateExtraInputs}
                        />
                    ))}
                <div className="form__row form__button-container third-party__button-margin--medium">
                    <PrimaryButton
                        type="submit"
                        title="ThirdParty.Authorize"
                        filled
                        onClick={addIntegration}
                        loading={addLoading || fetchClientIdLoading}
                        testAttr="add-third-party-integration"
                        icon={<MaterialIcon name="launch" />}
                        testId="add-third-party-integration"
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            ADD_THIRD_PARTY_INTEGRATION: { loading: addLoading },
            FETCH_THIRD_PARTY_INTEGRATION_CLIENT_ID: { loading: fetchClientIdLoading },
        },
    } = state;

    return {
        addLoading,
        fetchClientIdLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    fetchClientId: (integration: NewThirdPartyIntegration): FetchThirdPartyIntegrationClientID =>
        dispatch(fetchThirdPartyIntegrationClientID(integration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddThirdPartyIntegrationFormViewComponent);
