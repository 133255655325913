import React, { useEffect } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PageType } from 'commons/src/analytics';
import { BUILDING_VIEWED_STATUS } from 'commons/src/analytics/AnalyticsEvents';
import sendAnalyticsEvent from 'commons/src/analytics/sendAnalyticsEvent';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import RenderComponentOrError from 'commons/src/components/responseMessages/RenderComponentOrError';
import useEffectOnceWhen from 'commons/src/hooks/useEffectsOnceWhen';
import {
    fetchBuildingHealthSummary,
    fetchBuildingHealthHubStats,
    fetchBuildingHealthDeviceStats,
} from '../../../actions/buildingHealthActions';
import Search from '../../../components/search/Search';
import { Store } from '../../../reducers';
import {
    selectedBuildingHealthSummary,
    selectBuildingDevicesHealth,
    selectBuildingHubsHealth,
} from '../buildingsStatus/buildingsHealthStatusSelectors';
import BuildingHealthSubHeader from './BuildingHealthSubHeader';
import DevicesTable from './DevicesTable';
import HubsTable from './HubsTable';
import BuildingHealthStatusSummaryBar from './summaryBar/BuildingHealthStatusSummaryBar';

export const BuildingStatusComponent = (): React.ReactElement => {
    const { buildingId = '' } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBuildingHealthSummary(buildingId));
        dispatch(fetchBuildingHealthHubStats(buildingId));
        dispatch(fetchBuildingHealthDeviceStats(buildingId));
    }, []);

    const { summary, summaryRequest } = useSelector((state: Store) => selectedBuildingHealthSummary(state, buildingId));

    const { hubs, hubsRequest } = useSelector((state: Store) => selectBuildingHubsHealth(state, buildingId));

    const { devices, devicesRequest, totalDevices } = useSelector((state: Store) =>
        selectBuildingDevicesHealth(state, buildingId)
    );

    useEffectOnceWhen(() => {
        sendAnalyticsEvent({
            type: BUILDING_VIEWED_STATUS,
            payload: {
                pageType: PageType.Building,
                offlineHubs: `${summary.hubStats.offline}/${summary.hubStats.total}`,
                offlineDevices: `${summary.deviceStats.offline}/${summary.deviceStats.total}`,
            },
        });
    }, !summaryRequest.loading && Boolean(summary));

    const isCSVLoading = hubsRequest.loading || devicesRequest.loading;
    const isCSVError = hubsRequest.error || devicesRequest.error;

    return (
        <div className="building-status-page">
            <Search minCharacters={3}>
                <div className="building-status-page__content">
                    <ReactPlaceholder ready={!isCSVLoading} customPlaceholder={fullwidthListElement}>
                        <RenderComponentOrError error={isCSVError}>
                            <BuildingHealthSubHeader
                                hubs={hubs}
                                devices={devices}
                                buildingId={buildingId}
                                isStreaming={devicesRequest.streaming}
                            />
                        </RenderComponentOrError>
                    </ReactPlaceholder>

                    <ReactPlaceholder ready={!summaryRequest.loading} customPlaceholder={fullwidthListElement}>
                        <RenderComponentOrError error={summaryRequest.error}>
                            <BuildingHealthStatusSummaryBar hubs={summary?.hubStats} devices={summary?.deviceStats} />
                        </RenderComponentOrError>
                    </ReactPlaceholder>

                    <RenderComponentOrError error={hubsRequest.error}>
                        <HubsTable hubs={hubs} isLoading={hubsRequest.loading} />
                    </RenderComponentOrError>

                    <RenderComponentOrError error={devicesRequest.error}>
                        <DevicesTable
                            devices={devices}
                            isReady={!devicesRequest.loading && !devicesRequest.streaming}
                            totalDevices={totalDevices}
                            isLoading={devicesRequest.loading}
                        />
                    </RenderComponentOrError>
                </div>
            </Search>
        </div>
    );
};

export default BuildingStatusComponent;
