import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { headerPlaceholder } from 'commons/src/components/placeholder';
import { RenameSegment, renameSegment as renameSegmentAction } from '../../actions/DeviceActions';
import { DeleteHub, deleteHub } from '../../actions/HubActions';
import { pageNotFoundUrl } from '../../components/errorComponents/PageNotFound';
import PageHeader from '../../components/headers/PageHeader';
import SubHeader from '../../components/headers/SubHeader';
import { IntercomAPI } from '../../components/Intercom';
import DeleteConfirmModal from '../../components/modals/DeleteConfirmModal';
import { roleRestrictions } from '../../constants';
import { ButtonColor } from '../../models/commonEnums';
import { HubData } from '../../models/commonTypeScript';
import { ActionButton } from '../../models/menuModels';
import { Store } from '../../reducers';
import DeviceEditNameHeader from '../devicePage/DeviceEditNameHeader';

export type ComponentProps = {
    displayedHub: HubData | undefined;
    invalidHub: boolean;
    onGoBack: () => void;
    onGoBackName: string;
};

type StateProps = {
    deleteHubLoading: boolean;
    deleteHubError: boolean;
};

type ActionProps = {
    onDelete: (serialNumber: string, locationId: string) => void;
    onRenameSegment: (serialNumber: string, segmentId: string, name: string) => void;
};

type Props = StateProps & ComponentProps & ActionProps;

export const HubPageHeader = ({
    deleteHubError,
    deleteHubLoading,
    onDelete,
    displayedHub,
    invalidHub,
    onRenameSegment,
    onGoBack,
    onGoBackName,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const [deletingHub, setDeletingHub] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (deletingHub && !deleteHubLoading) {
            if (!deleteHubError) {
                onGoBack();
            }
            setDeletingHub(false);
        }
    }, [deleteHubError, deleteHubLoading, deletingHub]);

    if (!deletingHub && invalidHub) {
        navigate(pageNotFoundUrl('invalidHub'));
        return <div />;
    }

    const onDeleteHub = (): void => {
        if (displayedHub) {
            IntercomAPI('trackEvent', 'delete-hub', {
                hubSerialNumber: displayedHub.serialNumber,
                hubName: displayedHub.name,
            });
            onDelete(displayedHub.serialNumber, displayedHub.locationId);
            setDeletingHub(true);
        }
    };

    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => setDeleteModalOpen(true),
            id: 'unpairHub',
            title: 'HubStrings.UnpairHub',
            testAttr: 'unpair-hub',
            color: ButtonColor.alert,
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            requiredGroupTypes: [],
        },
    ];
    const deleteHubDescription = (
        <p className="modal__content__description">
            {txt('HubStrings.DeleteHubDescription', { name: displayedHub ? displayedHub.name : '' })}
        </p>
    );

    const renameSegmentProxy = (newName: string): void => {
        if (!displayedHub) {
            return;
        }

        onRenameSegment(displayedHub.serialNumber, 'latest', newName);
    };

    return (
        <ReactPlaceholder ready={!!displayedHub} customPlaceholder={headerPlaceholder}>
            <PageHeader
                title={(displayedHub && displayedHub.name) || ''}
                subHeader={onGoBackName}
                subHeaderClick={onGoBack}
                customHeader={
                    <DeviceEditNameHeader
                        name={(displayedHub && displayedHub.name) || ''}
                        renameSegment={renameSegmentProxy}
                    />
                }
            />
            <div className="container">
                <SubHeader actionButtons={actionButtons} />
                {deleteModalOpen && (
                    <DeleteConfirmModal
                        title="HubStrings.UnpairHub"
                        loading={deleteHubLoading}
                        description={deleteHubDescription}
                        onSubmit={onDeleteHub}
                        onCancel={(): void => setDeleteModalOpen(false)}
                        onSubmitText="Unpair"
                        onCancelText="Cancel"
                        error={deleteHubError}
                        errorText="SomethingWentWrong"
                    />
                )}
            </div>
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { deleteHubLoading, deleteHubError },
    } = state;
    return {
        deleteHubLoading,
        deleteHubError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onDelete: (serialNumber: string, locationId: string): DeleteHub => dispatch(deleteHub(serialNumber, locationId)),
    onRenameSegment: (serialNumber, segmentId, name): RenameSegment =>
        dispatch(renameSegmentAction(serialNumber, segmentId, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubPageHeader);
