import * as Sentry from '@sentry/browser';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import da from './translations/da.json';
import de from './translations/de.json';
import en from './translations/en.json';
import fr from './translations/fr.json';
import no from './translations/no.json';
import se from './translations/se.json';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/da';

const options = {
    // order and from where user language should be detected
    order: ['localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        fallbackLng: {
            no: ['no', 'en'],
            nn: ['no', 'en'],
            nb: ['no', 'en'],
            se: ['sv', 'en'],
            default: ['en'],
        },
        debug: false, // change to true to see console output
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: true,
        missingKeyHandler: (lngs, ns, key, fallbackValue) => {
            Sentry.captureEvent({
                message: `Missing translation for key`,
                extra: { lngs, ns, key, fallbackValue },
                level: 'debug',
            });
        },
        resources: {
            en: {
                translation: en,
            },
            no: {
                translation: no,
            },
            de: {
                translation: de,
            },
            fr: {
                translation: fr,
            },
            da: {
                translation: da,
            },
            sv: {
                translation: se,
            },
        },
    });

export default i18n;
