import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { A4BSupportMail, airthingsGeoLocation } from 'commons/src/constants';
import AddLocation from 'commons/src/features/addNewDevice/AddNewLocation';
import {
    ErrorType,
    LocationAddressType,
    LocationType,
    DayUsageHours,
    LocationDetails,
    BuildingType,
    LocationRfRegionResponse,
} from 'commons/src/models/commonTypeScript';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { FloorRange } from '../../../constants';
import { BuildingOptions, Ventilation } from '../../../models/common';
import { Store } from '../../../reducers';
import BuildingDetails from './BuildingDetails';
import BuildingOccupancySchedule from './BuildingOccupancySchedule';
import { findTimeZoneValues } from './timeZones';

export type ParentProps = {
    onClose: () => void;
    closeOnSave: boolean;
    building?: BuildingType;
    updateLoading: boolean;
    updateError?: ErrorType;
    saveLocation: (locationDetails: LocationDetails) => void;
};

interface StateProps {
    locations: LocationType[];
    rfRegionValidationLoading: boolean;
    rfRegionResponse?: LocationRfRegionResponse;
}

export type Props = StateProps & ParentProps;

export const BuildingFormComponent = ({
    locations,
    saveLocation,
    updateLoading,
    updateError,
    onClose,
    closeOnSave,
    building,
    rfRegionValidationLoading,
    rfRegionResponse,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();

    const [displayRadioRegionSubmitError, setDisplayRadioRegionSubmitError] = useState(false);
    const [locationName, setLocationName] = useState(building ? building.name : '');
    const [validLocationName, setValidLocationName] = useState(true);
    const [validateForm, setValidateForm] = useState(!!building);
    const [locationAddress, setLocationAddress] = useState((building && building.address) || '');
    const [locationCoords, setLocationCoords] = useState(
        building ? { lat: building.lat, lng: building.lng } : airthingsGeoLocation
    );
    const [countryCode, setCountryCode] = useState((building && building.countryCode) || '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [validAddress, setValidAddress] = useState(true);

    const [buildingTimezone, setBuildingTimezone] = useState(findTimeZoneValues(building));
    const [buildingYear, setBuildingYear] = useState<string>(
        building && building.buildingYear ? building.buildingYear.toString() : ''
    );
    const [ventilation, setVentilation] = useState<Ventilation | string | undefined>(
        (building && building.ventilationType) || undefined
    );
    const [buildingType, setBuildingType] = useState<BuildingOptions | string | undefined>(
        (building && building.buildingType) || undefined
    );
    const [floors, setFloors] = useState<number | undefined>((building && building.floors) || undefined);
    const [buildingHeight, setBuildingHeight] = useState<number | undefined>(
        (building && building.buildingHeight) || undefined
    );
    const [buildingVolume, setBuildingVolume] = useState<number | undefined>(
        (building && building.buildingVolume) || undefined
    );
    const [buildingSize, setBuildingSize] = useState<number | undefined>(
        (building && building.buildingSize) || undefined
    );

    const initialValidDays = (): { [day: string]: boolean } => {
        const validDaysObject: { [day: string]: boolean } = {};
        if (building) {
            Object.keys(building.usageHours).forEach(day => {
                validDaysObject[day] = true;
            });
        }
        return validDaysObject;
    };
    const [validBuildingDetails, setValidBuildingDetails] = useState(true);
    const [updatedUsageHours, setUpdatedUsageHours] = useState((building && building.usageHours) || {});
    const [validDays, setValidDays] = useState(initialValidDays());

    const locationNameIsValid = (): boolean => {
        const unusedLocationName = !locations
            .map(locationElm => locationElm.name.toLowerCase())
            .includes(locationName.toLowerCase());
        const nameValid =
            (building && building.name === locationName) || (locationName.length > 0 && unusedLocationName);
        setValidLocationName(nameValid);
        return nameValid;
    };

    const formIsValid = (): boolean => {
        const locationChanged = building && locationAddress !== building.address;
        const addressValid =
            locationAddress.length > 0 && (!locationChanged || (locationChanged && countryCode.length > 1));
        setValidAddress(addressValid);
        const validUsageHours = !Object.keys(validDays).some(key => !validDays[key]);
        const validFloors = floors ? floors <= FloorRange.high : false;
        const validVentilation = !!ventilation;
        const validBuildingType = !!buildingType;
        setValidateForm(true);
        const validRadioRegion = !rfRegionResponse || rfRegionResponse?.validChange;
        if (validAddress && !validRadioRegion) {
            setDisplayRadioRegionSubmitError(true);
        }
        return (
            validRadioRegion &&
            locationNameIsValid() &&
            addressValid &&
            validUsageHours &&
            validBuildingDetails &&
            validFloors &&
            validBuildingType &&
            validVentilation
        );
    };

    const cleanUpEmptyHours = (updated: { [day: string]: DayUsageHours }): { [day: string]: DayUsageHours } => {
        const cleanedUpUsageHours: { [day: string]: DayUsageHours } = {};
        Object.keys(updated).forEach(day => {
            const daySchedule = updated[day];
            let cleanSchedule: { from?: string; to?: string; closed: boolean } = { closed: daySchedule.closed };
            if (!daySchedule.closed && daySchedule.from && daySchedule.from.length > 0) {
                cleanSchedule = { ...cleanSchedule, from: daySchedule.from };
            }
            if (!daySchedule.closed && daySchedule.to && daySchedule.to.length > 0) {
                cleanSchedule = { ...cleanSchedule, to: daySchedule.to };
            }
            cleanedUpUsageHours[day] = cleanSchedule;
        });
        const allDaysClosed = !Object.values(cleanedUpUsageHours).some(day => day.from || day.to);
        if (allDaysClosed) {
            return {};
        }
        return cleanedUpUsageHours;
    };

    const onSubmit = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const validForm = formIsValid();
        if (validForm && locationCoords) {
            const cleanedUpUsageHours = cleanUpEmptyHours(updatedUsageHours);

            const buildingData = {
                ventilationType: ventilation as Ventilation,
                buildingType: buildingType as BuildingOptions,
                buildingYear: buildingYear ? parseInt(buildingYear, 10) : undefined,
                floors,
                buildingSize,
                buildingHeight,
                timeZone: buildingTimezone.id,
            };

            const locationDetails = {
                name: locationName.trim(),
                address: locationAddress.trim(),
                lat: locationCoords.lat,
                lng: locationCoords.lng,
                countryCode,
                buildingVolume,
                usageHours: cleanedUpUsageHours,
            };

            saveLocation({ ...locationDetails, ...buildingData });
            setIsSubmitting(true);
        }
    };

    const updateAddress = (address: LocationAddressType): void => {
        setLocationCoords(address.coords);
        setLocationAddress(address.address);
        setCountryCode(address.countryCode || '');
        setDisplayRadioRegionSubmitError(false);
    };

    useEffect(() => {
        if (closeOnSave && isSubmitting && !updateLoading && !updateError) {
            onClose();
        }
    }, [isSubmitting, updateLoading, updateError]);

    const radioRegionErrorSubText = (
        <span>
            <Trans i18nKey="LocationRfRegion.IllegalRegionDescription">
                <a href={`mailto: ${A4BSupportMail}`}>{A4BSupportMail}</a>
            </Trans>
        </span>
    );

    return (
        <form className="edit-building__form">
            <AddLocation
                address={locationAddress}
                locationName={locationName}
                updateLocationName={setLocationName}
                setAddress={updateAddress}
                displayAddressValidation={!validAddress}
                validateLocationName={!validLocationName}
                locationNameIsValid={validLocationName}
                countryCode={building && building.countryCode}
                rfRegionResponse={rfRegionResponse}
            />
            <BuildingDetails
                setBuildingTimezone={setBuildingTimezone}
                buildingTimezone={buildingTimezone}
                buildingType={buildingType}
                buildingYear={buildingYear}
                ventilation={ventilation}
                setBuildingType={setBuildingType}
                setBuildingYear={setBuildingYear}
                setVentilation={setVentilation}
                setValidBuildingDetails={setValidBuildingDetails}
                floors={floors}
                setFloors={setFloors}
                buildingHeight={buildingHeight}
                setBuildingHeight={setBuildingHeight}
                buildingSize={buildingSize}
                setBuildingSize={setBuildingSize}
                setBuildingVolume={setBuildingVolume}
                buildingVolume={buildingVolume}
                validateForm={validateForm}
            />
            <BuildingOccupancySchedule
                updateBuildingUsageHours={setUpdatedUsageHours}
                buildingUsageHours={updatedUsageHours}
                setValidDays={setValidDays}
                validDays={validDays}
            />
            {updateError && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${updateError.error}`)} />}
            {displayRadioRegionSubmitError && (
                <ResponseBox text="LocationRfRegion.InvalidChange" subtext={radioRegionErrorSubText} />
            )}
            <div className="form__button-container change-location__form__buttons">
                <PrimaryButton
                    type="button"
                    id="closeEditBuilding"
                    testAttr="cancel-create-building-button"
                    title="Cancel"
                    disabled={false}
                    loading={false}
                    onClick={onClose}
                />
                <PrimaryButton
                    type="button"
                    id="saveLocationDetailsButton"
                    testAttr="create-building-button"
                    title="Save"
                    disabled={rfRegionValidationLoading}
                    loading={updateLoading}
                    onClick={onSubmit}
                    filled
                    testId="save-location-details-button"
                />
            </div>
        </form>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, rfRegionResponse },
        commonRequests: {
            [CommonRequestType.ValidateLocationRfRegion]: { loading: rfRegionValidationLoading },
        },
    } = state;
    return {
        locations,
        rfRegionValidationLoading,
        rfRegionResponse,
    };
};

export default connect(mapStateToProps)(BuildingFormComponent);
