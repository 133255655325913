import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    UpdateUserPreferences,
    updateUserPreferences,
    UpdateUserSettingsPayload,
} from '../../../actions/SettingsActions';
import { analyticsLogger, PageType } from '../../../analytics';
import { ACCOUNT_SAVED_SETTINGS, ACCOUNT_SELECTED_LANGUAGE } from '../../../analytics/AnalyticsEvents';
import { getLanguageNameString } from '../../../commonFunctions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import ButtonWrapper from '../../../components/containers/ButtonWrapper';
import Dropdown from '../../../components/dropdown/MultipleAttrDropdown';
import RadioButtons from '../../../components/input/Radio';
import getUserPreferenceSelectors, { userSettingsSelectors } from '../../../components/units/UserPreferenceOptions';
import { languages } from '../../../constants';
import { DashboardLocale, Units } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';
import styles from './EditUserPreferences.module.scss';

type StateProps = {
    userName: string;
    loading: boolean;
    dateFormat: string;
    units: Units;
    language: string;
};

type ActionProps = {
    onSubmit: (data: UpdateUserSettingsPayload) => void;
};

export type ParentProps = {
    onClose: () => void;
};

type Props = StateProps & ActionProps & ParentProps;

export const EditUserPreferencesComponent = (props: Props): React.ReactElement => {
    const { loading, onClose, language, units, dateFormat, userName, onSubmit } = props;
    const [selectedRadonUnit, setSelectedRadonUnit] = useState(units.radonUnit);
    const [selectedTempUnit, setSelectedTempUnit] = useState(units.tempUnit);
    const [selectedPressureUnit, setSelectedPressureUnit] = useState(units.pressureUnit);
    const [selectedVocUnit, setSelectedVocUnit] = useState(units.vocUnit);
    const [selectedDateFormat, setSelectedDateFormat] = useState(dateFormat);
    const [selectedLengthUnit, setSelectedLengthUnit] = useState(units.lengthUnit);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const languageTag = selectedLanguage.split('-')[0];

    useEffect((): void => {
        if (!loading && isSubmitting) {
            setIsSubmitting(false);
            onClose();
        }
    }, [loading]);

    const onUpdateSelector = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const { value, name } = e.currentTarget;
        const selectorName = name;
        if (selectorName === userSettingsSelectors.dateFormat) setSelectedDateFormat(value);
        if (selectorName === userSettingsSelectors.radonUnit) setSelectedRadonUnit(value);
        if (selectorName === userSettingsSelectors.tempUnit) setSelectedTempUnit(value);
        if (selectorName === userSettingsSelectors.pressureUnit) setSelectedPressureUnit(value);
        if (selectorName === userSettingsSelectors.vocUnit) setSelectedVocUnit(value);
        if (selectorName === userSettingsSelectors.lengthUnit) setSelectedLengthUnit(value);
    };

    const onChangeLanguage = (languageSelected: { id: string; inputValue: string }): void => {
        setSelectedLanguage(languageSelected.id);
        analyticsLogger(ACCOUNT_SELECTED_LANGUAGE, {
            pageType: PageType.Account,
            language: languageSelected.inputValue,
        });
    };

    const onSubmitUserInfo = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setIsSubmitting(true);
        moment.locale(selectedLanguage);
        onSubmit({
            name: userName,
            units: {
                radonUnit: selectedRadonUnit,
                tempUnit: selectedTempUnit,
                pressureUnit: selectedPressureUnit,
                lengthUnit: selectedLengthUnit,
                vocUnit: selectedVocUnit,
            },
            dateFormat: selectedDateFormat,
            language: selectedLanguage as DashboardLocale | undefined,
        });
        analyticsLogger(ACCOUNT_SAVED_SETTINGS, {
            pageType: PageType.Account,
            radonUnit: selectedRadonUnit,
            tempUnit: selectedTempUnit,
            pressureUnit: selectedPressureUnit,
            dateFormat: selectedDateFormat,
            lengthUnit: selectedLengthUnit,
            vocUnit: selectedVocUnit,
            selectedLanguage,
        });
    };
    const languageOptions = Object.keys(languages)
        .map(key => ({ id: key, inputValue: languages[key] }))
        .sort((lang1, lang2) => lang1.inputValue.localeCompare(lang2.inputValue));

    const languageOptionsWithCurrentSelection = (): { id: string; inputValue: string; disabled?: boolean }[] => {
        if (Object.keys(languages).includes(languageTag)) return languageOptions;
        return [
            ...languageOptions,
            { id: languageTag, inputValue: getLanguageNameString(selectedLanguage), disabled: true },
        ];
    };

    const settingsRow = (
        settingId: string,
        options: { label: string; value: string }[],
        header: string,
        selectedValue: string
    ): React.ReactElement => {
        return (
            <div className={styles.selectorRow} key={settingId}>
                <RadioButtons
                    buttons={options}
                    selectorName={settingId}
                    row
                    header={header}
                    onChange={onUpdateSelector}
                    value={selectedValue}
                    labelId={`user-settings-${settingId}`}
                />
            </div>
        );
    };

    return (
        <form>
            <div>
                <div className="form__field form__field--single-width">
                    <Dropdown
                        id="language-selector"
                        title="Language"
                        options={languageOptionsWithCurrentSelection()}
                        loading={false}
                        defaultOption={languages[selectedLanguage]}
                        value={getLanguageNameString(selectedLanguage)}
                        onSelect={onChangeLanguage}
                        testAttr="language-dropdown"
                        testId="language-dropdown"
                    />
                </div>
            </div>
            {getUserPreferenceSelectors(
                settingsRow,
                selectedRadonUnit,
                selectedTempUnit,
                selectedPressureUnit,
                selectedVocUnit,
                selectedDateFormat,
                selectedLengthUnit
            )}
            <ButtonWrapper>
                <PrimaryButton title="Cancel" onClick={onClose} disabled={loading} />
                <PrimaryButton
                    id="editUserPreferencesButton"
                    title="SaveChanges"
                    loading={loading}
                    onClick={onSubmitUserInfo}
                    filled
                />
            </ButtonWrapper>
        </form>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat, userName, units, language },
        commonRequests: {
            [CommonRequestType.UpdateUserPreferences]: { loading },
        },
    } = state;

    return {
        dateFormat,
        userName,
        units,
        loading,
        language,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onSubmit: (settings): UpdateUserPreferences => dispatch(updateUserPreferences(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPreferencesComponent);
