import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { renameSegment, RenameSegment } from 'commons/src/actions/DeviceActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceType, ErrorType } from 'commons/src/models/commonTypeScript';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { Store } from '../../../reducers';
import styles from './DeviceDetails.module.scss';

type StateProps = {
    device: DeviceType;
    loading: boolean;
    error?: ErrorType;
};

type ActionProps = {
    onRenameSegment: (serialNumber: string, segmentId: string, name: string) => void;
};

export type PassedProps = {
    serialNumber: string;
    segmentId: string;
    onClose: () => void;
};

export type Props = PassedProps & ActionProps & StateProps;

export const DeviceDetailsComponent = (props: Props): React.ReactElement => {
    const { device, segmentId, error, onClose, onRenameSegment, loading, serialNumber } = props;

    const { t: txt } = useTranslation();

    const [roomName, setRoomName] = useState(device.segmentName);
    const [roomNameIsValid, setRoomNameIsValid] = useState(false);
    const [displayRoomNameValidation, setDisplayRoomNameValidation] = useState(false);

    const onChangeRoomName = (e: SyntheticEvent<HTMLInputElement>): void => {
        const updatedRoomName = e.currentTarget.value.trim();
        setRoomName(updatedRoomName);
        setDisplayRoomNameValidation(false);
    };

    const validateRoomName = (): boolean => {
        const validRoomName = !!roomName && roomName.length > 0;
        setDisplayRoomNameValidation(!validRoomName);
        setRoomNameIsValid(validRoomName);
        return validRoomName;
    };

    const submit = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        const validRoomName = validateRoomName();
        if (validRoomName) {
            onRenameSegment(serialNumber, segmentId, roomName);
        }
    };

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="change-location__form">
                <Input
                    type="text"
                    id="room_name"
                    label="Name"
                    defaultValue={roomName}
                    required
                    maxLength={50}
                    hint="RoomNameHint"
                    isValid={roomNameIsValid}
                    validate={displayRoomNameValidation}
                    onChange={onChangeRoomName}
                />
                <div className={styles.text}>{txt('DeviceSettings.DeviceSettingsMoved')}</div>
                {error && <ResponseBox text={`ErrorCodes.${error}`} />}
                <div className={styles.buttonWrapper}>
                    <PrimaryButton id="close" title="Close" loading={false} onClick={onClose} />
                    <PrimaryButton
                        id="submit"
                        type="submit"
                        title="SaveChanges"
                        filled
                        loading={loading}
                        onClick={submit}
                        testId="save-changes-device-info"
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store, passedProps: PassedProps): StateProps => {
    const {
        devicePage: { loading },
        devices: { devices },
        commonRequests: {
            [CommonRequestType.RenameSegment]: { loading: renameSegmentLoading, error },
        },
    } = state;

    const device = devices[passedProps.serialNumber];
    return {
        device,
        error,
        loading: loading || renameSegmentLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onRenameSegment: (serialNumber, segmentId, name): RenameSegment =>
        dispatch(renameSegment(serialNumber, segmentId, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsComponent);
