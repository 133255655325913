import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_SELECTED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { roleRestrictions } from 'commons/src/constants';
import { ButtonColor, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';

export const openCsvModalButton = (openCsvModal: () => void): ActionButton => ({
    onClick: (): void => openCsvModal(),
    icon: <MaterialIcon name="download" />,
    title: 'ExportToCSV',
    id: 'deviceExportToCSV',
    color: ButtonColor.primary,
    testAttr: 'device-export-to-csv',
    requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
    requiredGroupTypes: [],
});

export const deviceActionButtons = (
    openCsvModal: () => void,
    deviceWithRadonSensor: boolean,
    navigate: NavigateFunction,
    onDeleteModalOpen?: () => void
): ActionButton[] => {
    const settingsDropdownOptions: ActionButton[] = [openCsvModalButton(openCsvModal)];

    if (deviceWithRadonSensor) {
        const generateRadonReportMenuItem: ActionButton = {
            onClick: (): void => {
                navigate('radon-report');
                analyticsLogger(INSIGHT_SELECTED_REPORT, {
                    pageType: PageType.Device,
                    reportType: ReportType.Radon,
                });
            },
            icon: <MaterialIcon name="assignment" />,
            title: 'RadonReport',
            testAttr: 'download-radon-report',
            id: 'downloadRadonReport',
            color: ButtonColor.noBackground,
            requiredRoleLevel: roleRestrictions.reports,
            requiredGroupTypes: [],
        };
        settingsDropdownOptions.unshift(generateRadonReportMenuItem);
    }
    if (onDeleteModalOpen) {
        const deleteDeviceMenuItem = {
            onClick: onDeleteModalOpen,
            title: 'DeleteEndedSegment',
            color: ButtonColor.alert,
            testAttr: 'delete-ended-segment',
            id: 'deleteEndedSegment',
            requiredRoleLevel: roleRestrictions.editDeviceOrBuilding,
            requiredGroupTypes: [],
        };
        settingsDropdownOptions.push(deleteDeviceMenuItem);
    }
    return settingsDropdownOptions;
};
