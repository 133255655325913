import React from 'react';
import { Link } from 'react-router-dom';

import styles from './DeviceName.module.scss';

type DeviceNameProps = {
    type?: 'hub' | 'device';
    name: string;
    serialNumber?: string;
};

const DeviceName = ({ type, name, serialNumber }: DeviceNameProps): React.ReactElement => {
    if (!type || !serialNumber) {
        return (
            <div className={styles.deviceNameWrapper}>
                <span className={styles.deviceName} title={name}>
                    {name}
                </span>
                {serialNumber && <sub className={styles.deviceSerialNumber}>{serialNumber}</sub>}
            </div>
        );
    }
    const to = `/${type === 'device' ? 'devices' : type}/${serialNumber}`;
    return (
        <div className={styles.deviceNameWrapper}>
            <Link to={to} className={styles.deviceName} target="_blank" rel="noopener noreferrer" title={name}>
                {name}
            </Link>
            {serialNumber && <sub className={styles.deviceSerialNumber}>{serialNumber}</sub>}
        </div>
    );
};

export default DeviceName;
