import React, { useEffect, useState, SyntheticEvent, FormEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import {
    DeleteThirdPartyIntegration,
    deleteThirdPartyIntegration,
    RegisterThirdPartyIntegrationMapping,
    registerThirdPartyIntegrationMapping,
    UpdateThirdPartyIntegrationMapping,
    updateThirdPartyIntegrationMapping,
} from '../../../actions/thirdPartyIntegrationActions';
import { thirdPartyChannels } from '../../../constants';
import {
    ThirdPartyAddedIntegration,
    ThirdPartyIntegrationMapping,
    ThirdPartyMappedLocations,
    NewThirdPartyMapping,
    ThirdPartyMappingLocation,
} from '../../../models/common';
import { Store } from '../../../reducers';
import LocationsSelectorSection from './LocationsSelectorSection';
import MappedTile from './MappedTile';

type StateProps = {
    saveLoading: boolean;
    deleteIntegrationLoading: boolean;
    thirdPartyMapping: ThirdPartyIntegrationMapping | undefined;
};

type ActionProps = {
    registerMapping: (integrationId: string, thirdPartyMapping: NewThirdPartyMapping[]) => void;
    updateIntegration: (integrationId: string, thirdPartyMapping: NewThirdPartyMapping[]) => void;
    deleteIntegration: (integrationId: string) => void;
};

export type ParentProps = {
    integration: ThirdPartyAddedIntegration | undefined;
    storeMapping?: (mapping: ThirdPartyMappedLocations[]) => void;
};

type Props = StateProps & ActionProps & ParentProps;

export const ThirdPartyMappingFormComponent = (props: Props): React.ReactElement | null => {
    const {
        integration,
        thirdPartyMapping,
        registerMapping,
        saveLoading,
        updateIntegration,
        deleteIntegrationLoading,
        deleteIntegration,
        storeMapping,
    } = props;

    const { t: txt } = useTranslation();
    const { integrationId } = useParams<'integrationId'>();

    const [selectedExternalLocation, setSelectedExternalLocation] = useState<ThirdPartyMappingLocation | undefined>(
        undefined
    );
    const [selectedLocation, setSelectedLocation] = useState<ThirdPartyMappingLocation | undefined>(undefined);
    const [externalLocationOptions, setExternalLocationOptions] = useState<ThirdPartyMappingLocation[]>([]);
    const [locationOptions, setLocationOptions] = useState<ThirdPartyMappingLocation[]>([]);
    const [mappedLocations, setMappedLocations] = useState<ThirdPartyMappedLocations[]>([]);
    const [filterLocation, setFilterLocation] = useState('');
    const [filterExternalLocation, setFilterExternalLocation] = useState('');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [editingIntegration] = useState(!!integrationId);
    const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

    const sortLocations = (locations: ThirdPartyMappingLocation[]): ThirdPartyMappingLocation[] =>
        locations.sort((loc1, loc2) => loc1.name.localeCompare(loc2.name));

    useEffect(() => {
        if (thirdPartyMapping) {
            const foundMappings: ThirdPartyMappedLocations[] = [];
            thirdPartyMapping.mapping.forEach(mappedItem => {
                const foundLocation = thirdPartyMapping.locations.find(item => item.id === mappedItem.locationId);
                const foundExternalLocation = thirdPartyMapping.externalLocations.find(
                    item => item.id === mappedItem.externalLocationId
                );
                if (foundLocation && foundExternalLocation) {
                    foundMappings.push({
                        id: foundExternalLocation.id,
                        location: foundLocation,
                        externalLocation: foundExternalLocation,
                    });
                }
            });

            const unmappedLocations = thirdPartyMapping.locations.filter(
                item => !foundMappings.find(loc => loc.location.id === item.id)
            );
            const unmappedExternalLocations = thirdPartyMapping.externalLocations.filter(
                item => !foundMappings.find(loc => loc.externalLocation.id === item.id)
            );

            setExternalLocationOptions(sortLocations(unmappedExternalLocations));
            setLocationOptions(sortLocations(unmappedLocations));
            setMappedLocations(foundMappings);
        }
    }, [thirdPartyMapping]);

    if (!integration) return null;

    const onSearchUpdate = (value: string, id: string): void => {
        const valueAsLoweCase = value.trim().toLowerCase();

        if (id === 'search-location') setFilterLocation(valueAsLoweCase);
        else setFilterExternalLocation(valueAsLoweCase);
    };

    const linkSelectedLocations = (): void => {
        if (!selectedExternalLocation || !selectedLocation) {
            setDisplayValidation(true);
            return;
        }

        const newMappedLocation = {
            id: selectedExternalLocation.id,
            location: selectedLocation,
            externalLocation: selectedExternalLocation,
        };

        const unmappedExternalLocations = externalLocationOptions.filter(
            externalLocation => externalLocation.id !== selectedExternalLocation.id
        );
        const unmappedLocations = locationOptions.filter(locationItem => locationItem.id !== selectedLocation.id);
        setExternalLocationOptions(unmappedExternalLocations);
        setLocationOptions(unmappedLocations);
        setMappedLocations([newMappedLocation, ...mappedLocations]);
        setSelectedExternalLocation(undefined);
        setSelectedLocation(undefined);
        setDisplayValidation(false);
    };

    const setSelectedOption = (locationId: string, selectorId: string): void => {
        const selectedLocationProps =
            (externalLocationOptions && externalLocationOptions.find(location => location.id === locationId)) ||
            (locationOptions && locationOptions.find(location => location.id === locationId));
        if (selectorId === 'locations') setSelectedLocation(selectedLocationProps);
        else setSelectedExternalLocation(selectedLocationProps);
    };

    const unlinkSelectedLocations = (mapping: ThirdPartyMappedLocations): void => {
        const newMappedLocations = mappedLocations.filter(option => option.id !== mapping.id);
        const unmappedExternalLocations = [...externalLocationOptions, mapping.externalLocation];
        const unmappedLocations = [...locationOptions, mapping.location];

        setMappedLocations(newMappedLocations);
        setExternalLocationOptions(sortLocations(unmappedExternalLocations));
        setLocationOptions(sortLocations(unmappedLocations));
        setDisplayValidation(false);
    };

    const submitMapping = (e: SyntheticEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (mappedLocations.length > 0) {
            const mapping = mappedLocations.map(location => ({
                locationId: location.location.id,
                externalLocationId: location.externalLocation.id,
            }));

            if (!editingIntegration && storeMapping) {
                storeMapping(mappedLocations);
                registerMapping(integration.id, mapping);
            } else updateIntegration(integration.id, mapping);
        } else setDisplayValidation(true);
    };

    const onDeleteClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        setDisplayDeleteModal(true);
    };

    const closeDeleteModal = (): void => {
        setDisplayDeleteModal(false);
    };
    const onDeleteIntegration = (): void => {
        deleteIntegration(integration.id);
    };

    const integrationTypeTranslation = txt(`ThirdParty.${integration.type}`);
    const filteredLocations = locationOptions.filter(option => option.name.toLowerCase().includes(filterLocation));
    const filteredExternalLocations = externalLocationOptions.filter(option =>
        option.name.toLowerCase().includes(filterExternalLocation)
    );
    const hasMapping = integration.type !== thirdPartyChannels.SLACK;

    return (
        <div className="page-wrapper__medium page-wrapper__medium--white">
            {displayDeleteModal && (
                <DeleteConfirmModal
                    title="ThirdParty.DeleteIntegration"
                    description={txt('ThirdParty.DeleteIntegrationDescription')}
                    onSubmit={onDeleteIntegration}
                    onCancel={closeDeleteModal}
                    onSubmitText="Delete"
                    onCancelText="Cancel"
                    loading={deleteIntegrationLoading}
                />
            )}
            <form className="form__wide-container third-party" onSubmit={submitMapping}>
                <h2 className={classNames({ 'form__header--padding-bottom': editingIntegration })}>
                    {editingIntegration
                        ? txt('ThirdParty.IntegrationWith', { integration: integrationTypeTranslation })
                        : txt('ThirdParty.MappingHeader')}
                </h2>
                {!editingIntegration ? (
                    <p className="text-large third-party__padding--large">
                        {txt('ThirdParty.MappingDescription', { integration: integrationTypeTranslation })}
                    </p>
                ) : (
                    <p className="centered text-large third-party__padding--medium">
                        {[txt('Name'), ': ', integration.name]}
                    </p>
                )}
                {hasMapping && (
                    <>
                        <div className="form__row third-party__mapping">
                            <LocationsSelectorSection
                                invertedMargin={false}
                                locations={filteredExternalLocations}
                                headerTitle="ThirdParty.ChooseExternalLocation"
                                inputId="search-external-location"
                                integrationType={integration.type}
                                onSearch={onSearchUpdate}
                                setSelectedLocation={setSelectedOption}
                                selectedLocation={selectedExternalLocation}
                                selectorId="external-locations"
                            />
                            <LocationsSelectorSection
                                invertedMargin
                                locations={filteredLocations}
                                headerTitle="ThirdParty.ChooseLocation"
                                integrationType={integration.type}
                                inputId="search-location"
                                onSearch={onSearchUpdate}
                                setSelectedLocation={setSelectedOption}
                                selectedLocation={selectedLocation}
                                selectorId="locations"
                            />
                        </div>
                        {displayValidation && !selectedLocation && filteredExternalLocations.length > 0 && (
                            <ResponseBox text="ThirdParty.LocationOptionHint" />
                        )}
                        <div className="form__row form__button-container third-party__mapping__button third-party--border">
                            <PrimaryButton
                                type="button"
                                title="ThirdParty.MapLocations"
                                onClick={linkSelectedLocations}
                                loading={false}
                                testAttr="map-locations"
                                icon={<MaterialIcon name="link" />}
                            />
                        </div>
                        {mappedLocations.length > 0 &&
                            mappedLocations.map(mapping => (
                                <MappedTile
                                    key={mapping.id}
                                    mapping={mapping}
                                    onUnlink={unlinkSelectedLocations}
                                    displayCircle
                                />
                            ))}
                    </>
                )}
                <div
                    className={classNames('form__row form__button-container third-party__button-margin--small', {
                        'third-party__button-margin--medium': mappedLocations.length === 0,
                    })}
                >
                    {editingIntegration && (
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                type="button"
                                title="Delete"
                                alert
                                id="delete"
                                onClick={onDeleteClick}
                                loading={deleteIntegrationLoading}
                                testAttr="delete-integration"
                                testId="delete-integration"
                            />
                        </div>
                    )}
                    {hasMapping && (
                        <div className="form__attr--element centered">
                            <PrimaryButton
                                type="submit"
                                title={editingIntegration ? 'Save' : 'Submit'}
                                filled
                                onClick={submitMapping}
                                loading={saveLoading}
                                testAttr="submit-mapping"
                            />
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            REGISTER_THIRD_PARTY_INTEGRATION_MAPPING: { loading: registerMappingLoading },
            UPDATE_THIRD_PARTY_INTEGRATION_MAPPING: { loading: updateIntegrationLoading },
            DELETE_THIRD_PARTY_INTEGRATION: { loading: deleteIntegrationLoading },
        },
        thirdPartyIntegrations: { thirdPartyMapping },
    } = state;
    return {
        saveLoading: registerMappingLoading || updateIntegrationLoading,
        deleteIntegrationLoading,
        thirdPartyMapping,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    registerMapping: (integrationId, thirdPartyMapping: NewThirdPartyMapping[]): RegisterThirdPartyIntegrationMapping =>
        dispatch(registerThirdPartyIntegrationMapping(integrationId, thirdPartyMapping)),
    updateIntegration: (integrationId, thirdPartyMapping: NewThirdPartyMapping[]): UpdateThirdPartyIntegrationMapping =>
        dispatch(updateThirdPartyIntegrationMapping(integrationId, thirdPartyMapping)),
    deleteIntegration: (integrationId: string): DeleteThirdPartyIntegration =>
        dispatch(deleteThirdPartyIntegration(integrationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyMappingFormComponent);
