import { sensorUnits } from '../../constants';
import { NetTypes } from '../../models/commonEnums';
import { HubResponseMetaData } from '../../models/commonTypeScript';

export const getFirmwareVersionString = (metaData: HubResponseMetaData): string[] => {
    const bleVersion = metaData.bleFirmwareVersion ? ['BLE ', metaData.bleFirmwareVersion] : [];
    const stVersion = metaData.stFirmwareVersion ? [', ST ', metaData.stFirmwareVersion] : [];
    const subVersion = metaData.subFirmwareVersion ? [', SUB ', metaData.subFirmwareVersion] : [];
    return [...bleVersion, ...stVersion, ...subVersion];
};

export const getHubConnectionString = (metaData: HubResponseMetaData, txt: (text: string) => string): string => {
    let hubConnectionType = '-';
    const { net, rssi, rsrq, cell } = metaData;
    if (cell !== undefined) {
        hubConnectionType = txt(metaData.cell ? 'HubStrings.SIM' : 'HubStrings.Ethernet');
        if (metaData.cell && metaData.net) {
            hubConnectionType = `${hubConnectionType}, ${net}`;
            if (net === NetTypes['2G'] && rssi) {
                hubConnectionType = `${hubConnectionType} (RSSI: ${rssi} ${sensorUnits.dbm})`;
            } else if (net === NetTypes['4G'] && rsrq) {
                hubConnectionType = `${hubConnectionType} (RSRQ: ${rsrq} ${sensorUnits.db})`;
            }
        }
    }
    return hubConnectionType;
};
