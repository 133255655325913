import React, { useEffect } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import CommonDeviceOverview from 'commons/src/features/devicePage/CommonDeviceOverview';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { BuildingType, FullDeviceData } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { fetchBuilding } from '../../actions/locationActions';
import { Store } from '../../reducers';
import { deviceActionButtons } from './DeviceMenuOptions';
import InsightInfoButtons from './InsightInfoButtons';

type ConnectedProps = {
    devicePageDevices: { [serialNumber: string]: FullDeviceData };
    devicePageLoading: boolean;
    fetching: boolean;
    buildings: { [buildingId: string]: BuildingType };
};

export type Props = ConnectedProps;

export const DeviceOverviewComponent = ({
    devicePageDevices,
    fetching,
    devicePageLoading,
    buildings,
}: Props): React.ReactElement => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const device = devicePageDevices[serialNumber] || {};
    const buildingId = device.locationId;
    const building = (device && buildings[buildingId as keyof typeof buildings]) || undefined;
    const deviceWithVirusRisk =
        device && device.sensors && device.sensors.some(sensor => sensor.type === SensorTypes.virusRisk);
    const deviceWithRadonSensor =
        device && device.sensors && device.sensors.some(sensor => sensor.type === SensorTypes.radonShortTermAvg);

    useEffect(() => {
        if (!building && buildingId) {
            dispatch(fetchBuilding(buildingId));
        }
    }, [buildingId]);

    const actionButtons: ActionButton[] = deviceActionButtons(
        deviceWithRadonSensor,
        device.deviceType,
        serialNumber,
        navigate,
        `/buildings/${device.locationId}/spaces/${device.spaceId}`
    );

    return (
        <CommonDeviceOverview actionButtons={actionButtons} timeZone={building?.timezone} serialNumber={serialNumber}>
            {deviceWithVirusRisk ? (
                <ReactPlaceholder ready={!devicePageLoading && !fetching} customPlaceholder={fullwidthListElement}>
                    <InsightInfoButtons sensor={SensorTypes.virusRisk} />
                </ReactPlaceholder>
            ) : (
                <div />
            )}
        </CommonDeviceOverview>
    );
};

const mapStateToProps = (state: Store): ConnectedProps => {
    const {
        devicePage: { devices: devicePageDevices, loading: devicePageLoading, fetching },
        buildings: { buildings },
    } = state;

    return {
        devicePageDevices,
        devicePageLoading,
        fetching,
        buildings,
    };
};

export default connect(mapStateToProps)(DeviceOverviewComponent);
