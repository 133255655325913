import React, { SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { businessPaths as paths } from 'commons/src/constants';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_CLICKED_TILE } from '../../analytics/AnalyticsEvents';
import { getDotColor } from '../../commonFunctions';
import SensorLabel from '../../components/sensors/SensorLabel';
import { DashboardVisibility } from '../../models/commonEnums';
import { IndoorVsOutdoorTileContent } from '../../models/commonTypeScript';
import CompareCard from '../indoorVsOutdoorTile/CompareCard';
import TileHeaderMenu from './TileHeaderMenu';

type Props = {
    visibility: DashboardVisibility;
    menuProps: { tileId: string; tileRef: string; removeTile: (tileId: string, tileRef: string) => void };
    isDragging?: boolean;
    comparisonData: IndoorVsOutdoorTileContent;
};

const IndoorVsOutdoorTile = (props: Props): React.ReactElement => {
    const { menuProps, isDragging, comparisonData, visibility } = props;

    const {
        locationId,
        locationName,
        serialNumber,
        segmentName,
        sensorType,
        unit,
        currentSensorValue,
        currentWeatherValue,
        weatherIcon,
        thresholds,
    } = comparisonData;

    const url = { pathname: paths.outdoorInsightPage, state: { locationId, serialNumber, generateReport: true } };

    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        } else {
            analyticsLogger(DASHBOARD_CLICKED_TILE, { pageType: PageType.Dashboard, tileType: 'outdoorComparison' });
        }
    };

    const dotColor = getDotColor(thresholds, sensorType, currentSensorValue);

    return (
        <NavLink to={url} onClick={preventLink} className="tile-wrapper__link page-wrapper__column">
            <div className="tile-wrapper">
                <div className="tile-header">
                    <div className="tile-header--info">
                        <div className="tile-header__title">
                            <h2 className="tile-header__title--name">{segmentName}</h2>
                            <span className="tile-header__title--address">{locationName}</span>
                        </div>
                        <SensorLabel sensorType={sensorType} />
                    </div>
                    <div className="tile-header__controls">
                        <TileHeaderMenu
                            visibility={visibility}
                            tileId={menuProps.tileId}
                            onRemoveTile={(): void => menuProps.removeTile(menuProps.tileId, menuProps.tileRef)}
                            isSensorTile={false}
                        />
                    </div>
                </div>
                <div className="tile-body--compare tile-body--full-height">
                    <CompareCard
                        sensorType={sensorType}
                        sensorUnit={unit}
                        sensorValue={currentSensorValue}
                        dotColor={dotColor}
                        isIndoor
                    />
                    <CompareCard
                        sensorType={sensorType}
                        sensorUnit={unit}
                        sensorValue={currentWeatherValue}
                        weatherIcon={weatherIcon}
                        isIndoor={false}
                    />
                </div>
            </div>
        </NavLink>
    );
};
IndoorVsOutdoorTile.defaultProps = {
    isDragging: false,
};

export default IndoorVsOutdoorTile;
