import React from 'react';
import { useTranslation } from 'react-i18next';

import ReactPlaceHolder from 'react-placeholder';
import SectionHeader from 'commons/src/components/headers/SectionHeader';
import DeviceStatusPill from 'commons/src/components/pills/DeviceStatusPill';
import Connections from 'commons/src/components/tableCells/Connections';
import DeviceName from 'commons/src/components/tableCells/DeviceName';
import Firmware from 'commons/src/components/tableCells/Firmware';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import MaterialTable from '../../../components/materialTable/MaterialTable';
import MaterialTablePlaceholder from '../../../components/materialTable/MaterialTablePlaceholder';
import { MaterialTableColumn } from '../../../components/materialTable/models';
import { BUILDING_HEALTH_TABLE_LENGTH } from '../../../constants';
import { BuildingHealthRowData } from '../../../models/buildingHealthModels';

type Props = {
    hubs: BuildingHealthRowData[];
    isLoading?: boolean;
};

const HubsTable = ({ hubs, isLoading }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();

    const columns: MaterialTableColumn[] = [
        {
            accessor: 'segmentName',
            label: txt('HealthStatus.Name'),
            sortable: true,
            width: '150px',
            locked: true,
            renderer: ({ rowData }): React.ReactNode => (
                <DeviceName
                    name={rowData.segmentName as string}
                    serialNumber={rowData.serialNumber as string}
                    type="hub"
                />
            ),
        },
        {
            accessor: 'firmware',
            label: txt('HealthStatus.Firmware'),
            sortable: false,
            width: '200px',
            defaultHidden: true,
            renderer: ({ rowData }): React.ReactNode => <Firmware firmware={rowData.firmware as object} />,
            infoBox: txt('HealthStatus.FirmwareTooltip'),
        },
        {
            accessor: 'region',
            label: txt('HealthStatus.RadioRegion'),
            sortable: false,
            width: '90px',
            defaultHidden: true,
        },
        {
            accessor: 'connections',
            label: txt('HealthStatus.Connections'),
            sortable: false,
            width: '70px',
            renderer: ({ rowData }): React.ReactNode => {
                return <Connections {...rowData} />;
            },
        },
        {
            accessor: 'healthStatus',
            label: txt('HealthStatus.Status'),
            sortable: true,
            renderer: ({ rowData }): React.ReactNode => {
                return <DeviceStatusPill status={rowData.healthStatus as DeviceHealthStatus} deviceType="hub" />;
            },
            width: '280px',
        },
    ];

    return (
        <div>
            <SectionHeader headerType={3} headerName={txt('Hubs')} initialHeader />
            <ReactPlaceHolder ready={!isLoading} customPlaceholder={<MaterialTablePlaceholder rows={5} cells={3} />}>
                <MaterialTable
                    idKey="serialNumber"
                    tableId="building_health_hubs"
                    columns={columns}
                    data={hubs}
                    isReady
                    totalDataLength={hubs.length}
                    pagination={BUILDING_HEALTH_TABLE_LENGTH}
                    allowColumnManagement
                />
            </ReactPlaceHolder>
        </div>
    );
};

export default HubsTable;
