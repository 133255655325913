import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import moment, { Moment, unitOfTime } from 'moment';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_PRESENCE_INSIGHT_DOWNLOAD_CSV } from 'commons/src/analytics/AnalyticsEvents';
import { setCustomPeriod } from 'commons/src/commonFunctions';
import FilterButton from 'commons/src/components/buttons/FilterButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DatePicker from 'commons/src/components/DatePicker';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { dateFormats } from 'commons/src/constants';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo } from 'commons/src/models/commonTypeScript';
import { PresenceByDevice, PresenceOverTime, PresenceRequest } from '../../../models/buildingModels';
import { Store } from '../../../reducers';
import RequestType from '../../../reducers/BusinessRequestType';
import styles from './PresenceInsightTimeSelector.module.scss';

type ParentProps = {
    buildingHasUsageHours: boolean;
    setWithOpeningHours: (withinOpeningHours: boolean) => void;
    withOpeningHours: boolean;
    selectedPeriod: TimePeriod;
    setSelectedPeriod: (newSelectedPeriod: {
        name: TimePeriod;
        fromDate: string;
        toDate: string;
        label?: string;
    }) => void;
    buildingId: string;
    presenceInsight?: {
        presenceOverTime: PresenceOverTime[];
        presenceByDevice: PresenceByDevice[];
        selectedPeriod: PresenceRequest;
    };
    buildingName: string;
};

type StateProps = {
    dateFormat: string;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    filters: {
        [locationId: string]: {
            [filterType: string]: { name: string; devices: string[] }[];
        };
    };
    loadingInsights: boolean;
};

type Props = ParentProps & StateProps;

const PresenceInsightTimeSelector = ({
    buildingHasUsageHours,
    setWithOpeningHours,
    withOpeningHours,
    selectedPeriod,
    setSelectedPeriod,
    dateFormat,
    presenceInsight,
    devicesWithKeyInfo,
    buildingId,
    filters,
    buildingName,
    loadingInsights,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [displayDatePicker, setDisplayDatePicker] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const filtersForBuilding = filters[buildingId] || {};
    const csvFileName = `${buildingName} - ${txt('PresenceInsight.SpaceUtilization')}.csv`;

    const isOutsideDateRange = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;

        const today = moment();
        const isBeforeBackCalculationStarted = day.isBefore(moment('2022-01-01'));
        return isBeforeBackCalculationStarted || day.isAfter(today.endOf('day'));
    };

    const isOutsideEndDateRange = (day: Moment, selectedStartDate: Moment | null): boolean => {
        const thirtyDaysAfterStartDate = selectedStartDate
            ? moment(day).endOf('day').diff(moment(selectedStartDate).add(30, 'days').endOf('day')) > 0
            : false;
        const isBeforeStartDate = moment(day).endOf('day').isBefore(moment(selectedStartDate).endOf('day'));
        if (isBeforeStartDate || thirtyDaysAfterStartDate) {
            return true;
        }
        return isOutsideDateRange(day);
    };

    const onStartDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(startDate).valueOf()) {
            setStartDate(date);
            const endDateMoreThan30DaysAfterStart =
                endDate && date && moment(date).add(30, 'days').endOf('day').isBefore(endDate);
            const startDateAfterEndDate = endDate && date && date.endOf('day').isAfter(endDate.endOf('day'));
            if (endDateMoreThan30DaysAfterStart || startDateAfterEndDate) {
                setEndDate(null);
            }
        }
    };

    const selectPeriod = (period: TimePeriod): void => {
        const to = moment().subtract(1, 'day').format('YYYY-MM-DD');
        const from = moment().subtract(1, period as unitOfTime.DurationConstructor);
        const { label } = setCustomPeriod(moment(from), moment(to));
        if (period !== TimePeriod.custom) {
            setStartDate(null);
            setEndDate(null);
        }
        setSelectedPeriod({ name: period, fromDate: from.format('YYYY-MM-DD'), toDate: to, label });
    };

    const fetchCustomTimeSpan = (): void => {
        if (startDate && endDate) {
            const { label } = setCustomPeriod(moment(startDate), moment(endDate));
            setSelectedPeriod({
                name: TimePeriod.custom,
                fromDate: startDate.format('YYYY-MM-DD'),
                toDate: endDate.format('YYYY-MM-DD'),
                label,
            });
        }
    };

    useEffect(() => {
        fetchCustomTimeSpan();
    }, [startDate, endDate]);

    const onEndDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(endDate).valueOf()) {
            setEndDate(date);
        }
    };

    const selectCustomDate = (): void => {
        if (!loadingInsights) {
            setDisplayDatePicker(!displayDatePicker);
        }
    };

    type csvData = {
        serialNumber: string;
        deviceName: string;
        totalTime: string;
        [filterOrDate: string]: string | number;
    };

    const csvData = (): csvData[] => {
        return (presenceInsight?.presenceByDevice ?? []).map(device => {
            const deviceInfo = devicesWithKeyInfo[device.serialNumber];
            const segmentProps = Object.entries(filtersForBuilding).map(([filterType, filterOptions]) => ({
                [filterType]:
                    filterOptions.find(filterOption => filterOption.devices.includes(device.serialNumber))?.name ?? '-',
            }));

            const datesInUseForDevice = (presenceInsight?.presenceOverTime ?? []).map(day => {
                const deviceUsedThisDay = day.devices.find(
                    deviceUsed => deviceUsed.serialNumber === device.serialNumber
                );
                const formattedDate = dayjs(day.date).format(
                    dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
                );
                return { [formattedDate]: deviceUsedThisDay?.minutesUsed || '-' };
            });
            return {
                deviceName: deviceInfo.segmentName,
                serialNumber: device.serialNumber,
                ...Object.assign({}, ...segmentProps),
                ...Object.assign({}, ...datesInUseForDevice),
                totalTime: device.minutesUsed,
            };
        });
    };

    const downloadCsv = (): void => {
        if (!loadingInsights) {
            analyticsLogger(BUILDING_PRESENCE_INSIGHT_DOWNLOAD_CSV, {});
            setDownloadModalOpen(true);
        }
    };

    const customTitle =
        startDate && endDate
            ? `${startDate.format(dateFormats[dateFormat as keyof typeof dateFormats].shortFormat)} - ${endDate.format(
                  dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
              )}`
            : 'TimeSelector.Custom';

    return (
        <div className={styles.wrapper}>
            {displayDatePicker ? (
                <div className={classNames('building-insight__time-selector__elements', styles.selectorOptions)}>
                    <PrimaryButton
                        icon={<MaterialIcon name={displayDatePicker ? 'close' : 'date_range'} />}
                        onClick={selectCustomDate}
                        testAttr="date-picker"
                        noBackground
                    />
                    <div className="small-padding-right">
                        <DatePicker
                            id="startDate"
                            onChange={onStartDateChanged}
                            dateFormat={dateFormat as keyof typeof dateFormats}
                            selectedDate={startDate}
                            initialVisibleMonth={moment()}
                            disabledDate={(day): boolean => isOutsideDateRange(day)}
                            placeholder="StartDate"
                        />
                    </div>
                    <DatePicker
                        id="endDate"
                        onChange={onEndDateChanged}
                        dateFormat={dateFormat as keyof typeof dateFormats}
                        selectedDate={endDate}
                        initialVisibleMonth={moment()}
                        disabledDate={(day): boolean => isOutsideEndDateRange(day, startDate)}
                        placeholder="EndDate"
                    />
                </div>
            ) : (
                <>
                    <div className={styles.selectorOptions}>
                        <FilterButton
                            title="TimeSelector.Last30Days"
                            isSelected={selectedPeriod === TimePeriod.month}
                            onClick={(): void => selectPeriod(TimePeriod.month)}
                        />
                        <FilterButton
                            title={customTitle}
                            isSelected={selectedPeriod === TimePeriod.custom}
                            onClick={selectCustomDate}
                        />
                        <CheckBox
                            disabled={!buildingHasUsageHours}
                            label="Building.DayScheduleOpeningHours"
                            id="EnableOpeningHours"
                            onChange={(): void => setWithOpeningHours(!withOpeningHours)}
                            checked={withOpeningHours}
                        />
                    </div>
                    <PrimaryButton
                        id="download-csv"
                        type="button"
                        title="CsvStrings.Csv"
                        loading={false}
                        disabled={false}
                        onClick={downloadCsv}
                        icon={<MaterialIcon name="download" />}
                        testAttr="csv-download-button"
                    />
                    {downloadModalOpen && (
                        <ReactPdfDownloadModal
                            title="Download"
                            description="PresenceInsight.DownloadPresenceInsight"
                            onClose={(): void => setDownloadModalOpen(false)}
                        >
                            <div className="form__button-container">
                                <CSVLink data={csvData()} separator=";" filename={csvFileName}>
                                    {csvFileName}
                                </CSVLink>
                            </div>
                        </ReactPdfDownloadModal>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        devices: { devicesWithKeyInfo },
        segmentPropertiesStore: { filters },
        requests: {
            [RequestType.GetPresenceData]: { loading: loadingInsights },
        },
    } = state;
    return {
        dateFormat,
        devicesWithKeyInfo,
        filters,
        loadingInsights,
    };
};

export default connect(mapStateToProps)(PresenceInsightTimeSelector);
