import React, { useRef, useState } from 'react';
import L, { latLngBounds } from 'leaflet';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MapContainer, ZoomControl } from 'react-leaflet';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    AddZoneAndDeviceToFloorplan,
    addZoneAndDeviceToFloorplan,
    RemoveDeviceFromFloorplanZone,
    removeDeviceFromFloorplanZone,
    UpdateZoneAndDeviceFloorplan,
    updateZoneAndDeviceFloorplan,
} from 'commons/src/actions/FloorPlanActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import {
    FLOORPLAN_CLICKED_ADD,
    FLOORPLAN_PLACED_DEVICE,
    FLOORPLAN_REMOVED_DEVICE,
    FLOORPLAN_SELECTED_FILTER,
} from 'commons/src/analytics/AnalyticsEvents';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import { dateFormats } from 'commons/src/constants';
import DeviceTile from 'commons/src/features/devices/DeviceTile/DeviceTile';
import HubTile from 'commons/src/features/hubs/HubTile';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import {
    AnyDeviceType,
    DeviceType,
    FloorPlanPosition,
    FloorPlanType,
    FloorplanZone,
    HubData,
} from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { floorPlanZoneHasDevice } from '../../../reducers/Floorplans';
import FloorEditor from './FloorEditor';
import FloorMapImage from './FloorMapImage';
import { MODES } from './FloorPlanConstants';
import HubMarkers from './HubMarkers';
import Toolbar from './Toolbar';
import Zones from './Zones';

type ParentProps = {
    locationId: string;
    floorplanImage: string;
    floor: FloorPlanType;
};
type StateProps = {
    devices: { [serialnumber: string]: DeviceType };
    hubs: HubData[];
    dateFormat: keyof typeof dateFormats;
};

type ActionProps = {
    addZoneAndDevice: (
        floorplanId: string,
        serialNumber: string,
        zoneBoundary: FloorPlanPosition[],
        devicePosition: FloorPlanPosition,
        locationId: string
    ) => void;
    updateZoneAndDevice: (
        floorplanId: string,
        serialNumber: string,
        zoneId: string,
        zoneBoundary: FloorPlanPosition[],
        devicePosition: FloorPlanPosition,
        locationId: string
    ) => void;
    removeZone: (floorplanId: string, zoneId: string, locationId: string) => void;
};

type Props = StateProps & ParentProps & ActionProps;

export const FloorMapComponent = ({
    removeZone,
    floor,
    devices,
    addZoneAndDevice,
    updateZoneAndDevice,
    floorplanImage,
    hubs,
    dateFormat,
    locationId,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [mode, setMode] = useState(MODES.VIEW);
    const [bounds, setBounds] = useState<L.LatLngBounds>(
        latLngBounds([
            [0, 0],
            [1000, 1000],
        ])
    );
    const [selectedSensor, setSelectedSensor] = useState<string | undefined>(undefined);
    const [selectedZone, setSelectedZone] = useState<FloorplanZone | undefined>(undefined);
    const [selectedDevice, setSelectedDevice] = useState<string | undefined>(undefined);
    const [showRemoveDeviceModal, setShowRemoveDeviceModal] = useState(false);
    const [selectedDeviceType, setSelectedDeviceType] = useState<AnyDeviceType | undefined>(undefined);
    const [mapIsReady, setMapIsReady] = useState(false);

    const floorPlanEditorRef = useRef<{ zone: L.Polygon; marker: L.Marker }>();

    const updateBounds = (): void => {
        // eslint-disable-next-line no-undef
        const image = new Image();
        image.onload = (): void => {
            const { width, height } = image;
            const newBounds = latLngBounds([
                [0, width],
                [-height, 0],
            ]);
            setBounds(newBounds);
        };
        image.src = floorplanImage;
    };

    const resetMapView = (): void => {
        setMode(MODES.VIEW);
        setSelectedZone(undefined);
    };

    const selectZone = (floorplanZone: FloorplanZone, deviceType: AnyDeviceType | undefined): void => {
        setMode(MODES.SELECTED);
        setSelectedZone(floorplanZone);
        setSelectedDeviceType(deviceType);
        setSelectedDevice(floorplanZone.device && floorplanZone.device.serialNumber);
    };

    const onZoneSave = (): void => {
        if (!(floorPlanEditorRef && floorPlanEditorRef.current)) return;
        const { zone, marker } = floorPlanEditorRef.current;
        const devicePosition = marker.getLatLng();
        const zoneBoundary = zone.getLatLngs ? (zone.getLatLngs()[0] as FloorPlanPosition[]) : [];

        if (selectedZone && floorPlanZoneHasDevice(selectedZone)) {
            const serialNumber = (selectedZone && selectedZone.device && selectedZone.device.serialNumber) || '';
            updateZoneAndDevice(floor.id, serialNumber, selectedZone.id, zoneBoundary, devicePosition, locationId);
        } else if (selectedDevice) {
            addZoneAndDevice(floor.id, selectedDevice, zoneBoundary, devicePosition, locationId);
            analyticsLogger(FLOORPLAN_PLACED_DEVICE, { pageType: PageType.Floorplan });
        }

        resetMapView();
    };

    const onMapReady = (): void => {
        updateBounds();
        resetMapView();
        setMapIsReady(true);
    };

    const onDeleteZone = (): void => {
        if (!selectedZone) return;
        removeZone(floor.id, selectedZone.id, locationId);
        setShowRemoveDeviceModal(false);

        resetMapView();
        analyticsLogger(FLOORPLAN_REMOVED_DEVICE, { pageType: PageType.Floorplan });
    };

    const onEdit = (): void => {
        if (!selectedZone) return;
        setMode(MODES.EDIT);
    };

    const drawModalMessageBox = (): React.ReactElement | null => {
        let descriptionText;
        if (mode === MODES.DRAW) descriptionText = 'FloorPlan.DrawRoomMessage';
        if (mode === MODES.EDIT) descriptionText = 'FloorPlan.DragToPlaceDevice';
        if (descriptionText) {
            return <div className="floormap__message-box">{txt(descriptionText)}</div>;
        }
        return null;
    };

    const renderRemoveDeviceModal = (): React.ReactElement | null => {
        const closeModal = (): void => {
            setShowRemoveDeviceModal(false);
        };
        if (showRemoveDeviceModal) {
            return (
                <DeleteConfirmModal
                    title="RemoveDevice"
                    description={txt('FloorPlan.RemoveFloorplanDeviceDescription')}
                    onSubmit={onDeleteZone}
                    onCancel={closeModal}
                    onSubmitText="Remove"
                    onCancelText="Cancel"
                    loading={false}
                />
            );
        }
        return null;
    };

    const drawNewZone = (serialNumber: string, deviceType: AnyDeviceType): void => {
        setMode(MODES.DRAW);
        setSelectedDevice(serialNumber);
        setSelectedDeviceType(deviceType);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onUndo = (): void => floorPlanEditorRef.current && floorPlanEditorRef.current.undo();

    const onSelectSensorFilter = (newSelectedSensor: string | undefined): void => {
        setSelectedSensor(newSelectedSensor);
        analyticsLogger(FLOORPLAN_SELECTED_FILTER, { pageType: PageType.Floorplan, filterType: newSelectedSensor });
    };

    const onSelectDevice = (serialNumber: string, deviceType: AnyDeviceType): void => {
        const isHub = deviceType === DeviceTypeNames.hub;
        if (isHub) {
            setMode(MODES.EDIT);
            setSelectedDevice(serialNumber);
            setSelectedDeviceType(deviceType);
        } else {
            drawNewZone(serialNumber, deviceType);
        }
        analyticsLogger(FLOORPLAN_CLICKED_ADD, { pageType: PageType.Floorplan, serialNumber });
    };

    const renderDeviceModal = (): React.ReactElement | null => {
        if (!selectedDevice) return null;

        if (mode === MODES.SELECTED && (Object.keys(devices).length > 0 || hubs.length > 0)) {
            const device = devices[selectedDevice];
            const selectedHub = hubs.find(hub => hub.serialNumber === selectedDevice);
            if (device) {
                return (
                    <div className="floormap__device-modal-wrapper">
                        <DeviceTile device={device} />
                    </div>
                );
            }
            if (selectedHub) {
                const numberOfDevices = selectedHub.metaData.devices
                    ? Object.keys(selectedHub.metaData.devices).length
                    : 0;
                const nowTime = moment();
                const oneHourAgo = nowTime.clone().subtract(1, 'hours').format('X');
                const numberOfConnectedDevices = selectedHub.metaData.devices
                    ? Object.values(selectedHub.metaData.devices).filter(
                          deviceSynced => deviceSynced > parseInt(oneHourAgo, 10)
                      ).length
                    : 0;

                return (
                    <div className="floormap__device-modal-wrapper">
                        <HubTile
                            name={selectedHub.name}
                            dateFormat={dateFormat.toString()}
                            serialNumber={selectedHub.serialNumber}
                            numberOfConnectedDevices={numberOfConnectedDevices}
                            numberOfDevices={numberOfDevices}
                            lastSeen={selectedHub.metaData.lastSeen}
                            connectionTypeCell={selectedHub.metaData.cell}
                        />
                    </div>
                );
            }
            return null;
        }
        return null;
    };

    const renderFloorEditor = (): React.ReactElement | null => {
        const editModes = [MODES.DRAW, MODES.EDIT];
        const isHub = selectedDeviceType === DeviceTypeNames.hub;
        if (isHub || !editModes.includes(mode)) return null;

        return (
            <FloorEditor
                innerRef={floorPlanEditorRef}
                selectedFloorPlanZone={selectedZone}
                onZoneCreated={(): void => setMode(MODES.EDIT)}
            />
        );
    };

    return (
        <div className="floormap">
            <Toolbar
                mode={mode}
                locationId={locationId}
                activeSensor={selectedSensor}
                setMode={setMode}
                onEdit={onEdit}
                onUndo={onUndo}
                onCancel={resetMapView}
                onSave={onZoneSave}
                onRemove={(): void => setShowRemoveDeviceModal(true)}
                onSelectDevice={onSelectDevice}
                onSelectSensor={onSelectSensorFilter}
                allDevices={devices}
                floorZones={floor.zones}
            />
            <MapContainer
                zoomControl={false}
                scrollWheelZoom={false}
                crs={L.CRS.Simple}
                bounds={bounds}
                className="floormap__map floormap__map--border"
                zoomSnap={0.1}
                minZoom={-4}
                whenReady={onMapReady}
            >
                <FloorMapImage
                    bounds={bounds}
                    floorPlanImage={floorplanImage}
                    mapReady={mapIsReady}
                    onMapReady={onMapReady}
                />
                {renderFloorEditor()}
                <Zones
                    floor={floor}
                    mode={mode}
                    selectedZone={selectedZone}
                    selectZone={selectZone}
                    resetMapView={resetMapView}
                    selectedSensor={selectedSensor}
                    devices={devices}
                />
                <HubMarkers
                    floor={floor}
                    selectedDevice={selectedDevice}
                    floorPlanEditorRef={floorPlanEditorRef}
                    selectedDeviceType={selectedDeviceType}
                    selectedZone={selectedZone}
                    selectZone={selectZone}
                    resetMapView={resetMapView}
                    mode={mode}
                />
                <ZoomControl position="bottomright" />
            </MapContainer>
            {renderDeviceModal()}
            {drawModalMessageBox()}
            {renderRemoveDeviceModal()}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { hubs, devices },
        userSettings: { dateFormat },
    } = state;
    return {
        devices,
        hubs,
        dateFormat,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    addZoneAndDevice: (
        floorplanId: string,
        serialNumber: string,
        zoneBoundary: FloorPlanPosition[],
        devicePosition: FloorPlanPosition,
        locationId: string
    ): AddZoneAndDeviceToFloorplan =>
        dispatch(addZoneAndDeviceToFloorplan(floorplanId, serialNumber, zoneBoundary, devicePosition, locationId)),
    updateZoneAndDevice: (
        floorplanId: string,
        serialNumber: string,
        zoneId: string,
        zoneBoundary: FloorPlanPosition[],
        devicePosition: FloorPlanPosition,
        locationId: string
    ): UpdateZoneAndDeviceFloorplan =>
        dispatch(
            updateZoneAndDeviceFloorplan(floorplanId, serialNumber, zoneId, zoneBoundary, devicePosition, locationId)
        ),
    removeZone: (floorplanId: string, zoneId: string, locationId: string): RemoveDeviceFromFloorplanZone =>
        dispatch(removeDeviceFromFloorplanZone(floorplanId, zoneId, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorMapComponent);
