import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { mapDeviceType } from 'commons/src/commonFunctions';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { DeviceLink, HubLink } from '../../buildingsStatus/components/common';
import DeviceInfoModal from './DeviceInfoModal';
import styles from './MobileTable.module.scss';

type SharedProps = {
    id?: string;
    className?: string;
};

type SectionProps = {
    order: number;
};

interface WithChildren {
    children: React.ReactElement | React.ReactElement[];
}

export const Section = ({ children, order }: WithChildren & SectionProps): React.ReactElement => (
    <div className="building-status-page-section" style={{ order }}>
        {children}
    </div>
);

export const Row = ({ children }: WithChildren): React.ReactElement => (
    <div className="building-status-page-section__row">{children}</div>
);

export const TileBody = ({ children, id, className }: WithChildren & SharedProps): React.ReactElement => (
    <div className={classNames(className, 'building-status-page__tile--body')} id={id} data-testid={id}>
        {children}
    </div>
);

export const LastSyncedStatus = ({ lastSeen }: { lastSeen?: string }): React.ReactElement => {
    const { t: txt } = useTranslation();
    if (lastSeen) {
        const lastSynced = moment.utc(lastSeen).local().locale(lastSeen).fromNow(true);
        return (
            <span className={styles.alignedCellContent}>
                <MaterialIcon name="wifi_off" extraClass={styles.paddedIcon} />
                {lastSynced}
            </span>
        );
    }
    return (
        <span className={styles.alignedCellContent}>
            <MaterialIcon name="sync_problem" extraClass={styles.syncProblemIcon} />
            {txt('neverSynced')}
        </span>
    );
};

export const DeviceNameHeader = ({
    name,
    deviceType,
    serialNumber,
    segmentId,
}: {
    name: string;
    deviceType: string;
    serialNumber: string;
    segmentId: string;
}): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const onButtonClick = (): void => {
        setModalOpen(true);
    };
    const onClose = (): void => {
        setModalOpen(false);
    };

    return (
        <div className={styles.nameWithInfo}>
            {modalOpen && (
                <DeviceInfoModal
                    deviceType={deviceType}
                    serialNumber={serialNumber}
                    segmentId={segmentId}
                    name={name}
                    onClose={onClose}
                />
            )}
            <div
                className={styles.infoButton}
                onClick={onButtonClick}
                onKeyUp={(e): void => {
                    if (e.key === 'Enter') onButtonClick();
                }}
                tabIndex={0}
                role="button"
            >
                <MaterialIcon name="info" />
            </div>
            <div>
                {deviceType === DeviceTypeNames.hub ? (
                    <HubLink hubId={serialNumber} hubName={name} />
                ) : (
                    <DeviceLink deviceId={serialNumber} deviceName={name} />
                )}
                {!!deviceType && deviceType !== DeviceTypeNames.hub && (
                    <div className="tile-header__info--chip">{txt(mapDeviceType(deviceType as AnyDeviceType))}</div>
                )}
            </div>
        </div>
    );
};

const onlineIcon = [
    'BuildingStatus.NoOfflineBuildings',
    'BuildingStatus.NoOfflineHubs',
    'BuildingStatus.NoOfflineSensorDevices',
];

export const EmptyTable = ({ emptyTableText }: { emptyTableText: string }): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div className={styles.emptyTable}>
            {onlineIcon.includes(emptyTableText) ? <MaterialIcon extraClass={styles.emptyTableIcon} name="wifi" /> : ''}
            {txt(emptyTableText)}
        </div>
    );
};

export const sortDevicesOnLastSeen = (
    offlineDevices: {
        lastSeen?: string;
        segmentName: string;
        serialNumber: string;
        batteryPercentage?: number | undefined;
    }[]
): { lastSeen?: string; segmentName: string; serialNumber: string; batteryPercentage?: number | undefined }[] =>
    offlineDevices.sort((deviceA, deviceB) => {
        if (!deviceA.lastSeen && !deviceB.lastSeen) {
            return deviceA.segmentName.localeCompare(deviceB.segmentName);
        }
        if (!deviceA.lastSeen) {
            return -1;
        }
        if (!deviceB.lastSeen) {
            return 1;
        }
        return moment(deviceB.lastSeen).diff(moment(deviceA.lastSeen));
    });
