import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import MaterialIcon from '../../../components/MaterialIcon';
import { mediumSectionLoader } from '../../../components/placeholder';
import { Store } from '../../../reducers';
import EditUserInfo from './EditUserInfo';

type StateProps = {
    email: string;
    userName: string;
    demoMode: boolean;
    loading: boolean;
};

type ParentProps = { children?: React.ReactElement };

type Props = ParentProps & StateProps;

export const UserInfoComponent = (props: Props): React.ReactElement => {
    const [isEditing, setIsEditing] = useState(false);
    const toggleEditing = (): void => setIsEditing(!isEditing);
    const { email, userName, demoMode, children, loading } = props;
    const { t: txt } = useTranslation();
    return (
        <div>
            <h2 className="settings__header">{txt('Profile')}</h2>
            <div className="settings-details-container">
                {isEditing ? (
                    <EditUserInfo onClose={toggleEditing} />
                ) : (
                    <ReactPlaceholder ready={!loading} customPlaceholder={mediumSectionLoader}>
                        <form>
                            <div className="settings__row__profile-container margin-20-bottom">
                                <div className="form__attr--element security-settings">
                                    <div id="userName" className="settings__row__header text-large text-bold">
                                        {userName}
                                    </div>
                                    <div id="email" className="settings__row__header text-large">
                                        {email}
                                    </div>
                                </div>
                            </div>
                            <div className="settings__row">
                                <PrimaryButton
                                    id="editUserInfoButton"
                                    title="EditProfile"
                                    onClick={toggleEditing}
                                    disabled={demoMode}
                                    icon={<MaterialIcon name="edit" />}
                                    testId="edit-profile"
                                />
                            </div>
                        </form>
                    </ReactPlaceholder>
                )}
                {children}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { email, userName, demoMode, loading },
    } = store;

    return {
        email,
        userName,
        demoMode,
        loading,
    };
};

export default connect(mapStateToProps)(UserInfoComponent);
