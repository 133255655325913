import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import monthAndWeekConfig from '../../commonHighChartsFunctions';
import { sensorUnits, sensorGraphColors, virtualSensors, colors } from '../../constants';
import IconNoData from '../../img/icon-no-data';
import { Resolution, SensorTypes } from '../../models/commonEnums';
import { SelectedPeriod, AggregateTypes, ExtraSeriesPeriod } from '../../models/commonTypeScript';
import { areaRangeData } from './graphDataFunctions';
import PresenceConfig from './PresenceConfig';
import SensorConfig, { redrawChart } from './SensorConfig';

HCMore(Highcharts);

const x = new Date();
const currentTimeZoneOffsetInHours = x.getTimezoneOffset();

const columnGraphType = (sensorType: string): string => {
    if (sensorType === SensorTypes.occupancy || sensorType === SensorTypes.occupants) {
        return 'column';
    }
    return 'areaspline';
};

export const highChartsTimeZoneOffset = (timezone: string, useUTC = true): void => {
    Highcharts.setOptions({
        time: {
            useUTC,
            timezoneOffset: -moment.tz(timezone).utcOffset(),
        },
    });
};

type ChartDataType = {
    [key: string]: number[][];
};

export type Props = {
    chartData: ChartDataType;
    extraSeries?: ExtraSeriesPeriod;
    aggregateTypes?: AggregateTypes;
    unit: string;
    thresholds: number[];
    chartHeight: number;
    selectedInterval: SelectedPeriod;
    sensorType: SensorTypes;
    minValues: {
        [key: string]: number;
    };
    averageValues: {
        [key: string]: number;
    };
    dateFormat: string;
    width?: number;
    displayResetZoom?: () => void;
    loadingVirtualSensor?: boolean;
    extraSeriesUnitsForSensor?: { type: SensorTypes; unit: string; thresholds: number[] }[];
    showExtraSeriesName?: boolean;
    timeZone?: string;
};

export const SensorGraphComponent = ({
    unit,
    chartHeight,
    selectedInterval,
    minValues,
    averageValues,
    dateFormat,
    chartData,
    width,
    displayResetZoom,
    sensorType,
    thresholds,
    extraSeries,
    aggregateTypes,
    loadingVirtualSensor,
    extraSeriesUnitsForSensor,
    showExtraSeriesName,
    timeZone,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    useEffect((): void => {
        const useUTC = selectedInterval.resolution === Resolution.hour || selectedInterval.resolution === undefined;
        if (timeZone) highChartsTimeZoneOffset(timeZone, useUTC);
        else {
            Highcharts.setOptions({
                global: { timezoneOffset: currentTimeZoneOffsetInHours },
            });
        }
    }, [timeZone, selectedInterval]);

    const hasDataWithinPeriod = (startDate: number, endDate: number, data: number[][]): boolean => {
        const hasData = data.findIndex(dataPoint => dataPoint[0] > startDate && dataPoint[0] < endDate);
        return hasData >= 0;
    };

    const addNullPointsToChartData = (newInterval: SelectedPeriod, newChartData: ChartDataType): number[][] => {
        if (!newInterval) return [];
        const { name, period, number, startDate, endDate } = selectedInterval;
        const data = newChartData && newChartData[name];

        if (data && data.length > 0) {
            let prevPointTime = data[0][0];
            const graphStartPoint = startDate ? startDate.valueOf() : moment().subtract(number, period).valueOf();
            const graphEndPoint = endDate ? endDate.valueOf() : moment().valueOf();
            const nullPointDistance = (graphEndPoint - graphStartPoint) / 10;
            const hasDataInPeriod = hasDataWithinPeriod(graphStartPoint, graphEndPoint, data);

            return data.reduce((addedNullPointsArray: number[][], dataPoint) => {
                if (hasDataInPeriod) {
                    const initialDataPoint = dataPoint[0];
                    if (addedNullPointsArray.length > 0 && initialDataPoint - prevPointTime > nullPointDistance) {
                        addedNullPointsArray.push([prevPointTime + nullPointDistance / 2, null]);
                    }
                    prevPointTime = initialDataPoint;
                    addedNullPointsArray.push(dataPoint);
                }
                return addedNullPointsArray;
            }, []);
        }
        return data;
    };

    const setThresholds = (): { value?: number; color: string }[] => {
        if (thresholds.length === 0) {
            return [{ color: colors.defaultGraphColor }];
        }

        const thresholdZones: { value?: number; color: string }[] = thresholds.map((threshold, i) => {
            const color = sensorGraphColors[`${i}${sensorType}`];
            return { value: threshold, color };
        });
        thresholdZones.push({ color: colors.graphRed });
        return thresholdZones;
    };

    const areaRangeDataPoints = areaRangeData((extraSeries || {})[selectedInterval.name], sensorType);

    const config =
        sensorType === SensorTypes.presence
            ? PresenceConfig({
                  chartData: addNullPointsToChartData(selectedInterval, chartData),
                  chartHeight,
                  selectedInterval,
                  dateFormat,
                  width,
                  animate: true,
                  displayResetZoom,
                  extraSeries,
                  extraSeriesUnitsForSensor,
                  showExtraSeriesName,
                  text: txt,
              })
            : SensorConfig({
                  chartData: addNullPointsToChartData(selectedInterval, chartData),
                  thresholds: setThresholds(),
                  unit: sensorUnits[unit],
                  chartHeight,
                  selectedInterval,
                  minValues,
                  averageValues,
                  dateFormat,
                  width,
                  animate: true,
                  displayResetZoom,
                  mainGraph: aggregateTypes && aggregateTypes[sensorType],
                  graphType: columnGraphType(sensorType),
                  extraSeries: areaRangeDataPoints ? undefined : extraSeries,
                  areaRangeData: areaRangeDataPoints,
              });

    const setLanguage = (): void => {
        const translatedOptions = monthAndWeekConfig(txt);
        Highcharts.setOptions(translatedOptions);
    };

    useEffect(() => {
        setLanguage();
    }, []);

    useEffect(() => {
        redrawChart();
    }, [width]);

    const isVirtualSensor = virtualSensors.includes(sensorType);
    const virtualSensorLoading = isVirtualSensor && loadingVirtualSensor;
    const noDataForSensor = !chartData || !chartData[selectedInterval.name];
    const tileLoading = isVirtualSensor ? virtualSensorLoading && noDataForSensor : noDataForSensor;
    const sensorHasData =
        !tileLoading && chartData && chartData[selectedInterval.name] && chartData[selectedInterval.name].length > 0;
    const presenceHasData = sensorType === SensorTypes.presence && config.series[0].data?.length > 0;
    return sensorHasData && (presenceHasData || (config.series[1].data && config.series[1].data.length > 0)) ? (
        <HighchartsReact highcharts={Highcharts} options={config} />
    ) : (
        <div className="centered">
            <div className="centered__content">
                {IconNoData}
                <div>{txt(tileLoading ? 'Loading' : 'NotEnoughData')}</div>
            </div>
        </div>
    );
};

export default SensorGraphComponent;
