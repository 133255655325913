import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { RootReducer as IaqPublicRootReducer } from 'iaq-public-dashboard/src/index';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import { RootReducer as BusinessRootReducer } from 'business-dashboard/src/store';
import { RootReducer as ConsumerRootReducer } from 'consumer-dashboard/src/index';
import config from '../config';
import { Environment } from '../constants';
import sagas from '../sagas';

type Reducer = Partial<ConsumerRootReducer & BusinessRootReducer & IaqPublicRootReducer>;
const initialState = (rootReducer: Reducer, customSagas?: () => Generator): EnhancedStore => {
    const sagaMiddleware = createSagaMiddleware();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sentryMiddleware = createSentryMiddleware(Sentry, {});

    if (config.env === Environment.prod || config.env === Environment.dev) {
        Sentry.init({
            dsn: 'https://24a432d7b7d34b54a543de044756bd5d@o135297.ingest.sentry.io/299028',
            integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
            release: process.env.REACT_APP_RELEASE_VERSION,
            environment: config.env,
        });
    }

    const store = configureStore({
        reducer: rootReducer,
        devTools: config.env !== Environment.prod,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignored actions due to the resolution duration included in the action
                    ignoredActions: [
                        'POLL_DEVICE_DATA',
                        'POLL_VIRTUAL_DEVICE_DATA/INIT',
                        'FETCH_SPACE_SENSOR_DATA/INIT',
                        'FETCH_SPACE_VIRTUAL_SENSOR_DATA/INIT',
                        'FETCH_OUTDOOR_DATA/INIT',
                    ],
                },
                immutableCheck: false,
            }).concat(sagaMiddleware, sentryMiddleware),
    });

    sagaMiddleware.run(sagas);
    if (customSagas) sagaMiddleware.run(customSagas);

    return store;
};

export default initialState;
