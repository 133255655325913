import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { radonInsightPlaceholder } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats } from 'commons/src/constants';
import { BuildingType, DeviceType, SegmentProperties, SensorThresholds } from 'commons/src/models/commonTypeScript';
import { IaqInsightData } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { generateImage } from '../GraphConfig';
import { createObserverAndFetchSvg } from '../insightFunctions';
import GeneratePdf from '../insightPdfs/IaqInsightPdf/GenerateReport';
import FooterSection from './FooterSection';
import IAQBuildingSummary from './IAQBuildingSummary';
import SensorResult from './SensorResult';

export type ParentProps = {
    from: Moment;
    to: Moment;
    optionalBuildingProps: string[];
};

type StateProps = {
    dateFormat: keyof typeof dateFormats;
    buildings: { [buildingId: string]: BuildingType };
    locationsLoading: boolean;
    loading: boolean;
    locationId: string | undefined;
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    sensorReports: { [sensor: string]: IaqInsightData };
    serialNumbers: string[];
    devices: { [serialNumber: string]: DeviceType };
    thresholds: { [sensor: string]: SensorThresholds };
    logoImage?: string;
    includeLogo: boolean;
    userName: string;
    reportId: string;
};

type Props = ParentProps & StateProps;

export const IAQInsightResultViewComponent = (props: Props): React.ReactElement => {
    const {
        from,
        to,
        locationId,
        dateFormat,
        locationsLoading,
        buildings,
        loading,
        sensorReports,
        serialNumbers,
        devices,
        includeLogo,
        logoImage,
        segmentProperties,
        thresholds,
        userName,
        reportId,
        optionalBuildingProps,
    } = props;
    const { t: txt } = useTranslation();
    const [listOfImages, setListOfImages] = useState<{ data: string; serialNumber: string }[]>([]);
    const [comment, setComment] = useState('');
    const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
    const [generateChartError, setChartError] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);

    const numberOfDays = moment(to).diff(moment(from), 'days');
    const displayGraph = numberOfDays <= 31;

    const sensorReportsWithData = Object.keys(sensorReports).filter(sensor => {
        const report = sensorReports[sensor];
        return report.averageGraph ? report.averageGraph.length > 0 : false;
    });

    const getSvg = async (): Promise<void> => {
        const images: { data: string; serialNumber: string }[] = [];
        const updateListOfImages = (response: { data: string; serialNumber: string }): void => {
            images.push(response);
        };
        await sensorReportsWithData.forEach(sensor => {
            generateImage(updateListOfImages, setChartError, sensor);
        });
        await setListOfImages(images);
    };

    useEffect(() => {
        const typesOfDevices: string[] = [];
        serialNumbers.forEach(number => {
            const device = devices[number];
            const deviceType = device && device.type;
            if (deviceType && !typesOfDevices.includes(deviceType)) {
                typesOfDevices.push(deviceType);
            }
            setDeviceTypes(typesOfDevices);
        });
    }, [devices, serialNumbers]);

    useEffect(() => {
        if (!loading) {
            const element = document.getElementById('resultHeader');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                const observer = createObserverAndFetchSvg('buildingResultSummary', getSvg);
                observer.observe(document, {
                    childList: true,
                    subtree: true,
                });
            }
        }
    }, [loading]);

    const building = locationId ? buildings[locationId] : undefined;
    const usageHours = building ? building.usageHours : {};
    const fromDate = from && moment(from).format(dateFormats[dateFormat].shortFormat);
    const toDate = to && moment(to).format(dateFormats[dateFormat].shortFormat);
    const timeZone = (building && building.timezone) || 'UTC';

    const pdfData = {
        fromDate,
        toDate,
        dateFormat,
        building: building || undefined,
        reports: sensorReports,
        thresholds,
        devices,
        segmentProperties,
        logo: includeLogo ? logoImage : undefined,
        comment,
        listOfImages,
        reportDetails: {
            userName,
            reportId,
            deviceTypes,
        },
    };

    const downloadButtonEnabled = !displayGraph || listOfImages.length === sensorReportsWithData.length;

    return (
        <div className="page-wrapper-flex page-wrapper-flex--content-margin">
            {downloadModalOpen && (
                <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                    <GeneratePdf {...pdfData} />
                </ReactPdfDownloadModal>
            )}
            <div id="resultHeader" className="inline-header-lined">
                <h2 className="inline-header-lined__text">
                    {!loading && fromDate && toDate
                        ? txt('RadonInsight.ReportGeneratedPeriod', { from: fromDate, to: toDate })
                        : txt('Loading')}
                </h2>
            </div>
            <ReactPlaceholder ready={!loading && !locationsLoading} customPlaceholder={radonInsightPlaceholder}>
                {building && (
                    <IAQBuildingSummary
                        building={building}
                        setDownloadModalOpen={setDownloadModalOpen}
                        downloadButtonEnabled={downloadButtonEnabled}
                        optionalBuildingProps={optionalBuildingProps}
                    />
                )}
                {Object.keys(sensorReports)
                    .sort((sensorA, sensorB) => sensorA.localeCompare(sensorB))
                    .map(sensor => (
                        <SensorResult
                            usageHours={usageHours}
                            key={`${sensor}-result-section`}
                            sensorReport={sensorReports[sensor]}
                            sensor={sensor}
                            from={from}
                            displayGraph={displayGraph}
                            to={to}
                            timeZone={timeZone}
                        />
                    ))}
                {generateChartError && (
                    <div className="form form__wide-container">
                        <ResponseBox text="SomethingWentWrong" subtext={txt('RadonInsight.GeneratePdfError')} />
                    </div>
                )}
                <FooterSection
                    updateComment={setComment}
                    deviceTypes={deviceTypes}
                    comment={comment}
                    reportId={reportId}
                />
                <div className="form__row form__button-container">
                    <PrimaryButton
                        onClick={(): void => setDownloadModalOpen(true)}
                        disabled={!downloadButtonEnabled}
                        title="Download"
                        filled
                    />
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        config: { thresholds },
        devices: { devices },
        iaqInsight: { locationId, sensorReports, serialNumbers, includeLogo, reportId },
        locations: { loading: locationsLoading },
        buildings: { buildings },
        organizationProperties: { logoImage },
        requests: {
            [RequestType.FetchIaqInsightData]: { loading },
        },
        segmentPropertiesStore: { segmentProperties },
        userSettings: { dateFormat, userName },
    } = state;
    return {
        dateFormat,
        devices,
        includeLogo,
        loading,
        locationId,
        buildings,
        locationsLoading,
        logoImage,
        sensorReports,
        serialNumbers,
        segmentProperties,
        thresholds,
        userName,
        reportId,
    };
};

export default connect(mapStateToProps)(IAQInsightResultViewComponent);
