import React, { ReactElement, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { deletePropertyDefinition } from 'commons/src/actions/SettingsActions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import StandAloneSpinner from 'commons/src/img/StandAloneSpinner';
import { PredefinedProperty } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';
import { SpacePropertyEditState } from './SpacePropertyEditCommon';
import styles from './SpacePropertyEditMain.module.scss';
import SpacePropertyEditName from './SpacePropertyEditName';
import SpacePropertyEditNewOption from './SpacePropertyEditNewOption';
import SpacePropertyEditOptions from './SpacePropertyEditOptions';

export type Props = {
    backToSpace: () => void;
    property: { id: string; data: SpaceProperty };
    allPropertyNames: string[];
};

const SpacePropertyEditMain = ({ backToSpace, property, allPropertyNames }: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch: Dispatch = useDispatch();

    const [showDeleteView, setShowDeleteView] = useState<boolean>(false);
    const [activeSectionState, setActiveSectionState] = useState<SpacePropertyEditState | undefined>(undefined);

    const { loading, error } = useSelector((store: Store) => store.commonRequests.DELETE_PROPERTY_DEFINITION);

    const onDelete = (): void => {
        dispatch(deletePropertyDefinition(property.id));
    };

    const prevLoading: React.MutableRefObject<boolean> = useRef(loading);
    useEffect((): void => {
        if (prevLoading.current && !loading && !error) {
            backToSpace();
        }
        prevLoading.current = loading;
    }, [loading]);

    return (
        <div
            className={classNames(styles.body, {
                [styles.centered]: showDeleteView,
            })}
        >
            {showDeleteView ? (
                <div className={styles.deleteSection}>
                    <div className={styles.deleteTitle}>{txt('CustomProperty.DeleteProperty')}</div>
                    <div className={styles.deleteSubText}>
                        {txt('CustomProperty.DeletePropertyConfirm', {
                            name:
                                property.data.predefinedType === PredefinedProperty.Custom
                                    ? property.data.propertyName
                                    : txt(`PredefinedProperties.${property.data.predefinedType}`),
                        })}
                    </div>
                    {loading ? (
                        <StandAloneSpinner />
                    ) : (
                        <div className={styles.deleteButtons}>
                            <PrimaryButton
                                type="button"
                                onClick={(): void => setShowDeleteView(false)}
                                title="Cancel"
                            />
                            <PrimaryButton
                                type="button"
                                alert
                                testId="confirm-delete-property-button"
                                onClick={onDelete}
                                loading={loading}
                                title="Delete"
                            />
                        </div>
                    )}
                    {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
                </div>
            ) : (
                <>
                    <TertiaryButton
                        onClick={backToSpace}
                        title={txt('Return')}
                        icon={<MaterialIcon name="chevron_left" />}
                    />
                    <SpacePropertyEditName
                        activeSection={activeSectionState}
                        setActiveSection={setActiveSectionState}
                        allPropertyNames={allPropertyNames}
                        property={property}
                        setShowDeleteView={setShowDeleteView}
                    />
                    <SpacePropertyEditNewOption
                        property={property}
                        activeSection={activeSectionState}
                        setActiveSection={setActiveSectionState}
                    />
                    <SpacePropertyEditOptions
                        property={property}
                        activeSection={activeSectionState}
                        setActiveSection={setActiveSectionState}
                    />
                </>
            )}
        </div>
    );
};

export default SpacePropertyEditMain;
