import React from 'react';
import { TableRow } from '@mui/material';
import convertRowTypeToText from './helpers/cells';
import useMaterialTableContext from './hooks/useMaterialTableContext';
import styles from './MobileMaterialTable.module.scss';
import { MaterialTableColumn, Row } from './models';

const MobileMaterialTableData = (): React.ReactElement => {
    const { paginatedData, data, columns, idKey } = useMaterialTableContext();
    const selectedColumns = [...columns].splice(1);

    const renderCell = (rowData: Row, column: MaterialTableColumn): React.ReactNode => {
        if (column.renderer) {
            return column.renderer({
                accessor: column.accessor,
                rowData,
                allRows: data,
            });
        }
        return convertRowTypeToText(rowData[column.accessor]);
    };

    return (
        <tbody>
            {paginatedData.map(rowData => (
                <TableRow hover tabIndex={0} key={rowData[idKey] as string} className={styles.mobileTableRow}>
                    <td className={styles.mobileCell}>
                        {renderCell(rowData, columns[0])}
                        <div className={styles.mobileProperties}>
                            {selectedColumns.map(column => {
                                return (
                                    <div className={styles.mobileRow} key={column.accessor}>
                                        <span>{`${column.label}: `}</span>
                                        <div>{renderCell(rowData, column)}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </td>
                </TableRow>
            ))}
        </tbody>
    );
};

export default MobileMaterialTableData;
