import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_ADDED_LABEL } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { linkToDeveloperDocs } from 'commons/src/constants';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { UpdateLocationLabels, updateLocationLabels } from '../../../actions/locationActions';
import { KeyValuePairType } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { removeEmptyKeyValuePair } from '../../../sagas/segmentPropertiesSaga';
import MapInput from '../../integrations/webhooks/MapInput';

export type ParentProps = {
    locationId: string;
};

type StateProps = {
    locationLabels: {
        [locationId: string]: KeyValuePairType[];
    };
    loading: boolean;
    error?: ErrorType;
};
type ActionProps = {
    onUpdateLabels: (locationId: string, labels: KeyValuePairType[]) => void;
};
export type Props = StateProps & ParentProps & ActionProps;

export const CustomLabelsComponent = (props: Props): React.ReactElement => {
    const { locationId, locationLabels, onUpdateLabels, loading, error } = props;
    const { t: txt } = useTranslation();

    const [labels, setLabels] = useState(locationLabels[locationId] || []);

    const onSubmit = (): void => {
        const nonEmptyLabels = removeEmptyKeyValuePair(labels);
        analyticsLogger(BUILDING_ADDED_LABEL, {
            labels: nonEmptyLabels,
        });
        onUpdateLabels(locationId, nonEmptyLabels);
    };

    return (
        <form className="edit-building__form">
            <p className="text-medium form__row--padded-small">
                <Trans i18nKey="CustomLabels.CustomLabelsDescription">
                    <a href={linkToDeveloperDocs} target="_blank" rel="noopener noreferrer">
                        {linkToDeveloperDocs}
                    </a>
                </Trans>
            </p>
            <MapInput
                id="location-labels-selector"
                updatePair={setLabels}
                pairs={labels}
                displayValidation={false}
                keyHeader="CustomLabels.LabelKey"
                keyHint="CustomLabels.LabelKeyRequiredHint"
                valueHeader="CustomLabels.LabelValue"
                valueHint=""
                addButtonText="CustomLabels.AddLabel"
                maxValueLength={255}
                maxKeyLength={255}
                maxRows={5}
            />
            {error && <ResponseBox text="SomethingWentWrong" subtext={txt(`ErrorCodes.${error.error}`)} />}
            <div className="form__button-container location-pick-map__container">
                <PrimaryButton
                    type="button"
                    id="saveBuildingFeaturesButton"
                    title="Save"
                    loading={loading}
                    onClick={onSubmit}
                    filled
                />
            </div>
        </form>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            FETCH_LOCATION_LABELS: { error },
            [RequestType.UpdateLocationLabels]: { error: labelsError, loading },
        },
        buildings: { locationLabels },
    } = state;

    return {
        locationLabels,
        loading,
        error: labelsError || error,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onUpdateLabels: (locationId, labels): UpdateLocationLabels => dispatch(updateLocationLabels(locationId, labels)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomLabelsComponent);
