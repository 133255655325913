import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { analyticsLogger, PageType, ReportType } from 'commons/src/analytics';
import { INSIGHT_SELECTED_REPORT } from 'commons/src/analytics/AnalyticsEvents';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { roleRestrictions } from 'commons/src/constants';
import { ButtonColor, DeviceTypeNames, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { ActionButton, MenuItem, OptionButtonType } from 'commons/src/models/menuModels';
import { paths as businessPaths } from '../../constants';

export const isIAQInsightDevice = (deviceType: AnyDeviceType): boolean => {
    const iaqDeviceTypes: AnyDeviceType[] = [
        DeviceTypeNames.wavePlus,
        DeviceTypeNames.waveCo2,
        DeviceTypeNames.spaceCo2Mini,
        DeviceTypeNames.mini,
        DeviceTypeNames.viewPlus,
        DeviceTypeNames.viewPlusBusiness,
        DeviceTypeNames.viewCo2,
    ];
    return iaqDeviceTypes.includes(deviceType);
};

export const downloadCsvButton = (onDownloadCSV: () => void): ActionButton => ({
    onClick: (): void => onDownloadCSV(),
    icon: <MaterialIcon name="download" />,
    title: 'ExportToCSV',
    id: 'deviceExportToCSV',
    color: ButtonColor.primary,
    testAttr: 'device-export-to-csv',
    requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
    requiredGroupTypes: [],
});

export const deviceActionButtons = (
    deviceWithRadonSensor: boolean,
    deviceType: AnyDeviceType,
    serialNumber: string,
    navigate: NavigateFunction,
    spaceLink: string
): ActionButton[] => {
    const settingsDropdownOptions: ActionButton[] = [
        {
            onClick: (): void => navigate(spaceLink),
            icon: <MaterialIcon name="info" />,
            title: 'Space.DeviceExportingMoved',
            testAttr: 'go-to-space',
            id: 'goToSpace',
            color: ButtonColor.noBackground,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];
    if (deviceWithRadonSensor) {
        const generateRadonReportMenuItem: ActionButton = {
            onClick: (): void => {
                navigate(`/${businessPaths.radonInsight}`, {
                    state: { serialNumber: serialNumber || undefined },
                });
                analyticsLogger(INSIGHT_SELECTED_REPORT, {
                    pageType: PageType.Device,
                    reportType: ReportType.Radon,
                });
            },
            icon: <MaterialIcon name="assignment" />,
            title: 'RadonReport',
            testAttr: 'download-radon-report',
            id: 'downloadRadonReport',
            color: ButtonColor.noBackground,
            requiredRoleLevel: roleRestrictions.reports,
            requiredGroupTypes: [],
        };
        settingsDropdownOptions.unshift(generateRadonReportMenuItem);
    }
    if (isIAQInsightDevice(deviceType)) {
        const generateAirQualityReportMenuItem: ActionButton = {
            onClick: () => {
                navigate(`/${businessPaths.iaqInsight}`, {
                    state: { serialNumber: serialNumber || undefined },
                });
                analyticsLogger(INSIGHT_SELECTED_REPORT, {
                    pageType: PageType.Device,
                    reportType: ReportType.IAQInsight,
                });
            },
            icon: <MaterialIcon name="assignment" />,
            title: 'IAQReport',
            testAttr: 'download-iaq-report',
            id: 'downloadIaqReport',
            color: ButtonColor.noBackground,
            requiredRoleLevel: roleRestrictions.reports,
            requiredGroupTypes: [],
        };
        settingsDropdownOptions.unshift(generateAirQualityReportMenuItem);
    }
    return settingsDropdownOptions;
};

export const endedSegmentOptionButton = (
    onDownloadCSV: () => void,
    deviceWithRadonSensor: boolean,
    navigate: NavigateFunction
): OptionButtonType => {
    const menuItems: MenuItem[] = [];

    if (deviceWithRadonSensor) {
        const generateRadonReportMenuItem: MenuItem = {
            onClick: (): void => {
                navigate('./report/radon');
                analyticsLogger(INSIGHT_SELECTED_REPORT, {
                    pageType: PageType.Device,
                    reportType: ReportType.Radon,
                });
            },
            icon: <MaterialIcon name="assignment" />,
            text: 'RadonReport',
            id: 'goToRadonReport',
            requiredRoleLevel: roleRestrictions.reports,
            requiredGroupTypes: [],
        };
        menuItems.push(generateRadonReportMenuItem);
    }
    return {
        title: 'ExportToCSV',
        id: 'deviceExportToCSV',
        onClick: (): void => onDownloadCSV(),
        color: ButtonColor.primary,
        testAttr: 'device-export-to-csv',
        requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
        options: menuItems,
        requiredGroupTypes: [],
    };
};
