import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from 'business-dashboard/src/constants';
import { userRoleAboveRequiredLevel } from '../../features/authorization/userRoleAboveRequiredLevel';
import DeviceIcon from '../../img/deviceIcons/icon_device';
import { RequiredRoleLevel, Role } from '../../models/commonEnums';

type Props = {
    locationId: string;
    userRole?: Role;
    requiredRoleLevel: RequiredRoleLevel;
};

const NoDevices = ({ locationId, userRole, requiredRoleLevel }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const accessToAddDevice = userRole && userRoleAboveRequiredLevel(userRole, requiredRoleLevel);
    return (
        <div className="no-content-page">
            <div className="no-content-page__image">
                <DeviceIcon />
            </div>
            {accessToAddDevice ? (
                <div className="no-content-page__text">
                    <Trans i18nKey="Business.AddSpaceToBuilding">
                        <Link to={`/${paths.buildings}/${locationId}/spaces`} />
                    </Trans>
                </div>
            ) : (
                <p className="no-content-page__text">{txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole) })}</p>
            )}
        </div>
    );
};

export default NoDevices;
