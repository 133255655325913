import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import config from 'commons/src/config';
import { ButtonColor, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import {
    AddThirdPartyIntegration,
    addThirdPartyIntegration,
    FetchThirdPartyIntegrationMapping,
    fetchThirdPartyIntegrationMapping,
} from '../../../actions/thirdPartyIntegrationActions';
import { paths, thirdPartyChannels } from '../../../constants';
import {
    NewThirdPartyIntegration,
    ThirdPartyAddedIntegration,
    ThirdPartyMappedLocations,
} from '../../../models/common';
import { Store } from '../../../reducers';
import AddThirdPartyIntegrationErrorView from './AddThirdPartyIntegrationErrorView';
import AddThirdPartyIntegrationFinishedView from './AddThirdPartyIntegrationFinishedView';
import AddThirdPartyIntegrationFormView from './AddThirdPartyIntegrationFormView';
import AddThirdPartyIntegrationSelectView from './AddThirdPartyIntegrationSelectView';
import ThirdPartyMappingForm from './ThirdPartyMappingForm';

type StateProps = {
    addIntegrationLoading: boolean;
    locationsLoading: boolean;
    error?: ErrorType;
    integration: ThirdPartyAddedIntegration | undefined;
    registerMappingLoading: boolean;
    fetchMappingLoading: boolean;
};

type ActionProps = {
    addIntegration: (integration: NewThirdPartyIntegration) => void;
    fetchMapping: (integrationId: string) => void;
};

type Props = StateProps & ActionProps;

export const AddThirdPartyIntegrationComponent = ({
    addIntegrationLoading,
    locationsLoading,
    addIntegration,
    error,
    integration,
    fetchMapping,
    registerMappingLoading,
    fetchMappingLoading,
}: Props): React.ReactElement => {
    enum Views {
        SelectIntegrationTypeView = 'SELECT_INTEGRATION_TYPE_VIEW',
        FinishedSetupView = 'FINISHED_SETUP_VIEW',
        FormView = 'FORM_VIEW',
        ErrorView = 'ERROR_VIEW',
        MappingView = 'MAPPING_VIEW',
    }

    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [search] = useSearchParams();
    const [selectedIntegrationType, setSelectedIntegrationType] = useState('');
    const [customError, setCustomError] = useState('');
    const [displayView, setDisplayView] = useState(Views.SelectIntegrationTypeView);
    const [mappedLocations, setMappedLocations] = useState<ThirdPartyMappedLocations[] | undefined>(undefined);

    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    const getUrlParams = (): { code: string | null; state: string | null } => {
        const code = search.get('code');
        const state = search.get('state');
        return { code, state };
    };

    const cleanUpUrl = (): void => {
        navigate(location.pathname, { replace: true, state: {} });
    };

    const onAddIntegration = (payload: NewThirdPartyIntegration): void => {
        addIntegration(payload);
    };

    useEffect(() => {
        const form = JSON.parse(localStorage.getItem('integration_form') || '{}');
        const { code, state } = getUrlParams();
        if (code && state && form.integration) {
            if (state !== form.state) {
                setCustomError('ThirdParty.AUTHORIZATION_ERROR');
                setDisplayView(Views.ErrorView);
            } else {
                onAddIntegration({
                    ...form.integration,
                    parameters: {
                        authorizationCode: code,
                        redirectUri: config.thirdPartyRedirectUrl,
                    },
                });
            }
        }
        localStorage.removeItem('integration_form');
        cleanUpUrl();
    }, []);

    const close = (): void => {
        navigate(`/${paths.thirdParty}`);
    };

    const selectIntegrationType = (type: string): void => {
        setSelectedIntegrationType(type);
        setDisplayView(Views.FormView);
    };

    const usePrevious = (value: boolean): boolean | undefined => {
        const ref: MutableRefObject<boolean | undefined> = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    };

    const previousAddIntegrationLoading = usePrevious(addIntegrationLoading);
    useEffect(() => {
        if (previousAddIntegrationLoading && !addIntegrationLoading) {
            if (!error && integration) {
                if (integration.type === thirdPartyChannels.SLACK) {
                    setDisplayView(Views.FinishedSetupView);
                } else {
                    fetchMapping(integration.id);
                    setDisplayView(Views.MappingView);
                }
            } else if (error && !selectedIntegrationType) {
                setDisplayView(Views.ErrorView);
            }
        }
    }, [addIntegrationLoading, previousAddIntegrationLoading, error]);

    const previousRegisterMappingLoading = usePrevious(registerMappingLoading);
    useEffect(() => {
        if (previousRegisterMappingLoading && !registerMappingLoading && !error) {
            setDisplayView(Views.FinishedSetupView);
        }
    }, [registerMappingLoading, previousRegisterMappingLoading, error]);

    const storeMappingInState = (mapping: ThirdPartyMappedLocations[]): void => {
        setMappedLocations(mapping);
    };

    const actionButton: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-add-third-party-integration',
            id: 'closeAddThirdPartyIntegration',
            title: 'Close',
            color: ButtonColor.secondary,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const errorText = error && txt(`ErrorCodes.${error.error}`);
    const loadingIfRedirect = addIntegrationLoading && !selectedIntegrationType;
    return (
        <>
            <PageHeader title={txt('ThirdParty.NewIntegration')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButton} />
            </div>
            <ReactPlaceholder
                ready={!(locationsLoading || loadingIfRedirect || fetchMappingLoading)}
                customPlaceholder={mediumFormLoader}
            >
                {displayView === Views.FormView && (
                    <AddThirdPartyIntegrationFormView
                        selectedIntegrationType={selectedIntegrationType}
                        onAddIntegration={onAddIntegration}
                    />
                )}
                {displayView === Views.ErrorView && (
                    <AddThirdPartyIntegrationErrorView errorText={customError || errorText} onClose={close} />
                )}
                {displayView === Views.FinishedSetupView && (
                    <AddThirdPartyIntegrationFinishedView
                        integration={integration}
                        mappedLocations={mappedLocations}
                        onClose={close}
                    />
                )}
                {displayView === Views.SelectIntegrationTypeView && (
                    <AddThirdPartyIntegrationSelectView selectIntegrationType={selectIntegrationType} />
                )}
                {displayView === Views.MappingView && (
                    <ThirdPartyMappingForm integration={integration} storeMapping={storeMappingInState} />
                )}
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            ADD_THIRD_PARTY_INTEGRATION: { loading: addIntegrationLoading, error: addIntegrationError },
            REGISTER_THIRD_PARTY_INTEGRATION_MAPPING: { error: registerMappingError, loading: registerMappingLoading },
            FETCH_THIRD_PARTY_INTEGRATION_MAPPING: { loading: fetchMappingLoading },
        },
        locations: { loading: locationsLoading },
        thirdPartyIntegrations: { integration },
    } = state;
    return {
        locationsLoading,
        addIntegrationLoading,
        integration,
        fetchMappingLoading,
        registerMappingLoading,
        error: addIntegrationError || registerMappingError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    addIntegration: (integration: NewThirdPartyIntegration): AddThirdPartyIntegration =>
        dispatch(addThirdPartyIntegration(integration)),
    fetchMapping: (integrationId: string): FetchThirdPartyIntegrationMapping =>
        dispatch(fetchThirdPartyIntegrationMapping(integrationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddThirdPartyIntegrationComponent);
