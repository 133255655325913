/* eslint-disable import/prefer-default-export */
/* This file has no default export */
// eslint-disable-next-line import/no-unresolved,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { Data } from 'react-csv/components/CommonPropTypes';
import { RequestActionType } from 'commons/src/actions/requestActions';
import { sensorUnits } from 'commons/src/constants';
import { InviteStatus, Role, SensorTypes, VirtualDeviceType } from 'commons/src/models/commonEnums';
import { DeviceMetaDataProperties, Units } from 'commons/src/models/commonTypeScript';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';
import { IotCertificateStatus } from './commonEnums';

// type Data = components.CommonPropTypes.Data;

export type PDFContext = (text: string, stringAttrs?: { [textKey: string]: string }) => string;

export interface BusinessActionRequestType {
    requestActionType: RequestActionType;
    requestType: RequestType;
}

export interface MemberDetail {
    name: string;
    email: string | undefined;
    role: Role;
}

export interface InvitedMember extends MemberDetail {
    inviteId: string;
    status: InviteStatus;
    userId?: string;
    resources?: UserResources;
}

export type Member = InvitedMember & { userId: string };

export interface GetOrganizationLogoResponse {
    logoFileName?: string;
    logoImage?: string;
}

export interface GetOrganizationResponse {
    members: Member[];
    invited: InvitedMember[];
}

export interface SendInviteBody {
    userGroupId: string;
    name: string;
    role: string;
    email: string;
    resources?: UserResources;
}

export interface UserResources {
    locations: string[];
}

export interface InviteCreatedResponse {
    inviteId: string;
    invitedUserName: string;
    invitedUserEmail: string;
    status: InviteStatus;
    role: Role;
}

export interface MembershipRolePayload {
    role: Role;
    resources?: UserResources;
    name: string;
}

export interface IaqInsightData {
    averageGraph?: number[][];
    averageValueWithinOpeningHours: number;
    averageValue: number;
    unit: keyof typeof sensorUnits;
    devicesOpeningHoursValues: {
        [serialNumber: string]: {
            average: number;
            highestMeasurement: number;
            lowestMeasurement: number;
        };
    };
}

export interface VirusRiskInsightData {
    graph: number[][];
    serialNumber: string;
    averageValueWithinOpeningHours: number;
    unit: keyof typeof sensorUnits;
    riskFactors: {
        [factorType: string]: {
            value: number;
            solutions: string[];
        };
    };
}

export interface RadonInsightSensorData {
    serialNumber: string;
    radonStep1: number;
    radonStep2: number;
    radonStep1StdDev: number | undefined;
    radonStep2StdDev: number | undefined;
    radonStep1Lld: number | undefined;
    radonStep2Lld: number | undefined;
    radonData: number[];
}

export enum BuildingOptions {
    School = 'School',
    Residential = 'Residential',
    Apartment = 'Apartment',
    Kindergarden = 'Kindergarden',
    HealthCenter = 'HealthCenter',
    Office = 'Office',
    Other = 'Other',
}

export enum Ventilation {
    Natural = 'Natural',
    Mechanical = 'Mechanical',
    Balanced = 'Balanced',
}

export enum Days {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
}

export interface KeyValuePairType {
    key: string;
    value: string;
}

export interface WebhookEvent {
    hookId: string;
    correlationId: string;
    eventType: WebhookEventTypes;
    url: string;
    createdAt: string;
    completedAt: string | undefined;
    updatedAt: string;
    statusCode: number;
}

export interface NewWebhook {
    name: string;
    url: string;
    deviceSelection: {
        locations: string[];
        devices: string[];
    };
    webhookHeaders: KeyValuePairType[];
    webhookLabels: KeyValuePairType[];
    measurementSystem?: string;
    sensorUnits: Units;
    active: boolean;
    eventTypes: WebhookEventTypes[];
}

export interface Webhook extends NewWebhook {
    id: string;
    mostRecentEvent?: WebhookEvent;
}

export type AlertRule = {
    sensorType: string;
    thresholdValues: number[];
    duration: number;
    enabled: boolean;
};

export type ChannelDetails = {
    name: string;
    integrationType: string;
    integrationChannel: { id: string; inputValue: string };
    emails: string[];
};

export interface ThirdPartyIntegrationTriggers {
    id: string;
    active: boolean;
    name: string;
    locationIds: string[];
    serialNumbers: string[];
    rules: ThirdPartyIntegrationAlertRule[];
    notifyOfflineDevice?: boolean;
    notifyLowBattery?: boolean;
    timeCondition?: string;
    units: Units;
}

export interface NotificationAlertEvent {
    content: {
        duration: number;
        serialNumber: string;
        locationName: string;
        alertIntegrationId: string;
        triggerName: string;
        locationId: string;
        sensorType: string;
        triggerId: string;
        type: string;
        thresholdValue: number;
        thresholdType: string;
        segmentName: string;
    };
    dateTime: string;
    icon: string;
    id: string;
    type: string;
    userGroupId: string;
}

export interface ThirdPartyIntegration {
    id: string;
    name: string;
    type: string;
    grantType: string;
    emails?: string[];
    triggers: ThirdPartyIntegrationTriggers[];
}

export interface ThirdPartyIntegrationAlert extends ThirdPartyIntegrationTriggers {
    integrationId: string;
    integrationType: string;
}

export interface ThirdPartyIntegrationAlertRule {
    sensorType: string;
    thresholdValue: number;
    thresholdType: string;
    duration: number;
    enabled?: boolean;
}

export interface NewNotificationAlert {
    active: boolean;
    locationIds: string[];
    serialNumbers: string[];
    name: string;
    rules: ThirdPartyIntegrationAlertRule[];
    notifyOfflineDevice?: boolean;
    notifyLowBattery?: boolean;
    timeCondition?: string;
    units: Units;
}

export interface EditNotificationAlert {
    integrationType: string;
    integrationId: string;
    triggerId: string;
    trigger: NewNotificationAlert;
}

export interface NewThirdPartyIntegration {
    type: string;
    name: string;
    grantType: string;
    parameters: {
        clientId?: string;
        clientSecret?: string;
        accessToken?: string;
        emails?: string[];
        extra?: IntegrationExtraAuth;
    };
}

export interface IntegrationExtraAuth {
    [key: string]: string;
}

export interface ThirdPartyAddedIntegration {
    id: string;
    type: string;
    name: string;
}

export type IntegrationTypes = {
    [index: string]: {
        authorizationMethods: string[];
        authUrl?: string;
        scope?: string;
        supportEmail?: string;
    };
};

export interface ThirdPartyIntegrationMapping {
    locations: { name: string; id: string }[];
    externalLocations: { name: string; id: string }[];
    mapping: { locationId: string; externalLocationId: string }[];
}

export interface ThirdPartyMappedLocations {
    id: string;
    location: { name: string; id: string };
    externalLocation: { name: string; id: string };
}

export interface ThirdPartyMappingLocation {
    name: string;
    id: string;
}

export interface NewThirdPartyMapping {
    locationId: string;
    externalLocationId: string;
}

export interface AddZoneGroupPayload {
    type: VirtualDeviceType | undefined;
    members: string[];
    segmentName: string;
    deviceMetaData?: DeviceMetaDataProperties;
}

export interface LabelsPayload {
    [key: string]: string;
}

export enum WebhookEventTypes {
    manuallyTriggeredTest = 'sample-manually-triggered-test',
    hubMetaDataFeed = 'hub-meta-data-feed',
    moldSampleFeed = 'mold-sample-feed',
    sampleFeed = 'sample-feed',
    virusRiskSampleFeed = 'virus-risk-sample-feed',
    occupancySampleFeed = 'occupancy-sample-feed',
    ventilationSampleFeed = 'ventilation-sample-feed',
    hourlyRadonSampleFeed = 'hourly-radon-sample-feed',
}

export interface LocationResetConnectionsResponse {
    locationId: string;
    devices: ResetConnection[];
}

export interface ResetConnection {
    serialNumber: string;
    deviceName: string;
    deviceType: string;
    resetConnectionId: string;
}

export interface CertificateInfo {
    distinguishedName: string;
    validUntil: string;
    status: IotCertificateStatus;
}

export interface MqttSubscription {
    id?: string;
    userGroupId: string;
    locationId?: string;
    serialNumber?: string;
}

export interface NewMqttClient {
    clientName: string;
    description?: string;
}

export interface EditMqttClient {
    id: string;
    userGroupId: string;
    clientName: string;
    description?: string;
    subscriptions: MqttSubscription[];
    active: boolean;
}

export interface MqttClient extends NewMqttClient {
    id: string;
    userGroupId: string;
    certificateInfo?: CertificateInfo;
    subscriptions?: MqttSubscription[];
}

export interface MqttClientCertificate {
    fileName: string;
    downloadUrl: string;
}

export interface SensorBreachThresholds {
    [sensorType: string]: {
        over: number[];
        under?: number[];
        unit: keyof typeof sensorUnits;
    };
}

export interface CSVFiles {
    fileName: string;
    content: string | Data | (() => string | Data);
}

export interface SingleSignOnResponse {
    singleSignOnClients: SingleSignOnClient[];
}

export interface SingleSignOnClient {
    singleSignOnId: string;
    clientId: string;
    issuer: string;
    domain: string;
    domainTxtRecord: string;
    domainTxtRecordVerifiedAt?: string;
    active: boolean;
    scope: string;
}

export interface CreateClientPayload {
    issuer: string;
    domain: string;
    clientId: string;
    clientSecret: string;
    scope: string;
}

export interface ActivatedClientResponse {
    singleSignOnId: string;
    active: boolean;
}

export interface VerifyClientDomainResponse {
    emailDomain: string;
    date?: string;
    status: boolean;
}

export interface LookupDomainResponse {
    isActiveSsoDomain: boolean;
}

export interface DownloadCsvPayload {
    from: string;
    to: string;
    sensors: SensorTypes[];
    virtualSensors: SensorTypes[];
    includeEndedSegments: boolean;
}

export enum PresenceInsightFilterType {
    floors = 'floors',
    roomTypes = 'roomTypes',
}
