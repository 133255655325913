import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import { paths } from '../../../constants';
import { EmailSummaryUser } from '../../../models/emailSummaryModels';

type Props = {
    onNext: () => void;
    users: EmailSummaryUser[];
    selectedRecipients: string[];
    updateSelectedRecipients: (payload: { id: string; changeAll: boolean }) => void;
    disableButton: boolean;
};

const EmailSummaryRecipientsSectionComponent = (props: Props): React.ReactElement => {
    const { onNext, users, disableButton, selectedRecipients, updateSelectedRecipients } = props;

    const recipientListOptions = users
        .map(user => ({
            id: user.userId,
            name: user.name,
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return (
        <div className="settings-details-container">
            <div className="form small-padding-bottom">
                <div className="text-medium text-paragraph">
                    <Trans i18nKey="EmailSummary.RecipientsDescription">
                        <Link to={`/${paths.userManagement}`} />
                    </Trans>
                </div>
                <div className="margin-20-bottom" />
                <SimpleTreeView
                    listId="email-summary-users"
                    options={recipientListOptions}
                    selectedOptions={selectedRecipients}
                    onChange={updateSelectedRecipients}
                />
                <div className="margin-20-bottom" />
                <div className="form__button-container form__button-container--no-padding form__button-container--margin-top">
                    {disableButton && (
                        <PrimaryButton
                            id="submit"
                            type="button"
                            title="Next"
                            filled
                            disabled={false}
                            onClick={onNext}
                            testId="exit-recipients-section"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailSummaryRecipientsSectionComponent;
