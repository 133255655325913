import React from 'react';
import DeviceName from 'commons/src/components/tableCells/DeviceName';
import styles from './GlobalSearch.module.scss';

type SearchResultProps = {
    locationName: string;
    segmentName: string;
    serialNumber: string;
    isHub: boolean;
};

const SearchResult = ({ locationName, segmentName, serialNumber, isHub }: SearchResultProps): React.ReactElement => {
    return (
        <li className={styles.listItem}>
            <span title={locationName} className={styles.locationName}>
                {locationName}
            </span>
            <span className={styles.deviceLink}>
                <DeviceName name={segmentName} serialNumber={serialNumber} type={isHub ? 'hub' : 'device'} />
            </span>
        </li>
    );
};

export default SearchResult;
