import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import BatteryIcon from 'commons/src/components/device/BatteryIcon';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { cardTile } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { batteryLevels } from 'commons/src/constants';
import { CardIssue } from '../../../models/buildingModels';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import { RootState } from '../../../store';
import styles from './BuildingsStatusSummaryCard.module.scss';
import LowBatteryStatusModal from './LowBatteryStatusModal';
import OfflineStatusModal from './OfflineStatusModal';
import PoorConnectionStatusModal from './PoorConnectionStatusModal';

type ParentProps = {
    numberOfDevicesWithIssue: number;
    headerText: string;
    cardIssue: CardIssue;
};

const issueIconMap: Record<CardIssue, JSX.Element> = {
    [CardIssue.Connection]: <MaterialIcon name="sim_card" extraClass={styles.poorConnectionSim} />,
    [CardIssue.Battery]: <BatteryIcon batteryPercentage={batteryLevels.low} />,
    [CardIssue.Offline]: <MaterialIcon name="wifi_off" extraClass={styles.redIcon} />,
};

const BuildingsStatusSummaryCard = ({
    numberOfDevicesWithIssue,
    headerText,
    cardIssue,
}: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [open, setOpen] = useState(false);

    const { loading, error } = useSelector(
        (state: RootState) => state.requests[RequestType.FetchOrganizationHealthStatusSummary]
    );

    if (error && !loading) {
        return <ResponseBox text={txt(`ErrorCodes.${error.error}`)} subtext={txt('SomethingWentWrongTryAgain')} />;
    }

    const toggle = (): void => setOpen(currentOpen => !currentOpen);
    const issueIcon = issueIconMap[cardIssue];

    return (
        <ReactPlaceHolder ready={!loading} customPlaceholder={cardTile}>
            <section className={styles.cardWrapper}>
                <div className={styles.issueType}>
                    <div className={styles.textBig}>{numberOfDevicesWithIssue} </div>
                    <div>{headerText}</div>
                </div>
                <div className={styles.buttonWrapper}>
                    {issueIcon}
                    {numberOfDevicesWithIssue !== 0 && (
                        <>
                            <PrimaryButton
                                icon={<MaterialIcon name="expand_content" />}
                                onClick={toggle}
                                small
                                noBackground
                            />
                            {cardIssue === CardIssue.Offline && (
                                <OfflineStatusModal
                                    open={open}
                                    toggle={toggle}
                                    header={headerText}
                                    displayedNumber={numberOfDevicesWithIssue}
                                />
                            )}
                            {cardIssue === CardIssue.Battery && (
                                <LowBatteryStatusModal
                                    open={open}
                                    toggle={toggle}
                                    header={headerText}
                                    displayedNumber={numberOfDevicesWithIssue}
                                />
                            )}
                            {cardIssue === CardIssue.Connection && (
                                <PoorConnectionStatusModal
                                    open={open}
                                    toggle={toggle}
                                    header={headerText}
                                    displayedNumber={numberOfDevicesWithIssue}
                                />
                            )}
                        </>
                    )}
                </div>
            </section>
        </ReactPlaceHolder>
    );
};

export default BuildingsStatusSummaryCard;
