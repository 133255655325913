import React, { ReactElement, useMemo, useState } from 'react';
import { groupBy as lodashGroupBy } from 'lodash';
import { connect, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats, roleRestrictions } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { ButtonColor, RequiredRoleLevel, Role, SensorTypes } from 'commons/src/models/commonEnums';
import { SpaceProperty } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { Store } from 'commons/src/reducers';
import { spacesGroupTypesOptions } from '../../../constants';
import { IndoorSpace } from '../../../models/spaceModels';
import SpacesCsvModal from './SpacesCsvModal';
import SpacesHeader from './SpacesHeader';
import SpacesRow from './SpacesRow';
import styles from './SpacesView.module.scss';

export type ParentProps = {
    addSpace: () => void;
    spaces: IndoorSpace[];
    availableSensors: SensorTypes[];
    buildingId: string;
};

type StateProps = {
    userRole: Role;
    propertyDefinitions: {
        [propertyId: string]: SpaceProperty;
    };
};

type Props = ParentProps & StateProps;

const SpacesView = ({
    addSpace,
    spaces,
    userRole,
    availableSensors,
    propertyDefinitions,
    buildingId,
}: Props): ReactElement => {
    const [selectedGrouping, setSelectedGrouping] = useState<string>(spacesGroupTypesOptions[0]);
    const [searchText, setSearchText] = useState('');
    const [selectedSensors, setSelectedSensors] = useState<SensorTypes[]>(availableSensors.slice(0, 5));
    const [csvModalOpen, setCsvModalOpen] = useState(false);
    const dateFormat: keyof typeof dateFormats = useSelector((store: Store) => store.userSettings.dateFormat);

    const spacesWithCustomProps = useMemo(
        () =>
            spaces.map(space => {
                const spaceVariables = Object.keys(space.properties)
                    .map(property => {
                        const propDef = propertyDefinitions[property];
                        return propDef ? { [propDef.predefinedType]: space.properties[property].value } : {};
                    })
                    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
                return { ...space, ...spaceVariables };
            }),
        [spaces, propertyDefinitions]
    );

    const filteredSpaces: IndoorSpace[] = useMemo(
        () => spacesWithCustomProps.filter(space => space.name.toLowerCase().includes(searchText.toLowerCase())),
        [spacesWithCustomProps, searchText]
    );

    const sections = lodashGroupBy(filteredSpaces, selectedGrouping);

    const actionButtons: ActionButton[] = [
        {
            id: 'exportButton',
            title: 'CsvExport.Export',
            onClick: (): void => setCsvModalOpen(true),
            color: ButtonColor.secondary,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            testAttr: 'export',
            requiredGroupTypes: [],
            icon: <MaterialIcon name="download" />,
        },
    ];

    return (
        <div>
            <SubHeader onSearchUpdate={setSearchText} actionButtons={actionButtons} small />
            {csvModalOpen && (
                <SpacesCsvModal
                    dateFormat={dateFormat}
                    locationId={buildingId}
                    devices={[...spaces.flatMap(it => it.devices), ...spaces.flatMap(it => it.endedSegments)]}
                    onClose={(): void => setCsvModalOpen(false)}
                />
            )}
            {userRoleAboveRequiredLevel(userRole, roleRestrictions.editSpace) && (
                <div className={styles.buttonWrapper}>
                    <PrimaryButton
                        onClick={addSpace}
                        testId="add-space"
                        title="AddSpace.AddSpace"
                        icon={<MaterialIcon name="add" />}
                        small
                        fullWidth
                    />
                </div>
            )}
            <div className={styles.sensorsHeader}>
                <SpacesHeader
                    groupedBy={selectedGrouping}
                    availableSensors={availableSensors}
                    setSelectedSensors={setSelectedSensors}
                    selectedSensors={selectedSensors}
                    setSelectedGrouping={setSelectedGrouping}
                    buildingId={buildingId}
                />
            </div>
            <div className={styles.spacesWrapper}>
                {Object.keys(sections)
                    .sort((a, b) => {
                        if (a === undefined) return 1;
                        if (b === undefined) return -1;

                        const numA: number = parseFloat(a);
                        const numB: number = parseFloat(b);

                        if (!Number.isNaN(numA) && !Number.isNaN(numB)) {
                            return numA - numB;
                        }

                        if (!Number.isNaN(numA)) return -1;
                        if (!Number.isNaN(numB)) return 1;

                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    })
                    .map(groupValue => (
                        <div key={groupValue}>
                            <div className={styles.groupValue}>{groupValue === 'undefined' ? '-' : groupValue}</div>
                            <div className={styles.groupSection}>
                                {sections[groupValue].map(space => (
                                    <SpacesRow
                                        key={`space-element-${space.id}`}
                                        space={space}
                                        selectedSensors={selectedSensors}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        config: { spacePropDefs },
    } = store;
    return {
        userRole: selectedGroup ? selectedGroup.role : Role.VIEWER,
        propertyDefinitions: spacePropDefs,
    };
};

export default connect(mapStateToProps)(SpacesView);
