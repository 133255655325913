import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { SensorIcon } from 'commons/src/components/sensors/SensorUnit';
import VirusInfoFactors from './VirusInfoFactors';
import VirusInfoSolutions from './VirusInfoSolutions';

export type ParentProps = {
    onClose: () => void;
    selectedTab?: number;
};

const VirusInfoModalComponent = ({ onClose, selectedTab }: ParentProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [activeTab, setActiveTab] = useState(selectedTab || 0);

    const selectTab = (event: React.SyntheticEvent, newTab: number): void => {
        setActiveTab(newTab);
    };

    // ensure related videos are from the airthings channel
    const youtubeOpts: { playerVars: { rel: 0 | 1 | undefined } } = { playerVars: { rel: 0 } };

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--virus-risk modal__content--mobile-full"
            overlayClassName="modal modal__overlay"
        >
            <div className="modal__content__header">
                <h1 className="modal-icon-header">
                    <SensorIcon sensor="virusRisk" />
                    {txt('VirusInsight.WhatDoesVirusRiskMean')}
                </h1>
            </div>
            <div className="modal__content__video">
                <YouTube
                    iframeClassName="modal__content__video__player"
                    className="modal__content__video__player"
                    videoId="YoTPKlad0hQ"
                    opts={youtubeOpts}
                    id="virus-risk-info-video"
                />
            </div>
            <div className="tab-header-container">
                <Tabs
                    textColor="inherit"
                    indicatorColor="secondary"
                    value={activeTab}
                    onChange={selectTab}
                    aria-label="menu tabs"
                    TabIndicatorProps={{ sx: { height: '3px' } }}
                >
                    <Tab aria-controls="simple-tabpanel-0" value={0} label={txt('InsightRiskFactor.Factors')} />
                    <Tab aria-controls="simple-tabpanel-1" value={1} label={txt('InsightRiskFactor.Solutions')} />
                </Tabs>
            </div>
            {activeTab === 0 && <VirusInfoFactors />}
            {activeTab === 1 && <VirusInfoSolutions />}
            <div className="modal__content__buttons--sticky-bottom">
                <div className="modal__content__buttons">
                    <PrimaryButton title="GotIt" onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
};

export default VirusInfoModalComponent;
