import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { ButtonColor, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { ActionButton } from 'commons/src/models/menuModels';
import {
    fetchWebhookEvents,
    FetchWebhookEvents,
    FetchWebhooks,
    fetchWebhooks,
} from '../../../actions/integrationActions';
import { paths } from '../../../constants';
import { Webhook } from '../../../models/common';
import { Store } from '../../../reducers';
import WebhookForm from './WebhookForm';

type StateProps = {
    webhooks: Webhook[];
    loadingWebhooks: boolean;
    loadingLocations: boolean;
};

type ActionProps = {
    getWebhooks: () => void;
    onFetchWebhookEvents: (webhookId: string) => void;
};

type Props = StateProps & ActionProps;

export const AddEditWebhookView = ({
    getWebhooks,
    webhooks,
    loadingWebhooks,
    loadingLocations,
    onFetchWebhookEvents,
}: Props): React.ReactElement => {
    const { webhookId } = useParams<'webhookId'>();
    const navigate = useNavigate();
    const { t: txt } = useTranslation();
    const [editingWebhook] = useState(!!webhookId);
    useEffect(() => {
        if (webhooks.length === 0 && !loadingWebhooks) {
            getWebhooks();
        }
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    useEffect(() => {
        if (webhookId && !loadingWebhooks && !loadingLocations) {
            const webhookExists = webhooks.find(webhook => webhook.id === webhookId);
            if (!webhookExists) navigate(`/${paths.webhooks}`);
            else onFetchWebhookEvents(webhookExists.id);
        }
    }, [webhookId, loadingWebhooks, loadingLocations]);

    const close = (): void => {
        navigate(`/${paths.webhooks}`);
    };

    const actionButton: ActionButton[] = [
        {
            onClick: close,
            testAttr: 'close-add-edit-webhook',
            title: 'Close',
            id: 'closeAddEditWebhook',
            color: ButtonColor.secondary,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    return (
        <>
            <PageHeader title={txt(editingWebhook ? 'Webhooks.EditWebhook' : 'Webhooks.CreateWebhook')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButton} />
            </div>
            <ReactPlaceholder ready={!(loadingLocations || loadingWebhooks)} customPlaceholder={mediumFormLoader}>
                <WebhookForm />
            </ReactPlaceholder>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        webhooks: { webhooks },
        requests: {
            FETCH_WEBHOOKS: { loading: loadingWebhooks },
        },
        locations: { loading: loadingLocations },
    } = state;

    return { webhooks, loadingLocations, loadingWebhooks };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getWebhooks: (): FetchWebhooks => dispatch(fetchWebhooks()),
    onFetchWebhookEvents: (webhookId: string): FetchWebhookEvents => dispatch(fetchWebhookEvents(webhookId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditWebhookView);
