import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'business-dashboard/src/constants';
import Empty from '../../img/icon-empty-screen';
import PrimaryButton from '../buttons/PrimaryButton';
import { userIsHbs } from '../findUserType';

type Props = {
    noContentText: string;
    link?: string;
    linkText?: string;
    hasLocations?: boolean;
};
const NoContent = ({ noContentText, link, linkText, hasLocations }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const userHbs = userIsHbs();
    const navigate = useNavigate();
    const redirectToAddBuilding = (): void => {
        navigate({ pathname: `/${paths.addBuilding}` });
    };

    if (userHbs && hasLocations === false) {
        return (
            <div className="no-content-page" data-buildings-no-content>
                <h2 className="no-content-page__header">{txt('WelcomeToAirthingsForBusiness')}</h2>
                <Empty />
                <p className="no-content-page__text">{txt('Business.AddBuilding')}</p>
                <div className="no-content-page__text">
                    <PrimaryButton
                        testAttr="add-building-button"
                        onClick={redirectToAddBuilding}
                        filled
                        title="Building.AddBuilding"
                    />
                </div>
            </div>
        );
    }
    return (
        <div className="no-content-page">
            <Empty />
            <p className="no-content-page__title">{txt('NoContent')}</p>
            {link && linkText ? (
                <p className="no-content-page__text">
                    {txt('Click')} <Link to={link}>{txt(linkText)}</Link> {txt(noContentText)}
                </p>
            ) : (
                <p className="no-content-page__text no-content-page__text--ultra-thin">{txt(noContentText)}</p>
            )}
        </div>
    );
};

export default NoContent;
