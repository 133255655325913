import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { GroupType } from 'commons/src/models/commonEnums';
import { ErrorType, SubscriptionPlan } from 'commons/src/models/commonTypeScript';
import AddSeatsSubscriptionSelector from '../../../components/subscriptionComponents/AddSeatsSubscriptionSelector';
import YourPlan from '../../../components/subscriptionComponents/YourPlan';
import { A4BSupportMail } from '../../../constants';
import { Subscription } from '../../../models/subscriptionModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import SubscriptionTable from '../../account/subscription/SubscriptionTable';
import styles from './PartnerCusomerSubscription.module.scss';

export type ParentProps = {
    deviceCounts: { [device: string]: number };
    subscriptionPlan?: SubscriptionPlan;
};

type StateProps = {
    error?: ErrorType;
    loading: boolean;
    customerSubscription?: { subscriptions: Subscription[]; userGroupId: string };
};

type Props = StateProps & ParentProps;

const PartnerCustomerSubscription = ({
    error,
    deviceCounts,
    loading,
    customerSubscription,
    subscriptionPlan,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const [addSeatsOpen, setAddSeatsOpen] = useState(false);
    const subscriptions = customerSubscription?.subscriptions || [];

    const deviceSeatsAlreadyPayedFor =
        customerSubscription?.subscriptions?.reduce((acc: { [deviceType: string]: number }, sub) => {
            const updatedAcc = { ...acc };
            sub.deviceSeats.forEach(device => {
                updatedAcc[device.deviceType] = (updatedAcc[device.deviceType] || 0) + device.seats;
            });
            return updatedAcc;
        }, {}) || {};

    const onAddSeatsClicked = (open: boolean): void => {
        if (subscriptions.length === 1) {
            const { subscriptionNumber } = subscriptions[0];
            navigate(`add-seats/${subscriptionNumber}`);
        } else setAddSeatsOpen(open);
    };

    const startSubscription = (): void => {
        navigate('start-subscription');
    };
    return (
        <>
            <h2 className="settings__header">{txt('Subscription.Subscription')}</h2>
            <YourPlan
                deployedDevices={deviceCounts}
                deviceSeatsAlreadyPayedFor={deviceSeatsAlreadyPayedFor}
                openAddSeats={subscriptions.length > 0 ? onAddSeatsClicked : undefined}
                subscriptionPlan={subscriptionPlan}
            />
            <AddSeatsSubscriptionSelector
                onClose={(): void => setAddSeatsOpen(false)}
                isOpen={addSeatsOpen}
                subscriptions={subscriptions}
            />
            <div className={styles.subscriptionSection}>
                {!loading && (
                    <SubscriptionTable
                        subscriptions={subscriptions}
                        groupType={GroupType.partner}
                        childUserGroupId={customerSubscription?.userGroupId}
                    />
                )}
                {!loading && subscriptions.length === 0 && !error && (
                    <div className={styles.startSubscriptionButton}>
                        <PrimaryButton filled onClick={startSubscription} title="Subscription.StartSubscription" />
                    </div>
                )}
                <p className={styles.supportText}>
                    <Trans i18nKey="Subscription.SubscriptionSupport">
                        <a id="open_intercom" href={`mailto: ${A4BSupportMail}`}>
                            chat
                        </a>
                    </Trans>
                </p>
                {error && <ResponseBox text={`ErrorCodes.${error.error}`} />}
            </div>
        </>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        requests: {
            [RequestType.GetSubscriptionForPartnerCustomer]: { error, loading },
        },
        partner: { customerSubscription },
    } = store;

    return {
        error,
        loading,
        customerSubscription,
    };
};

export default connect(mapStateToProps)(PartnerCustomerSubscription);
