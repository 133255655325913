import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { selectDashboard, SelectDashboard } from '../../../actions/DashboardActions';
import CircleButton from '../../../components/buttons/CircleButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import ChangeSelectedSearchHeader from '../../../components/menu/ChangeSelectedSearchHeader';
import { DashboardProps, ErrorType } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';

type StateProps = {
    dashboardList?: { name: string; id: string }[];
    dashboardProps?: DashboardProps;
    loadingDashboard: boolean;
    fetchDashboardError?: ErrorType;
};

type ActionProps = {
    switchDashboard: (id: string) => void;
};

type PassedProps = {
    onClose: () => void;
};

export type Props = PassedProps & StateProps & ActionProps;

export const SwitchDashboardModalComponent = (props: Props): React.ReactElement => {
    const { dashboardList, dashboardProps, switchDashboard, onClose, loadingDashboard, fetchDashboardError } = props;
    const [searchText, setSearchText] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState<string | undefined>(undefined);

    const clickSelect = (id: string): void => {
        switchDashboard(id);
        setSelectedDashboard(id);
    };
    useEffect(() => {
        if (fetchDashboardError) {
            setSelectedDashboard(undefined);
        }
    }, [fetchDashboardError]);

    useEffect(() => {
        if (selectedDashboard && !loadingDashboard) {
            onClose();
        }
    }, [loadingDashboard]);

    const filteredDashboards = dashboardList
        ? dashboardList.filter(group => group.name.toLowerCase().includes(searchText.toLowerCase()))
        : [];

    const isActiveDashboard = (id: string): boolean =>
        selectedDashboard ? selectedDashboard === id : !!dashboardProps && id === dashboardProps.id;
    const sortedList = filteredDashboards.sort((group1, group2) => group1.name.localeCompare(group2.name));

    return (
        <Modal
            isOpen
            appElement={document.body}
            onRequestClose={onClose}
            className="modal__content modal__content--swap-organization"
            overlayClassName="modal modal__overlay"
        >
            <div className="modal__content__sticky">
                <ChangeSelectedSearchHeader
                    setSearchText={setSearchText}
                    displaySearch={(dashboardList || []).length > 5}
                    headerText="PublicDashboard.SwapDashboard"
                />
                <div className="modal__content__body modal__content__body--scroll">
                    <div className="modal__content--swap-organization__list">
                        {sortedList.map(group => (
                            <div
                                className="modal__content--swap-organization__list__row"
                                key={`organization-row-${group.id}`}
                            >
                                <div className="modal__content--swap-organization__list__row__text">
                                    <div className="modal__content--swap-organization__list__row__text__overflow">
                                        {group.name}
                                    </div>
                                </div>
                                <CircleButton
                                    onClick={(): void => clickSelect(group.id)}
                                    testAttr="swap-organization"
                                    iconName={isActiveDashboard(group.id) ? 'check' : 'arrow_forward'}
                                    color={isActiveDashboard(group.id) ? 'confirmed' : 'secondary'}
                                    loading={loadingDashboard && isActiveDashboard(group.id)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal__content__buttons modal__content__buttons--sticky">
                    <PrimaryButton title="Cancel" loading={false} disabled={false} onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        dashboardData: { dashboardList, dashboardProps },
        commonRequests: {
            [CommonRequestType.SelectDashboard]: { loading: loadingDashboard, error: fetchDashboardError },
        },
    } = state;
    return {
        dashboardList,
        loadingDashboard,
        fetchDashboardError,
        dashboardProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    switchDashboard: (id: string): SelectDashboard => dispatch(selectDashboard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SwitchDashboardModalComponent);
