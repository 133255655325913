import {
    ErrorType,
    DeviceConfigUpdate,
    SelectedPeriod,
    SensorData,
    MinSensorPeriodValues,
    AverageSensorPeriodValues,
    LocationChangePayload,
    DeviceConfigResponse,
    DeviceDataType,
    DeviceType,
    ExtraSeries,
} from '../models/commonTypeScript';
import { CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum DeviceActionType {
    FetchDeviceConfig = 'FETCH_DEVICE_CONFIG/INIT',
    FetchDeviceConfigSuccess = 'FETCH_DEVICE_CONFIG_SUCCESS',
    UpdateDeviceConfig = 'UPDATE_DEVICE_CONFIG/INIT',
    UpdateDeviceConfigSuccess = 'UPDATE_DEVICE_CONFIG_SUCCESS',
    EstimateBatteryLifeSuccess = 'ESTIMATE_BATTERY_LIFE_SUCCESS',
    FetchDevice = 'FETCH_DEVICE/INIT',
    FetchDeviceSuccess = 'FETCH_DEVICE_SUCCESS',
    FetchDeviceSegmentSuccess = 'FETCH_DEVICE_SEGMENT_SUCCESS',
    FetchDeviceSegmentError = 'FETCH_DEVICE_SEGMENT_ERROR',
    DeleteDevice = 'DELETE_DEVICE/INIT',
    DeleteDeviceSuccess = 'DELETE_DEVICE_SUCCESS',
    DeleteEndedSegment = 'DELETE_ENDED_SEGMENT/INIT',
    DeleteEndedSegmentSuccess = 'DELETE_ENDED_SEGMENT_SUCCESS',
    PollVirtualDeviceSensorData = 'POLL_VIRTUAL_DEVICE_DATA/INIT',
    GetVirtualDeviceSensorDataSuccess = 'GET_VIRTUAL_DEVICE_DATA_SUCCESS',
    FetchCustomSegmentVirtualSensorsSuccess = 'FETCH_CUSTOM_SEGMENT_VIRTUAL_SENSORS_SUCCESS',
    FetchCustomSegmentVirtualSensorsInit = 'FETCH_CUSTOM_SEGMENT_VIRTUAL_SENSORS/INIT',
    RenameSegmentInit = 'RENAME_SEGMENT/INIT',
    RenameSegmentSuccess = 'RENAME_SEGMENT_SUCCESS',
    RenameSegmentError = 'RENAME_SEGMENT_ERROR',
}

export type PollDeviceDataPayload = {
    serialNumber: string;
    selectedInterval: SelectedPeriod;
    fetching?: boolean;
    loading?: boolean;
    parent?: string;
    extraParams?: { [extraParam: string]: string | undefined };
};
export const POLL_DEVICE_DATA = 'POLL_DEVICE_DATA';
export interface PollDeviceData {
    type: typeof POLL_DEVICE_DATA;
    payload: PollDeviceDataPayload;
    withPolling: boolean;
}
export const pollDeviceData = (payload: PollDeviceDataPayload, withPolling = true): PollDeviceData => ({
    type: POLL_DEVICE_DATA,
    payload,
    withPolling,
});

export const STOP_POLL_DEVICE_DATA = 'STOP_POLL_DEVICE_DATA';
export interface StopPollDeviceData {
    type: typeof STOP_POLL_DEVICE_DATA;
}
export const stopPollDeviceData = (): StopPollDeviceData => ({ type: STOP_POLL_DEVICE_DATA });

export const FETCH_CUSTOM_DEVICE_SEGMENT = 'FETCH_CUSTOM_DEVICE_SEGMENT';
export interface FetchCustomDeviceSegment {
    type: typeof FETCH_CUSTOM_DEVICE_SEGMENT;
    serialNumber: string;
    timeRange: SelectedPeriod;
    extraParams?: { [extraParam: string]: string | undefined };
}
export const fetchCustomDeviceSegment = (
    serialNumber: string,
    timeRange: SelectedPeriod,
    extraParams?: { [extraParam: string]: string | undefined }
): FetchCustomDeviceSegment => ({
    type: FETCH_CUSTOM_DEVICE_SEGMENT,
    serialNumber,
    timeRange,
    extraParams,
});

export interface FetchCustomSegmentVirtualSensors {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchCustomSegmentVirtualSensors;
    type: DeviceActionType.FetchCustomSegmentVirtualSensorsInit;
    serialNumber: string;
    timeRange: SelectedPeriod;
    extraParams?: { [extraParam: string]: string | undefined };
}
export const fetchCustomSegmentVirtualSensors = (
    serialNumber: string,
    timeRange: SelectedPeriod,
    extraParams?: { [extraParam: string]: string | undefined }
): FetchCustomSegmentVirtualSensors => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchCustomSegmentVirtualSensors,
    type: DeviceActionType.FetchCustomSegmentVirtualSensorsInit,
    serialNumber,
    timeRange,
    extraParams,
});

export interface PollVirtualSensorData {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.PollVirtualDeviceSensorData;
    type: DeviceActionType.PollVirtualDeviceSensorData;
    payload: PollDeviceDataPayload;
}

export const pollVirtualSensorData = (payload: PollDeviceDataPayload): PollVirtualSensorData => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.PollVirtualDeviceSensorData,
    type: DeviceActionType.PollVirtualDeviceSensorData,
    payload,
});

export interface GetVirtualSensorDataSuccess {
    type: DeviceActionType.GetVirtualDeviceSensorDataSuccess;
    segment: DeviceDataType;
    serialNumber: string;
}
export const getVirtualSensorDataSuccess = (
    segment: DeviceDataType,
    serialNumber: string
): GetVirtualSensorDataSuccess => ({
    type: DeviceActionType.GetVirtualDeviceSensorDataSuccess,
    segment,
    serialNumber,
});

export interface FetchDeviceSegmentSuccess {
    type: DeviceActionType.FetchDeviceSegmentSuccess;
    segment: DeviceDataType;
    serialNumber: string;
}
export const fetchDeviceSegmentSuccess = (
    segment: DeviceDataType,
    serialNumber: string
): FetchDeviceSegmentSuccess => ({
    type: DeviceActionType.FetchDeviceSegmentSuccess,
    segment,
    serialNumber,
});

export interface FetchDeviceSegmentError {
    type: DeviceActionType.FetchDeviceSegmentError;
    serialNumber: string;
    error: ErrorType;
}
export const fetchDeviceSegmentError = (serialNumber: string, error: ErrorType): FetchDeviceSegmentError => ({
    type: DeviceActionType.FetchDeviceSegmentError,
    serialNumber,
    error,
});

export const FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS = 'FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS';
type CustomSegment = {
    fetchedIntervals: { [name: string]: boolean };
    sensorData: SensorData;
    minValues: MinSensorPeriodValues;
    averageValues: AverageSensorPeriodValues;
    extraSeries: ExtraSeries;
};

export interface FetchCustomDeviceSegmentSuccess {
    type: typeof FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS;
    segment: CustomSegment;
    serialNumber: string;
}
export const fetchCustomDeviceSegmentSuccess = (
    segment: CustomSegment,
    serialNumber: string
): FetchCustomDeviceSegmentSuccess => ({
    type: FETCH_CUSTOM_DEVICE_SEGMENT_SUCCESS,
    segment,
    serialNumber,
});

export interface FetchCustomSegmentVirtualSensorsSuccess {
    type: DeviceActionType.FetchCustomSegmentVirtualSensorsSuccess;
    segment: CustomSegment;
    serialNumber: string;
}

export const fetchCustomSegmentVirtualSensorsSuccess = (
    segment: CustomSegment,
    serialNumber: string
): FetchCustomSegmentVirtualSensorsSuccess => ({
    type: DeviceActionType.FetchCustomSegmentVirtualSensorsSuccess,
    segment,
    serialNumber,
});

export const FETCH_CUSTOM_DEVICE_SEGMENT_ERROR = 'FETCH_DEVICE_SEGMENT_ERROR';
export interface FetchCustomDeviceSegmentError {
    type: typeof FETCH_CUSTOM_DEVICE_SEGMENT_ERROR;
    serialNumber: string;
    error: ErrorType;
}
export const fetchCustomDeviceSegmentError = (
    serialNumber: string,
    error: ErrorType
): FetchCustomDeviceSegmentError => ({
    type: FETCH_CUSTOM_DEVICE_SEGMENT_ERROR,
    serialNumber,
    error,
});

export const FETCH_DEVICE_SEGMENTID = 'FETCH_DEVICE_SEGMENTID';
export interface FetchDeviceSegmentId {
    type: typeof FETCH_DEVICE_SEGMENTID;
    serialNumber: string;
    segmentId: string;
    segmentPeriod: SelectedPeriod;
}
export const fetchDeviceSegmentId = (
    serialNumber: string,
    segmentId: string,
    segmentPeriod: SelectedPeriod
): FetchDeviceSegmentId => ({
    type: FETCH_DEVICE_SEGMENTID,
    serialNumber,
    segmentId,
    segmentPeriod,
});

export const FETCH_DEVICE_SEGMENTID_SUCCESS = 'FETCH_DEVICE_SEGMENTID_SUCCESS';

export interface FetchDeviceSegmentIdSuccess {
    type: typeof FETCH_DEVICE_SEGMENTID_SUCCESS;
    segmentId: string;
    device: DeviceDataType;
}
export const fetchDeviceSegmentIdSuccess = (
    device: DeviceDataType,
    segmentId: string
): FetchDeviceSegmentIdSuccess => ({
    type: FETCH_DEVICE_SEGMENTID_SUCCESS,
    segmentId,
    device,
});

export const FETCH_DEVICE_SEGMENTID_ERROR = 'FETCH_DEVICE_SEGMENTID_ERROR';
export interface FetchDeviceSegmentIdError {
    type: typeof FETCH_DEVICE_SEGMENTID_ERROR;
}
export const fetchDeviceSegmentIdError = (): FetchDeviceSegmentIdError => ({ type: FETCH_DEVICE_SEGMENTID_ERROR });

export const FETCH_DEVICE_SEGMENTID_PERIOD = 'FETCH_DEVICE_SEGMENTID_PERIOD';
export interface FetchDeviceSegmentIdPeriod {
    type: typeof FETCH_DEVICE_SEGMENTID_PERIOD;
    serialNumber: string;
    segmentId: string;
    timeRange: SelectedPeriod;
}
export const fetchDeviceSegmentIdPeriod = (
    serialNumber: string,
    segmentId: string,
    timeRange: SelectedPeriod
): FetchDeviceSegmentIdPeriod => ({
    type: FETCH_DEVICE_SEGMENTID_PERIOD,
    serialNumber,
    segmentId,
    timeRange,
});

export const FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS = 'FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS';
export interface FetchDeviceSegmentIdPeriodSuccess {
    type: typeof FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS;
    segment: {
        fetchedIntervals: { [timeRange: string]: boolean };
        sensorData: SensorData;
        minValues: MinSensorPeriodValues;
        averageValues: AverageSensorPeriodValues;
    };
    segmentId: string;
}
export const fetchDeviceSegmentIdPeriodSuccess = (
    segment: {
        fetchedIntervals: { [timeRange: string]: boolean };
        sensorData: SensorData;
        minValues: MinSensorPeriodValues;
        averageValues: AverageSensorPeriodValues;
    },
    segmentId: string
): FetchDeviceSegmentIdPeriodSuccess => ({
    type: FETCH_DEVICE_SEGMENTID_PERIOD_SUCCESS,
    segment,
    segmentId,
});

export const FETCH_DEVICE_SEGMENTID_PERIOD_ERROR = 'FETCH_DEVICE_SEGMENTID_PERIOD_ERROR';
export interface FetchDeviceSegmentIdPeriodError {
    type: typeof FETCH_DEVICE_SEGMENTID_PERIOD_ERROR;
}
export const fetchDeviceSegmentIdPeriodError = (): FetchDeviceSegmentIdPeriodError => ({
    type: FETCH_DEVICE_SEGMENTID_PERIOD_ERROR,
});

export interface DeleteDevice {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.DeleteDevice;
    type: DeviceActionType.DeleteDevice;
    serialNumber: string;
    locationId: string;
}
export const deleteDevice = (serialNumber: string, locationId: string): DeleteDevice => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.DeleteDevice,
    type: DeviceActionType.DeleteDevice,
    serialNumber,
    locationId,
});

export interface DeleteDeviceSuccess {
    type: DeviceActionType.DeleteDeviceSuccess;
    serialNumber: string;
    locationId: string;
}
export const deleteDeviceSuccess = (serialNumber: string, locationId: string): DeleteDeviceSuccess => ({
    type: DeviceActionType.DeleteDeviceSuccess,
    serialNumber,
    locationId,
});

export interface DeleteEndedSegment {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.DeleteEndedSegment;
    type: DeviceActionType.DeleteEndedSegment;
    serialNumber: string;
    segmentId: string;
}
export const deleteEndedSegment = (serialNumber: string, segmentId: string): DeleteEndedSegment => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.DeleteEndedSegment,
    type: DeviceActionType.DeleteEndedSegment,
    serialNumber,
    segmentId,
});

export interface DeleteEndedSegmentSuccess {
    type: DeviceActionType.DeleteEndedSegmentSuccess;
    serialNumber: string;
    segmentId: string;
}
export const deleteEndedSegmentSuccess = (serialNumber: string, segmentId: string): DeleteEndedSegmentSuccess => ({
    type: DeviceActionType.DeleteEndedSegmentSuccess,
    serialNumber,
    segmentId,
});

export interface RenameSegment {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.RenameSegment;
    type: DeviceActionType.RenameSegmentInit;
    serialNumber: string;
    segmentId: string;
    name: string;
}
export const renameSegment = (serialNumber: string, segmentId: string, name: string): RenameSegment => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.RenameSegment,
    type: DeviceActionType.RenameSegmentInit,
    serialNumber,
    segmentId,
    name,
});

export interface RenameSegmentSuccess {
    type: DeviceActionType.RenameSegmentSuccess;
    serialNumber: string;
    segmentId: string;
    name: string;
}
export const renameSegmentSuccess = (serialNumber: string, segmentId: string, name: string): RenameSegmentSuccess => ({
    type: DeviceActionType.RenameSegmentSuccess,
    serialNumber,
    segmentId,
    name,
});

export const EDIT_DEVICE_SAVE = 'EDIT_DEVICE_SAVE';
export interface EditDeviceSave {
    type: typeof EDIT_DEVICE_SAVE;
    payload: LocationChangePayload;
}
export const editDeviceSave = (payload: LocationChangePayload): EditDeviceSave => ({ type: EDIT_DEVICE_SAVE, payload });

export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export interface ChangeLocation {
    type: typeof CHANGE_LOCATION;
    payload: LocationChangePayload;
}
export const changeLocation = (payload: LocationChangePayload): ChangeLocation => ({ type: CHANGE_LOCATION, payload });

export const CHANGE_LOCATION_SUCCESS = 'CHANGE_LOCATION_SUCCESS';
export interface ChangeLocationSuccess {
    type: typeof CHANGE_LOCATION_SUCCESS;
    payload: {
        name: string;
        locationName: string;
        serialNumber: string;
        locationId: string;
    };
}

export const changeLocationSuccess = (payload: {
    name: string;
    locationName: string;
    serialNumber: string;
    locationId: string;
}): ChangeLocationSuccess => ({ type: CHANGE_LOCATION_SUCCESS, payload });

export const CHANGE_LOCATION_FAIL = 'CHANGE_LOCATION_FAIL';
export interface ChangeLocationFailure {
    type: typeof CHANGE_LOCATION_FAIL;
    error: ErrorType;
}
export const changeLocationFailure = (error: ErrorType): ChangeLocationFailure => ({
    type: CHANGE_LOCATION_FAIL,
    error,
});

export const CLEAR_DEVICE_DATA = 'CLEAR_DEVICE_DATA';
export interface ClearDeviceData {
    type: typeof CLEAR_DEVICE_DATA;
}
export const clearDeviceData = (): ClearDeviceData => ({ type: CLEAR_DEVICE_DATA });

export interface FetchDevice {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchDevice;
    type: DeviceActionType.FetchDevice;
    serialNumber: string;
    segmentId: string;
}

export const fetchDevice = (serialNumber: string, segmentId: string): FetchDevice => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchDevice,
    type: DeviceActionType.FetchDevice,
    serialNumber,
    segmentId,
});

export interface FetchDeviceSuccess {
    type: DeviceActionType.FetchDeviceSuccess;
    device: DeviceType;
    serialNumber: string;
}

export const fetchDeviceSuccess = (device: DeviceType, serialNumber: string): FetchDeviceSuccess => ({
    type: DeviceActionType.FetchDeviceSuccess,
    device,
    serialNumber,
});

export interface FetchDeviceConfig {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchDeviceConfig;
    type: DeviceActionType.FetchDeviceConfig;
    serialNumber: string;
    segmentId: string;
}
export const fetchDeviceConfig = (serialNumber: string, segmentId: string): FetchDeviceConfig => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.FetchDeviceConfig,
    type: DeviceActionType.FetchDeviceConfig,
    serialNumber,
    segmentId,
});

export interface UpdateDeviceConfig {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.UpdateDeviceConfig;
    type: DeviceActionType.UpdateDeviceConfig;
    serialNumber: string;
    config: DeviceConfigUpdate;
    segmentId: string;
    displaySuccessMessage?: boolean;
}
export const updateDeviceConfig = (
    config: DeviceConfigUpdate,
    serialNumber: string,
    segmentId: string,
    displaySuccessMessage?: boolean
): UpdateDeviceConfig => ({
    requestActionType: RequestActionType.Init as RequestActionType.Init,
    requestType: CommonRequestType.UpdateDeviceConfig,
    type: DeviceActionType.UpdateDeviceConfig,
    serialNumber,
    config,
    segmentId,
    displaySuccessMessage,
});

export interface FetchDeviceConfigSuccess {
    type: DeviceActionType.FetchDeviceConfigSuccess;
    config: DeviceConfigResponse;
    serialNumber: string;
}
export const fetchDeviceConfigSuccess = (
    config: DeviceConfigResponse,
    serialNumber: string
): FetchDeviceConfigSuccess => ({
    type: DeviceActionType.FetchDeviceConfigSuccess,
    config,
    serialNumber,
});

export interface UpdateDeviceConfigSuccess {
    type: DeviceActionType.UpdateDeviceConfigSuccess;
    config: DeviceConfigUpdate;
    serialNumber: string;
}
export const updateDeviceConfigSuccess = (
    config: DeviceConfigUpdate,
    serialNumber: string
): UpdateDeviceConfigSuccess => ({
    type: DeviceActionType.UpdateDeviceConfigSuccess,
    config,
    serialNumber,
});

export type DeviceReducerActions =
    | FetchDeviceConfigSuccess
    | FetchDeviceSuccess
    | UpdateDeviceConfigSuccess
    | FetchCustomDeviceSegment
    | PollDeviceData
    | FetchCustomDeviceSegmentSuccess
    | FetchDeviceSegmentSuccess
    | FetchDeviceSegmentError
    | DeleteDevice
    | DeleteDeviceSuccess
    | EditDeviceSave
    | ChangeLocation
    | ChangeLocationSuccess
    | ChangeLocationFailure
    | RenameSegment
    | StopPollDeviceData
    | ClearDeviceData
    | DeleteEndedSegment
    | DeleteEndedSegmentSuccess
    | FetchDeviceSegmentId
    | FetchDeviceSegmentIdPeriod
    | FetchDeviceSegmentIdSuccess
    | FetchDeviceSegmentIdError
    | FetchDeviceSegmentIdPeriodError
    | FetchDeviceSegmentIdPeriodSuccess
    | GetVirtualSensorDataSuccess
    | FetchCustomSegmentVirtualSensorsSuccess
    | RenameSegmentSuccess;
