import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { displayAlertBoxSagaAction } from 'commons/src/actions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import { fetchLocationMoldRiskSegments, updateLocationMoldRiskSegments } from '../../../api/buildingsApi';
import { Store } from '../../../reducers';
import styles from './MoldRiskSection.module.scss';

type StateProps = {
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type PassedProps = {
    locationId: string;
    closeSection: () => void;
};

export type Props = StateProps & PassedProps;

export const MoldRiskSectionComponent = ({
    devicesWithKeyInfo,
    locationId,
    closeSection,
}: Props): React.ReactElement => {
    const devicesOnLocation = Object.values(devicesWithKeyInfo)
        .filter(device => device.locationId === locationId)
        .filter(it => [DeviceTypeNames.wavePlus, DeviceTypeNames.waveNano].includes(it.type as DeviceTypeNames));

    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const [segmentIdsWithMoldRiskEnabled, setSegmentIdsWithMoldRiskEnabled] = useState<string[]>([]);
    const [fetchLoading, setFetchLoading] = useState<boolean>(true);
    const [postLoading, setPostLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | undefined>(undefined);

    const updateSelectedSegmentIds = ({ id: segmentId, changeAll }: { id: string; changeAll: boolean }): void => {
        if (changeAll) {
            if (devicesOnLocation.length === segmentIdsWithMoldRiskEnabled.length) {
                setSegmentIdsWithMoldRiskEnabled([]);
            } else {
                setSegmentIdsWithMoldRiskEnabled(devicesOnLocation.map(device => device.segmentId));
            }
        } else {
            const newSelectedSegmentIds = segmentIdsWithMoldRiskEnabled.includes(segmentId)
                ? segmentIdsWithMoldRiskEnabled.filter(device => device !== segmentId)
                : [...segmentIdsWithMoldRiskEnabled, segmentId];
            setSegmentIdsWithMoldRiskEnabled(newSelectedSegmentIds);
        }
    };

    const fetchMoldEnabledDevices = useCallback(async (): Promise<void> => {
        await fetchLocationMoldRiskSegments(locationId)
            .then(response => {
                setSegmentIdsWithMoldRiskEnabled(response.segmentIdsWithMoldRiskEnabled);
            })
            .catch(err => setError(err));

        setFetchLoading(false);
    }, []);

    const onSaveDeviceSelection = useCallback(async (segmentIds: string[]): Promise<void> => {
        setPostLoading(true);

        await updateLocationMoldRiskSegments(locationId, segmentIds)
            .then(() => {
                dispatch(displayAlertBoxSagaAction('MoldRisk.DevicesUpdated', false, true));
            })
            .catch(err => {
                displayAlertBoxSagaAction(toErrorTypeWithMessage(err).message, true, true);
            });

        setPostLoading(false);
    }, []);

    useEffect((): void => {
        fetchMoldEnabledDevices().catch();
    }, []);

    if (error) {
        return (
            <div className={styles.errorWrapper}>
                <ResponseBox text={error.error} />
            </div>
        );
    }

    const onSaveSegmentsClick = (): void => {
        onSaveDeviceSelection(segmentIdsWithMoldRiskEnabled).catch();
    };

    const devicesListOptions = devicesOnLocation
        .map(device => ({
            id: device.segmentId,
            name: device.segmentName,
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return (
        <ReactPlaceHolder ready={!fetchLoading} customPlaceholder={mediumFormLoader}>
            <div className={styles.container}>
                <p className={styles.disclaimerMessage}>
                    <MaterialIcon name="info" extraClass={styles.iconPadding} />
                    <span>{txt('MoldRiskIndicator.DamageDisclaimer')}</span>
                </p>
                <div className={styles.selectDevicesInfo}>{txt('MoldRiskIndicator.SelectDevices')}</div>
                <SimpleTreeView
                    listId="mold-risk-segments"
                    options={devicesListOptions}
                    selectedOptions={segmentIdsWithMoldRiskEnabled}
                    onChange={updateSelectedSegmentIds}
                />
                <div className={styles.buttons}>
                    <PrimaryButton id="cancel" title="Cancel" disabled={postLoading} onClick={closeSection} />
                    <PrimaryButton
                        type="button"
                        id="save-mold-risk-button"
                        testAttr="save-mold-risk-button"
                        title="Save"
                        onClick={onSaveSegmentsClick}
                        filled
                        loading={postLoading}
                        testId="save-mold-risk-button"
                    />
                </div>
            </div>
        </ReactPlaceHolder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo },
    } = state;

    return {
        devicesWithKeyInfo,
    };
};

export default connect(mapStateToProps)(MoldRiskSectionComponent);
