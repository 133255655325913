import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats, measurementUnits } from 'commons/src/constants';
import { BuildingType, Units } from 'commons/src/models/commonTypeScript';
import { BuildingProps } from '../../../models/commonEnums';
import OpeningHours from '../../reports/reportSelector/OpeningHoursTable';
import BuildingSettingsModalComponent from '../buildingEditDetails/BuildingSettingsModal';

type ParentProps = {
    building: BuildingType;
    validate: boolean;
    dateFormat: keyof typeof dateFormats;
    units: Units;
};

type Props = ParentProps;

export const GroupBuildingInfoComponent = (props: Props): React.ReactElement => {
    const { building, validate, dateFormat, units } = props;
    const { t: txt } = useTranslation();
    const [editBuildingOpen, setEditBuildingOpen] = useState(false);

    return (
        <>
            <h2>{txt('Location')}</h2>
            {editBuildingOpen && building && (
                <BuildingSettingsModalComponent onClose={(): void => setEditBuildingOpen(false)} building={building} />
            )}
            <div className="form__row flex--spaced">
                <div className="labeled-text form__field--standard-width">
                    <span className="labeled-text__label">{txt('Building.Building')}</span>
                    <span className="labeled-text__multiline text-large">{building.name}</span>
                </div>
                <div className="form__button-container">
                    <PrimaryButton
                        onClick={(): void => setEditBuildingOpen(true)}
                        title="Edit"
                        id="editBuilding"
                        testAttr="edit-building"
                        icon={<MaterialIcon extraClass="icon-circle" name="edit" />}
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field--medium-slim">
                    <LabeledText
                        label="NumberOfFloors"
                        id="numberOfFloors"
                        largeText
                        invalid={validate && !building.floors}
                        value={(building.floors && building.floors.toString()) || '-'}
                    />
                </div>
                <div className="form__field--medium-slim">
                    <LabeledText
                        customLabel={txt('Building.BuildingHeight', {
                            unit:
                                measurementUnits[units.lengthUnit as keyof typeof measurementUnits] &&
                                measurementUnits[units.lengthUnit as keyof typeof measurementUnits].length,
                        })}
                        id="numberOfFloors"
                        largeText
                        invalid={validate && !building.buildingHeight}
                        value={(building.buildingHeight && building.buildingHeight.toString()) || '-'}
                    />
                </div>
                <div className="form__field--medium-slim">
                    <LabeledText
                        customLabel={txt('Building.BuildingSize', {
                            unit:
                                measurementUnits[units.lengthUnit as keyof typeof measurementUnits] &&
                                measurementUnits[units.lengthUnit as keyof typeof measurementUnits].area,
                        })}
                        id="buildingSize"
                        largeText
                        invalid={validate && !building.buildingSize}
                        value={(building.buildingSize && building.buildingSize.toString()) || '-'}
                    />
                </div>
            </div>
            <OpeningHours
                optionalBuildingProps={[BuildingProps.openingHours]}
                dateFormat={dateFormat}
                validate={false}
                building={building}
            />
        </>
    );
};

export default GroupBuildingInfoComponent;
