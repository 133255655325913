import * as React from 'react';
import StandAloneSpinner from '../../img/StandAloneSpinner';

export type Props = {
    isLoading: boolean;
    children: React.ReactElement | null;
};

const SpinnerBlocker = ({ isLoading, children }: Props): React.ReactElement | null => {
    if (isLoading) {
        return (
            <div className="modal__content__spinner">
                <StandAloneSpinner />
            </div>
        );
    }
    return children;
};

export default SpinnerBlocker;
