import React, { useEffect, useState } from 'react';
import moment, { Moment, unitOfTime } from 'moment';
import { connect } from 'react-redux';
import FilterButton from 'commons/src/components/buttons/FilterButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DatePicker from 'commons/src/components/DatePicker';
import CheckBox from 'commons/src/components/input/Checkbox';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { dateFormats } from 'commons/src/constants';
import { Resolution, TimePeriod } from 'commons/src/models/commonEnums';
import { Store } from '../../../reducers';

type ParentProps = {
    buildingHasUsageHours: boolean;
    setWithOpeningHours: (withinOpeningHours: boolean) => void;
    withOpeningHours: boolean;
    selectedPeriod: TimePeriod;
    setSelectedPeriod: (period: TimePeriod, fromDate: string, toDate: string, resolution: Resolution) => void;
};

type StateProps = {
    dateFormat: string;
};

type Props = ParentProps & StateProps;

const BuildingInsightTimeSelector = ({
    buildingHasUsageHours,
    setWithOpeningHours,
    withOpeningHours,
    selectedPeriod,
    setSelectedPeriod,
    dateFormat,
}: Props): React.ReactElement => {
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [displayDatePicker, setDisplayDatePicker] = useState(false);

    const isOutsideDateRange = (day: Moment): boolean => {
        if (!moment.isMoment(day)) return false;

        const today = moment();
        const isBeforeBackCalculationStarted = day.isBefore(moment('2022-01-01'));
        return isBeforeBackCalculationStarted || day.isAfter(today.endOf('day'));
    };

    const isOutsideEndDateRange = (day: Moment, selectedStartDate: Moment | null): boolean => {
        const thirtyDaysAfterStartDate = selectedStartDate
            ? moment(day).endOf('day').diff(moment(selectedStartDate).add(30, 'days').endOf('day')) > 0
            : false;
        const isBeforeStartDate = moment(day).endOf('day').isBefore(moment(selectedStartDate).endOf('day'));
        if (isBeforeStartDate || thirtyDaysAfterStartDate) {
            return true;
        }
        return isOutsideDateRange(day);
    };

    const onStartDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(startDate).valueOf()) {
            setStartDate(date);
            const endDateMoreThan30DaysAfterStart =
                endDate && date && moment(date).add(30, 'days').endOf('day').isBefore(endDate);
            const startDateAfterEndDate = endDate && date && date.endOf('day').isAfter(endDate.endOf('day'));
            if (endDateMoreThan30DaysAfterStart || startDateAfterEndDate) {
                setEndDate(null);
            }
        }
    };

    const selectPeriod = (period: TimePeriod): void => {
        const to =
            period === TimePeriod.day
                ? moment().format('YYYY-MM-DD')
                : moment().subtract(1, 'day').format('YYYY-MM-DD');
        let from = moment().subtract(1, period as unitOfTime.DurationConstructor);
        if (period === TimePeriod.yesterday) {
            from = moment().subtract(1, 'day');
        } else if (period === TimePeriod.day) {
            from = moment();
        }
        const resolution =
            period === TimePeriod.yesterday || period === TimePeriod.day ? Resolution.hour : Resolution.day;
        if (period !== TimePeriod.custom) {
            setStartDate(null);
            setEndDate(null);
        }
        setSelectedPeriod(period, from.format('YYYY-MM-DD'), to, resolution);
    };

    const fetchCustomTimeSpan = (): void => {
        if (startDate && endDate) {
            const timeSpan = endDate.diff(startDate, 'days');
            const resolution = timeSpan > 1 ? Resolution.day : Resolution.hour;
            setSelectedPeriod(
                TimePeriod.custom,
                startDate.format('YYYY-MM-DD'),
                endDate.format('YYYY-MM-DD'),
                resolution
            );
        }
    };

    useEffect(() => {
        fetchCustomTimeSpan();
    }, [startDate, endDate]);

    const onEndDateChanged = (date: Moment): void => {
        if (moment(date).startOf('day').valueOf() !== moment(endDate).valueOf()) {
            setEndDate(date);
        }
    };

    const selectCustomDate = (): void => {
        setDisplayDatePicker(true);
    };

    const closeSelectCustomDate = (): void => {
        setDisplayDatePicker(false);
    };

    const customTitle =
        startDate && endDate
            ? `${startDate.format(dateFormats[dateFormat as keyof typeof dateFormats].shortFormat)} - ${endDate.format(
                  dateFormats[dateFormat as keyof typeof dateFormats].shortFormat
              )}`
            : 'TimeSelector.Custom';

    return (
        <div className="building-insight__time-selector__elements">
            {displayDatePicker ? (
                <>
                    <PrimaryButton
                        icon={<MaterialIcon name={displayDatePicker ? 'close' : 'date_range'} />}
                        onClick={closeSelectCustomDate}
                        testAttr="date-picker"
                        noBackground
                        small
                    />
                    <div className="small-padding-right">
                        <DatePicker
                            id="startDate"
                            onChange={onStartDateChanged}
                            dateFormat={dateFormat as keyof typeof dateFormats}
                            selectedDate={startDate}
                            initialVisibleMonth={moment()}
                            disabledDate={(day): boolean => isOutsideDateRange(day)}
                            placeholder="StartDate"
                        />
                    </div>
                    <DatePicker
                        id="endDate"
                        onChange={onEndDateChanged}
                        dateFormat={dateFormat as keyof typeof dateFormats}
                        selectedDate={endDate}
                        initialVisibleMonth={moment()}
                        disabledDate={(day): boolean => isOutsideEndDateRange(day, startDate)}
                        placeholder="EndDate"
                    />
                </>
            ) : (
                <>
                    <FilterButton
                        title="NotificationAlerts.Today"
                        isSelected={selectedPeriod === TimePeriod.day}
                        onClick={(): void => selectPeriod(TimePeriod.day)}
                    />
                    <FilterButton
                        title="NotificationAlerts.Yesterday"
                        isSelected={selectedPeriod === TimePeriod.yesterday}
                        onClick={(): void => selectPeriod(TimePeriod.yesterday)}
                    />
                    <FilterButton
                        title="TimeSelector.Last7Days"
                        isSelected={selectedPeriod === TimePeriod.week}
                        onClick={(): void => selectPeriod(TimePeriod.week)}
                    />
                    <FilterButton
                        title="TimeSelector.Last30Days"
                        isSelected={selectedPeriod === TimePeriod.month}
                        onClick={(): void => selectPeriod(TimePeriod.month)}
                    />
                    <FilterButton
                        title={customTitle}
                        isSelected={selectedPeriod === TimePeriod.custom}
                        onClick={selectCustomDate}
                    />
                    <CheckBox
                        disabled={!buildingHasUsageHours}
                        label="Building.DayScheduleOpeningHours"
                        id="EnableOpeningHours"
                        onChange={(): void => setWithOpeningHours(!withOpeningHours)}
                        checked={withOpeningHours}
                    />
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
    } = state;
    return {
        dateFormat,
    };
};

export default connect(mapStateToProps)(BuildingInsightTimeSelector);
