import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_THRESHOLD_BREACH_INSIGHT_FETCHED } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { dateFormats, sensorUnits } from 'commons/src/constants';
import { Resolution, SensorTypes, TimePeriod } from 'commons/src/models/commonEnums';
import { BuildingType, DeviceWithKeyInfo, ErrorType } from 'commons/src/models/commonTypeScript';
import { fetchBuildingWithDevices, FetchBuildingWithDevices } from '../../../../actions/locationActions';
import {
    FetchThresholdBreachAllInsights,
    FetchThresholdBreachAllInsightsType,
    fetchThresholdsBreachAllInsights,
} from '../../../../actions/thresholdBreachInsightActions';
import { BuildingInsight } from '../../../../models/buildingModels';
import { BuildingProps } from '../../../../models/commonEnums';
import { Store } from '../../../../reducers';
import RequestType from '../../../../reducers/BusinessRequestType';
import { generateImage } from '../../../reports/GraphConfig';
import { createObserverAndFetchSvg } from '../../../reports/insightFunctions';
import BuildingInfo from '../../../reports/reportSelector/BuildingInfo';
import { numberOfPhysicalDevicesInBuilding } from '../buildingInsightCommonFunctions';
import ThresholdBreachCsvDownload from '../ThresholdBreachCsvDownload';
import ThresholdBreachBreakDownPerDevice from './ThresholdBreachBreakDownPerDevice';
import ThresholdBreachPdf, { GenerateReportProps } from './ThresholdBreachPdf';
import styles from './ThresholdBreachReport.module.scss';
import ThresholdBreachSensorResult from './ThresholdBreachSensorResult';

type StateProps = {
    thresholdBreachInsights: BuildingInsight[];
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    locationIdForData?: string;
    buildings: { [buildingId: string]: BuildingType };
    loading: boolean;
    error?: ErrorType;
    logoImage?: string;
    dateFormat: keyof typeof dateFormats;
};

type ActionProps = {
    onFetchBuilding: (buildingId: string) => void;
    getThresholdBreachForBuilding: (payload: FetchThresholdBreachAllInsightsType) => void;
};

export type Props = StateProps & ActionProps;

const ThresholdBreachReportPage = ({
    thresholdBreachInsights,
    locationIdForData,
    buildings,
    error,
    loading,
    onFetchBuilding,
    devicesWithKeyInfo,
    dateFormat,
    getThresholdBreachForBuilding,
    logoImage,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { buildingId } = useParams() as { buildingId: string };
    const maxNumberOfDevicesToGenerateReport = 30;

    const fromDate = searchParams.get('from');
    const toDate = searchParams.get('to');
    const sensor = searchParams.get('sensor') as string;
    const withinOpeningHours = searchParams.get('opening-hours') === 'true';
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [trendOverTimeGraph, setTrendOverTimeGraph] = useState<{ data: string; id: string } | undefined>(undefined);
    const [totalByDeviceGraph, setTotalByDeviceGraph] = useState<{ data: string; id: string } | undefined>(undefined);
    const [breakdownPerDeviceGraphs, setBreakdownPerDeviceGraphs] = useState<{ data: string; serialNumber: string }[]>(
        []
    );
    const [generateChartError, setChartError] = useState(false);

    const goBack = (): void => {
        navigate(-1);
    };

    if (!fromDate || !toDate || !sensor || withinOpeningHours === undefined) {
        goBack();
    }

    const resolution = moment(toDate).diff(moment(fromDate), 'days') > 1 ? Resolution.day : Resolution.hour;
    const periodName =
        moment(toDate).isSame(moment(), 'day') && resolution === Resolution.hour ? TimePeriod.day : TimePeriod.custom;

    const selectedPeriod = {
        fromDate: fromDate as string,
        toDate: toDate as string,
        resolution,
        name: periodName,
    };

    const building = buildings[buildingId];
    useEffect(() => {
        if (!building) {
            onFetchBuilding(buildingId);
        }
    }, []);

    const buildingThresholdBreaches = locationIdForData === buildingId ? thresholdBreachInsights : [];
    const thresholdBreachForSensor = buildingThresholdBreaches.find(breach => breach.sensor === sensor);
    const numberOfBreakDownGraphs = thresholdBreachForSensor
        ? Object.keys(thresholdBreachForSensor.totalDeviceData.devicesOverTime).length
        : 0;

    const canDownloadPdfReport = numberOfBreakDownGraphs <= maxNumberOfDevicesToGenerateReport;

    const fetchComponentData = (): void => {
        const payload = {
            locationId: buildingId,
            fromDate: fromDate as string,
            toDate: toDate as string,
            withOpeningHours: withinOpeningHours,
            resolution,
        };
        analyticsLogger(BUILDING_THRESHOLD_BREACH_INSIGHT_FETCHED, { payload });
        getThresholdBreachForBuilding(payload);
    };

    useEffect(() => {
        if (buildingThresholdBreaches.length === 0) {
            fetchComponentData();
        }
    }, []);

    const getSvg = async (): Promise<void> => {
        const breakdownForDevices: { data: string; serialNumber: string }[] = [];
        const updateListOfImages = (response: { data: string; serialNumber: string }): void => {
            if (response.serialNumber === 'totalByDeviceGraph') {
                setTotalByDeviceGraph({ data: response.data, id: response.serialNumber });
            } else if (response.serialNumber === 'trendOverTimeGraph') {
                setTrendOverTimeGraph({ data: response.data, id: response.serialNumber });
            } else {
                breakdownForDevices.push(response);
            }
        };

        const sensorGraphs = ['totalByDeviceGraph', 'trendOverTimeGraph'];
        const breakdownGraphIds = thresholdBreachForSensor
            ? Object.keys(thresholdBreachForSensor.totalDeviceData.devicesOverTime).map(
                  serialNumber => `deviceBreakDown-${sensor}-${serialNumber}`
              )
            : [];
        const chartIds = [...sensorGraphs, ...breakdownGraphIds];

        await chartIds.forEach(graphId => {
            generateImage(updateListOfImages, setChartError, graphId);
        });
        await setBreakdownPerDeviceGraphs(breakdownForDevices);
    };

    useEffect(() => {
        const elementId = 'thresholdBreachReport';
        if (!!building && !loading && canDownloadPdfReport) {
            if (document.getElementById(elementId)) {
                getSvg();
            } else {
                const observer = createObserverAndFetchSvg(elementId, getSvg);
                observer.observe(document, {
                    childList: true,
                    subtree: true,
                });
            }
        }
    }, [loading, building]);

    if (error) {
        return <Error />;
    }

    const locationName = building?.name;
    const unit = thresholdBreachForSensor?.unit;

    const highThreshold = thresholdBreachForSensor?.thresholds.find(threshold => threshold.type === 'over');
    const lowThreshold = thresholdBreachForSensor?.thresholds.find(threshold => threshold.type === 'under');
    const numberOfDevicesWithDataOutsideForTimeFrame =
        thresholdBreachForSensor?.totalDeviceData?.devicesInTimeFrame?.length;
    const numberOfDevicesInBuilding = loading
        ? 0
        : numberOfPhysicalDevicesInBuilding(devicesWithKeyInfo, building?.devices || []);
    let thresholdsText = `${txt('BuildingInsight.TimeOver')}${highThreshold?.value}${
        sensorUnits[unit as keyof typeof sensorUnits]
    }`;
    if (lowThreshold) {
        thresholdsText = `${thresholdsText} ${txt('BuildingInsight.AndTimeBelow')} ${lowThreshold.value}${
            sensorUnits[unit as keyof typeof sensorUnits]
        }`;
    }
    const subHeaderText = `${thresholdsText} - ${numberOfDevicesWithDataOutsideForTimeFrame}/${numberOfDevicesInBuilding} ${txt(
        'DevicesLowerCase'
    )}`;

    const reportGeneratedForPeriodText =
        fromDate &&
        toDate &&
        txt('RadonInsight.ReportGeneratedPeriod', {
            from: fromDate,
            to: toDate,
        });
    const fileDownloadName = `ThresholdBreach-${sensor}_${fromDate}-to-${toDate}.pdf`;

    const onDownload = (): void => {
        setDownloadModalOpen(true);
    };

    const pdfData: GenerateReportProps | undefined = thresholdBreachForSensor && {
        dateFormat,
        sensor,
        thresholdsText,
        numberOfDevicesWithDataOutsideForTimeFrame: numberOfDevicesWithDataOutsideForTimeFrame || 0,
        numberOfDevicesInBuilding,
        withinOpeningHours,
        reportGeneratedForPeriodText: reportGeneratedForPeriodText || '',
        building,
        trendOverTimeGraph: trendOverTimeGraph?.data,
        totalByDeviceGraph: totalByDeviceGraph?.data,
        breakDownGraphs: breakdownPerDeviceGraphs,
        totalDeviceData: thresholdBreachForSensor.totalDeviceData,
        logoImage,
        fileDownloadName,
    };

    const displayDownloadButton =
        !canDownloadPdfReport ||
        (trendOverTimeGraph && totalByDeviceGraph && breakdownPerDeviceGraphs.length === numberOfBreakDownGraphs);

    return (
        <div className="radon-insight-view">
            <PageHeader
                title={`${txt(sensor)} ${txt('BuildingInsight.TimeOverThreshold').toLowerCase()}`}
                subHeader={locationName}
                subHeaderClick={goBack}
            />
            {downloadModalOpen &&
                pdfData &&
                thresholdBreachForSensor &&
                (canDownloadPdfReport ? (
                    <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                        <ThresholdBreachPdf {...pdfData} />
                    </ReactPdfDownloadModal>
                ) : (
                    <ThresholdBreachCsvDownload
                        timezone={building?.timezone}
                        dateFormat={dateFormat}
                        buildingThresholdBreaches={[thresholdBreachForSensor]}
                        selectedPeriod={selectedPeriod}
                        setDownloadModalOpen={setDownloadModalOpen}
                        buildingName={building.name}
                    />
                ))}
            <div className="page-wrapper-flex page-wrapper-flex--wrap">
                <div id="resultHeader" className="inline-header-lined inline-header-lined--no-margin-top">
                    <h2 className="inline-header-lined__text">
                        {!loading && fromDate && toDate ? reportGeneratedForPeriodText : txt('Loading')}
                    </h2>
                </div>
                <ReactPlaceholder
                    ready={!!building && !loading}
                    className="container"
                    customPlaceholder={fullwidthListElement}
                >
                    {!canDownloadPdfReport && (
                        <div className={styles.onlyCsvHeader}>
                            <MaterialIcon name="info" />
                            {txt('BuildingInsight.TimeOverThresholdReportOnlyCsv')}
                        </div>
                    )}
                    <div
                        id="thresholdBreachReport"
                        className="form__wide-container insight-tile insight-tile--full-width"
                    >
                        <div className="insight-tile__header insight-tile__header--no-border insight-tile__header--with-sub-header  flex--align-center">
                            <div>
                                <div className="text-bold text-large">{`${txt(sensor)} ${txt(
                                    'BuildingInsight.TimeOverThreshold'
                                ).toLowerCase()}`}</div>
                                <div className="text-large">{subHeaderText}</div>
                                <div className="text-small">
                                    {txt(
                                        withinOpeningHours
                                            ? 'BuildingInsight.ReportWithinOpeningHours'
                                            : 'BuildingInsight.ReportNoOpeningHours'
                                    )}
                                </div>
                            </div>
                            {!generateChartError && (
                                <PrimaryButton
                                    onClick={onDownload}
                                    type="button"
                                    title={canDownloadPdfReport ? 'Download' : 'DownloadCSVTitle'}
                                    loading={!displayDownloadButton}
                                    disabled={!displayDownloadButton}
                                />
                            )}
                        </div>
                        {generateChartError && (
                            <ResponseBox text="SomethingWentWrong" subtext={txt('RadonInsight.GeneratePdfError')} />
                        )}
                        <div className="inline-header-lined inline-header-lined--no-margin-top" />
                        <BuildingInfo
                            building={building}
                            validate={false}
                            optionalBuildingProps={[BuildingProps.volume, BuildingProps.height, BuildingProps.floors]}
                        />
                    </div>
                    {thresholdBreachForSensor && (
                        <>
                            <ThresholdBreachSensorResult
                                thresholdBreachForSensor={thresholdBreachForSensor}
                                selectedPeriod={selectedPeriod}
                                timeZone={building?.timezone}
                            />
                            <div className="form__wide-container insight-tile insight-tile--full-width">
                                <h2>{txt('BuildingInsight.BreakdownPerDevice')}</h2>
                                {Object.keys(thresholdBreachForSensor.totalDeviceData.devicesOverTime).map(
                                    serialNumber => (
                                        <ThresholdBreachBreakDownPerDevice
                                            key={`deviceBreakDown-${serialNumber}`}
                                            insight={thresholdBreachForSensor}
                                            selectedPeriod={selectedPeriod}
                                            highThreshold={highThreshold?.value.toString() || ''}
                                            lowThreshold={lowThreshold?.value.toString()}
                                            timeZone={building?.timezone}
                                            serialNumber={serialNumber}
                                            sensor={sensor as SensorTypes}
                                        />
                                    )
                                )}
                            </div>
                        </>
                    )}
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { dateFormat },
        organizationProperties: { logoImage },
        thresholdBreachInsight: { thresholdBreachInsights, locationId: locationIdForData },
        buildings: { buildings },
        devices: { devicesWithKeyInfo },
        requests: {
            [RequestType.FetchBuildingWithDevices]: { loading: fetchBuildingLoading, error: fetchBuildingError },
            [RequestType.FetchThresholdBreachAllInsights]: { loading, error: fetchThresholdBreachError },
        },
    } = state;

    return {
        dateFormat,
        thresholdBreachInsights,
        locationIdForData,
        devicesWithKeyInfo,
        buildings,
        loading: loading || fetchBuildingLoading,
        error: fetchBuildingError || fetchThresholdBreachError,
        logoImage,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchBuilding: (locationId: string): FetchBuildingWithDevices => dispatch(fetchBuildingWithDevices(locationId)),
    getThresholdBreachForBuilding: (payload): FetchThresholdBreachAllInsights =>
        dispatch(fetchThresholdsBreachAllInsights(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThresholdBreachReportPage);
