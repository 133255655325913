import { ErrorType, HubData } from '../models/commonTypeScript';
import { CommonRequestType } from '../reducers/requestReducer';
import { RequestActionType } from './requestActions';

export enum HubActionType {
    DeleteHubInit = 'DELETE_HUB/INIT',
    DeleteHubSuccess = 'DELETE_HUB_SUCCESS',
    DeleteHubError = 'DELETE_HUB_ERROR',
    FetchHub = 'FETCH_HUB/INIT',
    FetchHubSuccess = 'FETCH_HUB_SUCCESS',
}

export interface DeleteHub {
    type: HubActionType.DeleteHubInit;
    serialNumber: string;
    locationId: string;
}
export const deleteHub = (serialNumber: string, locationId: string): DeleteHub => ({
    type: HubActionType.DeleteHubInit,
    serialNumber,
    locationId,
});

export interface DeleteHubSuccess {
    type: HubActionType.DeleteHubSuccess;
    serialNumber: string;
    locationId: string;
}
export const deleteHubSuccess = (serialNumber: string, locationId: string): DeleteHubSuccess => ({
    type: HubActionType.DeleteHubSuccess,
    serialNumber,
    locationId,
});

export interface DeleteHubError {
    type: HubActionType.DeleteHubError;
    error: ErrorType;
}
export const deleteHubError = (error: ErrorType): DeleteHubError => ({ type: HubActionType.DeleteHubError, error });

export interface FetchHub {
    requestActionType: RequestActionType.Init;
    requestType: CommonRequestType.FetchHub;
    type: HubActionType.FetchHub;
    serialNumber: string;
}

export const fetchHub = (serialNumber: string): FetchHub => ({
    requestActionType: RequestActionType.Init,
    requestType: CommonRequestType.FetchHub,
    type: HubActionType.FetchHub,
    serialNumber,
});

export interface FetchHubSuccess {
    type: HubActionType.FetchHubSuccess;
    hub: HubData;
    serialNumber: string;
}

export const fetchHubSuccess = (hub: HubData, serialNumber: string): FetchHubSuccess => ({
    type: HubActionType.FetchHubSuccess,
    hub,
    serialNumber,
});

export type HubReducerActions = DeleteHub | DeleteHubSuccess | DeleteHubError | FetchHubSuccess;
