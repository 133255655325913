import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { SearchLocation } from '../../../../../models/buildingModels';
import { Store } from '../../../../../reducers';
import { selectSearchLocation } from '../../buildingsHealthStatusSelectors';
import SearchModal from './SearchModal';

const deviceTypesToInclude = Object.values(DeviceTypeNames) as AnyDeviceType[];

const getAvailableLocations = (locations: SearchLocation[]): SearchLocation[] =>
    locations.filter(({ type }) => deviceTypesToInclude.includes(type as AnyDeviceType));

const GlobalSearch = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    const [open, setOpen] = useState(false);
    const { locations, isLoading } = useSelector((state: Store) => selectSearchLocation(state));

    const data = useMemo((): SearchLocation[] => {
        if (isLoading) return [];
        return getAvailableLocations(locations);
    }, [locations, isLoading]);

    const toggle = (): void => setOpen(currentOpen => !currentOpen);

    return (
        <>
            <PrimaryButton
                onClick={toggle}
                disabled={isLoading}
                title={txt('Search')}
                testId="search_button"
                loading={isLoading}
                trailingIcon={<MaterialIcon name="search" />}
                small
            />
            <SearchModal open={open} toggle={toggle} minCharacters={1} data={data} />
        </>
    );
};

export default GlobalSearch;
