import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getValidLanguageTagForMoment } from '../../../commonFunctions';
import { DashboardVisibility } from '../../../models/commonEnums';
import { DeviceType } from '../../../models/commonTypeScript';
import TileHeaderMenu from '../../dashboard/TileHeaderMenu';
import DeviceListStatusBlock from './DeviceListStatusBlock';

export type Props = {
    device: DeviceType;
    visibility?: DashboardVisibility;
    showLocation?: boolean;
    menuProps?: { tileId: string; tileRef: string; removeTile: (tileId: string, tileRef: string) => void };
};

const TileHeader = (props: Props): React.ReactElement => {
    const {
        device: {
            segmentName,
            latestSample,
            segmentStart,
            locationName,
            type,
            rssi,
            batteryPercentage,
            serialNumber,
            relayDevice,
            isHubConnectionLost,
        },
        showLocation,
        menuProps,
        visibility,
    } = props;
    const { t: txt } = useTranslation();
    const language = getValidLanguageTagForMoment();
    const lastSynced = latestSample ? moment.utc(latestSample).local().locale(language).fromNow() : txt('neverSynced');

    return (
        <div className="tile-header">
            <div className="tile-header__title">
                <h2 className="tile-header__title--name">{segmentName}</h2>
                <span className="tile-header__title--address">{showLocation ? locationName : serialNumber}</span>
                <div className="tile-header__last-synced">{[txt('LastSynced'), ' ', lastSynced]}</div>
            </div>
            <DeviceListStatusBlock
                deviceType={type}
                segmentStart={segmentStart}
                relayDevice={relayDevice}
                rssi={rssi}
                batteryPercentage={batteryPercentage}
                isHubConnectionLost={isHubConnectionLost}
            />
            {menuProps && (
                <div className="tile-header__controls">
                    <TileHeaderMenu
                        visibility={visibility}
                        tileId={menuProps.tileId}
                        onRemoveTile={(): void => menuProps.removeTile(menuProps.tileId, menuProps.tileRef)}
                        isSensorTile={false}
                    />
                </div>
            )}
        </div>
    );
};
TileHeader.defaultProps = {
    showLocation: undefined,
    menuProps: undefined,
};

export default TileHeader;
