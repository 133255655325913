import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { linkToDeveloperDocs, linkToAirthingsGithub } from 'commons/src/constants';
import { Group } from 'commons/src/models/commonTypeScript';
import { A4BSupportMail } from '../../constants';
import { Store } from '../../reducers';

interface StateProps {
    selectedGroup?: Group;
}

const IntegrationsOverview = (props: StateProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { selectedGroup } = props;

    return (
        <div>
            <h2 className="settings__header">{txt('Integrations.Overview')}</h2>
            <div className="settings-details-container">
                <p>{txt('Integrations.Description')}</p>
                <a href={linkToDeveloperDocs} target="_blank" rel="noopener noreferrer">
                    {txt('Integrations.InfoAndTechDocumentation')}
                </a>
                <p>
                    {txt('Integrations.LinkToApiCodeExamples')}
                    <a href={linkToAirthingsGithub} target="_blank" rel="noopener noreferrer">
                        github.com/airthings
                    </a>
                </p>
                <div>
                    {txt('Integrations.ContactHBSSupport')}
                    <a href={`mailto: ${A4BSupportMail}`}> {A4BSupportMail}</a>
                </div>
                <p>
                    {txt('OrganizationProfile.AccountId')}
                    <span className="text-bold">{selectedGroup && selectedGroup.id}</span>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
    } = store;

    return { selectedGroup };
};

export default connect(mapStateToProps, null)(IntegrationsOverview);
