import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import Error from 'commons/src/components/errorComponents/Error';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import { paths } from 'commons/src/constants';
import HubInfo from 'commons/src/features/hubs/HubInfo';
import HubPageContent from 'commons/src/features/hubs/HubPageContent';
import HubPageHeader from 'commons/src/features/hubs/HubPageHeader';
import { BuildingType, DeviceWithKeyInfo, HubData } from 'commons/src/models/commonTypeScript';
import { FetchBuildingWithDevices, fetchBuildingWithDevices } from '../../actions/locationActions';
import { Store } from '../../reducers';
import { BusinessRequestType as RequestType } from '../../reducers/BusinessRequestType';

type StateProps = {
    error: string | boolean;
    hubs: HubData[];
    fetchBuildingLoading: boolean;
    buildings: { [buildingId: string]: BuildingType };
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type ActionProps = {
    onFetchBuildingWithDevices: (locationId: string) => void;
};

export type Props = StateProps & ActionProps;

export const HubPageComponent = ({
    error,
    hubs,
    onFetchBuildingWithDevices,
    fetchBuildingLoading,
    buildings,
    hubsWithKeyInfo,
}: Props): React.ReactElement => {
    const { hubId } = useParams() as { hubId: string };
    const navigate = useNavigate();
    const [initialLoading, setInitialLoading] = useState(true);
    const [hubLocationId, setHubLocationId] = useState(hubsWithKeyInfo[hubId] && hubsWithKeyInfo[hubId].locationId);
    const { t: txt } = useTranslation();
    useEffect(() => {
        // need this to keep locationId in state when deleting hub for correct redirect to building
        const locationId = hubsWithKeyInfo[hubId] && hubsWithKeyInfo[hubId].locationId;
        if (locationId) {
            setHubLocationId(locationId);
        }
    }, [hubsWithKeyInfo]);

    const displayedHub = hubId ? hubs.find(hub => hub.serialNumber === hubId) : undefined;
    const building = buildings[hubLocationId];
    const initialBuildingLoading = fetchBuildingLoading || !building;

    useEffect(() => {
        if (hubLocationId && !building) {
            onFetchBuildingWithDevices(hubLocationId);
        }
    }, [hubLocationId]);

    useEffect((): void => {
        if (initialLoading && !initialBuildingLoading) {
            setInitialLoading(false);
        }
    }, [initialLoading, initialBuildingLoading]);

    if (error) {
        return <Error />;
    }

    const invalidHub = !initialLoading && !displayedHub;
    const hubRecentlySynced =
        displayedHub &&
        displayedHub.metaData.lastSeen &&
        moment.utc().diff(moment.utc(displayedHub.metaData.lastSeen).local(), 'minutes') < 65;
    const numberOfDevices =
        displayedHub && displayedHub.metaData.devices && hubRecentlySynced
            ? Object.keys(displayedHub.metaData.devices).length
            : 0;

    const onGoBack = (): void => {
        const path: string = displayedHub?.spaceId
            ? `/${paths.buildings}/${hubLocationId}/spaces/${displayedHub.spaceId}`
            : `/${paths.buildings}/${hubLocationId}/devices`;
        navigate(path);
    };

    return (
        <div>
            <HubPageHeader
                displayedHub={displayedHub}
                invalidHub={invalidHub}
                onGoBack={onGoBack}
                onGoBackName={displayedHub?.spaceId ? txt('Spaces.BackToSpace') : building && building.name}
            />
            <div className="container page-header--padded">
                <ReactPlaceholder ready={!initialLoading} customPlaceholder={fullwidthListElement}>
                    <HubInfo hub={displayedHub} />
                </ReactPlaceholder>
                <div className="inline-header-lined">
                    <h2 className="inline-header-lined__text">
                        {txt('HubStrings.ConnectedDevices')}
                        <span className="inline-header-lined__info-chip">{numberOfDevices}</span>
                    </h2>
                </div>
            </div>
            <HubPageContent displayedHub={displayedHub} loading={initialBuildingLoading} />
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        devices: { hubs, hubsWithKeyInfo },
        requests: {
            [RequestType.FetchBuildingWithDevices]: { loading: fetchBuildingLoading, error: fetchBuildingError },
        },
        buildings: { buildings },
    } = state;
    return {
        error: !!fetchBuildingError,
        hubs,
        buildings,
        fetchBuildingLoading,
        hubsWithKeyInfo,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onFetchBuildingWithDevices: (locationId: string): FetchBuildingWithDevices =>
        dispatch(fetchBuildingWithDevices(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubPageComponent);
