import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { MAX_DEVICES_PER_SPACE } from '../../../../constants';
import { IndoorSpace } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import styles from './SpaceAddDevice.module.scss';
import SpaceAddDeviceAppLinks from './SpaceAddDeviceAppLinks';
import SpaceAddDeviceForm from './SpaceAddDeviceForm';

type AddDeviceProps = {
    buildingId: string;
    space: IndoorSpace;
    close: () => void;
};

const getLinkToSetupGuideForLanguage = (language: string): string => {
    if (language.includes('fr')) return 'https://www.airthings.com/fr/business/setup#step_2';
    if (language.includes('no')) return 'https://www.airthings.com/no/business/setup#step_2';
    if (language.includes('se')) return 'https://www.airthings.com/sv/business/setup#step_2';
    if (language.includes('da')) return 'https://www.airthings.com/da/business/setup#step_2';
    return 'https://www.airthings.com/business/setup#step_2';
};

const SpaceAddDevice = ({ buildingId, space, close }: AddDeviceProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const language = useSelector((state: Store) => state.userSettings.language);
    const { error } = useSelector((state: Store) => state.requests.ADD_DEVICE_TO_SPACE);
    const hasAnyDevice = Boolean(space.devices.length);
    const maxDevicesText = txt('Space.MaxDevices', { value: MAX_DEVICES_PER_SPACE });

    return (
        <section className={styles.formWrapper} data-space-add-device>
            <div className={styles.header}>
                <h4 className={styles.title}>{`${txt('AddDevice.AddDevice')} ${maxDevicesText}`}</h4>
                <div className={styles.closeButton}>
                    {hasAnyDevice && <TertiaryButton testId="close-add-device-form" onClick={close} title="Close" />}
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.addDeviceSection}>
                    <SpaceAddDeviceForm spaceId={space.id} buildingId={buildingId} onSuccess={close} />
                    <footer className={styles.footer}>
                        <a
                            href={getLinkToSetupGuideForLanguage(language)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.externalLink}
                        >
                            <MaterialIcon name="launch" />
                            <span>{txt('AddDevice.HowToSetupDevice')}</span>
                        </a>
                    </footer>
                </div>
                <SpaceAddDeviceAppLinks />
            </div>
            {error && (
                <ResponseBox
                    text={txt(`ErrorCodes.${error.error}`)}
                    subtext={txt('ErrorCodes.INTERNAL_SERVER_ERROR')}
                />
            )}
        </section>
    );
};

export default SpaceAddDevice;
