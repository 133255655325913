import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { analyticsLogger, IntegrationType, PageType } from 'commons/src/analytics';
import { INTEGRATION_CLICKED_ADD, INTEGRATION_VIEWED_INTEGRATION } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userlane } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import IntegrationRow from 'commons/src/features/integrations/IntegrationRow';
import { ButtonColor } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { integrationTypeLogos, paths, thirdPartyChannels } from '../../../constants';
import { ThirdPartyIntegration } from '../../../models/common';
import { Store } from '../../../reducers';

type StateProps = {
    thirdPartyIntegrations: ThirdPartyIntegration[];
    loading: boolean;
    error?: ErrorType;
    numberOfLocations: number;
    locationsLoading: boolean;
};

type Props = StateProps;

const analyticsObject = {
    pageType: PageType.Integration,
    integrationType: IntegrationType.ThirdParty,
};

export const ThirdPartyPageComponent = ({
    locationsLoading,
    numberOfLocations,
    loading,
    error,
    thirdPartyIntegrations,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const { t: txt } = useTranslation();

    useEffect(() => analyticsLogger(INTEGRATION_VIEWED_INTEGRATION, analyticsObject));

    if (error) {
        return <Error />;
    }

    const goToAddThirdPartyIntegration = (): void => {
        navigate({ pathname: `/${paths.addThirdPartyIntegration}` });
        analyticsLogger(INTEGRATION_CLICKED_ADD, analyticsObject);
    };

    const editIntegration = (integrationId: string): void => {
        navigate({ pathname: `/${paths.thirdParty}/${integrationId}` });
    };

    const thirdPartyIntegrationRows = (): React.ReactElement[] | false =>
        thirdPartyIntegrations.length > 0 &&
        thirdPartyIntegrations.map(integration => {
            const integrationLogo = (
                <img
                    src={integrationTypeLogos(integration.type)}
                    alt={txt('ThirdParty.IntegrationTypeLogo', {
                        type: txt(`ThirdParty.${integration.type}`),
                    })}
                />
            );
            return (
                <IntegrationRow
                    key={integration.id}
                    name={integration.name}
                    firstRow={txt('ThirdParty.IntegrationRowInfo', {
                        type: txt(`ThirdParty.${integration.type}`),
                    })}
                    firstRowLogo={integrationLogo}
                    id={integration.id}
                    onClick={editIntegration}
                />
            );
        });

    const actionButtons: ActionButton[] = [
        {
            onClick: goToAddThirdPartyIntegration,
            title: 'ThirdParty.NewIntegration',
            id: 'addThirdPartyIntegration',
            color: ButtonColor.primary,
            testAttr: 'add-third-party-integration',
            icon: <MaterialIcon name="add" />,
            requiredRoleLevel: roleRestrictions.thirdPartyIntegration,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div>
            <SubHeader actionButtons={numberOfLocations > 0 ? actionButtons : []} />
            <h2 className="settings__header settings__header--no-margin-top">{txt('ThirdParty.ThirdParty')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                {txt('ThirdParty.ThirdPartyDescription')}
                {!locationsLoading && numberOfLocations === 0 && (
                    <div>{txt('ThirdParty.AddIntegrationDisabledText')}</div>
                )}
            </div>
            <ul className="list">
                <ReactPlaceHolder
                    ready={!(loading && thirdPartyIntegrations.length === 0)}
                    customPlaceholder={userlane}
                >
                    {thirdPartyIntegrationRows()}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        thirdPartyIntegrations: { thirdPartyIntegrations },
        requests: {
            FETCH_THIRD_PARTY_INTEGRATIONS: { loading, error },
        },
        locations: { locations, loading: locationsLoading },
    } = state;

    return {
        thirdPartyIntegrations: thirdPartyIntegrations.filter(it => it.type !== thirdPartyChannels.EMAIL),
        loading,
        error,
        numberOfLocations: locations.length,
        locationsLoading,
    };
};

export default connect(mapStateToProps)(ThirdPartyPageComponent);
