import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_CONFIGURED_DISPLAY } from 'commons/src/analytics/AnalyticsEvents';
import { mapDeviceType } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Error from 'commons/src/components/errorComponents/Error';
import ComponentTabNavigation from 'commons/src/components/headers/ComponentTabNavigation';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceTypeNames, ViewDisplayUnits } from 'commons/src/models/commonEnums';
import {
    ErrorType,
    BuildingConfiguration,
    DeviceWithKeyInfo,
    DisplaySelectedScreen,
    AnyDeviceType,
} from 'commons/src/models/commonTypeScript';
import { ComponentTab } from 'commons/src/models/menuModels';
import {
    UpdateLocationConfigWithDeviceType,
    updateLocationConfigWithDeviceType,
} from '../../../actions/locationActions';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import DisplayUnitSettings from './DisplayUnitSettings';
import ViewDisplaySelector from './ViewDisplaySelector';

type StateProps = {
    updateConfigError?: ErrorType;
    fetchConfigError?: ErrorType;
    updateLoading: boolean;
    configurations: { [locationId: string]: BuildingConfiguration };
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
};

type PassedProps = {
    goBack: (e: SyntheticEvent<HTMLElement>) => void;
    locationId: string;
    buildingDevices: string[];
};
type ScreenConfig = {
    [deviceType: string]: {
        selectedScreens: { screenIndex: number; insights: string[] }[];
        measurementUnits: ViewDisplayUnits;
    };
};
type ActionProps = {
    onUpdateScreenConfig: (
        config: {
            selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
            screens?: ScreenConfig;
        },
        locationId: string
    ) => void;
};

export type Props = PassedProps & StateProps & ActionProps;

export const ViewDisplaySettingsComponent = ({
    configurations,
    goBack,
    updateLoading,
    onUpdateScreenConfig,
    updateConfigError,
    fetchConfigError,
    locationId,
    devicesWithKeyInfo,
    buildingDevices,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const buildingConfig = configurations[locationId];
    const initialScreenConfig =
        buildingConfig && buildingConfig.configuration && buildingConfig.configuration.screenConfigurations;
    const [activeTab, setActiveTab] = useState(0);
    const [activeDeviceType, setActiveDeviceType] = useState<DeviceTypeNames | undefined>();
    const [screenConfigs, setScreenConfigs] = useState(initialScreenConfig || {});

    const tabOptions = initialScreenConfig
        ? Object.keys(initialScreenConfig).map(deviceType => ({
              deviceType,
              id: `displaySettings${deviceType}`,
          }))
        : [];

    const tabOptionsForDevicesInBuilding = tabOptions.filter(option =>
        buildingDevices.find(
            serialNumber =>
                devicesWithKeyInfo[serialNumber] && devicesWithKeyInfo[serialNumber].type === option.deviceType
        )
    );
    useEffect(() => {
        if (!activeDeviceType && tabOptionsForDevicesInBuilding.length > 0) {
            setActiveDeviceType(tabOptionsForDevicesInBuilding[0].deviceType as DeviceTypeNames);
        }
    }, [tabOptionsForDevicesInBuilding]);

    if (fetchConfigError) {
        return <Error />;
    }

    const updateScreenConfigForDeviceType = (deviceType: string, newScreenConfig: DisplaySelectedScreen[]): void => {
        const updatedConfig = {
            ...screenConfigs,
            [deviceType]: { ...screenConfigs[deviceType], selectedScreens: newScreenConfig },
        };
        setScreenConfigs(updatedConfig);
    };

    const updateMeasurementUnitsForDeviceType = (deviceType: string, measurementUnits: ViewDisplayUnits): void => {
        const updatedConfig = {
            ...screenConfigs,
            [deviceType]: { ...screenConfigs[deviceType], measurementUnits },
        };
        setScreenConfigs(updatedConfig);
    };

    const screenWithoutSelection = Object.keys(screenConfigs).find(
        deviceType => screenConfigs[deviceType].selectedScreens[0].insights.length === 0
    );

    const onSubmit = (): void => {
        if (screenWithoutSelection) return;
        const configToUpdate = Object.keys(screenConfigs).reduce((newConfig: ScreenConfig, deviceType) => {
            return {
                ...newConfig,
                [deviceType]: {
                    selectedScreens: screenConfigs[deviceType].selectedScreens,
                    measurementUnits: screenConfigs[deviceType].measurementUnits,
                },
            };
        }, {});
        onUpdateScreenConfig({ screens: configToUpdate }, locationId);
        analyticsLogger(BUILDING_CONFIGURED_DISPLAY, {
            screenConfigs,
        });
    };

    const validateMessage = screenWithoutSelection && 'DeviceSettings.SelectAtLeastOneInsight';
    const errorMessage = updateConfigError ? `ErrorCodes.${updateConfigError.error}` : '';

    const selectTab = (index: number): void => {
        const deviceTypeTab = tabOptionsForDevicesInBuilding[index].deviceType;
        setActiveTab(index);
        setActiveDeviceType(deviceTypeTab as DeviceTypeNames);
    };

    const tabs: ComponentTab[] = tabOptionsForDevicesInBuilding.map(tab => ({
        title: txt(`${mapDeviceType(tab.deviceType as AnyDeviceType)}FullName`),
        id: tab.id,
        testAttr: `display-settings-${tab.deviceType.toLowerCase()}`,
    }));

    return (
        <div className="page-wrapper__inner">
            {tabOptionsForDevicesInBuilding.length > 1 && (
                <ComponentTabNavigation tabs={tabs} activeTab={activeTab} setActiveTab={selectTab} />
            )}
            {activeDeviceType && (
                <ViewDisplaySelector
                    deviceType={activeDeviceType}
                    configuration={screenConfigs[activeDeviceType]}
                    updateScreenConfig={updateScreenConfigForDeviceType}
                />
            )}
            {activeDeviceType && (
                <DisplayUnitSettings
                    activeDeviceType={activeDeviceType}
                    configuration={screenConfigs[activeDeviceType]}
                    updateMeasurementUnits={updateMeasurementUnitsForDeviceType}
                />
            )}

            {(errorMessage || validateMessage) && <ResponseBox text={validateMessage || errorMessage} />}
            <div className="change-location__form__buttons">
                <PrimaryButton id="close" title="Close" loading={false} onClick={goBack} />
                <PrimaryButton
                    id="submit"
                    type="button"
                    title="Save"
                    filled
                    loading={updateLoading}
                    onClick={onSubmit}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        buildings: { configurations },
        devices: { devicesWithKeyInfo },
        requests: {
            [RequestType.GetLocationConfiguration]: { error: fetchConfigError },
            [RequestType.UpdateLocationConfigurationWithDeviceType]: { loading, error: updateConfigError },
        },
    } = state;

    return {
        configurations,
        devicesWithKeyInfo,
        updateLoading: loading,
        fetchConfigError,
        updateConfigError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onUpdateScreenConfig: (
        config: {
            selectedSensorIntervals?: { [deviceType: string]: { [sensorType: string]: number } };
            screens?: ScreenConfig;
        },
        locationId: string
    ): UpdateLocationConfigWithDeviceType => dispatch(updateLocationConfigWithDeviceType(config, locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDisplaySettingsComponent);
