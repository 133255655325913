import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { LogoutAllUnits, logoutAllUnits } from '../../../actions/LoginAndRegisterActions';
import { lookupDomain } from '../../../actions/SettingsActions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import DeleteConfirmModal from '../../../components/modals/DeleteConfirmModal';
import ResponseBox from '../../../components/responseMessages/ResponseBox';
import { GroupType } from '../../../models/commonEnums';
import { ErrorType, Group } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';
import { isB2B } from '../../group';
import ChangePassword from './ChangePassword';

type ActionProps = {
    logOutAll: () => void;
};

type StateProps = {
    loading: boolean;
    demoMode: boolean;
    email: string;
    sso: boolean;
    lookupLoading: boolean;
    lookupError?: ErrorType;
    selectedGroup?: Group;
};

type Props = ActionProps & StateProps;

export const SecuritySettingsComponent = ({
    demoMode,
    loading,
    email,
    sso,
    lookupLoading,
    lookupError,
    logOutAll,
    selectedGroup,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const userIsB2B = isB2B(selectedGroup);

    useEffect((): void => {
        if (userIsB2B) {
            const emailDomain = email.split('@')[1];
            dispatch(lookupDomain(emailDomain));
        }
    }, [email, userIsB2B]);

    const onLogoutModalOpen = (): void => {
        if (demoMode) {
            return;
        }
        setLogoutModalOpen(true);
    };

    const onLogoutModalClose = (): void => {
        setLogoutModalOpen(false);
    };

    const onLogout = (): void => {
        logOutAll();
    };
    const description = <p className="modal__content__description">{txt('LogoutAllSessionsDescription')}</p>;
    const disableLogout = loading || demoMode;
    const customerCanEditPassword =
        selectedGroup?.groupType === GroupType.consumer || (userIsB2B && !sso && !lookupLoading);
    return (
        <div>
            {logoutModalOpen && (
                <DeleteConfirmModal
                    title="LogoutAllSessions"
                    description={description}
                    onSubmit={onLogout}
                    onCancel={onLogoutModalClose}
                    onSubmitText="Logout"
                    onCancelText="Cancel"
                    loading={loading}
                />
            )}
            {lookupError ? (
                <div>
                    <ResponseBox text={`ErrorCodes.${lookupError.error}`} />
                </div>
            ) : (
                customerCanEditPassword && <ChangePassword />
            )}
            <div className="form">
                <h2 className="settings__header">{txt('LogoutAllSessions')}</h2>
                <div className="settings-details-container">
                    <p className="form__attr--element text-large">{txt('LogoutAllSessionsDescription')}</p>
                    <PrimaryButton
                        id="logOutFromAll"
                        title="Logout"
                        onClick={onLogoutModalOpen}
                        disabled={disableLogout}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { loading, demoMode, email, sso, selectedGroup },
        commonRequests: {
            [CommonRequestType.LookupDomain]: { loading: lookupLoading, error: lookupError },
        },
    } = state;

    return {
        selectedGroup,
        loading,
        demoMode,
        email,
        sso,
        lookupLoading,
        lookupError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    logOutAll: (): LogoutAllUnits => dispatch(logoutAllUnits()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecuritySettingsComponent);
