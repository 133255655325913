import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { SensorTypes } from 'commons/src/models/commonEnums';
import {
    LocationType,
    DeviceType,
    AnyDeviceType,
    ErrorType,
    SegmentProperties,
    BuildingType,
    Units,
} from 'commons/src/models/commonTypeScript';
import { SetInsightSelectedDevicesReturnType, setInsightSelectedDevices } from '../../../actions/insightActions';
import { fetchBuildingWithDevices, FetchBuildingWithDevices } from '../../../actions/locationActions';
import { FetchSegmentProperties, fetchSegmentProperties } from '../../../actions/segmentPropertiesActions';
import { Store } from '../../../reducers';
import { validateBuildingData, validUsageHours } from '../insightFunctions';
import BuildingInsight from './BuildingInsight';
import DateToFromSelector from './DateSelector';
import DeviceInsight from './DeviceInsight';
import LogoSection from './LogoSection';
import SensorSelector from './SensorSelector';

interface ActionProps {
    getSegmentProperties: (serialNumber: string) => void;
    setSelectedDevices: (devices: DeviceType[]) => void;
    onFetchBuilding: (locationId: string) => void;
}

export type ReportData = {
    from: string;
    to: string;
    serialNumbers: string[];
    locationId: string;
    sensors: string[];
    includeLogo?: boolean;
};

type StateProps = {
    locationsLoading: boolean;
    locations: LocationType[];
    devices: { [serialNumber: string]: DeviceType };
    segmentPropertiesLoading: boolean;
    segmentProperties: { [serialNumber: string]: SegmentProperties };
    selectedDevices: DeviceType[];
    units: Units;
    buildings: { [buildingId: string]: BuildingType };
    fetchBuildingLoading: boolean;
};

export type ParentProps = {
    setDisplayResultSection: (displayResult: boolean) => void;
    fetchReportDataLoading: boolean;
    fetchReportError?: ErrorType;
    fetchReportData: (payload: ReportData) => void;
    reportStartTime: Moment | null;
    reportEndTime: Moment | null;
    setReportStartTime?: (startTime: Moment | null) => void;
    setReportEndTime?: (endTime: Moment | null) => void;
    possibleSensorTypes?: SensorTypes[];
    acceptedDeviceTypes: AnyDeviceType[];
    minNumberOfDays: number;
    maxNumberOfDays?: number;
    allowLogo?: boolean;
    selectSingleDevice?: boolean;
    optionalBuildingProps: string[];
    hideGraphProps?: {
        header: string;
        subText: string;
        isHidden: boolean;
    };
    sensorWarning?: { sensors: SensorTypes[]; text: string | React.ReactElement };
    showAllSensors?: boolean;
    sensorNotToAutoSelect?: SensorTypes[];
};

export type Props = StateProps & ActionProps & ParentProps;

export const InsightMultipleDevicesComponent = ({
    acceptedDeviceTypes,
    fetchReportError,
    locationsLoading,
    getSegmentProperties,
    devices,
    locations,
    fetchReportDataLoading,
    fetchReportData,
    segmentPropertiesLoading,
    segmentProperties,
    selectedDevices,
    setDisplayResultSection,
    setReportStartTime,
    setReportEndTime,
    setSelectedDevices,
    reportEndTime,
    reportStartTime,
    possibleSensorTypes,
    minNumberOfDays,
    maxNumberOfDays,
    allowLogo,
    selectSingleDevice,
    hideGraphProps,
    optionalBuildingProps,
    sensorWarning,
    units,
    onFetchBuilding,
    buildings,
    fetchBuildingLoading,
    showAllSensors,
    sensorNotToAutoSelect,
}: Props): React.ReactElement => {
    const location = useLocation();
    const { t: txt } = useTranslation();
    const [datesAreValid, setDatesAreValid] = useState(false);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [invalidElements, setInvalidElements] = useState<string[]>([]);
    const [selectedSensors, setSelectedSensors] = useState<SensorTypes[]>([]);
    const [includeLogo, setIncludeLogo] = useState(false);
    const [reportGenerated, setReportGenerated] = useState(false);
    const [selectedBuildingId, setSelectedBuildingId] = useState<string>('');
    const building = buildings[selectedBuildingId] || undefined;

    useEffect((): void => {
        setDisplayResultSection(false);
    }, [building, selectedDevices, reportStartTime, reportEndTime, includeLogo, selectedSensors]);

    const selectBuilding = (locationId: string): void => {
        setSelectedBuildingId(locationId);
        if (!buildings[locationId]) {
            onFetchBuilding(locationId);
        }
        setSelectedDevices([]);
        if (setReportStartTime && setReportEndTime) {
            setReportStartTime(null);
            setReportEndTime(null);
        }
    };

    useEffect((): (() => void) => {
        return (): void => setSelectedDevices([]);
    }, []);

    const validateReport = (): { missingBuildingElements: string[] } => {
        if (!building) {
            setInvalidElements(['Building']);
            return { missingBuildingElements: ['Building'] };
        }
        const invalidBuildingElement = validateBuildingData({ txt }, building, optionalBuildingProps, units);
        const usageHoursValid = validUsageHours(building, optionalBuildingProps)
            ? []
            : [txt('Building.DayScheduleOpeningHours')];
        const missingDeviceProps = selectedDevices.length < 1 ? [txt('Device')] : [];

        const missingSelectedSensors =
            possibleSensorTypes && selectedSensors.length === 0 ? [txt('OutdoorInsight.OutdoorNoSelectedSensors')] : [];
        const missingBuildingElements = [
            ...invalidBuildingElement,
            ...usageHoursValid,
            ...missingDeviceProps,
            ...missingSelectedSensors,
        ];
        setInvalidElements(missingBuildingElements);
        return { missingBuildingElements };
    };

    useEffect((): void => {
        if (displayValidation) {
            validateReport();
        }
    }, [selectedDevices]);

    const updateDeviceSelection = (deviceSelection: DeviceType[]): void => {
        if (deviceSelection.length > 0 && selectSingleDevice) {
            const deviceToSet = deviceSelection.find(
                device => selectedDevices.length === 0 || selectedDevices[0].serialNumber !== device.serialNumber
            );
            if (deviceToSet) setSelectedDevices([deviceToSet]);
        } else {
            setSelectedDevices(deviceSelection);
        }
        if (setReportStartTime && setReportEndTime) {
            setReportStartTime(null);
            setReportEndTime(null);
        }
    };

    useEffect((): void => {
        let deviceSelected: DeviceType | undefined;
        let buildingSelected: LocationType | undefined;

        if (location.state && location.state.serialNumber) {
            const deviceSerialNumber: number = parseInt(location.state.serialNumber, 10);
            deviceSelected = devices[deviceSerialNumber];
            buildingSelected = locations.find(loc => loc.id === (deviceSelected && deviceSelected.locationId));
        } else if (location.state && location.state.locationId) {
            buildingSelected = locations.find(loc => loc.id === location.state.locationId);
        }

        if (buildingSelected) {
            const initSelectedDevices = [...selectedDevices];
            const initSelectedDevicesBuildingSameAsSelectedBuilding =
                initSelectedDevices.length > 0 && initSelectedDevices[0].locationId === buildingSelected.id;
            selectBuilding(buildingSelected.id);
            if (
                deviceSelected &&
                (location.state.generateReport || !initSelectedDevicesBuildingSameAsSelectedBuilding)
            ) {
                setSelectedDevices([deviceSelected]);
            } else if (selectedDevices.length > 0 && initSelectedDevices[0].locationId === buildingSelected.id) {
                setSelectedDevices(initSelectedDevices);
            }
        } else {
            setSelectedDevices([]);
        }
        if (deviceSelected) {
            getSegmentProperties(deviceSelected.serialNumber);
        }
    }, []);

    const generateReport = (): void => {
        if (!building) {
            setInvalidElements(['Building']);
            return;
        }

        const { missingBuildingElements } = validateReport();
        const reportRangeValid =
            (!setReportEndTime && !setReportStartTime) || (datesAreValid && reportStartTime && reportEndTime);
        if (reportRangeValid && missingBuildingElements.length === 0 && selectedDevices.length > 0) {
            fetchReportData({
                from: reportStartTime ? reportStartTime.format('YYYY-MM-DD') : '',
                to: reportEndTime ? reportEndTime.format('YYYY-MM-DD') : '',
                serialNumbers: selectedDevices.map(device => device.serialNumber),
                locationId: building.id,
                sensors: selectedSensors,
                includeLogo: allowLogo ? includeLogo : undefined,
            });
            setDisplayResultSection(true);
        } else {
            setDisplayValidation(true);
        }
    };

    useEffect((): void => {
        if (
            location &&
            location.state &&
            location.state.generateReport &&
            building &&
            selectedDevices.length > 0 &&
            selectedSensors.length > 0 &&
            Object.keys(segmentProperties).length > 0 &&
            !reportGenerated
        ) {
            generateReport();
            setReportGenerated(true);
        }
    }, [building, selectedDevices, selectedSensors, segmentProperties]);

    let errorText;
    if (invalidElements.length > 0) {
        errorText = `${txt('RadonInsight.FillOutMissingReportInfo', {
            invalidElements: invalidElements.join(', '),
        })}`;
    } else if (fetchReportError) {
        errorText = txt(`ErrorCodes.${fetchReportError.error}`);
    }

    const earliestSegmentEndDate =
        selectedDevices.reduce((earliestEndDate: string | undefined, segment: DeviceType) => {
            const isBeforeEndDate = moment(earliestEndDate).isAfter(segment.segmentEnded);
            if (!earliestEndDate || isBeforeEndDate) {
                return segment.segmentEnded;
            }
            return earliestEndDate;
        }, undefined) || undefined;

    const earliestSegmentStartDate: string | undefined =
        selectedDevices.reduce((earliestStartDate: string | undefined, segment: DeviceType) => {
            if (!earliestStartDate || moment(segment.segmentStart).isBefore(earliestStartDate)) {
                return segment.segmentStart;
            }
            return earliestStartDate;
        }, undefined) || undefined;

    return (
        <>
            <BuildingInsight
                building={building}
                setBuilding={selectBuilding}
                validate={displayValidation}
                optionalBuildingProps={optionalBuildingProps}
            />
            <DeviceInsight
                building={building}
                selectedDevices={selectedDevices}
                setSelectedDevices={updateDeviceSelection}
                acceptedDeviceTypes={acceptedDeviceTypes}
                minDaysMeasured={minNumberOfDays < 2 ? minNumberOfDays : 2}
                selectSingleDevice={selectSingleDevice}
            />
            {possibleSensorTypes && (
                <>
                    <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                        <h2>{txt('Insight.Sensors')}</h2>
                    </div>
                    <SensorSelector
                        sensors={possibleSensorTypes}
                        selectedDevices={selectedDevices}
                        updateSelectedSensors={setSelectedSensors}
                        selectedSensors={selectedSensors}
                        sensorWarning={sensorWarning}
                        showAllSensors={showAllSensors}
                        sensorNotToAutoSelect={sensorNotToAutoSelect}
                    />
                </>
            )}
            {allowLogo && <LogoSection includeLogo={includeLogo} updateIncludeLogo={setIncludeLogo} />}
            {setReportStartTime && setReportEndTime && (
                <>
                    <div className="insight-tile__header insight-tile__header--padded insight-tile__header--no-border">
                        <h2>{txt('Insight.TimePeriod')}</h2>
                    </div>
                    <div className="form__row form__button-container">
                        <DateToFromSelector
                            segmentEndDate={earliestSegmentEndDate}
                            segmentStartDate={earliestSegmentStartDate}
                            setEndDate={setReportEndTime}
                            setStartDate={setReportStartTime}
                            startDate={reportStartTime}
                            endDate={reportEndTime}
                            minNumberOfDays={minNumberOfDays}
                            maxNumberOfDays={maxNumberOfDays}
                            setDatesAreValid={setDatesAreValid}
                            validateSelection={displayValidation || !!(reportStartTime && reportEndTime)}
                        />
                    </div>
                </>
            )}
            {(errorText || (hideGraphProps && hideGraphProps.isHidden)) && (
                <ResponseBox
                    text={errorText || !hideGraphProps ? 'RequiredDataMissing' : hideGraphProps.header}
                    subtext={errorText || !hideGraphProps ? errorText : txt(hideGraphProps.subText)}
                    greenBox={!errorText}
                />
            )}
            <div className="form__row form__button-container">
                <PrimaryButton
                    type="button"
                    title="GenerateReport"
                    filled
                    disabled={locationsLoading || segmentPropertiesLoading || (!building && fetchBuildingLoading)}
                    onClick={generateReport}
                    id="generateRadonReport"
                    loading={fetchReportDataLoading}
                    testAttr="generate-radon-report"
                />
            </div>
        </>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading },
        devices: { devices },
        userSettings: { units },
        radonInsight: { selectedDevices },
        segmentPropertiesStore: { segmentProperties },
        requests: {
            FETCH_SEGMENT_PROPERTIES: { loading: segmentPropertiesLoading },
            FETCH_BUILDING_WITH_DEVICES: { loading: fetchBuildingLoading },
        },
        buildings: { buildings },
    } = state;
    return {
        locations,
        locationsLoading: loading && locations.length === 0,
        devices,
        segmentPropertiesLoading,
        segmentProperties,
        selectedDevices,
        units,
        buildings,
        fetchBuildingLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    getSegmentProperties: (serialNumber: string): FetchSegmentProperties =>
        dispatch(fetchSegmentProperties(serialNumber)),
    setSelectedDevices: (selectedDevices: DeviceType[]): SetInsightSelectedDevicesReturnType =>
        dispatch(setInsightSelectedDevices(selectedDevices)),
    onFetchBuilding: (locationId: string): FetchBuildingWithDevices => dispatch(fetchBuildingWithDevices(locationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightMultipleDevicesComponent);
