import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deviceCount } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { GroupType } from 'commons/src/models/commonEnums';
import { DeviceWithKeyInfo, ErrorType, Group, SubscriptionPlan } from 'commons/src/models/commonTypeScript';
import { getSubscriptionForEndCustomer } from '../../../actions/subscriptionActions';
import AddSeatsSubscriptionSelector from '../../../components/subscriptionComponents/AddSeatsSubscriptionSelector';
import YourPlan from '../../../components/subscriptionComponents/YourPlan';
import { A4BSupportMail, paths } from '../../../constants';
import { Subscription } from '../../../models/subscriptionModels';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import BillingDetails from './BillingDetails/BillingDetails';
import styles from './Subscription.module.scss';
import SubscriptionTable from './SubscriptionTable';

interface StateProps {
    selectedGroup?: Group;
    hubsWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    devicesWithKeyInfo: { [serialNumber: string]: DeviceWithKeyInfo };
    subscriptionsLoading: boolean;
    subscriptionsError?: ErrorType;
    subscriptions: Subscription[];
    subscriptionForGroup?: SubscriptionPlan;
}

export type Props = StateProps;

export const SubscriptionComponent = ({
    hubsWithKeyInfo,
    devicesWithKeyInfo,
    loading,
    selectedGroup,
    subscriptionsError,
    subscriptionsLoading,
    subscriptions,
    subscriptionForGroup,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addSeatsOpen, setAddSeatsOpen] = useState(false);

    const isPartner = selectedGroup?.groupType === GroupType.partner;

    useEffect(() => {
        if (selectedGroup && !isPartner) {
            dispatch(getSubscriptionForEndCustomer());
        }
    }, [selectedGroup]);

    useEffect(() => {
        if (!loading && !selectedGroup) {
            navigate(`/${paths.settings}`);
        }
    }, [loading, selectedGroup]);

    const deviceCounts = deviceCount(devicesWithKeyInfo, hubsWithKeyInfo);
    const managedByPartner = selectedGroup?.managedByOrganization;
    const businessNotManagedByPartner = selectedGroup?.groupType === GroupType.business && !managedByPartner;

    const onAddSeatsClicked = (open: boolean): void => {
        if (subscriptions.length === 1) {
            const { subscriptionNumber } = subscriptions[0];
            navigate(`add-seats/${subscriptionNumber}`);
        } else setAddSeatsOpen(open);
    };

    const startSubscription = (): void => {
        navigate('start-subscription');
    };

    const showCreateSubscriptionButton =
        businessNotManagedByPartner && !subscriptionsLoading && subscriptions.length === 0 && !subscriptionsError;

    return (
        <div>
            <BillingDetails selectedGroup={selectedGroup} />
            {selectedGroup && !isPartner && (
                <div>
                    <h2 className="settings__header">{txt('YourPlan')}</h2>
                    <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                        <YourPlan
                            deployedDevices={deviceCounts}
                            partnerName={selectedGroup?.managedByOrganizationName}
                            openAddSeats={
                                businessNotManagedByPartner && subscriptions.length > 0 ? onAddSeatsClicked : undefined
                            }
                            subscriptionPlan={subscriptionForGroup}
                        />
                        {businessNotManagedByPartner && (
                            <AddSeatsSubscriptionSelector
                                onClose={(): void => setAddSeatsOpen(false)}
                                isOpen={addSeatsOpen}
                                subscriptions={subscriptions}
                            />
                        )}
                        <div className={styles.subscriptionSection}>
                            {businessNotManagedByPartner && !subscriptionsLoading && (
                                <SubscriptionTable
                                    subscriptions={subscriptions || []}
                                    groupType={selectedGroup.groupType}
                                />
                            )}
                            {showCreateSubscriptionButton && (
                                <div className={styles.startSubscriptionButton}>
                                    <PrimaryButton
                                        filled
                                        onClick={startSubscription}
                                        title="Subscription.StartSubscription"
                                    />
                                </div>
                            )}
                            <p className={styles.supportText}>
                                {managedByPartner ? (
                                    <Trans
                                        i18nKey="Subscription.ManagedByPartnerSupport"
                                        values={{ partnerName: selectedGroup?.managedByOrganizationName }}
                                    />
                                ) : (
                                    <Trans i18nKey="Subscription.SubscriptionSupport">
                                        <a id="open_intercom" href={`mailto: ${A4BSupportMail}`}>
                                            chat
                                        </a>
                                    </Trans>
                                )}
                            </p>
                            {subscriptionsError && (
                                <div className={styles.errorWrapper}>
                                    <ResponseBox text={`ErrorCodes.${subscriptionsError.error}`} />
                                </div>
                            )}
                        </div>
                    </ReactPlaceholder>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        devices: { devicesWithKeyInfo, hubsWithKeyInfo },
        userSettings: { selectedGroup, loading, subscriptionForGroup },
        requests: {
            [RequestType.GetSubscriptionForEndCustomer]: { loading: subscriptionsLoading, error: subscriptionsError },
        },
        subscription: { subscriptions },
    } = store;

    return {
        hubsWithKeyInfo,
        devicesWithKeyInfo,
        selectedGroup,
        loading: loading || subscriptionsLoading,
        subscriptionsLoading,
        subscriptionsError,
        subscriptions,
        subscriptionForGroup,
    };
};

export default connect(mapStateToProps)(SubscriptionComponent);
