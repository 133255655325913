import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { ACCOUNT_INVITED_USER } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ElementsSelectorWithChips from 'commons/src/components/dropdown/ElementsSelectorWithChips';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import RadioButtons from 'commons/src/components/input/Radio';
import { IntercomAPI } from 'commons/src/components/Intercom';
import LabeledText from 'commons/src/components/LabeledText';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { EMAIL_REGEX } from 'commons/src/constants';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { GroupType, RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import { ErrorType, Group, LocationType, SelectableRole, SelectionItem } from 'commons/src/models/commonTypeScript';
import { changeMembershipRole, sendInvite } from '../../../actions/organizationMemberActions';
import { getFederatedClients } from '../../../actions/singleSignOnActions';
import { InvitedMember, Member, SingleSignOnClient } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';

export type ParentProps = {
    inModalView: boolean;
    userToEdit?: Member | InvitedMember;
};

type StateProps = {
    userGroupId?: string;
    ownRole?: Role;
    locations: LocationType[];
    loadingEditingUser: boolean;
    ownUserId?: string;
    loadingSendInvite: boolean;
    errorSendInvite?: ErrorType;
    selectedGroup?: Group;
    singleSignOnClients: SingleSignOnClient[];
};

type InviteSelection = { id: SelectableRole; inputValue: string };
export type Props = StateProps & ParentProps;

export const selectableRoles: SelectableRole[] = [Role.ADMIN, Role.USER, Role.VIEWER];
export const buildingAccessRoles: Role[] = [Role.USER, Role.VIEWER];

export const InviteUserFormComponent = (props: Props): React.ReactElement | null => {
    const {
        userGroupId,
        ownRole,
        locations,
        inModalView,
        userToEdit,
        loadingEditingUser,
        ownUserId,
        loadingSendInvite,
        errorSendInvite,
        selectedGroup,
        singleSignOnClients,
    } = props;
    const dispatch = useDispatch();
    const [ssoWarningModalOpen, setSsoWarningModalOpen] = useState(false);
    const [name, setName] = useState(userToEdit ? userToEdit.name : '');
    const [email, setEmail] = useState(userToEdit && userToEdit.email ? userToEdit.email : '');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [inviteRole, setRole] = useState<SelectableRole>(
        userToEdit ? (userToEdit.role as SelectableRole) : Role.ADMIN
    );
    const [displayError, setDisplayError] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState<SelectionItem[]>(
        userToEdit && userToEdit.resources
            ? locations
                  .map(loc => ({ id: loc.id, name: loc.name }))
                  .filter(loc => userToEdit.resources && userToEdit.resources.locations.some(id => id === loc.id))
            : []
    );
    const [locationsSelectorOn, setLocationsSelectorOn] = useState(
        !!userToEdit &&
            !!userToEdit.resources &&
            userToEdit.resources.locations !== undefined &&
            userToEdit.resources.locations.length > 0
    );

    const { t: txt } = useTranslation();

    useEffect((): void => {
        if (userGroupId && singleSignOnClients && singleSignOnClients.length < 1) {
            dispatch(getFederatedClients());
        }
    }, [userGroupId]);

    useEffect(() => {
        setDisplayError(false);
    }, [name, email]);

    useEffect((): void => {
        setDisplayError(!!errorSendInvite);
    }, [errorSendInvite]);

    const throughEvent =
        (setter: (value: string) => void) =>
        (event: SyntheticEvent<HTMLInputElement>): void => {
            setter(event.currentTarget.value);
        };

    const updateEmail = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        setEmail(value.toLowerCase());
    };

    const clearForm = (): void => {
        setEmail('');
        setName('');
    };

    const prevLoadingRef = useRef(loadingSendInvite);
    useEffect((): void => {
        if (prevLoadingRef.current && !loadingSendInvite && !errorSendInvite) {
            clearForm();
        }
        prevLoadingRef.current = loadingSendInvite;
    }, [prevLoadingRef, loadingSendInvite]);

    const validForm = (): boolean => {
        const nameValid = name.trim().length > 0;
        const emailValid = EMAIL_REGEX.test(email);
        const formIsValid = nameValid && emailValid;
        if (!formIsValid) setDisplayValidation(true);
        return formIsValid;
    };

    const setUserRole = (role: { id: string; inputValue: string }): void => {
        const selectedRole = role.id as SelectableRole;
        if (selectableRoles.includes(selectedRole)) {
            setRole(selectedRole);
        }
    };
    const validSsoDomain = (): boolean => {
        const ssoDomains = singleSignOnClients.filter(client => client.active).map(client => client.domain);
        const newUserDomain = email.slice(email.indexOf('@') + 1, email.length);
        const domainIsRegistered = ssoDomains.includes(newUserDomain);
        const validDomain = ssoDomains.length === 0 || domainIsRegistered;
        if (!validDomain) setSsoWarningModalOpen(true);
        return validDomain;
    };

    const clickSendInvite = (): void => {
        if (validForm() && userGroupId) {
            if (ssoWarningModalOpen || validSsoDomain()) {
                setSsoWarningModalOpen(false);
                const resources = locationsSelectorOn ? { locations: selectedLocations.map(loc => loc.id) } : undefined;
                dispatch(
                    sendInvite({
                        name,
                        email,
                        role: inviteRole,
                        userGroupId,
                        resources,
                    })
                );
                IntercomAPI('trackEvent', 'invited-user', {
                    name,
                    email,
                    role: inviteRole,
                    userGroupId,
                });
                analyticsLogger(ACCOUNT_INVITED_USER, {
                    pageType: PageType.Account,
                    userRole: inviteRole,
                    userGroupId,
                });
            }
        }
    };
    if (!ownRole || !userGroupId || !userRoleAboveRequiredLevel(ownRole, RequiredRoleLevel.ABOVE_ADMIN)) {
        return null;
    }

    const onSelectLocation = (element: SelectionItem): void => {
        setSelectedLocations([...selectedLocations, element]);
    };

    const removeLocationFromSelection = (id: string): void => {
        const filteredSelection = selectedLocations.filter(loc => loc.id !== id);
        setSelectedLocations(filteredSelection);
    };

    const roleTranslation = (role: SelectableRole): string => txt(`AuthorizationRole.${role}`);

    const roleOptions = selectableRoles.map<InviteSelection>((key: SelectableRole) => ({
        id: key,
        inputValue: roleTranslation(key),
    }));

    const submitUserEdit = (): void => {
        if (validForm()) {
            const resources =
                locationsSelectorOn && buildingAccessRoles.includes(inviteRole)
                    ? { locations: selectedLocations.map(loc => loc.id) }
                    : undefined;
            dispatch(
                changeMembershipRole((userToEdit && userToEdit.userId) || '', {
                    name,
                    role: inviteRole as Role,
                    resources,
                })
            );
        }
    };

    const canInviteToSpecificBuilding = selectedGroup?.groupType !== GroupType.partner || locations.length > 0;

    const locationsNotSelected: SelectionItem[] = locations
        .map(loc => ({ id: loc.id, name: loc.name }))
        .filter(loc => !selectedLocations.some(selectedLoc => selectedLoc.id === loc.id));

    const updateLocationSelector = (e: React.SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setLocationsSelectorOn(value === 'on');
    };

    const radioButtonOptions = [
        {
            value: 'off',
            label: 'UserManagementSettings.AllBuildings',
        },
        {
            value: 'on',
            label: 'UserManagementSettings.SpecificBuildings',
        },
    ];
    return (
        <div className="settings-details-container">
            <div
                className={classNames('user-management', {
                    'user-management--modal-view': inModalView,
                })}
            >
                {ssoWarningModalOpen && (
                    <DeleteConfirmModal
                        loading={false}
                        onCancel={(): void => setSsoWarningModalOpen(false)}
                        onSubmit={clickSendInvite}
                        title="InviteUser.ConfirmInvitationOutsideDomain"
                        onSubmitText="InviteUser.InviteUser"
                        description={txt('InviteUser.OutsideSsoDescription')}
                        onCancelText="Cancel"
                        regularSubmitButton
                        centerBody
                    />
                )}
                <div className="form__row">
                    <div className="form__field form__field--half">
                        <Input
                            type="text"
                            id="invite-form-first-name"
                            validate={displayValidation && name.trim().length === 0}
                            label={txt('Name')}
                            currentValue={name}
                            onChange={throughEvent(setName)}
                            testId="invite-form-first-name"
                        />
                    </div>
                    {!inModalView ? (
                        <div className="form__field form__field--half">
                            <Input
                                type="text"
                                id="invite-form-email"
                                validate={displayValidation && !EMAIL_REGEX.test(email)}
                                label={txt('Email')}
                                currentValue={email}
                                hint="EmailHint"
                                onChange={updateEmail}
                                testId="invite-form-email"
                            />
                        </div>
                    ) : (
                        <div className="form__field form__field--standard-width">
                            <LabeledText label="Email" value={email} id={email} />
                        </div>
                    )}
                </div>
                <div className="form__row flex--align-center">
                    <div className="form__field form__field--single-width">
                        <Dropdown
                            id="AccessLevelSelector"
                            loading={false}
                            title="Role"
                            options={roleOptions}
                            value={roleTranslation(inviteRole)}
                            defaultOption={roleTranslation(inviteRole)}
                            onSelect={setUserRole}
                            testAttr="role-list"
                            disabled={ownUserId === (userToEdit && userToEdit.userId)}
                        />
                    </div>
                    <p className="form__field--single-width text-paragraph">
                        {txt(`UserRoleDescription.${inviteRole}`)}
                    </p>
                </div>
                {canInviteToSpecificBuilding && buildingAccessRoles.includes(inviteRole) && (
                    <>
                        <div className="form__row">
                            <div className="form__field flex-wrap">
                                <RadioButtons
                                    buttons={radioButtonOptions}
                                    selectorName="locationSelector"
                                    row
                                    onChange={updateLocationSelector}
                                    value={locationsSelectorOn ? 'on' : 'off'}
                                    labelId="location-selector"
                                />
                            </div>
                        </div>
                        {locationsSelectorOn && (
                            <ElementsSelectorWithChips
                                onSelect={onSelectLocation}
                                onRemove={removeLocationFromSelection}
                                selectedElements={selectedLocations}
                                elementsNotSelected={locationsNotSelected}
                            />
                        )}
                    </>
                )}
            </div>
            <div
                className={classNames('form__row', {
                    'submit-container': !inModalView,
                    'form__button-container': inModalView,
                })}
            >
                <PrimaryButton
                    loading={loadingSendInvite || loadingEditingUser}
                    filled
                    disabled={loadingSendInvite}
                    title={inModalView ? 'Save' : 'InviteUserToOrganization'}
                    icon={!inModalView ? <MaterialIcon extraClass="button-icon" name="send" /> : undefined}
                    onClick={inModalView ? submitUserEdit : clickSendInvite}
                    testId="invite-user-to-organization"
                />
            </div>
            {errorSendInvite && displayError && <ResponseBox text={`ErrorCodes.${errorSendInvite.error}`} />}
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        settings: { singleSignOnClients },
        userSettings: { selectedGroup, userId },
        locations: { locations },
        requests: {
            [RequestType.ChangeMembershipRole]: { loading: loadingEditingUser },
            [RequestType.SendInvite]: { loading: loadingSendInvite, error: errorSendInvite },
        },
    } = store;
    return {
        userGroupId: selectedGroup && selectedGroup.id,
        ownRole: selectedGroup && selectedGroup.role,
        locations,
        loadingEditingUser,
        ownUserId: userId,
        loadingSendInvite,
        errorSendInvite,
        selectedGroup,
        singleSignOnClients,
    };
};

export default connect(mapStateToProps)(InviteUserFormComponent);
