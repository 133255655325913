import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import NoContent from '../../../components/emptyStates/NoContent';
import Error from '../../../components/errorComponents/Error';
import { userIsHbs } from '../../../components/findUserType';
import { fullWidthListPlaceholder } from '../../../components/placeholder';
import { dateFormats } from '../../../constants';
import { DeviceType, EndedSegment, HubData, LocationType } from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';
import EndedMeasurementsTile from '../EndedMeasurements/EndedMeasurementsTile';
import LocationSection from './LocationSection';

export type ParentProps = {
    locationQuery?: string;
    loading: boolean;
    devicesLoading: boolean;
    locations: LocationType[];
    devices: { [key: string]: DeviceType };
    error: boolean;
    dateFormat: keyof typeof dateFormats;
    endedMeasurements: EndedSegment[];
    hubs: HubData[];
    showLocationHeader: boolean;
    searchText: string;
    onlyVirtualDevices?: boolean;
    onlyPhysicalDevices?: boolean;
    url?: string;
};

type StateProps = {
    loadingEndedMeasurements: boolean;
};

export type Props = StateProps & ParentProps;

export const DevicesListComponent = (props: Props): React.ReactElement => {
    const {
        locations,
        loading,
        locationQuery,
        devicesLoading,
        devices,
        error,
        dateFormat,
        endedMeasurements,
        hubs,
        showLocationHeader,
        searchText,
        loadingEndedMeasurements,
        onlyPhysicalDevices,
        onlyVirtualDevices,
        url,
    } = props;

    const { t: txt } = useTranslation();

    const [pageLoading, setPageLoading] = useState(true);

    useEffect((): void => {
        const userHasHubs = (): boolean => {
            if (locations.length === 0) return false;
            const userHub = locations.find(loc => loc.hubs.length > 0);
            return userHub !== undefined;
        };

        if (pageLoading && !loading && (!userHasHubs() || !devicesLoading)) {
            setPageLoading(false);
        }
    }, [loading, devicesLoading]);

    const scrollToLocation = (focusedLocation: string): void => {
        const element = document.getElementById(focusedLocation);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect((): void => {
        if (locationQuery) {
            setTimeout(() => {
                scrollToLocation(locationQuery);
            }, 10);
        }
    }, []);

    if (error) {
        return <Error />;
    }

    const filteredDevices: { [serialNumber: string]: DeviceType } = searchText
        ? Object.keys(devices)
              .filter(
                  key =>
                      devices[key].serialNumber.toLowerCase().includes(searchText) ||
                      devices[key].locationName.toLowerCase().includes(searchText) ||
                      devices[key].segmentName.toLowerCase().includes(searchText)
              )
              .reduce((res, key) => ({ ...res, [key]: devices[key] }), {})
        : devices;

    const filteredHubs = searchText
        ? hubs.filter((hub: HubData) => {
              const hubLocation = locations.find(loc => loc.id === hub.locationId);
              const locationNameMatchingFilter = hubLocation && hubLocation.name.toLowerCase().includes(searchText);
              return (
                  hub.serialNumber.includes(searchText) ||
                  locationNameMatchingFilter ||
                  hub.name.toLowerCase().includes(searchText)
              );
          })
        : hubs;

    const filteredEndedMeasurements = searchText
        ? endedMeasurements.filter(
              endedSegment =>
                  endedSegment.locationName.toLowerCase().includes(searchText) ||
                  endedSegment.serialNumber.includes(searchText) ||
                  endedSegment.name.toLowerCase().includes(searchText)
          )
        : endedMeasurements;

    const renderLocations = (): React.ReactElement => {
        const activeDevicesInState = Object.values(filteredDevices).length > 0;
        const hasHubs = filteredHubs.length > 0;
        const hasDevices = activeDevicesInState || (endedMeasurements && endedMeasurements.length > 0) || hasHubs;
        if (!hasDevices) {
            return (
                <div className="page-wrapper">
                    <NoContent noContentText={userIsHbs() ? '' : 'Consumer.AddDevicesToAccount'} />
                </div>
            );
        }

        const sortedLocations = locations.sort((location1, location2) => location1.name.localeCompare(location2.name));
        const activeLocations =
            (hasHubs || activeDevicesInState) &&
            sortedLocations.map((loc: LocationType) => (
                <LocationSection
                    key={loc.id}
                    url={url}
                    devices={filteredDevices}
                    onlyVirtualDevices={onlyVirtualDevices || false}
                    onlyPhysicalDevices={onlyPhysicalDevices || false}
                    hubs={filteredHubs}
                    location={loc}
                    dateFormat={dateFormat}
                    showLocationHeader={showLocationHeader}
                />
            ));

        return (
            <div className="page-wrapper">
                <div className="page-section--full-width">
                    {activeLocations}
                    {filteredEndedMeasurements.length > 0 && (
                        <div className="page-section--full-width">
                            <div className="inline-header-lined">
                                <h2 className="inline-header-lined__text">
                                    {txt('EndedMeasurements')}
                                    <span className="inline-header-lined__info-chip">
                                        {filteredEndedMeasurements.length}
                                    </span>
                                </h2>
                            </div>
                            <EndedMeasurementsTile
                                listElements={filteredEndedMeasurements}
                                showLocation
                                dateFormat={dateFormat}
                                headerText="EndedMeasurements"
                                sortElement="segmentEnd"
                                sortByDate
                                width={3}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    return (
        <ReactPlaceholder
            showLoadingAnimation
            ready={!devicesLoading && !loadingEndedMeasurements}
            customPlaceholder={fullWidthListPlaceholder}
        >
            {renderLocations()}
        </ReactPlaceholder>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        commonRequests: {
            [CommonRequestType.FetchLocationsHistory]: { loading: loadingEndedMeasurements },
        },
    } = state;
    return {
        loadingEndedMeasurements,
    };
};

export default connect(mapStateToProps)(DevicesListComponent);
