import React from 'react';
import { useTranslation } from 'react-i18next';
import { linkToDeveloperDocs, linkToAirthingsGithub, ConsumerSupportMail } from 'commons/src/constants';

export const IntegrationsOverviewComponent = (): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <div>
            <h2 className="settings__header">{txt('Integrations.Overview')}</h2>
            <div className="settings-details-container">
                <p>{txt('Integrations.ConsumerDescription')}</p>
                <a href={linkToDeveloperDocs} target="_blank" rel="noopener noreferrer">
                    {txt('Integrations.InfoAndTechDocumentation')}
                </a>
                <p>
                    {txt('Integrations.LinkToApiCodeExamples')}
                    <a href={linkToAirthingsGithub} target="_blank" rel="noopener noreferrer">
                        github.com/airthings
                    </a>
                </p>
                <div>
                    {txt('Integrations.ContactHBSSupport')}
                    <a href={`mailto: ${ConsumerSupportMail}`}> {ConsumerSupportMail}</a>
                </div>
            </div>
        </div>
    );
};

export default IntegrationsOverviewComponent;
