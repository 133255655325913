import { SelectGroupActionType, SelectGroupReducerActions } from 'commons/src/actions/selectGroupActions';
import DeviceHealthStatus from 'commons/src/models/deviceHealthStatus';
import { HealthStatusActions, HealthStatusActionType } from '../actions/healthStatusActions';
import { HealthStatusIssueDataSummary, OrganizationHealthStatusSummary } from '../models/buildingModels';

export type HealthStatusState = {
    healthStatusSummary?: OrganizationHealthStatusSummary;
    offlineDevices?: HealthStatusIssueDataSummary;
    lowBatteryDevices?: HealthStatusIssueDataSummary;
    poorConnectionDevices?: HealthStatusIssueDataSummary;
};

export const initialState: HealthStatusState = {
    healthStatusSummary: undefined,
    offlineDevices: undefined,
    lowBatteryDevices: undefined,
    poorConnectionDevices: undefined,
};

export default (
    state: HealthStatusState = initialState,
    action: HealthStatusActions | SelectGroupReducerActions
): HealthStatusState => {
    switch (action.type) {
        case HealthStatusActionType.FetchOrganizationHealthStatusSummarySuccess:
            return { ...state, healthStatusSummary: action.healthStatusSummary };
        case HealthStatusActionType.FetchHealthStatusIssueDataSuccess:
            switch (action.status) {
                case DeviceHealthStatus.offline:
                    return { ...state, offlineDevices: action.healthStatusIssueData };
                case DeviceHealthStatus.lowBattery:
                    return { ...state, lowBatteryDevices: action.healthStatusIssueData };
                case DeviceHealthStatus.poorConnection:
                    return { ...state, poorConnectionDevices: action.healthStatusIssueData };
                default:
                    return state;
            }
        case SelectGroupActionType.SelectGroupInit:
            return initialState;
        default:
            return state;
    }
};
