import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { AddLocation, addLocation } from 'commons/src/actions/LocationActions';

import { analyticsLogger, PageType } from 'commons/src/analytics';
import { BUILDING_ADDED_BUILDING } from 'commons/src/analytics/AnalyticsEvents';
import Error from 'commons/src/components/errorComponents/Error';
import PageHeader from 'commons/src/components/headers/PageHeader';
import SubHeader from 'commons/src/components/headers/SubHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { ButtonColor, RequiredRoleLevel } from 'commons/src/models/commonEnums';
import { LocationDetails } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { paths } from '../../../constants';
import { Store } from '../../../reducers';
import BuildingForm from '../buildingEditDetails/BuildingForm';

type StateProps = {
    loading: boolean;
    error: boolean;
    addLoading: boolean;
};

type ActionProps = {
    saveLocation: (payload: LocationDetails, redirectToAddDevice?: boolean) => void;
};

export type Props = ActionProps & StateProps;

export const AddBuildingComponent = ({ error, loading, addLoading, saveLocation }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        document.body.className = 'blue-body';
        return (): void => {
            document.body.className = '';
        };
    }, []);

    if (error) {
        return <Error />;
    }

    const submit = (locationInfo: LocationDetails): void => {
        saveLocation(locationInfo);
        analyticsLogger(BUILDING_ADDED_BUILDING, { pageType: PageType.Building, ...locationInfo });
    };

    const close = (): void => {
        navigate({ pathname: `/${paths.buildings}` });
    };

    const actionButtons: ActionButton[] = [
        {
            testAttr: 'edit-location',
            title: 'Close',
            color: ButtonColor.secondary,
            id: 'close-add-building',
            onClick: close,
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];
    return (
        <div data-add-building-page>
            <PageHeader title={txt('Building.AddBuilding')} />
            <div className="page-wrapper__medium">
                <SubHeader actionButtons={actionButtons} />
            </div>
            <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                <div className="page-wrapper__medium page-wrapper__medium--white form">
                    <h2 className="form__header">{txt('Building.Building')}</h2>
                    <div className="page-wrapper__inner page-wrapper__inner--slim">
                        <BuildingForm
                            closeOnSave={false}
                            onClose={close}
                            updateLoading={addLoading}
                            saveLocation={submit}
                        />
                    </div>
                </div>
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { loading, error },
        requests: {
            ADD_LOCATION: { loading: addLoading },
        },
    } = state;
    return {
        loading,
        error,
        addLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    saveLocation: (payload): AddLocation => dispatch(addLocation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBuildingComponent);
