import React, { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { SPACE_MOVED_DEVICE } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { RequestState } from 'commons/src/reducers/requestReducer';
import { moveDeviceBetweenSpaces, MoveDevicePayload } from '../../../../actions/spaceActions';
import { IndoorSpace, SpaceDeviceEntity } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { ModalState } from './moveSpaceCommons';
import styles from './SpaceMoveDeviceStep3.module.scss';

type MoveDeviceModalProps = {
    closeModal: () => void;
    locationId: string;
    space: IndoorSpace;
    device: SpaceDeviceEntity;
    startNewSegment: boolean;
    selectedDeviceName: string;
    selectedSpace: Option;
    selectedBuilding: Option;
    setModalState: (state: ModalState) => void;
};

const SpaceMoveDeviceModalStep3 = ({
    closeModal,
    space,
    locationId,
    device,
    startNewSegment,
    selectedDeviceName,
    selectedBuilding,
    selectedSpace,
    setModalState,
}: MoveDeviceModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const moveRequest: RequestState = useSelector((state: Store) => state.requests.MOVE_DEVICE_BETWEEN_SPACES);

    const handleMove = (): void => {
        const payload: MoveDevicePayload = {
            toLocationId: selectedBuilding.id,
            toSpaceId: selectedSpace.id,
            deviceName: selectedDeviceName,
            startNewSegment,
        };
        dispatch(moveDeviceBetweenSpaces(locationId, space.id, device.serialNumber, payload, space.name));
        analyticsLogger(SPACE_MOVED_DEVICE, {
            fromLocationId: locationId,
            toLocationId: selectedBuilding.id,
            fromSpaceId: space.id,
            toSpaceId: selectedSpace.id,
            serialNumber: device.serialNumber,
        });
    };

    const previousLoading = useRef(moveRequest.loading);
    useEffect((): void => {
        if (previousLoading.current && !moveRequest.loading && !moveRequest.error) {
            closeModal();
        }
    }, [previousLoading, moveRequest.loading]);

    return (
        <>
            <div className={styles.content}>
                <div>
                    <Trans
                        i18nKey={txt('MoveSpaceDevice.DeviceWillBeMoved', {
                            building: selectedBuilding?.inputValue,
                            space: selectedSpace?.inputValue,
                            deviceName: selectedDeviceName,
                        })}
                        components={{ bold: <span className={styles.bold} /> }}
                    />
                </div>
                <div className={styles.row}>
                    <MaterialIcon name="move_group" />
                    <div>
                        <Trans
                            i18nKey={
                                startNewSegment
                                    ? txt('MoveSpaceDevice.MeasurementWillEnd', {
                                          currentSpace: space.name,
                                          newSpace: selectedSpace?.inputValue,
                                      })
                                    : txt('MoveSpaceDevice.MeasurementWillCarryOver', {
                                          newSpace: selectedSpace?.inputValue,
                                      })
                            }
                            components={{ bold: <span className={styles.bold} /> }}
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <MaterialIcon name="report" fill extraClass={styles.alertIcon} />
                    <div>{txt('MoveSpaceDevice.CannotBeUndone')}</div>
                </div>
            </div>
            {moveRequest.error && (
                <div className={styles.errorWrapper}>
                    <ResponseBox text={`ErrorCodes.${moveRequest.error.error}`} />
                </div>
            )}
            <div className={styles.buttons}>
                <PrimaryButton onClick={(): void => setModalState(ModalState.STEP2)} title="Back" />
                <PrimaryButton onClick={handleMove} title="Confirm" filled loading={moveRequest.loading} />
            </div>
        </>
    );
};

export default SpaceMoveDeviceModalStep3;
