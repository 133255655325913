import React, { useState, SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CircleButton from 'commons/src/components/buttons/CircleButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Input from 'commons/src/components/input/Input';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { ORGANIZATION_NAME_REGEX } from 'commons/src/constants';
import { ErrorType, Group } from 'commons/src/models/commonTypeScript';
import {
    updateOrganizationProperties,
    updateOrganizationLogoInit,
    UpdateOrganizationPropertiesType,
    UpdateOrganizationLogoInit,
    UpdateOrganizationPropertiesInit,
} from '../../../actions/organizationPropertiesActions';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

interface ParentProps {
    onClose: () => void;
}

interface StateProps {
    selectedGroup?: Group;
    logoFileName?: string;
    logoImage?: string;
    logoLoading: boolean;
    logoError?: ErrorType;
}

interface ActionProps {
    updateLogo: (fileName: string, logoImage: string) => void;
    updateProperties: (properties: UpdateOrganizationPropertiesType) => void;
}

export type Props = StateProps & ActionProps & ParentProps;

export const EditOrganizationProfileComponent = (props: Props): React.ReactElement => {
    const { selectedGroup, logoFileName, logoImage, updateProperties, updateLogo, onClose, logoError, logoLoading } =
        props;
    const { t: txt } = useTranslation();
    const imageMaxSize = 2000000;
    const currentOrganizationName = (selectedGroup && selectedGroup.organizationName) || '';

    const [newOrganizationName, setNewOrganizationName] = useState(currentOrganizationName);
    const [displayedLogoImage, setDisplayedLogoImage] = useState(logoImage || '');
    const [displayedFileName, setDisplayedFileName] = useState(logoFileName || '');
    const [imageSizeTooLarge, setImageSizeTooLarge] = useState(false);
    const [invalidFileType, setInvalidFileType] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect((): void => {
        if (!logoLoading) {
            if (logoError) {
                setInvalidFileType(true);
                setIsSubmitting(false);
            } else if (isSubmitting) {
                setIsSubmitting(false);
                onClose();
            }
        }
    }, [currentOrganizationName, logoImage, logoError, logoLoading]);

    const handleOrganizationNameChange = (event: SyntheticEvent<HTMLInputElement>): void => {
        setNewOrganizationName(event.currentTarget.value.trim());
    };

    const handleImageChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>): void => {
        const file = currentTarget.files && currentTarget.files.length > 0 && currentTarget.files[0];
        setInvalidFileType(false);
        setImageSizeTooLarge(false);
        if (!file) return;
        if (!file.type.includes('image')) {
            setInvalidFileType(true);
        } else if (file.size > imageMaxSize) {
            setImageSizeTooLarge(true);
        }

        const reader = new FileReader();
        reader.onloadend = (): void => {
            const base64Image: string | ArrayBuffer | null = reader.result;

            let base64ImageAsString;
            if (base64Image === null) base64ImageAsString = '';
            else base64ImageAsString = base64Image.toString();

            setDisplayedLogoImage(base64ImageAsString);
            setDisplayedFileName(file.name);
        };
        reader.readAsDataURL(file);
    };

    const changesMade =
        newOrganizationName !== currentOrganizationName ||
        displayedLogoImage !== logoImage ||
        displayedFileName !== logoFileName;
    const newOrganizationNameIsValid = ORGANIZATION_NAME_REGEX.test(newOrganizationName);
    const newLogoImageIsValid = !imageSizeTooLarge && !invalidFileType;

    const handleSubmit = (): void => {
        if (!changesMade) {
            onClose();
        } else {
            const validForm = newOrganizationNameIsValid && newLogoImageIsValid;
            if (validForm) {
                const properties: UpdateOrganizationPropertiesType = { name: newOrganizationName };
                setIsSubmitting(true);
                updateProperties(properties);
                updateLogo(displayedFileName, displayedLogoImage);
            }
        }
    };

    const clearLogoFile = (): void => {
        setDisplayedFileName('');
        setDisplayedLogoImage('');
        setInvalidFileType(false);
        setImageSizeTooLarge(false);
    };

    const triggerFileUpload = (): void => {
        const fileLoader = document.getElementById('logoFileUploader');
        if (fileLoader) {
            fileLoader.click();
        }
    };

    let invalidFileMessage = '';
    if (invalidFileType) {
        invalidFileMessage = logoError
            ? txt(`ErrorCodes.${logoError.error}`)
            : txt('OrganizationProfile.InvalidOrganizationLogoFileType');
    } else if (imageSizeTooLarge)
        invalidFileMessage = txt('ImageTooLargeMaxSize', { size: `${imageMaxSize / 1000000}MB` });

    return (
        <div>
            <div className="form__field form__field--single-width settings__row">
                <Input
                    type="text"
                    id="organizationName"
                    label="OrganizationProfile.OrganizationName"
                    defaultValue={currentOrganizationName}
                    maxLength={255}
                    hint="OrganizationProfile.EnterOrganizationName"
                    onChange={handleOrganizationNameChange}
                    validate={!newOrganizationNameIsValid}
                    testId="organization-name"
                />
            </div>
            <div className="settings__row">
                <div
                    id="editImage"
                    className="form__attr form__attr--edit-image--logo form__attr--image form__attr--image--medium no-margin"
                    role="button"
                    tabIndex={0}
                    onClick={triggerFileUpload}
                    onKeyUp={(e): void => {
                        if (e.key === 'Enter') triggerFileUpload();
                    }}
                >
                    {displayedLogoImage ? (
                        <img src={displayedLogoImage} alt={txt('OrganizationProfile.LogoImage')} />
                    ) : (
                        <div className="form__attr--image__placeholder">
                            <MaterialIcon extraClass="building-tile__image__icon" name="photo" />
                            {txt('OrganizationProfile.UploadLogo')}
                            <br />
                            <br />
                            <span className="text-wrap">{txt('OrganizationProfile.LogoRecommendations')}</span>
                        </div>
                    )}
                </div>
                <div className="add-logo__container">
                    <input
                        type="file"
                        id="logoFileUploader"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        accept="image/png"
                    />
                    <div className="input-container">
                        <div className="add-logo__input-upload">
                            <CircleButton
                                onClick={triggerFileUpload}
                                testAttr="file-upload"
                                iconName="cloud_upload"
                                color="secondary"
                            />
                            <span className="add-logo__file-name">{displayedFileName || txt('NoFileChosen')}</span>
                            <button
                                className="add-logo__remove-upload"
                                type="button"
                                onClick={clearLogoFile}
                                data-testid="clear-logo"
                            >
                                <MaterialIcon name="close" />
                            </button>
                        </div>
                    </div>
                </div>
                {invalidFileMessage && <ResponseBox text={invalidFileMessage} />}
            </div>
            <div className="form__row">
                <div className="form__attr--element">
                    <PrimaryButton
                        id="cancel"
                        title="Cancel"
                        loading={false}
                        onClick={onClose}
                        testId="cancel-save-organization-name"
                    />
                </div>
                <div className="form__attr--element">
                    <PrimaryButton
                        id="submitOrganizationProfileButton"
                        title="SaveChanges"
                        type="submit"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                        filled
                        testId="save-organization-name"
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup },
        organizationProperties: { logoFileName, logoImage },
        requests: {
            [BusinessRequestType.UpdateOrganizationLogo]: { loading: logoLoading, error: logoError },
        },
    } = store;

    return {
        selectedGroup,
        logoFileName,
        logoImage,
        logoLoading,
        logoError,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateLogo: (fileName: string, logoImage: string): UpdateOrganizationLogoInit =>
        dispatch(updateOrganizationLogoInit(fileName, logoImage)),
    updateProperties: (properties: UpdateOrganizationPropertiesType): UpdateOrganizationPropertiesInit =>
        dispatch(updateOrganizationProperties(properties)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizationProfileComponent);
