import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusPill from 'commons/src/components/pills/StatusPill';
import SmartLinkIcon from 'commons/src/img/icon-smartlink';
import { PillStatus } from 'commons/src/models/commonEnums';
import DeviceIcon from '../../../../img/icon_device';
import { BuildingHealthStats } from '../../../../models/buildingHealthModels';
import styles from './BuildingHealtStatusSummaryBar.module.scss';

type HealthSummaryProps = {
    stats?: BuildingHealthStats;
    isHub: boolean;
    showOnlyValues?: boolean;
};

const HealthSummary = ({ stats, isHub, showOnlyValues }: HealthSummaryProps): React.ReactElement => {
    const { t: txt } = useTranslation();

    const renderDeviceInfo = (deviceInfo: Partial<BuildingHealthStats>): (React.ReactElement | null)[] =>
        Object.entries(deviceInfo).map(([propertyName, value]) =>
            value ? (
                <StatusPill
                    status={PillStatus.neutral}
                    key={propertyName}
                    data-testid={propertyName}
                    text={txt(`BuildingHealth.${propertyName}`, { value })}
                />
            ) : null
        );

    const icon = isHub ? <SmartLinkIcon /> : <DeviceIcon />;
    const text = isHub ? 'BuildingHealth.TotalHubs' : 'BuildingHealth.TotalDevices';
    const displayText = showOnlyValues ? stats?.total : txt(text, { value: stats?.total });

    return (
        <div className={styles.statusBarDeviceInfo} data-testid="hubs">
            <div className={styles.statusBarTotalInfo}>
                {icon}
                {stats ? displayText : 'N/A'}
            </div>
            {renderDeviceInfo({
                offline: stats?.offline,
                poorConnection: stats?.poorConnection,
                lowBattery: stats?.lowBattery,
            })}
        </div>
    );
};

export default HealthSummary;
