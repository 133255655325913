import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dateFormats } from '../../../constants';

export type Props = {
    type: string;
    locationName?: string;
    name: string;
    showLocation?: boolean;
    serialNumber: string;
    segmentStart: string;
    segmentEnd: string;
    dateFormat: keyof typeof dateFormats;
    segmentId: string;
    isLastRow: boolean;
};

const EndedMeasurementsTile = ({
    name,
    segmentEnd,
    segmentStart,
    locationName,
    type,
    showLocation,
    serialNumber,
    dateFormat,
    segmentId,
    isLastRow,
}: Props): React.ReactElement => {
    const navigate = useNavigate();
    const onClick = (): void => {
        navigate(`/devices/${serialNumber}/${segmentId}`);
    };
    const onKeyUp = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (e.key === 'Enter') {
            onClick();
        }
    };

    const { t: txt } = useTranslation();
    return (
        <tr
            onClick={onClick}
            tabIndex={0}
            onKeyUp={onKeyUp}
            className={classNames('table__row', 'table__row--rescale-when-small', { 'table__row--last': isLastRow })}
        >
            <td className="table__column">
                <span className="table__column__content">{name}</span>
            </td>
            {showLocation && (
                <td className="table__column table__column--hidden-when-small">
                    <span className="table__column__content">{locationName}</span>
                </td>
            )}
            <td className="table__column">
                {moment.utc(segmentStart).local().format(dateFormats[dateFormat].shortFormat)}
                {' - '}
                {moment.utc(segmentEnd).local().format(dateFormats[dateFormat].shortFormat)}
            </td>
            <td className="table__column table__column--last">
                <div className="tile-header__info--chip">{txt(type)}</div>
            </td>
        </tr>
    );
};
EndedMeasurementsTile.defaultProps = {
    locationName: undefined,
    showLocation: undefined,
};

export default EndedMeasurementsTile;
