import React, { ReactElement, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_VIEWED_SPACES } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { fullWidthListPlaceholder } from 'commons/src/components/placeholder';
import { roleRestrictions } from 'commons/src/constants';
import { PlacementType } from 'commons/src/models/commonEnums';
import { fetchSpaces } from '../../../actions/spaceActions';
import { OutdoorSpace, IndoorSpace } from '../../../models/spaceModels';
import { Store } from '../../../reducers';
import SpaceAddModal from '../addEdit/SpaceAddModal';
import { spacesSelector } from '../space/spaceSelectors';
import SpacesEmpty from './SpacesEmpty';
import SpacesOutdoorElement from './SpacesOutdoorElement';
import styles from './SpacesRoute.module.scss';
import SpacesView from './SpacesView';

const SpacesRoute = (): ReactElement => {
    const { buildingId = '' } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRole = useSelector((store: Store) => store.userSettings.selectedGroup?.role);
    const [addSpaceOpen, setAddSpaceOpen] = useState(false);

    const {
        spaces: { spaces, availableSensors },
        request,
    } = useSelector((store: Store) => spacesSelector(store, buildingId));

    const spacePropsLoading = useSelector((store: Store) => Object.keys(store.config.spacePropDefs).length === 0);
    const spacesByPlacement = groupBy(spaces, 'placement');
    const outdoorSpaces: OutdoorSpace[] = spacesByPlacement[PlacementType.OUTDOOR] as OutdoorSpace[];
    const roomSpaces: IndoorSpace[] = (spacesByPlacement[PlacementType.ROOM] || []) as IndoorSpace[];

    const isLoading = request.loading && spaces.length === 0;

    useEffect(() => {
        dispatch(fetchSpaces(buildingId));
        analyticsLogger(BUILDING_VIEWED_SPACES, { buildingId });
    }, [buildingId]);

    const redirectToFloorPlans = (): void => {
        navigate(`/buildings/${buildingId}/insights?subPage=floorPlan`);
    };

    return (
        <ReactPlaceholder ready={!isLoading && !spacePropsLoading} customPlaceholder={fullWidthListPlaceholder}>
            <div className="container" data-building-no-spaces-page>
                <div className={styles.linkToFloorPlan}>
                    <SpacesOutdoorElement space={outdoorSpaces ? outdoorSpaces[0] : undefined} />
                    <PrimaryButton
                        onClick={redirectToFloorPlans}
                        small
                        title="Space.GoToFloorPlan"
                        trailingIcon={<MaterialIcon name="map" />}
                    />
                </div>
                <div className="page-section--full-width">
                    {roomSpaces.length === 0 ? (
                        <SpacesEmpty
                            userRole={userRole}
                            requiredRoleLevel={roleRestrictions.editDeviceOrBuilding}
                            onClick={(): void => setAddSpaceOpen(true)}
                        />
                    ) : (
                        <SpacesView
                            spaces={roomSpaces}
                            addSpace={(): void => setAddSpaceOpen(true)}
                            availableSensors={availableSensors}
                            buildingId={buildingId}
                        />
                    )}
                </div>
                {buildingId && (
                    <SpaceAddModal
                        isOpen={addSpaceOpen}
                        onClose={(): void => setAddSpaceOpen(false)}
                        locationId={buildingId}
                    />
                )}
            </div>
        </ReactPlaceholder>
    );
};

export default SpacesRoute;
