import React, { SyntheticEvent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { analyticsLogger, PageType } from '../../analytics';
import { DASHBOARD_CLICKED_TILE } from '../../analytics/AnalyticsEvents';
import { getValidLanguageTagForMoment } from '../../commonFunctions';
import SensorValueLarge from '../../components/sensors/SensorValueLarge';
import NoDataTileBody from '../../components/tile/NoDataTileBody';
import { DashboardVisibility } from '../../models/commonEnums';
import { SensorValueTileContent } from '../../models/commonTypeScript';
import TileHeaderMenu from './TileHeaderMenu';

export type Props = {
    visibility?: DashboardVisibility;
    url: string;
    menuProps?: { tileId: string; tileRef: string; removeTile: (tileId: string, tileRef: string) => void };
    isDragging?: boolean;
    tile: SensorValueTileContent;
};

export const SensorValueTileComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { url, menuProps, isDragging, tile, visibility } = props;
    const { serialNumber, currentSensorValue, segmentName, locationName, latestSample } = tile;

    let link = url.slice(-1) === '/' ? url.slice(0, -1) : url;
    link = `${link}/${serialNumber}`;

    const preventLink = (e: SyntheticEvent<HTMLElement>): void => {
        if (isDragging) {
            e.preventDefault();
        } else {
            analyticsLogger(DASHBOARD_CLICKED_TILE, { pageType: PageType.Dashboard, tileType: 'SensorValue' });
        }
    };

    let tileBody;
    if (!currentSensorValue) {
        tileBody = <NoDataTileBody message="NotEnoughData" />;
    } else {
        const { value, type, unit } = currentSensorValue;

        tileBody = (
            <SensorValueLarge
                sensorValue={value}
                sensor={type}
                unit={unit}
                key={`sensor-component-${serialNumber}-${type}`}
            />
        );
    }
    const language = getValidLanguageTagForMoment();
    const lastSynced = latestSample ? moment.utc(latestSample).local().locale(language).fromNow() : txt('neverSynced');

    return (
        <NavLink to={link} onClick={preventLink} className="tile-wrapper__link page-wrapper__column" data-tile>
            <div className="tile-wrapper">
                <div className="tile-header">
                    <div className="tile-header__title">
                        <h2 className="tile-header__title--name">{segmentName}</h2>
                        <span className="tile-header__title--address">{locationName}</span>
                        <div className="tile-header__last-synced">{[txt('LastSynced'), ' ', lastSynced]}</div>
                    </div>
                    {menuProps && (
                        <div className="tile-header__controls">
                            <TileHeaderMenu
                                visibility={visibility}
                                tileId={menuProps.tileId}
                                onRemoveTile={(): void => menuProps.removeTile(menuProps.tileId, menuProps.tileRef)}
                                isSensorTile={false}
                            />
                        </div>
                    )}
                </div>
                <div className="tile-body">{tileBody}</div>
            </div>
        </NavLink>
    );
};
SensorValueTileComponent.defaultProps = {
    menuProps: undefined,
    isDragging: false,
    visibility: undefined,
};

export default SensorValueTileComponent;
