import React, { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsLogger } from 'commons/src/analytics';
import { BUILDING_FILTERED_SENSORS_IN_SPACE, BUILDING_GROUPED_SPACES } from 'commons/src/analytics/AnalyticsEvents';
import CheckBox from 'commons/src/components/input/Checkbox';
import RadioButtons from 'commons/src/components/input/Radio';
import ModalWrapper from 'commons/src/components/modals/ModalWrapper';
import { SensorTypes } from 'commons/src/models/commonEnums';
import { spacesGroupTypesOptions } from '../../../constants';
import styles from './SpacesTuneModal.module.scss';

export type Props = {
    onClose: () => void;
    availableSensors: SensorTypes[];
    selectedSensors: SensorTypes[];
    setSelectedSensors: (sensors: SensorTypes[]) => void;
    groupedBy: string;
    setSelectedGrouping: (groupedBy: string) => void;
    buildingId: string;
};

const SpacesTuneModal = ({
    onClose,
    availableSensors,
    selectedSensors,
    setSelectedSensors,
    groupedBy,
    setSelectedGrouping,
    buildingId,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();

    const onSensorSelect = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { checked, id } = e.currentTarget;
        if (checked) {
            setSelectedSensors(
                [...selectedSensors, id as SensorTypes].sort(
                    (a, b) => availableSensors.indexOf(a) - availableSensors.indexOf(b)
                )
            );
        } else setSelectedSensors(selectedSensors.filter(sensor => sensor !== id));
        analyticsLogger(BUILDING_FILTERED_SENSORS_IN_SPACE, {
            selectedSensors,
            buildingId,
        });
    };

    const onGroupingSelect = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { value } = e.currentTarget;
        setSelectedGrouping(value);
        analyticsLogger(BUILDING_GROUPED_SPACES, {
            groupedBy: value,
            buildingId,
        });
    };

    return (
        <ModalWrapper isOpen onClose={onClose} size="small" header="">
            <div className={styles.body}>
                <div>
                    <div className={styles.grouping}>
                        <RadioButtons
                            row={false}
                            buttons={spacesGroupTypesOptions.map(option => ({
                                value: option,
                                label: txt(`PredefinedProperties.${option}`),
                            }))}
                            onChange={onGroupingSelect}
                            labelId="group-spaces"
                            selectorName="group-spaces"
                            value={groupedBy}
                            header={txt('Space.SpaceTuneGroupBy')}
                        />
                    </div>
                </div>
                <div>
                    <h1 className={styles.header}>{txt('Space.SpaceTuneSensors', { max: 5 })}</h1>
                    <div className={styles.sensors}>
                        {availableSensors.map(sensor => (
                            <div className={styles.sensor} key={sensor}>
                                <CheckBox
                                    label={txt(sensor)}
                                    id={sensor}
                                    disabled={selectedSensors.length === 5 && !selectedSensors.includes(sensor)}
                                    onChange={onSensorSelect}
                                    checked={selectedSensors.includes(sensor)}
                                    testId={`checkbox-${sensor}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default SpacesTuneModal;
