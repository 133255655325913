import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown, { Option } from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import { PlacementType } from 'commons/src/models/commonEnums';
import { fetchSpaces } from '../../../../actions/spaceActions';
import { MAX_DEVICES_PER_SPACE } from '../../../../constants';
import { IndoorSpace } from '../../../../models/spaceModels';
import { Store } from '../../../../reducers';
import { spacesSelector } from '../spaceSelectors';
import { ModalState } from './moveSpaceCommons';
import styles from './SpaceMoveDeviceStep1.module.scss';

type MoveDeviceModalProps = {
    closeModal: () => void;
    locationId: string;
    space: IndoorSpace;
    deviceName: string;
    setDeviceName: (name: string) => void;
    setModalState: (state: ModalState) => void;
    selectedBuilding?: Option;
    setSelectedBuilding: (option: Option | undefined) => void;
    selectedSpace?: Option;
    setSelectedSpace: (option: Option | undefined) => void;
};

const SpaceMoveDeviceModalStep1 = ({
    closeModal,
    space,
    locationId,
    setModalState,
    deviceName,
    setDeviceName,
    selectedBuilding,
    setSelectedBuilding,
    selectedSpace,
    setSelectedSpace,
}: MoveDeviceModalProps): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const { locations, loading: loadingLocations } = useSelector((state: Store) => state.locations);
    const {
        spaces: { spaces },
        request: spacesRequest,
    } = useSelector((state: Store) => spacesSelector(state, selectedBuilding?.id ?? locationId));

    const locationOptions: Option[] = useMemo(
        (): Option[] => locations.map(location => ({ id: location.id, inputValue: location.name })),
        [locations]
    );

    useEffect(() => {
        const defaultLocation: Option | undefined =
            locationOptions.find(location => location.id === locationId) ?? undefined;
        setSelectedBuilding(defaultLocation);
    }, [locationId, locationOptions]);

    const spaceOptions: Option[] = useMemo((): Option[] => {
        const indoorSpaces: IndoorSpace[] = spaces
            .filter(it => it.placement === PlacementType.ROOM)
            .map(it => it as IndoorSpace);
        return indoorSpaces
            .filter(it => it.id !== space.id && it.devices.length < MAX_DEVICES_PER_SPACE)
            .map(it => ({ id: it.id, inputValue: it.name }));
    }, [spaces]);

    const selectLocation = (location: Option): void => {
        if (location.id !== locationId) {
            setSelectedBuilding(location);
            setSelectedSpace(undefined);
            dispatch(fetchSpaces(location.id));
        }
    };

    const selectSpace = (spaceOption: Option): void => {
        setSelectedSpace(spaceOption);
        setDeviceName(spaceOption.inputValue);
    };

    const updateName = (event: SyntheticEvent<HTMLInputElement>): void => {
        setDeviceName(event.currentTarget.value);
    };

    const validDeviceName: boolean = deviceName.trim().length > 0;
    return (
        <>
            <div className={styles.form}>
                <h2 className={styles.header}>{txt('MoveSpaceDevice.MoveToNewSpace')}</h2>
                <div className={styles.dropdownRow}>
                    <div className={styles.input}>
                        <Dropdown
                            id="locationSelector"
                            title="Building.Building"
                            options={locationOptions}
                            defaultOption="Select"
                            value={selectedBuilding?.inputValue}
                            onSelect={selectLocation}
                            testAttr="location-dropdown"
                            loading={loadingLocations}
                            noBottomMargin
                        />
                    </div>
                    <div className={styles.input}>
                        <Dropdown
                            id="spaceSelector"
                            title="Space.Space"
                            options={spaceOptions}
                            defaultOption="Select"
                            value={selectedSpace?.inputValue}
                            onSelect={selectSpace}
                            testAttr="space-dropdown"
                            loading={spacesRequest.loading}
                            noBottomMargin
                        />
                    </div>
                </div>
                {selectedSpace && (
                    <div className={styles.input}>
                        <Input
                            required
                            markedMandatory
                            validate={!validDeviceName}
                            isValid={validDeviceName}
                            type="text"
                            id="spaceName"
                            label="MoveSpaceDevice.NewDeviceName"
                            currentValue={deviceName}
                            maxLength={50}
                            onBlur={updateName}
                            onChange={updateName}
                        />
                    </div>
                )}
            </div>
            <div className={styles.buttons}>
                <PrimaryButton onClick={closeModal} title="Cancel" />
                <PrimaryButton
                    onClick={(): void => setModalState(ModalState.STEP2)}
                    title="Continue"
                    filled
                    disabled={!selectedBuilding || !selectedSpace || !validDeviceName}
                />
            </div>
        </>
    );
};

export default SpaceMoveDeviceModalStep1;
