import { RequestActionType } from '../actions/requestActions';
import { ErrorType } from '../models/commonTypeScript';

export interface RequestState {
    loading: boolean;
    loadingId?: string;
    error?: ErrorType;
    streaming?: boolean;
}

const initialStateObject: RequestState = {
    loading: false,
    error: undefined,
    streaming: false,
};

export enum BusinessRequestTypesUsedInCommons {
    AddLocation = 'ADD_LOCATION',
    DeleteLocation = 'DELETE_LOCATION',
}

export enum CommonRequestType {
    AddDevice = 'ADD_DEVICE',
    AddPublicDashboard = 'ADD_PUBLIC_DASHBOARD',
    DeleteDevice = 'DELETE_DEVICE',
    DeleteEndedSegment = 'DELETE_ENDED_SEGMENT',
    DeletePublicDashboard = 'DELETE_PUBLIC_DASHBOARD',
    EditPublicCDashboardSettings = 'EDIT_PUBLIC_DASHBOARD_SETTINGS',
    FetchCustomSegmentVirtualSensors = 'FETCH_CUSTOM_SEGMENT_VIRTUAL_SENSORS',
    FetchDevice = 'FETCH_DEVICE',
    FetchDeviceConfig = 'FETCH_DEVICE_CONFIG',
    FetchHub = 'FETCH_HUB',
    FetchLocationsHistory = 'FETCH_LOCATIONS_HISTORY',
    FetchOutdoorAirInfo = 'FETCH_OUTDOOR_AIR_INFO',
    FetchDeviceDetails = 'FETCH_DEVICE_DETAILS',
    GetSubscriptionForGroup = 'GET_SUBSCRIPTION_FOR_GROUP',
    PollVirtualDeviceSensorData = 'POLL_VIRTUAL_DEVICE_SENSOR_DATA',
    SelectDashboard = 'SELECT_DASHBOARD',
    UpdateDeviceConfig = 'UPDATE_DEVICE_CONFIG',
    ValidateLocationRfRegion = 'VALIDATE_LOCATION_RF_REGION',
    FetchLocationsStatus = 'FETCH_LOCATIONS_STATUS',
    LookupDomain = 'LOOKUP_DOMAIN',
    // API:
    AddApiClient = 'ADD_API_CLIENT',
    DeleteApiClient = 'DELETE_API_CLIENT',
    FetchApiClients = 'FETCH_API_CLIENTS',
    FetchApiClientSecret = 'FETCH_API_CLIENT_SECRET',
    UpdateApiClient = 'UPDATE_API_CLIENT',
    UpdateApiClientActiveState = 'UPDATE_API_CLIENT_ACTIVE_STATE',
    CreatePropertyDefinition = 'CREATE_PROPERTY_DEFINITION',
    DeletePropertyDefinition = 'DELETE_PROPERTY_DEFINITION',
    UpdatePropertyDefinition = 'UPDATE_PROPERTY_DEFINITION',
    CreatePropertyValue = 'CREATE_PROPERTY_VALUE',
    DeletePropertyValue = 'DELETE_PROPERTY_VALUE',
    UpdatePropertyValue = 'UPDATE_PROPERTY_VALUE',
    RenameSegment = 'RENAME_SEGMENT',
    UpdateUserProfile = 'UPDATE_USER_PROFILE',
    UpdateUserPreferences = 'UPDATE_USER_PREFERENCES',
}

export interface CommonActionRequestType {
    requestActionType: RequestActionType;
    requestType: CommonRequestType;
}

export type CommonsRequestStore = {
    [K in CommonRequestType]: RequestState;
};

export const initialStore: CommonsRequestStore = {
    [CommonRequestType.AddDevice]: initialStateObject,
    [CommonRequestType.AddPublicDashboard]: initialStateObject,
    [CommonRequestType.DeleteEndedSegment]: initialStateObject,
    [CommonRequestType.DeleteDevice]: initialStateObject,
    [CommonRequestType.DeletePublicDashboard]: initialStateObject,
    [CommonRequestType.EditPublicCDashboardSettings]: initialStateObject,
    [CommonRequestType.FetchCustomSegmentVirtualSensors]: initialStateObject,
    [CommonRequestType.FetchDevice]: initialStateObject,
    [CommonRequestType.FetchHub]: initialStateObject,
    [CommonRequestType.FetchDeviceConfig]: initialStateObject,
    [CommonRequestType.FetchLocationsHistory]: initialStateObject,
    [CommonRequestType.FetchOutdoorAirInfo]: initialStateObject,
    [CommonRequestType.FetchDeviceDetails]: initialStateObject,
    [CommonRequestType.GetSubscriptionForGroup]: initialStateObject,
    [CommonRequestType.PollVirtualDeviceSensorData]: initialStateObject,
    [CommonRequestType.SelectDashboard]: initialStateObject,
    [CommonRequestType.UpdateDeviceConfig]: initialStateObject,
    [CommonRequestType.ValidateLocationRfRegion]: initialStateObject,
    [CommonRequestType.FetchLocationsStatus]: initialStateObject,
    [CommonRequestType.LookupDomain]: initialStateObject,
    // API:
    [CommonRequestType.AddApiClient]: initialStateObject,
    [CommonRequestType.FetchApiClients]: initialStateObject,
    [CommonRequestType.FetchApiClientSecret]: initialStateObject,
    [CommonRequestType.UpdateApiClient]: initialStateObject,
    [CommonRequestType.UpdateApiClientActiveState]: initialStateObject,
    [CommonRequestType.DeleteApiClient]: initialStateObject,
    [CommonRequestType.RenameSegment]: initialStateObject,
    [CommonRequestType.CreatePropertyDefinition]: initialStateObject,
    [CommonRequestType.CreatePropertyValue]: initialStateObject,
    [CommonRequestType.DeletePropertyValue]: initialStateObject,
    [CommonRequestType.UpdatePropertyValue]: initialStateObject,
    [CommonRequestType.DeletePropertyDefinition]: initialStateObject,
    [CommonRequestType.UpdatePropertyDefinition]: initialStateObject,
    [CommonRequestType.UpdateUserPreferences]: initialStateObject,
    [CommonRequestType.UpdateUserProfile]: initialStateObject,
};

const requestReducer = (store: RequestState, action: RequestActionBase): RequestState => {
    switch (action.requestActionType) {
        case RequestActionType.Init:
            return {
                ...store,
                loading: true,
                loadingId: action.loadingId,
                error: undefined,
                streaming: false,
            };
        case RequestActionType.Success:
            return {
                ...store,
                loading: false,
                loadingId: undefined,
                error: undefined,
                streaming: false,
            };
        case RequestActionType.Error:
            return {
                ...store,
                loading: false,
                error: action.error,
                streaming: false,
            };
        case RequestActionType.Streaming:
            return {
                ...store,
                loading: false,
                streaming: true,
            };
        case RequestActionType.ClearError:
            return {
                ...store,
                loading: false,
                loadingId: undefined,
                error: undefined,
                streaming: false,
            };
        default:
            return {
                ...store,
            };
    }
};

interface RequestActionBase {
    requestType: string;
    requestActionType: string;
    error?: ErrorType;
    loadingId?: string;
}

export const reducer =
    <RequestStore extends Record<string, RequestState>, RequestAction extends RequestActionBase>(
        initStore: RequestStore
    ) =>
    (store: RequestStore = initStore, action: RequestAction): RequestStore => {
        if (!(action.requestType in initStore)) return store;

        return {
            ...store,
            [action.requestType]: {
                ...requestReducer(store[action.requestType], action),
            },
        };
    };

export default reducer(initialStore);
