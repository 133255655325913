import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import spinner from '../../img/spinner';
import styles from './PrimaryButton.module.scss';

type Props = {
    title?: string;
    type?: 'button' | 'submit';
    alert?: boolean;
    filled?: boolean;
    autoFocus?: boolean;
    onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void | Promise<void>;
    id?: string;
    icon?: React.ReactElement;
    trailingIcon?: React.ReactElement;
    disabled?: boolean;
    loading?: boolean;
    testAttr?: string;
    testId?: string;
    small?: boolean;
    translate?: boolean;
    noBackground?: boolean;
    fullWidth?: boolean;
};

const PrimaryButton = ({
    type,
    title,
    alert,
    filled,
    disabled = false,
    onClick,
    id,
    autoFocus,
    loading,
    testAttr,
    testId,
    small,
    icon,
    trailingIcon,
    translate = true,
    noBackground,
    fullWidth,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type === undefined ? 'button' : type}
            disabled={disabled}
            id={id}
            className={classNames(styles.button, {
                [styles.small]: small,
                [styles.filled]: filled,
                [styles.alert]: alert,
                [styles.disabled]: disabled && !loading,
                [styles.iconButton]: icon && !title && !small,
                [styles.smallIcon]: icon && !title && small,
                [styles.noBackground]: noBackground,
                [styles.fullWidth]: fullWidth,
            })}
            onClick={(e): void => {
                if (!disabled) onClick(e);
            }}
            ref={(button): void => {
                if (autoFocus && button) button.focus();
            }}
            {...{ [`data-${testAttr || 'button'}`]: true }}
            {...{ 'data-testid': testId }}
        >
            {!loading && icon}
            {loading && spinner}
            {!loading && title && <div className={styles.title}>{translate ? txt(title) : title}</div>}
            {!loading && trailingIcon}
        </button>
    );
};

export default PrimaryButton;
