import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TertiaryButton from 'commons/src/components/buttons/TertiaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { SensorTypes } from 'commons/src/models/commonEnums';
import styles from './SpacesHeader.module.scss';
import SpacesTuneModal from './SpacesTuneModal';

export type Props = {
    groupedBy: string;
    availableSensors: SensorTypes[];
    selectedSensors: SensorTypes[];
    setSelectedSensors: (sensors: SensorTypes[]) => void;
    setSelectedGrouping: (groupedBy: string) => void;
    buildingId: string;
};

const SpacesHeader = ({
    groupedBy,
    availableSensors,
    setSelectedSensors,
    selectedSensors,
    setSelectedGrouping,
    buildingId,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const [modalOpen, setIsModalOpen] = useState(false);

    return (
        <div className={styles.body}>
            {modalOpen && (
                <SpacesTuneModal
                    onClose={(): void => setIsModalOpen(false)}
                    availableSensors={availableSensors}
                    selectedSensors={selectedSensors}
                    setSelectedSensors={setSelectedSensors}
                    groupedBy={groupedBy}
                    setSelectedGrouping={setSelectedGrouping}
                    buildingId={buildingId}
                />
            )}
            <span className={styles.filterColumn}>{txt(`PredefinedProperties.${groupedBy}`)}</span>
            <div className={styles.sensorsWrapper}>
                <div className={styles.sensors}>
                    {selectedSensors.map(sensor => (
                        <div key={sensor} className={styles.sensorColumn}>
                            {txt(sensor)}
                        </div>
                    ))}
                </div>
                <div className={styles.tuneColumn}>
                    <TertiaryButton
                        onClick={(): void => setIsModalOpen(true)}
                        icon={<MaterialIcon name="tune" />}
                        noColor
                        testId="open-modal"
                    />
                </div>
            </div>
        </div>
    );
};

export default SpacesHeader;
