import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger, IntegrationType, PageType } from 'commons/src/analytics';
import { INTEGRATION_CLICKED_ADD, INTEGRATION_VIEWED_INTEGRATION } from 'commons/src/analytics/AnalyticsEvents';
import LinkButton from 'commons/src/components/buttons/LinkButton';
import Error from 'commons/src/components/errorComponents/Error';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userlane } from 'commons/src/components/placeholder';
import { linkToWebhooksDocs, roleRestrictions } from 'commons/src/constants';
import IntegrationRow from 'commons/src/features/integrations/IntegrationRow';
import { ButtonColor } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { UpdateWebhookActiveState, updateWebhookActiveState } from '../../../actions/integrationActions';
import { paths } from '../../../constants';
import { Webhook } from '../../../models/common';
import { Store } from '../../../reducers';

type StateProps = {
    webhooks: Webhook[];
    loading: boolean;
    error?: ErrorType;
    numberOfLocations: number;
    locationsLoading: boolean;
};

type ActionProps = {
    updateWebhookDetails: (webhook: Webhook) => void;
};

type Props = StateProps & ActionProps;

const analyticsObject = {
    pageType: PageType.Integration,
    page: IntegrationType.Webhooks,
};

export const WebhooksPageComponent = ({
    updateWebhookDetails,
    numberOfLocations,
    webhooks,
    loading,
    locationsLoading,
    error,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => analyticsLogger(INTEGRATION_VIEWED_INTEGRATION, analyticsObject));

    if (error) {
        return <Error />;
    }

    const clickAddWebhook = (): void => {
        navigate({ pathname: `/${paths.addWebhook}` });
        analyticsLogger(INTEGRATION_CLICKED_ADD, analyticsObject);
    };

    const editIntegration = (id: string): void => {
        navigate({ pathname: `/${paths.webhooks}/${id}` });
    };

    const hooksRows = (): React.ReactElement[] | false =>
        webhooks.length > 0 &&
        webhooks.map(hook => {
            const status = hook.mostRecentEvent && hook.mostRecentEvent.statusCode;
            let statusColor = 'red';
            if (!status || !hook.active) {
                statusColor = 'grey';
            } else if ([200, 204].includes(status)) {
                statusColor = 'green';
            }

            const update = (isOn: boolean): void => {
                updateWebhookDetails({ ...hook, active: isOn });
            };

            return (
                <IntegrationRow
                    key={hook.id}
                    name={hook.name}
                    firstRow={hook.url}
                    id={hook.id}
                    active={hook.active}
                    statusColor={statusColor}
                    updateIntegration={update}
                    onClick={editIntegration}
                />
            );
        });

    const actionButtons: ActionButton[] = [
        {
            onClick: clickAddWebhook,
            title: 'Webhooks.NewWebhook',
            id: 'AddWebhook',
            color: ButtonColor.primary,
            testAttr: 'add-webhook',
            icon: <MaterialIcon name="add" />,
            requiredRoleLevel: roleRestrictions.webhooks,
            requiredGroupTypes: [],
        },
    ];

    return (
        <div>
            <SubHeader actionButtons={numberOfLocations > 0 ? actionButtons : []} />
            <h2 className="settings__header settings__header--no-margin-top">{txt('Webhooks.Webhooks')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                <div>{txt('Webhooks.WebhookDescription')}</div>
                <div>
                    <LinkButton link={linkToWebhooksDocs} title="Webhooks.LinkToDocs" />
                </div>
                {!locationsLoading && numberOfLocations === 0 && <div>{txt('Webhooks.AddWebhookDisabledText')}</div>}
            </div>
            <ul className="list">
                <ReactPlaceHolder ready={!(loading && webhooks.length === 0)} customPlaceholder={userlane}>
                    {hooksRows()}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        webhooks: { webhooks },
        requests: {
            FETCH_WEBHOOKS: { loading, error },
        },
        locations: { locations, loading: locationsLoading },
    } = state;
    return {
        webhooks,
        loading,
        error,
        numberOfLocations: locations.length,
        locationsLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateWebhookDetails: (webhook: Webhook): UpdateWebhookActiveState => dispatch(updateWebhookActiveState(webhook)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksPageComponent);
