import { businessPaths, helpArticleAfBMainLink } from 'commons/src/constants';
import { DeviceTypeNames, PredefinedProperty, SensorTypes } from 'commons/src/models/commonEnums';
import { AnyDeviceType } from 'commons/src/models/commonTypeScript';
import { FdvHusetLogo, FdvWebLogo, LaftLogo, ProperlyLogo, SlackLogo } from './img';
import { IntegrationTypes } from './models/common';
import { IntercomArticleId } from './models/commonEnums';

export const paths = businessPaths;

export type Path = keyof typeof paths;
export const A4BSupportMail = 'business-support@airthings.com';
export const subscriptionTermsAndConditions = 'https://www.airthings.com/en/business/terms-and-conditions';

export default paths;

/**
 * Replaced with availableSpaceRoomTypes due to capitalization of enum types. Only to be used in segment properties.
 */
export enum availableRoomTypes {
    openPlanOffice = 'OpenPlanOffice',
    bathroom = 'Bathroom',
    reception = 'Reception',
    storage = 'Storage',
    kitchen = 'Kitchen',
    meetingRoom = 'MeetingRoom',
    classroom = 'Classroom',
    other = 'Other',
}

export enum availableSpaceRoomTypes {
    openPlanOffice = 'openPlanOffice',
    bathroom = 'bathroom',
    reception = 'reception',
    storage = 'storage',
    kitchen = 'kitchen',
    meetingRoom = 'meetingRoom',
    classroom = 'classroom',
    other = 'other',
}

export enum grantType {
    oauthClientCredentials = 'OAUTH_CLIENT_CREDENTIALS',
    oauthAuthorizationCode = 'OAUTH_AUTHORIZATION_CODE',
    accessToken = 'ACCESS_TOKEN',
    none = 'NONE',
}

export const thirdPartyChannels = {
    SLACK: 'SLACK',
    FDV_HUSET: 'FDV_HUSET',
    LAFT: 'LAFT',
    FDV_WEB: 'FDV_WEB',
    EMAIL: 'EMAIL',
    EIENDOMSAPPEN: 'EIENDOMSAPPEN',
};

export const getBusinessIntercomArticleLink = (articleId: IntercomArticleId): string =>
    `${helpArticleAfBMainLink}/${articleId}`;

export const showMoldToggle = (deviceType: AnyDeviceType): boolean => deviceType === DeviceTypeNames.mini;
export const showVirusRiskToggle = (deviceType: AnyDeviceType): boolean => {
    const virusRiskDeviceTypes: AnyDeviceType[] = [
        DeviceTypeNames.wavePlus,
        DeviceTypeNames.waveCo2,
        DeviceTypeNames.viewPlus,
        DeviceTypeNames.viewPlusBusiness,
        DeviceTypeNames.viewCo2,
        DeviceTypeNames.spaceCo2Mini,
    ];
    return virusRiskDeviceTypes.includes(deviceType);
};
export const showPubliclyAvailableToggle = (deviceType: AnyDeviceType): boolean => deviceType !== undefined;

export const deviceHasFeaturesInSettings = (deviceType: AnyDeviceType): boolean => {
    return showMoldToggle(deviceType) || showPubliclyAvailableToggle(deviceType) || showVirusRiskToggle(deviceType);
};

export const integrationTypeLogos = (type: string): string => {
    const logo: { [type: string]: string } = {
        LAFT: LaftLogo,
        FDV_WEB: FdvWebLogo,
        FDV_HUSET: FdvHusetLogo,
        SLACK: SlackLogo,
        EIENDOMSAPPEN: ProperlyLogo,
    };
    return logo[type];
};

export const integrationTypes: IntegrationTypes = {
    SLACK: {
        authorizationMethods: [grantType.oauthAuthorizationCode],
        authUrl: 'https://slack.com/oauth/v2/authorize',
        scope: 'incoming-webhook',
    },
    EIENDOMSAPPEN: {
        authorizationMethods: [grantType.oauthClientCredentials],
    },
    LAFT: {
        authorizationMethods: [grantType.oauthAuthorizationCode],
        authUrl: 'https://kommune.laft.io/api/v1/oauth/authorize',
        scope: 'get_buildings create_work_order',
    },
    FDV_HUSET: {
        authorizationMethods: [grantType.accessToken],
        supportEmail: 'support@fdvhuset.no',
    },
    FDV_WEB: {
        authorizationMethods: [grantType.oauthClientCredentials],
        supportEmail: 'support@curotech.no',
    },
};

export const alertRuleThresholdCategories = {
    allAbove: 'ALL_ABOVE',
    allBelow: 'ALL_BELOW',
};

export const thresholdDurationLimits = {
    hourMin: 0,
    hourMax: 24,
    minuteMin: 5,
    minuteMax: 59,
    durationDefault: '00:05',
};

export const alertsTimeConditions = {
    INSIDE_USAGE_HOURS: 'INSIDE_USAGE_HOURS',
    OUTSIDE_USAGE_HOURS: 'OUTSIDE_USAGE_HOURS',
    ALWAYS: 'ALWAYS',
};

export enum alertSystemNotificationRules {
    deviceOffline = 'deviceOffline',
    lowBattery = 'lowBattery',
}

export enum FloorRange {
    low = -15,
    high = 200,
}

export const alertSensors = [
    SensorTypes.voc,
    SensorTypes.co2,
    SensorTypes.humidity,
    SensorTypes.temp,
    SensorTypes.pressure,
    SensorTypes.pm25,
];

export const alertInsightSensors: SensorTypes[] = [SensorTypes.virusRisk, SensorTypes.mold];

export const mqttClientNameValidation = '[a-zA-Z0-9_.,@/:#-]';
export const integrationsMqtt = 'integrationsMqtt';

export const BUILDING_HEALTH_TABLE_LENGTH = 100;
export const ORGANIZATION_HEALTH_TABLE_LENGTH = 40;

export const MAX_DEVICES_PER_SPACE = 3;

export const spacesGroupTypesOptions: PredefinedProperty[] = [PredefinedProperty.Floor, PredefinedProperty.RoomType];
