import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import styles from './DesktopMaterialTable.module.scss';
import convertRowTypeToText from './helpers/cells';
import useMaterialTableContext from './hooks/useMaterialTableContext';

const MaterialTableData = (): React.ReactElement => {
    const { paginatedData, data, columns, idKey } = useMaterialTableContext();

    return (
        <tbody className={styles.scrollBody}>
            {paginatedData.map((rowData, rowIndex) => (
                <TableRow hover key={rowData[idKey] as string} className={styles.tableRow}>
                    {columns.map(({ accessor, width, renderer, locked }, columnIndex) => {
                        return (
                            <TableCell
                                key={accessor}
                                width={width}
                                data-sticky={locked}
                                className={classNames(styles.tableCell, styles.dataCell, { [styles.sticky]: locked })}
                            >
                                {renderer
                                    ? renderer({
                                          accessor,
                                          rowData,
                                          allRows: data,
                                          rowIndex: rowIndex + 1,
                                          columnIndex: columnIndex + 1,
                                      })
                                    : convertRowTypeToText(rowData[accessor])}
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))}
        </tbody>
    );
};

export default MaterialTableData;
