// radon home report options
export const roomTypeOptions = ['Bedroom', 'LivingRoom', 'Hall', 'Kitchen', 'Attic', 'Office', 'Other'];

export const ventilationTypeOptions = ['Natural', 'Mechanical', 'Balanced'];

export const floorOptions = ['Basement', 'FirstFloor', 'SecondFloor', 'ThirdFloor', 'FourthOrHigherFloor'];

export const buildingTypeOptions = ['House', 'Rental', 'School', 'Workplace', 'Other'];

export const businessBuildingTypes = [
    'School',
    'Residential',
    'Apartment',
    'Kindergarten',
    'HealthCenter',
    'Office',
    'Other',
];

const countries = new Map([
    ['AT', 'Austria'],
    ['BE', 'Belgium'],
    ['CA', 'Canada'],
    ['CZ', 'Czech Republic'],
    ['DK', 'Denmark'],
    ['FI', 'Finland'],
    ['FR', 'France'],
    ['DE', 'Germany'],
    ['GR', 'Greece'],
    ['IE', 'Ireland'],
    ['IT', 'Italy'],
    ['LU', 'Luxembourg'],
    ['MY', 'Malaysia'],
    ['NO', 'Norway'],
    ['PT', 'Portugal'],
    ['ES', 'Spain'],
    ['SI', 'Slovenia'],
    ['PL', 'Poland'],
    ['SE', 'Sweden'],
    ['CH', 'Switzerland'],
    ['US', 'United States'],
    ['GB', 'United Kingdom'],
]);

export const countryArray = Array.from(countries);
export const countryNames = Array.from(countries.values());
export const countryOptions = Array.from(countries.keys());
export const reportLanguageOptions = new Map([
    ['CA', ['en', 'fr']],
    ['NO', ['no', 'en']],
    ['DK', ['da', 'en']],
    ['SE', ['sv', 'en']],
    ['FR', ['fr', 'en']],
    ['DE', ['de', 'en']],
    ['CH', ['de', 'fr', 'en']],
]);
