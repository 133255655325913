import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { deleteDevice, DeleteDevice, FetchDeviceConfig, fetchDeviceConfig } from 'commons/src/actions/DeviceActions';
import { deviceIsView, mapDeviceType } from 'commons/src/commonFunctions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import DeviceInfo from 'commons/src/components/device/DeviceInfo';
import CollapsableSectionHeader from 'commons/src/components/headers/CollapsableSectionHeader';
import { mediumFormLoader } from 'commons/src/components/placeholder';
import { paths } from 'commons/src/constants';
import DeleteModal from 'commons/src/features/devicePage/dropdownOptions/DeleteModal';
import ViewPlusSettings from 'commons/src/features/devicePage/dropdownOptions/DisplaySettings';
import { DeviceTypeNames } from 'commons/src/models/commonEnums';
import { DeviceConfig, DeviceType } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { CommonRequestType as RequestType } from 'commons/src/reducers/requestReducer';
import BatterySettings from './BatterySettings';
import ChangeLocation from './ChangeLocation';

type StateProps = {
    loading: boolean;
    devices: { [serialNumber: string]: DeviceType };
    locationsLoading: boolean;
    demoMode: boolean;
    deviceConfigs: {
        [serialNumber: string]: DeviceConfig;
    };
};

type ActionProps = {
    fetchConfig: (serialNumber: string, segmentId: string) => void;
    onDeleteDevice: (serialNumber: string, locationId: string, isB2B: boolean) => void;
};

export type Props = StateProps & ActionProps;

export const DeviceSettingsComponent = ({
    loading,
    locationsLoading,
    devices,
    demoMode,
    fetchConfig,
    onDeleteDevice,
    deviceConfigs,
}: Props): React.ReactElement | null => {
    const { serialNumber } = useParams() as { serialNumber: string };
    const navigate = useNavigate();
    const hasBatterySettings = deviceConfigs[serialNumber] && deviceConfigs[serialNumber].batteryMode;
    const selectedDevice = devices[serialNumber];
    const [deviceDetailsOpen, setDeviceDetailsOpen] = useState(false);
    const [displaySettingsOpen, setDisplaySettingsOpen] = useState(false);
    const [batterySettingsOpen, setBatterySettingsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { t: txt } = useTranslation();

    useEffect((): void => {
        if (!locationsLoading) {
            fetchConfig(serialNumber, (selectedDevice && selectedDevice.segmentId) || 'latest');
        }
    }, [locationsLoading, fetchConfig, serialNumber, selectedDevice]);

    useEffect(() => {
        if (!loading && !selectedDevice) {
            navigate(`/${paths.devices}`);
        }
    }, [loading, selectedDevice]);

    const onDeleteModalOpenClose = (): void => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const clickDeleteDevice = (): void => {
        if (demoMode) return;
        onDeleteDevice(serialNumber, selectedDevice.locationId, false);
    };

    const deviceIsViewPlus = selectedDevice && deviceIsView(selectedDevice.type as DeviceTypeNames);

    return (
        <div className="flex-column">
            <div className="page-wrapper__medium">
                <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                    {isDeleteModalOpen && (
                        <DeleteModal
                            isB2B={false}
                            isEndedSegment={false}
                            onDeleteDevice={clickDeleteDevice}
                            isModalOpen={isDeleteModalOpen}
                            onModalClose={onDeleteModalOpenClose}
                            segmentName={selectedDevice.segmentName}
                        />
                    )}
                    <div className="settings-details-container settings-details-container--center settings-details-container--margin">
                        <DeviceInfo
                            serialNumber={serialNumber}
                            deviceType={selectedDevice && selectedDevice.type}
                            segmentStart={selectedDevice && selectedDevice.segmentStart}
                            noMargin
                        />
                    </div>
                    <div className="settings-details-container settings-details-container--margin">
                        <CollapsableSectionHeader
                            openSection={setDeviceDetailsOpen}
                            headerIsOpen={deviceDetailsOpen}
                            headerText="DeviceSettings.DeviceSettings"
                            subtext="DeviceSettings.DeviceSettingsSubHeader"
                            loading={false}
                            testAttr="settings"
                        />
                        {deviceDetailsOpen && (
                            <ChangeLocation
                                goBack={(): void => setDeviceDetailsOpen(false)}
                                serialNumber={serialNumber}
                            />
                        )}
                    </div>
                    {hasBatterySettings && (
                        <div className="settings-details-container settings-details-container--margin">
                            <CollapsableSectionHeader
                                openSection={setBatterySettingsOpen}
                                headerIsOpen={batterySettingsOpen}
                                headerText="DeviceSettings.BatteryLife"
                                subtext="DeviceSettingsConsumer.BatteryLifeDescription"
                                loading={false}
                                testAttr="battery"
                            />
                            {batterySettingsOpen && (
                                <BatterySettings
                                    serialNumber={serialNumber}
                                    onClose={(): void => setBatterySettingsOpen(false)}
                                />
                            )}
                        </div>
                    )}
                    {deviceIsViewPlus && (
                        <div className="settings-details-container settings-details-container--margin">
                            <CollapsableSectionHeader
                                openSection={setDisplaySettingsOpen}
                                headerIsOpen={displaySettingsOpen}
                                headerText="DeviceSettings.Display"
                                subtext={txt('DeviceSettings.DisplaySubHeader', {
                                    deviceType: txt(mapDeviceType(DeviceTypeNames.viewPlus)),
                                })}
                                loading={false}
                                testAttr="display-settings"
                                translateSubText={false}
                            />
                            {displaySettingsOpen && (
                                <ViewPlusSettings
                                    goBack={(): void => setDisplaySettingsOpen(false)}
                                    serialNumber={serialNumber}
                                    publicUrl={selectedDevice.publicUrlPath}
                                />
                            )}
                        </div>
                    )}
                    <div className="change-location__form__buttons">
                        <PrimaryButton onClick={onDeleteModalOpenClose} alert title="DeleteDevice" />
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        devices: { devices },
        userSettings: { demoMode },
        deviceConfig: { deviceConfigs },
        locations: { loading: locationsLoading, locations },
        commonRequests: {
            [RequestType.FetchDeviceConfig]: { loading: configLoading },
        },
    } = store;

    return {
        devices,
        demoMode,
        deviceConfigs,
        loading: (locationsLoading && locations.length === 0) || configLoading,
        locationsLoading: locationsLoading && locations.length === 0,
    };
};
const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onDeleteDevice: (serialNumber, locationId): DeleteDevice => dispatch(deleteDevice(serialNumber, locationId)),
    fetchConfig: (serialNumber: string, segmentId: string): FetchDeviceConfig =>
        dispatch(fetchDeviceConfig(serialNumber, segmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsComponent);
