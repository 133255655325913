import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlaceHolder from 'react-placeholder';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { analyticsLogger, IntegrationType, PageType } from 'commons/src/analytics';
import { INTEGRATION_CLICKED_ADD, INTEGRATION_VIEWED_INTEGRATION } from 'commons/src/analytics/AnalyticsEvents';
import LinkButton from 'commons/src/components/buttons/LinkButton';
import Error from 'commons/src/components/errorComponents/Error';
import SubHeader from 'commons/src/components/headers/SubHeader';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import { userlane } from 'commons/src/components/placeholder';
import { linkToMqttDocs, roleRestrictions } from 'commons/src/constants';
import IntegrationRow from 'commons/src/features/integrations/IntegrationRow';
import { ButtonColor, EnumStatusColors } from 'commons/src/models/commonEnums';
import { ErrorType } from 'commons/src/models/commonTypeScript';
import { ActionButton } from 'commons/src/models/menuModels';
import { updateMqttClientActiveState, UpdateMqttClientActiveState } from '../../../actions/mqttIntegrationActions';
import { paths } from '../../../constants';
import { MqttClient } from '../../../models/common';
import { IotCertificateStatus } from '../../../models/commonEnums';
import { Store } from '../../../reducers';
import { BusinessRequestType } from '../../../reducers/BusinessRequestType';

type StateProps = {
    clients: MqttClient[];
    loading: boolean;
    error?: ErrorType;
    numberOfLocations: number;
    locationsLoading: boolean;
};

type ActionProps = {
    updateClientActiveState: (client: MqttClient, active: boolean) => void;
};

export type Props = StateProps & ActionProps;

const analyticsObject = {
    pageType: PageType.Integration,
    page: IntegrationType.MQTT,
};

export const MqttPageComponent = ({
    clients,
    numberOfLocations,
    loading,
    error,
    locationsLoading,
    updateClientActiveState,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => analyticsLogger(INTEGRATION_VIEWED_INTEGRATION, analyticsObject));

    if (error) {
        return <Error />;
    }

    const clickAddClient = (): void => {
        navigate(`/${paths.addMqttClient}`);
        analyticsLogger(INTEGRATION_CLICKED_ADD, analyticsObject);
    };

    const clickEditClient = (id: string): void => {
        navigate(`/${paths.mqtt}/${id}`);
    };

    const actionButtons: ActionButton[] = [
        {
            onClick: clickAddClient,
            title: 'Mqtt.NewClient',
            id: 'AddMqttClient',
            color: ButtonColor.primary,
            testAttr: 'add-mqtt-client',
            icon: <MaterialIcon name="add" />,
            requiredRoleLevel: roleRestrictions.mqtt,
            requiredGroupTypes: [],
        },
    ];

    const clientsRows = (): React.ReactElement[] | false =>
        clients.length > 0 &&
        clients.map(client => {
            const certStatus =
                client && client.certificateInfo
                    ? client.certificateInfo.status
                    : IotCertificateStatus.PendingActivation;

            const statusColor = (): string => {
                switch (certStatus) {
                    case IotCertificateStatus.Active:
                        return EnumStatusColors.green;
                    case IotCertificateStatus.Inactive:
                        return EnumStatusColors.grey;
                    case IotCertificateStatus.PendingActivation:
                        return EnumStatusColors.yellow;
                    default:
                        return EnumStatusColors.red;
                }
            };

            const updateActiveState = (isOn: boolean): void => {
                updateClientActiveState(client, isOn);
            };

            const certActiveState = (): boolean | undefined => {
                switch (certStatus) {
                    case IotCertificateStatus.Active:
                        return true;
                    case IotCertificateStatus.Inactive:
                        return false;
                    default:
                        return undefined;
                }
            };

            return (
                <IntegrationRow
                    key={client.id}
                    id={client.id}
                    name={client.clientName}
                    firstRow={client.description}
                    secondRow={certStatus === IotCertificateStatus.PendingActivation ? txt('Mqtt.NoCert') : ''}
                    active={certActiveState()}
                    statusColor={statusColor()}
                    onClick={clickEditClient}
                    updateIntegration={updateActiveState}
                />
            );
        });

    return (
        <div>
            <SubHeader actionButtons={numberOfLocations > 0 ? actionButtons : []} />
            <h2 className="settings__header settings__header--no-margin-top">{txt('Mqtt.Mqtt')}</h2>
            <div className="settings-details-container settings-details-container--margin">
                <div>{txt('Mqtt.MqttDescription')}</div>
                <div>
                    <LinkButton link={linkToMqttDocs} title="Mqtt.LinkToDocs" testId="mqtt-link-to-docs" />
                </div>
                {!locationsLoading && numberOfLocations === 0 && <div>{txt('Mqtt.AddMqttClientDisabledText')}</div>}
            </div>
            <ul className="list">
                <ReactPlaceHolder ready={!(loading && clients.length === 0)} customPlaceholder={userlane}>
                    {clientsRows()}
                </ReactPlaceHolder>
            </ul>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        mqtt: { clients },
        requests: {
            [BusinessRequestType.FetchMqttClients]: { loading, error },
        },
        locations: { locations, loading: locationsLoading },
    } = state;
    return {
        clients: clients.sort((a, b) => a.clientName.localeCompare(b.clientName)),
        loading,
        error,
        numberOfLocations: locations.length,
        locationsLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateClientActiveState: (client: MqttClient, active: boolean): UpdateMqttClientActiveState =>
        dispatch(updateMqttClientActiveState(client, active)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MqttPageComponent);
