import React from 'react';
import classNames from 'classnames';
import { analyticsLogger, PageType } from '../../analytics';
import { DEVICE_CHANGED_TIMEFRAME } from '../../analytics/AnalyticsEvents';
import { sensorGraphPeriods } from '../../constants';
import { SelectedPeriod } from '../../models/commonTypeScript';

// TODO: delete calendar icons?

import FilterButton from './FilterButton';

type Props = {
    selectGraphPeriod: (arg0: SelectedPeriod) => void;
    limitTimeSelector?: boolean;
    selectedPeriod: SelectedPeriod;
    isMobile?: boolean;
    disabled: boolean;
    tileWidth?: number;
};

const SensorFilterButtons = ({
    selectedPeriod,
    selectGraphPeriod,
    disabled,
    tileWidth,
    limitTimeSelector,
    isMobile,
}: Props): React.ReactElement => {
    const selectPeriod = (selected: SelectedPeriod): void => {
        if (disabled) return;
        selectGraphPeriod(selected);
        analyticsLogger(DEVICE_CHANGED_TIMEFRAME, { pageType: PageType.Device, timeframe: selected.period });
    };
    const showShortText = isMobile || tileWidth === 2;

    return (
        <div
            className={classNames('options-row__filter-buttons', {
                'options-row__filter-buttons--full-width': isMobile,
            })}
        >
            <FilterButton
                title={showShortText ? '12hoursShort' : '12hours'}
                isSelected={selectedPeriod.name === sensorGraphPeriods.halfDay.name}
                onClick={(): void => selectPeriod(sensorGraphPeriods.halfDay)}
            />
            <FilterButton
                title={showShortText ? '48hoursShort' : '48hours'}
                isSelected={selectedPeriod.name === sensorGraphPeriods.twoDays.name}
                onClick={(): void => selectPeriod(sensorGraphPeriods.twoDays)}
            />
            <FilterButton
                title={showShortText ? 'weekShort' : 'Week'}
                isSelected={selectedPeriod.name === sensorGraphPeriods.week.name}
                onClick={(): void => selectPeriod(sensorGraphPeriods.week)}
            />
            <FilterButton
                title={showShortText ? 'monthShort' : 'Month'}
                isSelected={selectedPeriod.name === sensorGraphPeriods.month.name}
                onClick={(): void => selectPeriod(sensorGraphPeriods.month)}
            />
            {!limitTimeSelector && (
                <FilterButton
                    title={showShortText ? 'yearShort' : 'Year'}
                    isSelected={selectedPeriod.name === sensorGraphPeriods.year.name}
                    onClick={(): void => selectPeriod(sensorGraphPeriods.year)}
                />
            )}
        </div>
    );
};

SensorFilterButtons.defaultProps = {
    limitTimeSelector: false,
    isMobile: false,
    tileWidth: undefined,
};

export default SensorFilterButtons;
