import React, { useRef, useState } from 'react';
import { Button, Checkbox, Popover } from '@mui/material';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import styles from './DesktopMaterialTable.module.scss';
import useMaterialTableContext from './hooks/useMaterialTableContext';
import { MaterialTableColumn } from './models';

const ColumnFilter = (): React.ReactElement => {
    const ref = useRef<HTMLButtonElement>(null);
    const { tableId, columns, availableColumns, updateColumns } = useMaterialTableContext();
    const [newColumns, setNewColumns] = useState<MaterialTableColumn[]>(columns);
    const [filterOpen, setFilterOpen] = useState(false);

    const applyColumnChanges = (): void => {
        updateColumns(newColumns);
    };

    const isSelected = (accessor: string): boolean => newColumns.some(current => current.accessor === accessor);

    const renderCheckbox = (locked: boolean, accessor: string, label: string): React.ReactNode => {
        const isChecked = isSelected(accessor);
        return (
            <>
                <Checkbox
                    disabled={locked}
                    checked={isChecked}
                    onChange={(): void => {
                        if (isChecked) {
                            const columnsWithRemoved = newColumns.filter(current => current.accessor !== accessor);
                            setNewColumns(columnsWithRemoved);
                        } else {
                            const columnsWithAdded = availableColumns.filter(
                                available => isSelected(available.accessor) || available.accessor === accessor
                            );
                            setNewColumns(columnsWithAdded);
                        }
                    }}
                    sx={{
                        '&': { width: '1.5em', height: '1.5em' },
                        '& .MuiSvgIcon-root': { fontSize: '1.5em' },
                    }}
                />
                <span className={styles.label}>{label}</span>
            </>
        );
    };

    return (
        <div id="filterWrapper">
            <Button
                data-testid="columnManagement-button"
                ref={ref}
                className={styles.showMoreColumnsButton}
                onClick={(): void => setFilterOpen(current => !current)}
                centerRipple={false}
                focusRipple={false}
            >
                <MaterialIcon name="tune" extraClass={styles.tune} />
            </Button>
            <Popover
                id={tableId}
                data-testid="columnManagement-modal"
                open={filterOpen}
                onClose={(): void => setFilterOpen(false)}
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ background: 'none' }}
            >
                <div className={styles.filterModal}>
                    <ul className={styles.columnList}>
                        {availableColumns.map(column => {
                            const { accessor, label, locked } = column;
                            return (
                                <li className={styles.columnListElement} key={accessor}>
                                    {renderCheckbox(locked ?? false, accessor, label)}
                                </li>
                            );
                        })}
                    </ul>
                    <div className={styles.buttons}>
                        <PrimaryButton small onClick={(): void => setFilterOpen(false)} title="Cancel" />
                        <PrimaryButton
                            onClick={(): void => {
                                applyColumnChanges();
                                setFilterOpen(false);
                            }}
                            title="PresenceInsight.Apply"
                            filled
                        />
                    </div>
                </div>
            </Popover>
        </div>
    );
};

export default ColumnFilter;
