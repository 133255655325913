import React, { useMemo } from 'react';
import { groupBy as lodashGroupBy } from 'lodash';
import { connect } from 'react-redux';
import SimpleTreeView from 'commons/src/components/lists/TreeView/SimpleTreeView';
import { DeviceWithKeyInfo, LocationType } from 'commons/src/models/commonTypeScript';
import { Store } from '../../../reducers';

type DeviceSelectionType = { devices: string[]; locations: string[] };
type StateProps = {
    locations: LocationType[];
    devices: { [serialNumber: string]: DeviceWithKeyInfo };
    loading: boolean;
    hubs: { [serialNumber: string]: DeviceWithKeyInfo };
};

export type ParentProps = {
    selectionUpdate: (selection: DeviceSelectionType) => void;
    currentSelection: DeviceSelectionType;
};

export type Props = StateProps & ParentProps;

export const DeviceSelectorComponent = ({
    locations,
    selectionUpdate,
    currentSelection,
    loading,
    devices,
    hubs,
}: Props): React.ReactElement => {
    const updateSelection = ({ id, changeAll }: { id: string; changeAll: boolean }): void => {
        let locationSelection = [...currentSelection.locations];
        let deviceSelection = [...currentSelection.devices];

        if (changeAll) {
            if (currentSelection.locations.length === locations.length) {
                locationSelection = [];
                deviceSelection = [];
            } else {
                locationSelection = locations.map(loc => loc.id);
            }
        } else {
            const selectedLocation = locations.find(location => location.id === id);
            const locationDevices = selectedLocation ? [...selectedLocation.devices, ...selectedLocation.hubs] : [];
            // Remove all devices from selected location from the device list. Both if location added and removed as we will not allow for selection devices any longer.
            deviceSelection = deviceSelection.filter(serialNumber => !locationDevices.includes(serialNumber));
            if (currentSelection.locations.includes(id)) {
                locationSelection = [...locationSelection].filter(el => el !== id);
            } else {
                locationSelection = [...locationSelection, id];
            }
        }
        selectionUpdate({ devices: deviceSelection, locations: locationSelection });
    };

    const selectedTreeViewOptions = useMemo(() => {
        return currentSelection.locations;
    }, [currentSelection]);

    const selectedDevicesWithLocationId = useMemo(
        () =>
            lodashGroupBy(
                currentSelection.devices.map(serialNumber => ({
                    serialNumber,
                    locationId: devices[serialNumber]?.locationId || hubs[serialNumber]?.locationId,
                })),
                'locationId'
            ),
        [currentSelection]
    );

    const treeViewOptions = useMemo(() => {
        return locations.map(location => {
            const numberOfDevicesInLocation = location.devices.length + location.hubs.length;
            // this should be removed when we no longer have webhooks on device level.
            const selectedDevicesForLocation = selectedDevicesWithLocationId[location.id] || [];
            const selectedDevicesCount =
                selectedDevicesForLocation.length > 0
                    ? `(${selectedDevicesForLocation.length}/${numberOfDevicesInLocation})`
                    : '';
            return {
                name: `${location.name} ${selectedDevicesCount}`,
                id: location.id,
            };
        });
    }, [locations, selectedDevicesWithLocationId]);

    if (loading) return <div />;

    return (
        <SimpleTreeView
            listId="device-selector"
            options={treeViewOptions}
            selectedOptions={selectedTreeViewOptions}
            onChange={updateSelection}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        locations: { locations, loading },
        devices: { devicesWithKeyInfo, hubsWithKeyInfo },
    } = state;
    return {
        locations,
        devices: devicesWithKeyInfo,
        loading,
        hubs: hubsWithKeyInfo,
    };
};

export default connect(mapStateToProps)(DeviceSelectorComponent);
