import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { analyticsLogger } from 'commons/src/analytics';
import { ACCOUNT_SSO_ADD_PROVIDER } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import Dropdown from 'commons/src/components/dropdown/MultipleAttrDropdown';
import Input from 'commons/src/components/input/Input';
import InputWithChips from 'commons/src/components/input/InputWithChips';
import DeleteConfirmModal from 'commons/src/components/modals/DeleteConfirmModal';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DOMAIN_REGEX, GOOGLE_ISSUER_URL, MICROSOFT_ISSUER_URL, URL_REGEX } from 'commons/src/constants';
import { IdentityProvider } from 'commons/src/models/commonEnums';
import { ErrorType, SelectionItem } from 'commons/src/models/commonTypeScript';
import { createFederatedClient, editFederatedClient } from '../../../../actions/singleSignOnActions';
import { SingleSignOnClient } from '../../../../models/common';
import { Store } from '../../../../reducers';
import { BusinessRequestType } from '../../../../reducers/BusinessRequestType';
import styles from './ClientForm.module.scss';
import { identityProviderValue, secretPlaceholder } from './commonSSOFunctions';
import RedirectUriComponent from './RedirectUriToRegister';

type StateProps = {
    createLoading: boolean;
    editLoading: boolean;
    error?: ErrorType;
};

type PassedProps = {
    singleSignOnClient?: SingleSignOnClient;
    isEditMode: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
};

export type Props = PassedProps & StateProps;

export const ClientForm = ({
    singleSignOnClient,
    isEditMode,
    setIsEditMode,
    createLoading,
    editLoading,
    error,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const dispatch = useDispatch();

    const defaultScopes: SelectionItem[] = [
        { id: 'openid', name: 'openid' },
        { id: 'profile', name: 'profile' },
        { id: 'email', name: 'email' },
        { id: 'offline_access', name: 'offline_access' },
    ];

    const [modalOpen, setModalOpen] = useState(false);
    const [identityProvider, setIdentityProvider] = useState<IdentityProvider>(
        identityProviderValue(singleSignOnClient ? singleSignOnClient.issuer : '')
    );
    const [clientId, setClientId] = useState(singleSignOnClient ? singleSignOnClient.clientId : '');
    const [clientSecret, setClientSecret] = useState('');
    const [issuer, setIssuer] = useState(singleSignOnClient ? singleSignOnClient.issuer : '');
    const [microsoftTenant, setMicrosoftTenant] = useState(
        singleSignOnClient ? singleSignOnClient.issuer.split('/')[3] : 'organizations'
    );
    const [emailDomain, setEmailDomain] = useState(singleSignOnClient ? singleSignOnClient.domain : '');
    const [displayValidation, setDisplayValidation] = useState(false);
    const [disabledInputField, setDisabledInputField] = useState(!!singleSignOnClient);
    const [selectedScopes, setSelectedScopes] = useState<SelectionItem[]>(
        singleSignOnClient
            ? (singleSignOnClient.scope || '').split(' ').map(clientScope => ({ id: clientScope, name: clientScope }))
            : defaultScopes
    );

    const identityProviderTranslation = (provider: string): string => txt(`IdentityProvider.${provider}`);

    const identityProviderOptions = Object.keys(IdentityProvider).map(provider => ({
        id: provider,
        inputValue: identityProviderTranslation(provider),
    }));

    const setSelectedProvider = (provider: { id: string; inputValue: string }): void => {
        const selectedProvider = provider.id as IdentityProvider;
        if (Object.keys(IdentityProvider).includes(selectedProvider)) {
            setIdentityProvider(selectedProvider);
            let fixedIssuer = '';
            let fixedScopes: SelectionItem[] = defaultScopes;
            if (provider.id === IdentityProvider.Google) {
                fixedIssuer = GOOGLE_ISSUER_URL;
                fixedScopes = [...defaultScopes.filter(scope => scope.id !== 'offline_access')];
            } else if (provider.id === IdentityProvider.Microsoft) {
                fixedIssuer = MICROSOFT_ISSUER_URL;
                fixedScopes = [
                    ...defaultScopes,
                    { id: 'https://graph.microsoft.com/user.read', name: 'https://graph.microsoft.com/user.read' },
                ];
            } else if (provider.id.includes('facebook') || provider.id.includes('twitter')) {
                fixedScopes = [...defaultScopes.filter(scope => scope.id !== 'offline_access')];
            }
            setIssuer(fixedIssuer);
            setSelectedScopes(fixedScopes);
        }
    };

    const onAddScope = (value: string): void => {
        const element: SelectionItem = { id: value, name: value };
        if (!selectedScopes.includes(element)) setSelectedScopes([...selectedScopes, element]);
    };

    const removeScopeFromSelection = (id: string): void => {
        const filteredSelection = selectedScopes.filter(loc => loc.id !== id);
        setSelectedScopes(filteredSelection);
    };

    const updateIssuer = (event: SyntheticEvent<HTMLInputElement>, provider: IdentityProvider): void => {
        const value = event.currentTarget.value.trim().toLowerCase();
        if (provider === IdentityProvider.Other) {
            setIssuer(value);
        }
    };

    const updateTenant = (event: SyntheticEvent<HTMLInputElement>): void => {
        const tenant = event.currentTarget.value.trim().toLowerCase();
        setMicrosoftTenant(tenant.toLowerCase());
        setIssuer(MICROSOFT_ISSUER_URL.replace('organizations', tenant));
    };

    const onBlurTenant = (event: SyntheticEvent<HTMLInputElement>): void => {
        const tenant = event.currentTarget.value.trim().toLowerCase();
        if (tenant.length === 0) {
            setMicrosoftTenant('organizations');
            setIssuer(MICROSOFT_ISSUER_URL);
        }
    };

    const updateEmailDomain = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        setEmailDomain(value.toLowerCase());
    };

    const updateClientId = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        setClientId(value);
    };

    const updateClientSecret = (event: SyntheticEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value.trim();
        setClientSecret(value);
    };

    const validForm = (): boolean => {
        const clientIdValid = clientId.trim().length > 0;
        const clientSecretValid = clientSecret.trim().length > 0;
        const issuerValid = URL_REGEX.test(issuer);
        const emailDomainValid = DOMAIN_REGEX.test(emailDomain);
        const microsoftTenantValid = identityProvider === IdentityProvider.Microsoft && microsoftTenant.length > 0;
        const scopeValid = selectedScopes.length > 0;
        const formIsValid =
            identityProvider === IdentityProvider.Microsoft
                ? issuerValid &&
                  emailDomainValid &&
                  clientIdValid &&
                  clientSecretValid &&
                  microsoftTenantValid &&
                  scopeValid
                : issuerValid && emailDomainValid && clientIdValid && clientSecretValid && scopeValid;
        if (!formIsValid) setDisplayValidation(true);
        return formIsValid;
    };

    const onButtonClick = (): void => {
        if (validForm()) {
            setModalOpen(true);
        }
    };
    const onClose = (): void => {
        setModalOpen(false);
    };
    const onConfirmModalSubmit = (): void => {
        const scope = selectedScopes.map(item => item.name).join(' ');

        dispatch(createFederatedClient({ issuer, domain: emailDomain, clientId, clientSecret, scope }));
        setModalOpen(false);
        analyticsLogger(ACCOUNT_SSO_ADD_PROVIDER, {});
    };

    const onEditSubmit = (): void => {
        const scope = selectedScopes.map(item => item.name).join(' ');

        if (singleSignOnClient && validForm()) {
            dispatch(
                editFederatedClient(
                    { issuer, domain: emailDomain, clientId, clientSecret, scope },
                    singleSignOnClient.singleSignOnId
                )
            );
        }
    };

    const disabledFieldPredefinedIssuer =
        (!isEditMode || identityProvider !== IdentityProvider.Other) &&
        (disabledInputField || identityProvider !== IdentityProvider.Other);

    const prevCreateLoadingRef = useRef(createLoading);

    useEffect((): void => {
        if (prevCreateLoadingRef.current && !createLoading && !error) {
            setDisabledInputField(true);
        }
        prevCreateLoadingRef.current = createLoading;
    }, [createLoading, prevCreateLoadingRef]);

    const prevEditLoadingRef = useRef(editLoading);

    useEffect((): void => {
        if (prevEditLoadingRef.current && !editLoading && !error) {
            setIsEditMode(false);
        }
        prevEditLoadingRef.current = editLoading;
    }, [editLoading, prevEditLoadingRef]);

    return (
        <div>
            {modalOpen && (
                <DeleteConfirmModal
                    title="SingleSignOn.ConfirmDomain"
                    description={txt('SingleSignOn.ConfirmDomainText')}
                    onCancel={onClose}
                    onSubmit={onConfirmModalSubmit}
                    onCancelText="Cancel"
                    onSubmitText="Confirm"
                    loading={false}
                    regularSubmitButton
                    centerBody
                />
            )}
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="text"
                        id="ssoDomain"
                        validate={displayValidation && !DOMAIN_REGEX.test(emailDomain)}
                        label="SingleSignOn.EmailDomain"
                        currentValue={emailDomain}
                        disabled={disabledInputField}
                        onChange={updateEmailDomain}
                        hint="SingleSignOn.InvalidDomain"
                        infoText="SsoInfoText.DomainInfo"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--single-width">
                    <Dropdown
                        id="identityProviderSelector"
                        loading={false}
                        title="SingleSignOn.IdentityProvider"
                        options={identityProviderOptions}
                        value={identityProviderTranslation(identityProvider)}
                        defaultOption={identityProviderTranslation(identityProvider)}
                        onSelect={setSelectedProvider}
                        disabled={!isEditMode && disabledInputField}
                        testAttr="provider-list"
                    />
                </div>
            </div>
            {identityProvider === IdentityProvider.Microsoft && (
                <div className="form__row">
                    <div className="form__field form__field--medium-width">
                        <Input
                            type="text"
                            id="microsoftTenant"
                            validate={false}
                            label="SingleSignOn.MicrosoftTenant"
                            currentValue={microsoftTenant}
                            disabled={!isEditMode && disabledInputField}
                            onChange={(e: SyntheticEvent<HTMLInputElement>): void => updateTenant(e)}
                            onBlur={(e: SyntheticEvent<HTMLInputElement>): void => onBlurTenant(e)}
                            infoText="SsoInfoText.TenantIdInfo"
                        />
                    </div>
                </div>
            )}
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="text"
                        id="ssoIssuer"
                        validate={displayValidation && !URL_REGEX.test(issuer)}
                        label="SingleSignOn.Issuer"
                        currentValue={issuer}
                        disabled={disabledFieldPredefinedIssuer}
                        onChange={(e: SyntheticEvent<HTMLInputElement>): void =>
                            updateIssuer(e, IdentityProvider.Other)
                        }
                        hint="SingleSignOn.InvalidIssuer"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="text"
                        id="clientId"
                        validate={displayValidation && clientId.trim().length === 0}
                        label="SingleSignOn.ClientId"
                        currentValue={clientId}
                        disabled={!isEditMode && disabledInputField}
                        onChange={updateClientId}
                        infoText="SsoInfoText.ClientIdInfo"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <Input
                        type="password"
                        id="clientSecret"
                        validate={displayValidation && clientSecret.trim().length === 0}
                        label="SingleSignOn.ClientSecret"
                        autoComplete="client-secret"
                        currentValue={clientSecret}
                        placeholder={
                            disabledInputField && clientSecret.length === 0 && !isEditMode ? secretPlaceholder : ''
                        }
                        disabled={!isEditMode && disabledInputField}
                        onChange={updateClientSecret}
                        infoText="SsoInfoText.ClientSecretInfo"
                    />
                </div>
            </div>
            <div className="form__row">
                <div className="form__field form__field--medium-width">
                    <InputWithChips
                        onRemove={removeScopeFromSelection}
                        selectedElements={selectedScopes}
                        onSubmit={onAddScope}
                        validate={displayValidation && selectedScopes.length === 0}
                        label="SingleSignOn.Scope"
                        disabled={disabledFieldPredefinedIssuer}
                        hint="SingleSignOn.InvalidScope"
                        infoText="SsoInfoText.ScopeInfo"
                    />
                </div>
            </div>
            <RedirectUriComponent />
            {singleSignOnClient ? (
                <div className="form__row submit-container">
                    <div>
                        <PrimaryButton title="Cancel" onClick={(): void => setIsEditMode(false)} />
                    </div>
                    <div>
                        <PrimaryButton
                            id="editSSOInfoButton"
                            title="SaveChanges"
                            filled
                            testId="save-changes"
                            onClick={onEditSubmit}
                            loading={editLoading}
                        />
                    </div>
                </div>
            ) : (
                <div className="form__row submit-container">
                    <div className={styles.saveButton}>
                        <PrimaryButton loading={createLoading} filled title="Save" onClick={onButtonClick} />
                        <div className={styles.saveText}>{txt('SingleSignOn.VerificationText')}</div>
                    </div>
                </div>
            )}
            {error && (
                <div className={styles.errorWrapper}>
                    <ResponseBox text={`ErrorCodes.${error.error}`} />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        requests: {
            [BusinessRequestType.CreateFederatedClient]: { loading: createLoading, error: createError },
            [BusinessRequestType.EditFederatedClient]: { loading: editLoading, error: editError },
        },
    } = state;
    return {
        createLoading,
        error: createError || editError,
        editLoading,
    };
};

export default connect(mapStateToProps)(ClientForm);
