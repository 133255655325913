import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import SearchDropdown from 'commons/src/components/dropdown/SearchDropdown';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { publicDeviceShortUrl } from 'commons/src/constants';
import DeviceIcon from 'commons/src/img/deviceIcons/icon_device';
import { BuildingType, DeviceType, Group, Units } from 'commons/src/models/commonTypeScript';
import { GetOrganizationLogo, getOrganizationLogo } from '../../../actions/organizationPropertiesActions';
import { SetDevicePubliclyAvailable, setDevicePubliclyAvailable } from '../../../actions/segmentPropertiesActions';
import { Store } from '../../../reducers';
import PrintAllQrLabels, { QrLabelProps } from '../../device/dropdownOptions/PDF/PrintAllQrLabels';
import LocationList from './LocationList';

type StateProps = {
    logoImage?: string;
    devices: { [serialNumber: string]: DeviceType };
    selectedGroup?: Group;
    language: string;
    units: Units;
};
type ParentProps = {
    building: BuildingType;
};
type ActionProps = {
    setPublicDevice: (publiclyAvailable: boolean, serialNumber: string, hasPublicUrl: boolean) => void;
    getLogo: () => void;
};
type Props = StateProps & ActionProps & ParentProps;

export const PublicDeviceOverviewComponent = (props: Props): React.ReactElement => {
    const { building, language, devices, units, setPublicDevice, getLogo, logoImage, selectedGroup } = props;
    const { t: txt } = useTranslation();

    const [checkedDevices, setCheckedDevices] = useState<string[]>([]);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);

    const devicesInBuilding =
        building.devices.map(serialNumber => devices[serialNumber]).filter(device => !!device) || [];
    const updateCheckedDevices = (serialNumbers: string[]): void => {
        setCheckedDevices(serialNumbers);
    };

    useEffect((): void => {
        if (!logoImage) {
            getLogo();
        }
    }, []);

    const makePublicDevice = ({ id }: { name: string; id: string }): void => {
        const hasPublicUrl = !!(devices[id] && devices[id].publicUrlPath);
        setPublicDevice(true, id, hasPublicUrl);
    };

    const removePublicDevices = (): void => {
        checkedDevices.map(serialNumber => setPublicDevice(false, serialNumber, true));
        setCheckedDevices([]);
    };

    const pdfProps = (): QrLabelProps[] => {
        const qrProps = {
            locationName: building.name,
            devices: checkedDevices.map(serialNumber => {
                const publicUrl = devices[serialNumber].publicUrlPath;
                const shortUrl = publicUrl && publicDeviceShortUrl.replace('{{publicUrlPath}}', publicUrl);
                const qrElement = document.getElementById(`qrCode${serialNumber}`) as HTMLCanvasElement;
                const qrImage = qrElement.toDataURL('img/png');
                return {
                    serialNumber,
                    organizationName: (selectedGroup && selectedGroup.organizationName) || '',
                    qrImage: qrImage || '',
                    logoImage,
                    url: shortUrl || '',
                    deviceName: devices[serialNumber].segmentName,
                };
            }),
        };
        return [qrProps];
    };

    const numberOfSelectedDevices = checkedDevices.length;
    const printQrCodes = (): void => setDownloadModalOpen(true);

    const nonPublicDevices = devicesInBuilding
        .filter(device => !device.publiclyAvailable)
        .map(device => ({ name: device.segmentName, id: device.serialNumber }));
    return (
        <div className="settings-details-container__content">
            {downloadModalOpen && (
                <ReactPdfDownloadModal title="Download" onClose={(): void => setDownloadModalOpen(false)}>
                    <PrintAllQrLabels qrLabels={pdfProps()} />
                </ReactPdfDownloadModal>
            )}
            <p className="text-bold text-large">{txt('PublicDevices.MakeDevicePublic')}</p>
            <div className="form__row form__row--padded-medium">
                <SearchDropdown listOfElements={nonPublicDevices} onSelect={makePublicDevice} icon={<DeviceIcon />} />
            </div>
            <LocationList
                devicesInBuilding={devicesInBuilding}
                updateCheckedDevices={updateCheckedDevices}
                checkedDevices={checkedDevices}
                language={language}
                units={units}
                setPublicDevice={setPublicDevice}
            />
            <div className="form__row form__button-container form__button-container--margin-bottom">
                <PrimaryButton
                    type="button"
                    title={txt('PublicDevices.RemoveSelection', {
                        numberOfDevices: numberOfSelectedDevices.toString(),
                    })}
                    translate={false}
                    onClick={removePublicDevices}
                    loading={false}
                    disabled={numberOfSelectedDevices === 0}
                    testAttr="remove-selection"
                    testId="remove-selection"
                />
                <PrimaryButton
                    type="button"
                    title={txt('PublicDevices.PrintSelection', {
                        numberOfDevices: numberOfSelectedDevices.toString(),
                    })}
                    translate={false}
                    onClick={printQrCodes}
                    loading={false}
                    filled
                    disabled={numberOfSelectedDevices === 0}
                    testAttr="print-public-qr-codes"
                />
            </div>
        </div>
    );
};
const mapStateToProps = (store: Store): StateProps => {
    const {
        devices: { devices },
        organizationProperties: { logoImage },
        userSettings: { selectedGroup, language, units },
    } = store;

    return {
        logoImage,
        devices,
        selectedGroup,
        language,
        units,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    setPublicDevice: (
        publiclyAvailable: boolean,
        serialNumber: string,
        hasPublicUrl: boolean
    ): SetDevicePubliclyAvailable =>
        dispatch(setDevicePubliclyAvailable(publiclyAvailable, serialNumber, hasPublicUrl)),
    getLogo: (): GetOrganizationLogo => dispatch(getOrganizationLogo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicDeviceOverviewComponent);
