import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userIsHbs } from 'commons/src/components/findUserType';
import { getDashboardPath } from '../../commonFunctions';
import PageHeader from '../../components/headers/PageHeader';
import SubHeader from '../../components/headers/SubHeader';
import { mediumFormLoader } from '../../components/placeholder';
import { roleRestrictions } from '../../constants';
import deviceTile from '../../img/tileImages/deviceTile.png';
import outdoorComparisonTile from '../../img/tileImages/indoorVsOutdoorTile.png';
import locationTile from '../../img/tileImages/locationTile.png';
import outdoorTile from '../../img/tileImages/outdoorTile.png';
import sensorTile from '../../img/tileImages/sensorTile.png';
import sensorValueTile from '../../img/tileImages/sensorValueTile.png';
import { ButtonColor, DashboardVisibility, RequiredRoleLevel, Role } from '../../models/commonEnums';
import { DashboardProps, DashboardTile } from '../../models/commonTypeScript';
import { ActionButton } from '../../models/menuModels';
import { Store } from '../../reducers';
import { userRoleAboveRequiredLevel } from '../authorization/userRoleAboveRequiredLevel';
import styles from './AddModule.module.scss';

type Props = {
    canAddOutdoorTile: boolean;
    userRole?: Role;
    dashboardProps?: DashboardProps;
    loading: boolean;
};

export const AddModuleComponent = ({
    canAddOutdoorTile,
    dashboardProps,
    userRole,
    loading,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const navigate = useNavigate();

    const actionButtons: ActionButton[] = [
        {
            onClick: (): void => navigate({ pathname: getDashboardPath() }),
            testAttr: 'close-add-tile',
            id: 'closeAddTile',
            color: ButtonColor.secondary,
            title: 'Close',
            requiredRoleLevel: RequiredRoleLevel.ANY_ROLE,
            requiredGroupTypes: [],
        },
    ];

    const publicDashboard =
        (dashboardProps && dashboardProps.visibility === DashboardVisibility.userGroupPublic) ||
        (dashboardProps && dashboardProps.visibility === DashboardVisibility.userGroup);
    return (
        <div data-add-tile-page>
            <PageHeader title={txt('AddNewTile')} />
            <div className="page-wrapper">
                <SubHeader actionButtons={actionButtons} />
                <p className="add-module__title">{txt('SelectTypeOfTile')}</p>
            </div>
            <div className="page-wrapper page-wrapper--grid-layout">
                <ReactPlaceholder ready={!loading} customPlaceholder={mediumFormLoader}>
                    <div>
                        {userIsHbs() && dashboardProps && dashboardProps.visibility === DashboardVisibility.user && (
                            <Link to="/add-tile/add-location-tile" className="page-wrapper--column">
                                <div className="tile-wrapper add-module__tile">
                                    <div className="tile-wrapper__title">{txt('Location')}</div>
                                    <img
                                        src={locationTile}
                                        alt={txt('LocationTile')}
                                        className="add-module__tile__image"
                                    />
                                    <div className={styles.tileButton}>{txt('Select')}</div>
                                </div>
                            </Link>
                        )}
                        {!publicDashboard && (
                            <Link
                                to="/add-tile/add-device-tile"
                                className="page-wrapper--column"
                                data-add-device-tile-link
                            >
                                <div className="tile-wrapper add-module__tile">
                                    <div className="tile-wrapper__title">{txt('Device')}</div>
                                    <img src={deviceTile} alt={txt('DeviceTile')} className="add-module__tile__image" />
                                    <div className={styles.tileButton}>{txt('Select')}</div>
                                </div>
                            </Link>
                        )}
                        {publicDashboard && (
                            <Link
                                to="/add-tile/add-sensor-value-tile"
                                className="page-wrapper--column"
                                data-add-sensor-value-tile-link
                            >
                                <div className="tile-wrapper add-module__tile">
                                    <div className="tile-wrapper__title">{txt('SensorValue')}</div>
                                    <img
                                        src={sensorValueTile}
                                        alt={txt('SensorValue')}
                                        className="add-module__tile__image"
                                    />
                                    <div className={styles.tileButton}>{txt('Select')}</div>
                                </div>
                            </Link>
                        )}
                        <Link to="/add-tile/add-sensor-tile" className="page-wrapper--column" data-add-sensor-tile-link>
                            <div className="tile-wrapper add-module__tile">
                                <div className="tile-wrapper__title">{txt('Sensor')}</div>
                                <img src={sensorTile} alt={txt('SensorTile')} className="add-module__tile__image" />
                                <div className={styles.tileButton}>{txt('Select')}</div>
                            </div>
                        </Link>
                        <Link
                            to={canAddOutdoorTile ? '/add-tile/add-outdoor-tile' : '#'}
                            className="page-wrapper--column"
                        >
                            <div className="tile-wrapper add-module__tile">
                                {!canAddOutdoorTile && (
                                    <div className="add-module__tile__blur">
                                        <span className="add-module__tile__blur__text">
                                            {txt('OutdoorTileLimitReached')}
                                        </span>
                                    </div>
                                )}
                                <div className={classNames({ 'add-module__tile--blurred': !canAddOutdoorTile })}>
                                    <div className="tile-wrapper__title">{txt('OutdoorAir')}</div>
                                    <img
                                        src={outdoorTile}
                                        alt={txt('OutdoorAir')}
                                        className="add-module__tile__image"
                                    />
                                    <div className={styles.tileButton}>{txt('Select')}</div>
                                </div>
                            </div>
                        </Link>

                        {userIsHbs() &&
                            userRole &&
                            userRoleAboveRequiredLevel(userRole, roleRestrictions.reports) &&
                            !publicDashboard && (
                                <Link to="/add-tile/add-indoor-vs-outdoor-tile" className="page-wrapper--column">
                                    <div className="tile-wrapper add-module__tile">
                                        <div
                                            className={classNames({ 'add-module__tile--blurred': !canAddOutdoorTile })}
                                        >
                                            <div className="tile-wrapper__title">{txt('OutdoorComparison')}</div>
                                            <img
                                                src={outdoorComparisonTile}
                                                alt={txt('OutdoorComparison')}
                                                className="add-module__tile__image"
                                            />
                                            <div className={styles.tileButton}>{txt('Select')}</div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                    </div>
                </ReactPlaceholder>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): Props => {
    const {
        dashboardData: { dashboardTiles, dashboardProps, loading },
        userSettings: { selectedGroup },
    } = state;
    const canAddOutdoorTile =
        userIsHbs() || dashboardTiles.find((tile: DashboardTile) => tile.type === 'outdoor') === undefined;
    return {
        canAddOutdoorTile,
        userRole: selectedGroup && selectedGroup.role,
        dashboardProps,
        loading,
    };
};

AddModuleComponent.defaultProps = {
    userRole: undefined,
    dashboardProps: undefined,
};

export default connect(mapStateToProps)(AddModuleComponent);
