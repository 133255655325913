import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import SearchComponent from '../input/SearchComponent';
import MaterialIcon from '../MaterialIcon';

type Props = {
    setSearchText: (searchText: string) => void;
    displaySearch: boolean;
    headerText: string;
    headerTextWithIcon?: boolean;
};

export const ChangeSelectedSearchHeaderComponent = (props: Props): React.ReactElement => {
    const { displaySearch, setSearchText, headerText, headerTextWithIcon } = props;
    const { t: txt } = useTranslation();

    const searchUpdate = (text: string): void => {
        const lowerCaseSearch = text.trim().toLowerCase();
        setSearchText(lowerCaseSearch);
    };

    return (
        <div
            className={classNames('modal__content__header', 'modal__content__header--sticky', {
                'modal__content__header--sticky__with-search': displaySearch,
            })}
        >
            {displaySearch ? (
                <div className="modal__content__search">
                    <SearchComponent onChange={searchUpdate} />
                </div>
            ) : (
                <h5 className="modal__content__title">
                    {headerTextWithIcon && (
                        <MaterialIcon
                            extraClass="sidebar-navigation__items-container__item__icon"
                            name="swap_horizontal_circle"
                        />
                    )}
                    {txt(headerText)}
                </h5>
            )}
        </div>
    );
};

ChangeSelectedSearchHeaderComponent.defaultProps = {
    headerTextWithIcon: true,
};

export default ChangeSelectedSearchHeaderComponent;
