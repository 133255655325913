import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CloseHBSOnboardingModal, closeHBSOnboardingModal } from '../../actions/OrganizationInvitesActions';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { getSelectedGroupFromStorage } from '../../components/findUserType';
import ModalHeader from '../../components/modals/ModalHeader';
import { hbsGettingStartedGuide } from '../../constants';
import { GroupType } from '../../models/commonEnums';
import { Group, SelectedGroup } from '../../models/commonTypeScript';

export type PassedProps = {
    acceptedInviteGroup?: Group;
};

type ActionProps = {
    closeModal: (selectedGroup: SelectedGroup) => void;
};

type Props = PassedProps & ActionProps;

const HbsWelcomeComponent = ({ closeModal, acceptedInviteGroup }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const onClose = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const currentSelectedGroup: SelectedGroup | null = getSelectedGroupFromStorage();

        closeModal({
            organizationId: acceptedInviteGroup?.organizationId || currentSelectedGroup?.organizationId || '',
            userGroupId: acceptedInviteGroup?.id || currentSelectedGroup?.userGroupId || '',
            groupType:
                acceptedInviteGroup?.groupType ||
                (currentSelectedGroup?.groupType as keyof typeof GroupType) ||
                GroupType.consumer,
        });
    };

    const orgName = acceptedInviteGroup ? acceptedInviteGroup.groupName : '';

    return (
        <div data-hbs-welcome>
            <ModalHeader headerText="WelcomeToAirthingsForBusiness" />
            <div className="modal__content__body modal__content__body--large" data-organization-invite>
                <h2 className="modal__content__description modal__content__description--bold">
                    {txt('CongratulationAllSet')}
                </h2>
                <div className="modal__content__description">
                    <Trans i18nKey="OrganizationSetUpSuccessfully">
                        {orgName}
                        <a href={hbsGettingStartedGuide} target="_blank" rel="noopener noreferrer">
                            {txt('GettingStartedGuide')}
                        </a>
                    </Trans>
                </div>
                <div className="modal__content__buttons modal__content__buttons--flex-end">
                    <PrimaryButton title={txt('GotIt')} onClick={onClose} autoFocus filled testAttr="close-button" />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    closeModal: (selectedGroup: SelectedGroup): CloseHBSOnboardingModal =>
        dispatch(closeHBSOnboardingModal(selectedGroup)),
});

export default connect(undefined, mapDispatchToProps)(HbsWelcomeComponent);
