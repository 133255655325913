import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { UpdateDeviceConfig, updateDeviceConfig } from '../../../actions/DeviceActions';
import { analyticsLogger, PageType } from '../../../analytics';
import { DEVICE_SAVED_DISPLAY_VIEW_PLUS } from '../../../analytics/AnalyticsEvents';
import { batteryIntervals, publicQrUrl } from '../../../commonFunctions';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Error from '../../../components/errorComponents/Error';
import SelectorList from '../../../components/lists/SelectorList';
import MaterialIcon from '../../../components/MaterialIcon';
import ResponseBox from '../../../components/responseMessages/ResponseBox';
import { SensorIcon } from '../../../components/sensors/SensorUnit';
import { ScreenOptions, SensorTypes } from '../../../models/commonEnums';
import {
    ErrorType,
    DisplayScreenOptions,
    DeviceConfigUpdate,
    DeviceConfig,
    Units,
} from '../../../models/commonTypeScript';
import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';
import DisplaySelector from './DisplaySelector';

type StateProps = {
    language: string;
    error?: ErrorType;
    fetchConfigError?: ErrorType;
    screenOptions: DisplayScreenOptions[];
    deviceConfigs: { [serialNumber: string]: DeviceConfig };
    updateLoading: boolean;
    units: Units;
};

type PassedProps = {
    serialNumber: string;
    goBack: (e: SyntheticEvent<HTMLElement>) => void;
    publiclyAvailable?: boolean;
    publicUrl?: string;
};

type ActionProps = {
    updateConfig: (
        config: DeviceConfigUpdate,
        serialNumber: string,
        segmentId: string,
        displaySuccessMessage?: boolean
    ) => void;
};

export type Props = PassedProps & StateProps & ActionProps;

export const DisplaySettingsComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        goBack,
        serialNumber,
        screenOptions,
        deviceConfigs,
        updateLoading,
        updateConfig,
        error,
        fetchConfigError,
        publiclyAvailable,
        language,
        publicUrl,
        units,
    } = props;

    const deviceConfig = deviceConfigs[serialNumber];
    const qrUrl = publicUrl && publicQrUrl(publicUrl, language, units);
    const [validate, setValidation] = useState(false);

    const singleScreenOptions = screenOptions.filter(option => option.singleScreenOption).map(option => option.insight);

    const initialSelectedOptions = (): { [screenNumber: number]: string[] } => {
        const config = { 0: [] };
        if (deviceConfig && deviceConfig.selectedScreens) {
            deviceConfig.selectedScreens.forEach(screenConfig => {
                config[screenConfig.screenIndex] = screenConfig.insights;
            });
        }
        return config;
    };

    const screenNumber = 0;
    const [selectedOptions, setSelectedOptions] = useState<{ [screenNumber: string]: string[] }>(
        initialSelectedOptions()
    );

    useEffect(() => {
        setSelectedOptions(initialSelectedOptions());
    }, [deviceConfig]);

    if (fetchConfigError) {
        return <Error />;
    }

    const onSubmit = (): void => {
        const screens = Object.keys(selectedOptions).map(screenKey => ({
            screenIndex: parseInt(screenKey, 10),
            insights: selectedOptions[screenKey],
        }));
        if (selectedOptions[0].length === 0) {
            setValidation(true);
            return;
        }
        const intervals = batteryIntervals(deviceConfig && deviceConfig.sensorIntervals);
        updateConfig(
            {
                sensorIntervals: intervals,
                screens,
                batteryMode: deviceConfig.batteryMode,
                turboMode: deviceConfig.turboMode,
            },
            serialNumber,
            'latest',
            true
        );
        analyticsLogger(DEVICE_SAVED_DISPLAY_VIEW_PLUS, {
            pageType: PageType.Device,
            selectedOptions: selectedOptions[0],
        });
    };

    const updateSelection = (id: string): void => {
        const selectedScreenOptions: string[] = selectedOptions[0];

        let updatedScreenOptions = selectedScreenOptions;
        if (selectedScreenOptions.includes(id)) {
            updatedScreenOptions = selectedScreenOptions.filter(selectedId => selectedId !== id);
        } else if (!selectedScreenOptions.includes(id) && selectedScreenOptions.length < 2) {
            if (singleScreenOptions.includes(id as ScreenOptions)) updatedScreenOptions = [id];
            else if (selectedScreenOptions.some(option => singleScreenOptions.includes(option))) return;
            else updatedScreenOptions = [...selectedScreenOptions, id];
        }
        setSelectedOptions({ ...selectedOptions, [screenNumber]: updatedScreenOptions });
    };

    const listOption = (
        option: DisplayScreenOptions
    ): { id: string; name: string; icon: React.ReactElement; subText?: string; disabled?: boolean } => {
        const hasSubtext = !!option.subtitle;
        const sensorWithBatteryInterval =
            deviceConfig.sensorIntervals &&
            deviceConfig.sensorIntervals.find(
                batterySensor =>
                    batterySensor.sensorType ===
                    (option.insight === ScreenOptions.noise ? SensorTypes.sla : option.insight)
            );
        const sensorNotAvailable =
            (option.insight === ScreenOptions.publicQr && !publiclyAvailable) ||
            (sensorWithBatteryInterval && sensorWithBatteryInterval.current === 0);

        let subText = hasSubtext ? `DisplaySettings.${option.insight}Description` : undefined;
        if (sensorNotAvailable)
            subText = sensorWithBatteryInterval
                ? 'DisplaySettings.EnableInSettings'
                : `DisplaySettings.${option.insight}Disabled`;

        const icon =
            option.insight === ScreenOptions.blank ? (
                <MaterialIcon extraClass="text-paragraph" name="visibility_off" />
            ) : (
                <SensorIcon sensor={option.iconName} />
            );
        return {
            id: option.id,
            name: txt(`DisplaySettings.${option.title.key}`),
            icon,
            subText,
            disabled: !!sensorNotAvailable,
        };
    };

    const singleWidthOptions = screenOptions
        .filter(option => option.optionOnScreen.includes(screenNumber))
        .filter(option => option.insight !== ScreenOptions.publicQr)
        .map(option => listOption(option));

    const validateMessage = validate && selectedOptions[0].length === 0 && 'DeviceSettings.SelectAtLeastOneInsight';
    const errorMessage = error ? `ErrorCodes.${error.error}` : '';

    const shouldHaveSingleSelectableOption = Object.values(selectedOptions)
        .map(options => options[0])
        .some(option => singleScreenOptions.includes(option as ScreenOptions));

    return (
        <div className="page-wrapper__inner">
            <div className="change-location__form">
                <DisplaySelector
                    selectedOptions={selectedOptions[screenNumber] || []}
                    screenOptions={screenOptions}
                    publicUrl={qrUrl}
                />
                <h3 className="centered">{txt('DeviceSettings.EditScreen')}</h3>
                <p className="text-small centered">{txt('DeviceSettings.EditScreenGuideline')}</p>
                <SelectorList
                    options={singleWidthOptions}
                    selectedOptions={selectedOptions[screenNumber] || []}
                    onSelect={updateSelection}
                    fullHeight
                    maxSelectable={shouldHaveSingleSelectableOption ? 1 : 2}
                    center
                    wide
                />
                {(errorMessage || validateMessage) && <ResponseBox text={validateMessage || errorMessage} />}
                <div className="change-location__form__buttons">
                    <PrimaryButton id="close" title="Close" loading={false} onClick={goBack} />
                    <PrimaryButton
                        id="submit"
                        type="button"
                        title="SaveChanges"
                        filled
                        loading={updateLoading}
                        onClick={onSubmit}
                        testId="submit"
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        deviceConfig: { deviceConfigs, screenOptions },
        userSettings: { language, units },
        commonRequests: {
            [CommonRequestType.FetchDeviceConfig]: { error: fetchConfigError },
            [CommonRequestType.UpdateDeviceConfig]: { loading: updateLoading, error },
        },
    } = state;

    return {
        deviceConfigs,
        screenOptions,
        updateLoading,
        error,
        fetchConfigError,
        language,
        units,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    updateConfig: (
        config,
        serialNumber: string,
        segmentId: string,
        displaySuccessMessage?: boolean
    ): UpdateDeviceConfig => dispatch(updateDeviceConfig(config, serialNumber, segmentId, displaySuccessMessage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySettingsComponent);
