import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { displayAlertBoxSagaAction } from 'commons/src/actions';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ElementsSelectorWithChips from 'commons/src/components/dropdown/ElementsSelectorWithChips';
import Error from 'commons/src/components/errorComponents/Error';
import { userIsHbs } from 'commons/src/components/findUserType';
import CheckBox from 'commons/src/components/input/Checkbox';
import spinner from 'commons/src/img/spinner';
import { ErrorType, LocationType, SelectionItem } from 'commons/src/models/commonTypeScript';
import { Store } from 'commons/src/reducers';
import { toErrorTypeWithMessage } from 'commons/src/sagas/isErrorType';
import { getWeeklyReport, updateWeeklyReport } from '../api/weeklyReportApi';

type StateProps = {
    demoMode: boolean;
    locations: LocationType[];
    locationsLoading: boolean;
};

export type Props = StateProps;

export const WeeklyReportSubscriptionComponent = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { demoMode, locations, locationsLoading } = props;

    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState<SelectionItem[]>([]);
    const [fetchLoading, setFetchLoading] = useState<boolean>(true);
    const [postLoading, setPostLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | undefined>(undefined);

    const onGetWeeklyReport = useCallback(async (): Promise<void> => {
        if (!locationsLoading) {
            await getWeeklyReport()
                .then(response => {
                    const subscribedToLocations: SelectionItem[] = locations
                        .filter(location => response.locations.includes(location.id))
                        .map(location => ({ id: location.id, name: location.name }));

                    setSelectedLocations(subscribedToLocations);
                })
                .catch(err => setError(err));

            setFetchLoading(false);
        }
    }, [locationsLoading]);

    const onUpdateWeeklyReport = useCallback(async (locationIds: string[]): Promise<void> => {
        setPostLoading(true);
        await updateWeeklyReport(locationIds)
            .then(() => {
                dispatch(displayAlertBoxSagaAction('WeeklyReport.WeeklyReportUpdateSuccess', false, true));
            })
            .catch(err => {
                dispatch(displayAlertBoxSagaAction(toErrorTypeWithMessage(err).message, true, true));
            });
        setPostLoading(false);
    }, []);

    useEffect(() => {
        if (locations.length > 0 && !locationsLoading) {
            onGetWeeklyReport().catch();
        }
    }, [locationsLoading]);

    const signupWeeklyReport = (e: React.SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        onUpdateWeeklyReport(selectedLocations.map(location => location.id)).catch();
    };

    const listOfPossibleElements = locations.map(location => ({ id: location.id, name: location.name }));

    const elementsNotSelected = listOfPossibleElements.filter(
        option => !selectedLocations.some(selected => selected.id === option.id)
    );

    const removeFromSelection = (id: string): void => {
        setSelectedLocations(selectedLocations.filter(selectedElement => selectedElement.id !== id));
    };

    const addElementAsSelected = (element: { id: string; name: string }): void => {
        setSelectedLocations([...selectedLocations, element]);
    };

    const toggleAllOptions = (e: SyntheticEvent<HTMLInputElement>): void => {
        const { checked } = e.currentTarget;
        setSelectedLocations(checked ? listOfPossibleElements : []);
    };

    const isHbs = userIsHbs();

    if (error) {
        return <Error />;
    }

    return (
        <>
            <h2 className="settings__header">{txt('WeeklyReport.WeeklyAirReport')}</h2>
            <div className="settings-details-container">
                <form noValidate>
                    <p className="text-large">{txt('WeeklyReport.WeeklyAirReportDescription')}</p>
                    <p className="text-large text-paragraph">
                        {isHbs
                            ? txt('WeeklyReport.SelectBuildingsDescription')
                            : txt('WeeklyReport.SelectLocationDescription')}
                    </p>
                    {locationsLoading || fetchLoading ? (
                        <div className="centered">{spinner}</div>
                    ) : (
                        <>
                            <div className="form__row">
                                <CheckBox
                                    label={
                                        isHbs
                                            ? 'NotificationAlerts.SelectAllBuildings'
                                            : 'WeeklyReport.SelectAllLocations'
                                    }
                                    id="select_all"
                                    onChange={toggleAllOptions}
                                    checked={listOfPossibleElements.length === selectedLocations.length}
                                />
                            </div>
                            <div className="form__row form__row--padded-small">
                                <ElementsSelectorWithChips
                                    onSelect={addElementAsSelected}
                                    onRemove={removeFromSelection}
                                    selectedElements={selectedLocations}
                                    elementsNotSelected={elementsNotSelected}
                                />
                            </div>
                            <div className="form__row">
                                <div className="form__button-container">
                                    <PrimaryButton
                                        id="weeklyReport-subscribe"
                                        title={txt('Save')}
                                        loading={postLoading}
                                        onClick={signupWeeklyReport}
                                        disabled={demoMode}
                                        testId="save-report"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { demoMode },
        locations: { locations, loading: locationsLoading },
    } = store;

    return {
        demoMode,
        locations,
        locationsLoading,
    };
};

export default connect(mapStateToProps)(WeeklyReportSubscriptionComponent);
