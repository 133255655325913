import { call, CallEffect, put, PutEffect, takeEvery, all } from 'redux-saga/effects';
import { displayAlertBoxSagaAction, DisplayAlertBoxSagaAction } from 'commons/src/actions';
import { RequestActionType, requestError, requestSuccess } from 'commons/src/actions/requestActions';
import { businessPaths as paths, customPeriodName } from 'commons/src/constants';
import i18n from 'commons/src/i18n';
import { PropertyType } from 'commons/src/models/commonEnums';
import { DashboardSensorData, DeviceDataType, SensorData } from 'commons/src/models/commonTypeScript';
import RequestActions from 'commons/src/models/RequestTypes';
import { getFetchAttributes } from 'commons/src/sagas/DevicePageSagas/fetchCustomDeviceSegment';
import { toErrorType } from 'commons/src/sagas/isErrorType';
import createGraphArrays from 'commons/src/sagas/pollData/createGraphArrays';
import { setFetchInterval } from 'commons/src/sagas/pollData/pollDeviceData';
import history from 'commons/src/store/history';
import { FetchBuilding, fetchBuilding } from '../actions/locationActions';
import {
    CreateSpace,
    CreateSpaceSuccess,
    createSpaceSuccess,
    DeleteSpace,
    deleteSpaceSuccess,
    DeleteSpaceSuccess,
    EditSpace,
    editSpaceSuccess,
    EditSpaceSuccess,
    FetchSpaces,
    fetchSpacesSuccess,
    FetchSpacesSuccess,
    SpaceActions,
    AddDeviceToSpace,
    AddDeviceToSpaceSuccess,
    addDeviceToSpaceSuccess,
    RemoveDeviceFromSpace,
    removeDeviceFromSpaceSuccess,
    RemoveDeviceFromSpaceSuccess,
    MoveDeviceBetweenSpaces,
    moveDeviceBetweenSpacesSuccess,
    MoveDeviceBetweenSpacesSuccess,
    FetchSpaceSensorData,
    fetchSpaceSensorDataSuccess,
    fetchSpaceVirtualSensorDataSuccess,
} from '../actions/spaceActions';
import fetchSpaces, {
    createSpace,
    deleteSpace,
    editSpace,
    addDeviceToSpace,
    deleteDeviceFromSpace,
    moveDeviceBetweenSpaces,
    GetSpacesResponse,
    migrateToSpaces,
    fetchSpaceSensorData,
    fetchSpaceVirtualSensorData,
} from '../api/spaceApi';
import { PropertyPayload, RoomProperties, SpaceDeviceEntity, SpaceDataResponse } from '../models/spaceModels';
import { BusinessRequestType as RequestType } from '../reducers/BusinessRequestType';

const getProperties = (propertyPayload: PropertyPayload[]): { [key: string]: RoomProperties } =>
    propertyPayload.reduce((combined, current) => {
        const value = current.value.toString();
        const roomProperty: RoomProperties =
            current.propertyType === PropertyType.Selection ? { value: '', optionId: value } : { value };
        return {
            ...combined,
            [current.propertyDefinitionId]: roomProperty,
        };
    }, {});

type CreateSpaceSagaReturnType = Generator<
    CallEffect<{ id: string }> | PutEffect<CreateSpaceSuccess> | RequestActions | void,
    void,
    { id: string }
>;
export function* createSpaceSaga({ newSpace, locationId, redirectToSpace }: CreateSpace): CreateSpaceSagaReturnType {
    try {
        const { id } = yield call(createSpace, newSpace, locationId);
        const properties = getProperties(newSpace.properties);
        yield put(
            createSpaceSuccess({
                name: newSpace.name,
                placement: newSpace.placement,
                id,
                locationId,
                devices: [],
                properties,
                endedSegments: [],
            })
        );
        yield put(requestSuccess(RequestType.CreateSpace, RequestActionType.Success));
        if (redirectToSpace) {
            yield history.push(`/${paths.buildings}/${locationId}/spaces/${id}`);
        }
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.CreateSpace, RequestActionType.Error));
    }
}

type FetchSpacesSagaReturnType = Generator<
    CallEffect<GetSpacesResponse> | PutEffect<FetchSpacesSuccess> | RequestActions,
    void,
    GetSpacesResponse
>;
export function* fetchSpacesSaga({ locationId }: FetchSpaces): FetchSpacesSagaReturnType {
    try {
        const response: GetSpacesResponse = yield call(fetchSpaces, locationId);
        yield put(fetchSpacesSuccess(locationId, response.spaces, response.availableSensors));
        yield put(requestSuccess(RequestType.FetchSpaces, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchSpaces, RequestActionType.Error));
    }
}

type EditSpaceSagaReturnType = Generator<CallEffect<void> | PutEffect<EditSpaceSuccess> | RequestActions, void, void>;
export function* editSpaceSaga({ space }: EditSpace): EditSpaceSagaReturnType {
    try {
        yield call(editSpace, space);
        const properties = getProperties(space.properties);
        yield put(
            editSpaceSuccess({
                ...space,
                id: space.spaceId,
                properties,
            })
        );
        yield put(requestSuccess(RequestType.EditSpace, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.EditSpace, RequestActionType.Error));
    }
}

type DeleteSpaceSagaReturnType = Generator<
    CallEffect<void> | PutEffect<DeleteSpaceSuccess> | RequestActions | PutEffect<DisplayAlertBoxSagaAction>,
    void,
    void
>;

export function* deleteSpaceSaga({ space }: DeleteSpace): DeleteSpaceSagaReturnType {
    try {
        yield call(deleteSpace, space);
        yield put(deleteSpaceSuccess(space));
        yield put(requestSuccess(RequestType.DeleteSpace, RequestActionType.Success));
        yield put(displayAlertBoxSagaAction('Space.SpaceDeleted', false, true));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.DeleteSpace, RequestActionType.Error));
    }
}

type AddDeviceToSpaceSagaReturnType = Generator<
    CallEffect<SpaceDeviceEntity> | PutEffect<AddDeviceToSpaceSuccess> | PutEffect<FetchBuilding> | RequestActions,
    void,
    SpaceDeviceEntity
>;

export function* addDeviceToSpaceSaga({
    locationId,
    spaceId,
    payload,
}: AddDeviceToSpace): AddDeviceToSpaceSagaReturnType {
    try {
        const response: SpaceDeviceEntity = yield call(addDeviceToSpace, locationId, spaceId, payload);
        yield put(addDeviceToSpaceSuccess(locationId, spaceId, response));
        // Refetch key info on success
        yield put(fetchBuilding(locationId));

        yield put(requestSuccess(RequestType.AddDeviceToSpace, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.AddDeviceToSpace, RequestActionType.Error));
    }
}

type RemoveDeviceFromSpaceSagaReturnType = Generator<
    CallEffect | PutEffect<RemoveDeviceFromSpaceSuccess> | PutEffect<DisplayAlertBoxSagaAction> | RequestActions,
    void
>;

export function* removeDeviceFromSpaceSaga({ payload }: RemoveDeviceFromSpace): RemoveDeviceFromSpaceSagaReturnType {
    try {
        yield call(deleteDeviceFromSpace, payload);
        yield put(removeDeviceFromSpaceSuccess(payload.locationId, payload.spaceId, payload.serialNumber));
        yield put(requestSuccess(RequestType.RemoveDeviceFromSpace, RequestActionType.Success));

        yield put(displayAlertBoxSagaAction('Space.RemoveDeviceSuccess', false, true));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.RemoveDeviceFromSpace, RequestActionType.Error));
    }
}

// Move devices

type MoveDevicesBetweenSpacesSagaReturnType = Generator<
    | CallEffect
    | PutEffect<MoveDeviceBetweenSpacesSuccess>
    | PutEffect<DisplayAlertBoxSagaAction>
    | RequestActions
    | Promise<void>,
    void
>;

export function* moveDeviceBetweenSpacesSaga({
    locationId,
    spaceId,
    serialNumber,
    payload,
    spaceName,
}: MoveDeviceBetweenSpaces): MoveDevicesBetweenSpacesSagaReturnType {
    try {
        yield call(moveDeviceBetweenSpaces, locationId, spaceId, serialNumber, payload);
        yield put(moveDeviceBetweenSpacesSuccess(locationId, spaceId, serialNumber, payload));
        yield put(requestSuccess(RequestType.MoveDeviceBetweenSpaces, RequestActionType.Success));
        yield put(displayAlertBoxSagaAction(i18n.t('Space.MoveDeviceSuccess', { spaceName }), false, true));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.MoveDeviceBetweenSpaces, RequestActionType.Error));
    }
}

export function* migrateToSpacesSaga(): Generator {
    try {
        yield call(migrateToSpaces);
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.MigrateToSpaces, RequestActionType.Error));
    }
}

type FetchSpaceSensorDataReturnType = Generator<
    | CallEffect<{ from: string; to: string; lastDataOlderThanResolutionDuration: boolean }>
    | CallEffect<SpaceDataResponse>
    | PutEffect
    | RequestActionType,
    void,
    SpaceDataResponse & { [serialNumber: string]: SensorData } & {
        from: string;
        to: string;
        lastDataOlderThanResolutionDuration: boolean;
    }
>;
export function* fetchSpaceSensorDataSaga({
    locationId,
    spaceId,
    selectedInterval,
}: FetchSpaceSensorData): FetchSpaceSensorDataReturnType {
    try {
        const prevDeviceData = {};
        let fetchAttrs: { from: string; to: string; lastDataOlderThanResolutionDuration?: boolean };
        if (selectedInterval.name === customPeriodName && selectedInterval.startDate && selectedInterval.endDate) {
            fetchAttrs = getFetchAttributes(selectedInterval);
        } else {
            fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData as DashboardSensorData);
        }
        const spaceData = yield call(fetchSpaceSensorData, locationId, spaceId, {
            from: fetchAttrs.from,
            to: fetchAttrs.to,
            resolution: selectedInterval.resolution,
        });
        const sensorData = spaceData.devices.map(device =>
            createGraphArrays(device, selectedInterval, prevDeviceData as DashboardSensorData, { device: undefined })
        );
        yield put(
            fetchSpaceSensorDataSuccess(
                locationId,
                spaceId,
                {
                    ...spaceData,
                    devices: sensorData as DeviceDataType[],
                },
                selectedInterval.name
            )
        );
        yield put(requestSuccess(RequestType.FetchSpaceSensorData, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchSpaceSensorData, RequestActionType.Error));
    }
}

export function* fetchSpaceVirtualSensorDataSaga({
    locationId,
    spaceId,
    selectedInterval,
}: FetchSpaceSensorData): FetchSpaceSensorDataReturnType {
    try {
        const prevDeviceData = {};
        let fetchAttrs: { from: string; to: string; lastDataOlderThanResolutionDuration?: boolean };
        if (selectedInterval.name === customPeriodName && selectedInterval.startDate && selectedInterval.endDate) {
            fetchAttrs = getFetchAttributes(selectedInterval);
        } else {
            fetchAttrs = yield call(setFetchInterval, selectedInterval, prevDeviceData as DashboardSensorData);
        }
        const spaceData = yield call(fetchSpaceVirtualSensorData, locationId, spaceId, {
            from: fetchAttrs.from,
            to: fetchAttrs.to,
            resolution: selectedInterval.resolution,
        });
        const sensorData = spaceData.devices.map(device =>
            createGraphArrays(device, selectedInterval, prevDeviceData as DashboardSensorData, { device: undefined })
        );
        yield put(
            fetchSpaceVirtualSensorDataSuccess(
                locationId,
                spaceId,
                {
                    ...spaceData,
                    devices: sensorData as DeviceDataType[],
                },
                selectedInterval.name
            )
        );
        yield put(requestSuccess(RequestType.FetchSpaceVirtualSensorData, RequestActionType.Success));
    } catch (error) {
        const asErrorType = toErrorType(error);
        yield put(requestError(asErrorType, RequestType.FetchSpaceVirtualSensorData, RequestActionType.Error));
    }
}

export default function* spaceSagas(): Generator {
    yield all([
        takeEvery(SpaceActions.CreateSpaceInit, createSpaceSaga),
        takeEvery(SpaceActions.FetchSpacesInit, fetchSpacesSaga),
        takeEvery(SpaceActions.EditSpaceInit, editSpaceSaga),
        takeEvery(SpaceActions.DeleteSpaceInit, deleteSpaceSaga),
        takeEvery(SpaceActions.AddDeviceToSpaceInit, addDeviceToSpaceSaga),
        takeEvery(SpaceActions.RemoveDeviceFromSpaceInit, removeDeviceFromSpaceSaga),
        takeEvery(SpaceActions.MoveDeviceBetweenSpacesInit, moveDeviceBetweenSpacesSaga),
        takeEvery(SpaceActions.MigrateToSpacesInit, migrateToSpacesSaga),
        takeEvery(SpaceActions.FetchSpaceSensorDataInit, fetchSpaceSensorDataSaga),
        takeEvery(SpaceActions.FetchSpaceVirtualSensorDataInit, fetchSpaceVirtualSensorDataSaga),
    ]);
}
