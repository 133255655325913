import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import Error from 'commons/src/components/errorComponents/Error';
import { fullwidthListElement } from 'commons/src/components/placeholder';
import useWindowSize from 'commons/src/hooks/useWindowSize';
import { TimePeriod } from 'commons/src/models/commonEnums';
import { ErrorType, Units } from 'commons/src/models/commonTypeScript';
import { fetchPartnerSummaryCSVUrls } from '../../../api/partnerDetails';
import { Store } from '../../../reducers';
import { fetchPartnerSummaryCSVData } from './PartnerSummaryApi';
import PartnerSummaryTimeSelector from './PartnerSummaryDateSelector';
import PartnerSummaryDesktop from './PartnerSummaryDesktop';
import PartnerSummaryMobile from './PartnerSummaryMobile';
import { PartnerSummaryCSVData, PartnerSummaryThresholdData, SelectedPeriod } from './PartnerSummaryModels';
import { getThresholdBreachCounts, averageValuesFromMultipleLists, parseCSV } from './PartnerSummaryUtils';

const MOBILE_TABLE_BREAKPOINT = 1000;

type StateProps = {
    loadingUser: boolean;
    selectedUserGroupId?: string;
    dateFormat: string;
    units: Units;
};
type Props = StateProps;

const PartnerSummary = ({ loadingUser, selectedUserGroupId, dateFormat, units }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const { width } = useWindowSize();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorType | undefined>(undefined);

    const [dataPerDevice, setDataPerDevice] = useState<PartnerSummaryCSVData[]>([]);
    const [dataPerThreshold, setDataPerThreshold] = useState<PartnerSummaryThresholdData | undefined>(undefined);

    const [selectedPeriod, setSelectedPeriod] = useState<SelectedPeriod>({
        toDate: moment().subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
        fromDate: moment().subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
        name: TimePeriod.yesterday,
    });

    const onFetchSummary = useCallback(async (userGroupId?: string, from?: string, to?: string): Promise<void> => {
        if (userGroupId) {
            setLoading(true);

            const fromDate = from || selectedPeriod.fromDate;
            const toDate = to || selectedPeriod.toDate;

            const urls = await fetchPartnerSummaryCSVUrls(fromDate, toDate)
                .then(response => response.urls)
                .catch(err => {
                    setError(err);
                    return null;
                });

            if (urls === null) return;

            await Promise.all(urls.map(url => fetchPartnerSummaryCSVData(url)))
                .then(res => {
                    const parsedCvs: PartnerSummaryCSVData[][] = res.map(it => parseCSV(it));
                    const mergedCsv: PartnerSummaryCSVData[] = averageValuesFromMultipleLists(parsedCvs);

                    setDataPerDevice(mergedCsv);
                    setDataPerThreshold(getThresholdBreachCounts(mergedCsv, units));
                })
                .catch(err => {
                    setError(err);
                    setLoading(false);
                });

            setLoading(false);
        }
    }, []);

    useEffect((): void => {
        onFetchSummary(selectedUserGroupId).catch();
    }, [selectedUserGroupId]);

    if (error) {
        return <Error />;
    }

    const selectPeriod = (period: TimePeriod, from: string, to: string): void => {
        setSelectedPeriod({
            fromDate: moment(from).format('YYYY-MM-DD'),
            toDate: moment(to).subtract(1, TimePeriod.day).format('YYYY-MM-DD'),
            name: period,
        });
        onFetchSummary(selectedUserGroupId, from, to).catch();
    };

    return (
        <div className="container">
            <h2 className="margin-20-bottom">{txt('Partner.SummaryAverages')}</h2>
            <PartnerSummaryTimeSelector selectedPeriod={selectedPeriod.name} setSelectedPeriod={selectPeriod} />
            <ReactPlaceholder
                ready={!loading && !loadingUser}
                className="container"
                customPlaceholder={fullwidthListElement}
            >
                {dataPerThreshold !== undefined &&
                    (width > MOBILE_TABLE_BREAKPOINT ? (
                        <PartnerSummaryDesktop
                            dataPerDevice={dataPerDevice}
                            dateFormat={dateFormat}
                            selectedPeriod={selectedPeriod}
                            dataPerThreshold={dataPerThreshold}
                            units={units}
                        />
                    ) : (
                        <PartnerSummaryMobile
                            perDeviceData={dataPerDevice}
                            dateFormat={dateFormat}
                            selectedPeriod={selectedPeriod}
                            perThresholdData={dataPerThreshold}
                            units={units}
                        />
                    ))}
            </ReactPlaceholder>
        </div>
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        userSettings: { selectedGroup, loading: loadingUser, dateFormat, units },
    } = state;

    return {
        selectedUserGroupId: selectedGroup && selectedGroup.id,
        loadingUser,
        dateFormat,
        units,
    };
};

export default connect(mapStateToProps)(PartnerSummary);
