// disable-eslint camelcase
import moment from 'moment';
import { matchRoutes, RouteObject } from 'react-router-dom';
import { put, call, select, PutEffect, CallEffect, SelectEffect } from 'redux-saga/effects';
import { loginSuccess, loginFailure, logOut } from '../../actions/LoginAndRegisterActions';
import fetchTokens, { refreshAccessToken } from '../../api/accountsApiTokens';
import { getQueryParams } from '../../commonFunctions';
import { businessPaths as paths, publicHomeReportPath, spinnerPath, unsubscribeFromAlertPath } from '../../constants';
import { TokenResponse } from '../../models/commonTypeScript';
import { isLoggedIn } from '../../reducers/reducerShortcuts';
import history from '../../store/history';
import { logoutAfterIdleTime } from './logout';

export interface Tokens {
    accessToken: string;
    refreshToken: string;
    expiresAt: number;
}

export const updateStorage = (response: Tokens, expiresAt: number): void => {
    localStorage.setItem('accessToken', response.accessToken);
    if (response.refreshToken) localStorage.setItem('refreshToken', response.refreshToken);
    localStorage.setItem('expiresAt', String(expiresAt));
};

const cleanUpUrl = (searchQuery: string): void => {
    const currentLocation = window.location.pathname;
    const pathsNotToClean: RouteObject[] = [
        { path: `/${paths.addThirdPartyIntegration}` },
        { path: unsubscribeFromAlertPath },
        { path: `/${paths.thresholdBreachReport}` },
        { path: `/${paths.addDevice}` },
        { path: `/${paths.buildingPage}` },
    ];
    const doNotClean = matchRoutes(pathsNotToClean, currentLocation);

    if (!doNotClean || doNotClean.length === 0) {
        let queryString = '';
        const searchParamsLinkToken = getQueryParams(searchQuery).token;
        if (searchParamsLinkToken) queryString = `?token${searchParamsLinkToken}`;
        const intercomProductTourQueryParam = getQueryParams(searchQuery).product_tour_id;
        if (intercomProductTourQueryParam) {
            queryString =
                queryString.length > 0
                    ? `${queryString}&product_tour_id=${intercomProductTourQueryParam}`
                    : `?product_tour_id=${intercomProductTourQueryParam}`;
        }

        const pathName = queryString.length > 0 ? `${currentLocation}${queryString}` : currentLocation;
        window.history.replaceState({}, document.title, pathName);
    }
};

const getCode = (searchQuery: string): string | undefined => {
    const { code } = getQueryParams(searchQuery);
    const deeplink = getQueryParams(searchQuery).state;
    if (code) {
        cleanUpUrl(searchQuery);
        if (deeplink) {
            const decodedDeepLink = atob(deeplink);
            history.push(decodedDeepLink);
        }
    }
    return code;
};

const redirectExemptedPaths = new Set([publicHomeReportPath, unsubscribeFromAlertPath, spinnerPath]);

export default function* authenticateLogin(): Generator<PutEffect | CallEffect | SelectEffect, void, TokenResponse> {
    const searchQuery = window.location.search;
    try {
        localStorage.setItem('fetchingTokens', 'false');
        const loggedIn = yield select(isLoggedIn);
        if (!loggedIn && redirectExemptedPaths.has(window.location.pathname)) return;
        const refreshToken = localStorage.getItem('refreshToken');
        const expireTime = localStorage.getItem('expiresAt');
        let expiresAt = expireTime ? parseInt(expireTime, 10) : undefined;
        const now = moment().unix();
        const code = getCode(searchQuery);
        if (window.location.pathname === '/registration') {
            yield put(logOut());
        } else if (code) {
            localStorage.setItem('fetchingTokens', 'true');
            const response = yield call(fetchTokens, code);
            expiresAt = now + response.expires_in;
            const responseObject: Tokens = {
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
                expiresAt,
            };
            yield call(updateStorage, responseObject, expiresAt);
            yield put(loginSuccess());
            localStorage.setItem('fetchingTokens', 'false');
        } else if (!refreshToken) {
            yield put(logOut());
        } else if (!expiresAt || now >= expiresAt) {
            const response = yield call(refreshAccessToken, refreshToken);
            expiresAt = now + response.expires_in;
            const responseObject = {
                accessToken: response.access_token,
                refreshToken: response.refresh_token,
                expiresAt,
            };
            yield call(updateStorage, responseObject, expiresAt);
            yield put(loginSuccess());
        } else if (!loggedIn) {
            yield put(loginSuccess());
            cleanUpUrl(searchQuery);
        }
        const accessToken = localStorage.getItem('accessToken');
        yield call(logoutAfterIdleTime, accessToken);
    } catch (error) {
        yield put(loginFailure(error));
        yield put(logOut());
    }
}
