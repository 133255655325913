import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { analyticsLogger } from 'commons/src/analytics';
import { ACCOUNT_DOWNLOAD_USER_LIST } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import MaterialIcon from 'commons/src/components/MaterialIcon';
import ReactPdfDownloadModal from 'commons/src/components/PDF/ReactPdfDownloadModal';
import { userlane } from 'commons/src/components/placeholder';
import { Role } from 'commons/src/models/commonEnums';
import { Group, LocationType } from 'commons/src/models/commonTypeScript';
import {
    deleteInvite,
    DeleteInvite,
    removeMember,
    RemoveMember,
    resendInvite,
    ResendInvite,
} from '../../../actions/organizationMemberActions';
import { InvitedMember, Member } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import styles from './MemberList.module.scss';
import MemberListRow from './MemberListRow';

type StateProps = {
    userId: string | undefined;
    organizationData: { members: Member[]; invited: InvitedMember[] };
    groups: Group[];
    subject: Member | undefined;
    loadingLocations: boolean;
    loadingMembers: boolean;
    deleteInviteLoadingId?: string;
    resendInviteLoadingId?: string;
    removeMemberLoadingId?: string;
    selectedGroup?: Group;
    locations: LocationType[];
};

interface ActionProps {
    onResendInvite: (inviteId: string) => void;
    onDeleteInvite: (inviteId: string) => void;
    onRemoveMember: (userId: string) => void;
}

export type Props = StateProps & ActionProps;

export const MemberListComponent = (props: Props): React.ReactElement => {
    const {
        organizationData,
        userId,
        subject,
        groups,
        loadingLocations,
        onResendInvite,
        onDeleteInvite,
        onRemoveMember,
        loadingMembers,
        deleteInviteLoadingId,
        resendInviteLoadingId,
        removeMemberLoadingId,
        selectedGroup,
        locations,
    } = props;
    const { t: txt } = useTranslation();
    const [downloadMemberListModalOpen, setDownloadMemberListModalOpen] = useState(false);

    const members =
        organizationData?.members
            .filter(member => member.userId !== subject?.userId)
            .sort((memberA, memberB) => memberA.name.localeCompare(memberB.name)) || [];

    const invited =
        organizationData?.invited.sort((memberA, memberB) => memberA.name.localeCompare(memberB.name)) || [];
    const resendInviteHandler = (inviteId: string) => (): void => onResendInvite(inviteId);
    const deleteInviteHandler = (inviteId: string) => (): void => onDeleteInvite(inviteId);

    const isLoading = !userId || loadingLocations || loadingMembers;
    const numberOfOrganizationMemberships = (groups || []).filter(orgGroup => !!orgGroup.organizationId).length;

    const onDownloadMemberList = (): void => {
        analyticsLogger(ACCOUNT_DOWNLOAD_USER_LIST, { Role: selectedGroup?.role });
        setDownloadMemberListModalOpen(true);
    };

    const userIsAdmin = selectedGroup?.role === Role.ADMIN;
    const memberListData = members.map(member => ({
        name: member.name,
        email: member.email,
        role: member.role === Role.USER ? 'EDITOR' : member.role,
        accessTo:
            member.resources?.locations.map(
                locationId => locations.find(location => location.id === locationId)?.name
            ) || 'All buildings',
    }));
    const memberListCSVFileName = `Members_of_${selectedGroup?.groupName}`;

    return (
        <div>
            {subject && (
                <div className="list__margin-top">
                    <MemberListRow
                        removeIsDisabled={subject.userId === userId && numberOfOrganizationMemberships < 2}
                        key={subject.userId}
                        user={subject}
                        loadingRemoveMember={subject.userId === removeMemberLoadingId}
                        onRemoveMember={(): void => onRemoveMember(subject.userId)}
                        subject={subject}
                    />
                </div>
            )}
            {downloadMemberListModalOpen && (
                <ReactPdfDownloadModal
                    title="UserList.DownloadUserList"
                    description="UserList.DownloadUserListDescription"
                    onClose={(): void => setDownloadMemberListModalOpen(false)}
                >
                    <div className="form__button-container">
                        <CSVLink data={memberListData} separator=";" filename={`${memberListCSVFileName}.csv`}>
                            <MaterialIcon name="download" />
                            {memberListCSVFileName}
                        </CSVLink>
                    </div>
                </ReactPdfDownloadModal>
            )}
            <div className={styles.membersHeader}>
                <h2>{txt('Active')}</h2>
                {members.length > 0 && userIsAdmin && (
                    <PrimaryButton
                        onClick={onDownloadMemberList}
                        noBackground
                        icon={<MaterialIcon name="download" />}
                        title="UserList.UserList"
                    />
                )}
            </div>
            <ul className="list list__margin">
                <ReactPlaceholder ready={!isLoading} customPlaceholder={userlane}>
                    {members.map(member => (
                        <MemberListRow
                            removeIsDisabled={member.userId === userId && numberOfOrganizationMemberships < 2}
                            key={`active-member-${member.userId}`}
                            user={member}
                            loadingRemoveMember={member.userId === removeMemberLoadingId}
                            onRemoveMember={(): void => onRemoveMember(member.userId)}
                            subject={subject}
                        />
                    ))}
                </ReactPlaceholder>
            </ul>
            <h2>{txt('Pending')}</h2>
            <ul className="list">
                <ReactPlaceholder ready={!isLoading} customPlaceholder={userlane}>
                    {invited.map(member => (
                        <MemberListRow
                            removeIsDisabled={member.userId === userId}
                            key={`pending-member-invite-${member.userId}-${member.inviteId}`}
                            user={member}
                            loadingDeleteInvite={member.inviteId === deleteInviteLoadingId}
                            loadingResendInvite={member.inviteId === resendInviteLoadingId}
                            onResendInvite={resendInviteHandler(member.inviteId)}
                            onDeleteInvite={deleteInviteHandler(member.inviteId)}
                            subject={subject}
                        />
                    ))}
                    {invited.length === 0 ? txt('NoPendingInvites') : null}
                </ReactPlaceholder>
            </ul>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { userId, groups, selectedGroup },
        settings: { members, invited },
        locations: { locations, loading: loadingLocations },
        requests: {
            [RequestType.GetOrganizationMembers]: { loading: loadingMembers },
            [RequestType.DeleteInvite]: { loadingId: deleteInviteLoadingId },
            [RequestType.ResendInvite]: { loadingId: resendInviteLoadingId },
            [RequestType.RemoveMember]: { loadingId: removeMemberLoadingId },
        },
    } = store;

    const subject = members.find(member => member.userId === userId);
    return {
        userId,
        organizationData: { members, invited },
        subject,
        groups,
        loadingLocations,
        loadingMembers,
        deleteInviteLoadingId,
        resendInviteLoadingId,
        removeMemberLoadingId,
        selectedGroup,
        locations,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onResendInvite: (inviteId: string): ResendInvite => dispatch(resendInvite(inviteId)),
    onDeleteInvite: (inviteId: string): DeleteInvite => dispatch(deleteInvite(inviteId)),
    onRemoveMember: (userId: string): RemoveMember => dispatch(removeMember(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberListComponent);
