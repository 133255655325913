import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import ModalHeader from './ModalHeader';
import styles from './ModalWrapper.module.scss';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    children: ReactElement;
    header?: string;
    customHeader?: ReactElement;
    size: 'small' | 'medium' | 'large';
    className?: string;
};
const ModalWrapper = ({ isOpen, onClose, children, header, customHeader, size, className }: Props): ReactElement => {
    return (
        <Modal
            isOpen={isOpen}
            appElement={document.body}
            onRequestClose={onClose}
            className={classNames(styles[size], className)}
            overlayClassName={styles.overlay}
        >
            {customHeader || (header && <ModalHeader headerText={header} onClose={onClose} />)} {children}
        </Modal>
    );
};

export default ModalWrapper;
