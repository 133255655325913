import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkButton from 'commons/src/components/buttons/LinkButton';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import { userRoleAboveRequiredLevel } from 'commons/src/features/authorization/userRoleAboveRequiredLevel';
import { RequiredRoleLevel, Role } from 'commons/src/models/commonEnums';
import SpacesIllustration from '../../../img/spaces-illustration';
import styles from './SpacesEmpty.module.scss';

type Props = {
    userRole?: Role;
    requiredRoleLevel: RequiredRoleLevel;
    onClick: () => void;
};

const intercomLink =
    'https://businesshelp.airthings.com/en/articles/172990-introducing-spaces-a-simple-way-to-map-your-entire-building-portfolio';

const SpacesEmpty = ({ userRole, requiredRoleLevel, onClick }: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const accessToAddDevice = userRole && userRoleAboveRequiredLevel(userRole, requiredRoleLevel);

    return (
        <div className={styles.noSpaces}>
            <SpacesIllustration />
            <div className={styles.title}>{txt('AddSpace.AddYourFirstSpace')}</div>
            <div className={styles.subText}>{txt('AddSpace.MapSpaces')}</div>
            <div className={styles.buttons}>
                {accessToAddDevice ? (
                    <PrimaryButton id="AddSpaceButton" onClick={onClick} filled title="AddSpace.AddSpace" />
                ) : (
                    <p className={styles.roleText}>
                        {txt('Access.RoleDoesNotHaveAccess', { role: txt(userRole || '') })}
                    </p>
                )}
                <LinkButton title="AddSpace.LearnMore" link={intercomLink} />
            </div>
        </div>
    );
};

export default SpacesEmpty;
