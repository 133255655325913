import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getSensorPillStatus } from '../../commonFunctions';
import { sensorUnits } from '../../constants';
import { PillStatus, SensorTypes } from '../../models/commonEnums';
import DeviceHealthStatus from '../../models/deviceHealthStatus';
import StatusPill from '../pills/StatusPill';
import { SensorIcon } from './SensorUnit';
import styles from './SpaceSensorValue.module.scss';

export type Props = {
    value?: number;
    unit: string;
    type: SensorTypes;
    thresholds: number[];
    showSensorType: boolean;
    withGreenStatus?: boolean;
    healthStatus: DeviceHealthStatus;
};

const SpaceSensorValue = ({
    value,
    thresholds,
    unit,
    type,
    showSensorType,
    withGreenStatus,
    healthStatus,
}: Props): ReactElement => {
    const { t: txt } = useTranslation();
    const valueIsNaN = value === undefined || value === null || Number.isNaN(value);
    const { status, icon } = getSensorPillStatus(thresholds, type, value);
    const showStatus: boolean =
        thresholds.length > 0
            ? [PillStatus.warning, PillStatus.alert, withGreenStatus === true ? [PillStatus.success] : []]
                  .flat()
                  .includes(status)
            : false;
    return (
        <div className={styles.sensorBlock}>
            <div className={styles.valueBlock}>
                <div className={styles.value}>
                    {valueIsNaN || healthStatus === DeviceHealthStatus.offline ? '-' : value}
                    <div className={styles.unit}>{sensorUnits[unit as keyof typeof sensorUnits]}</div>
                </div>
                {showStatus && <StatusPill status={status} outline size="small" icon={icon} />}
            </div>
            {showSensorType && (
                <div className={styles.sensorType}>
                    <SensorIcon sensor={type} />
                    {txt(type)}
                </div>
            )}
        </div>
    );
};

export default SpaceSensorValue;
