import React from 'react';
import * as Sentry from '@sentry/browser';
import { sensorUnits } from '../../constants';
import { SensorTypes } from '../../models/commonEnums';
import SensorUnit, { SensorUnitOnlyName } from './SensorUnit';

type SensorComponentProps = {
    dotColor: string;
    value?: number;
    sensorType: SensorTypes | 'radonLongTermAvg' | 'radon';
    unit: keyof typeof sensorUnits;
    displayStatusBar: boolean;
    isRadonAverage?: boolean;
    customSensorName?: string;
};

export const SensorComponent = ({
    dotColor,
    value,
    sensorType,
    unit,
    displayStatusBar,
    isRadonAverage,
    customSensorName,
}: SensorComponentProps): React.ReactElement | null => {
    switch (sensorType) {
        case SensorTypes.humidity:
        case SensorTypes.outdoorHumidity:
        case SensorTypes.temp:
        case SensorTypes.outdoorTemp:
        case SensorTypes.light:
        case SensorTypes.occupants:
        case SensorTypes.mold:
        case SensorTypes.ventilationRate:
        case SensorTypes.virusRisk:
        case SensorTypes.staleAir:
        case SensorTypes.transmissionEfficiency:
        case SensorTypes.virusSurvivalRate:
            return (
                <SensorUnit
                    sensor={sensorType}
                    dotColor={dotColor}
                    sensorValue={value}
                    unit={unit as string}
                    displayStatusBar={displayStatusBar}
                    unitTop={false}
                />
            );
        case SensorTypes.totalPowerLost:
        case SensorTypes.moistGuard:
        case SensorTypes.potentialPowerSaved:
        case SensorTypes.potentialPowerSavedPercent:
        case SensorTypes.zeroHeightPercent:
        case SensorTypes.controlSignal:
            return (
                <SensorUnit
                    sensor={sensorType}
                    dotColor=""
                    sensorValue={value}
                    unit={unit as string}
                    displayStatusBar={false}
                    unitTop={false}
                />
            );
        case SensorTypes.regulationHeight:
        case SensorTypes.regulationPressure:
            return (
                <SensorUnit
                    sensor={sensorType}
                    dotColor=""
                    sensorValue={value}
                    unit={unit as string}
                    displayStatusBar={false}
                    unitTop
                />
            );
        case SensorTypes.co2:
        case SensorTypes.pressure:
        case SensorTypes.outdoorPressure:
        case SensorTypes.voc:
        case SensorTypes.hourlyRadon:
        case SensorTypes.hourlyRadonStandardDeviation:
        case SensorTypes.pm1:
        case SensorTypes.pm10:
        case SensorTypes.pm25:
        case SensorTypes.lux:
        case SensorTypes.outdoorPm1:
        case SensorTypes.outdoorPm10:
        case SensorTypes.outdoorPm25:
        case SensorTypes.sla:
        case SensorTypes.noise:
        case SensorTypes.historicVentilationRunning:
        case SensorTypes.ventilationRunning:
        case SensorTypes.ventilationAmount:
            return (
                <SensorUnit
                    sensor={sensorType}
                    dotColor={dotColor}
                    sensorValue={value}
                    unit={unit as string}
                    displayStatusBar={displayStatusBar}
                    unitTop
                />
            );
        case SensorTypes.radonShortTermAvg:
        case 'radonLongTermAvg':
        case SensorTypes.radon:
            return (
                <SensorUnit
                    sensor="radon"
                    isRadonAvg={isRadonAverage}
                    dotColor={dotColor}
                    sensorValue={value}
                    unit={unit as string}
                    displayStatusBar={displayStatusBar}
                    unitTop
                />
            );
        case SensorTypes.airExchangeRate:
        case SensorTypes.occupancy:
        case SensorTypes.presence:
            return <SensorUnitOnlyName name={customSensorName || sensorType} sensorType={sensorType} />;
        default:
            Sentry.captureEvent({
                message: `Unknown sensorType: ${sensorType}`,
                level: 'debug',
            });
            return null;
    }
};

export default SensorComponent;
