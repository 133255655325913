import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { analyticsLogger } from '../../analytics';
import { SUBSCRIPTION_LIMITED, SUBSCRIPTION_SOON_LIMITED } from '../../analytics/AnalyticsEvents';
import { limitSubscription, limitWithinAWeek } from '../../commonFunctions';
import { userIsHbs } from '../../components/findUserType';
import { dateFormats } from '../../constants';
import { SubscriptionPlan } from '../../models/commonTypeScript';
import { Store } from '../../reducers';
import InactiveSubscriptionModal from './InactiveSubscriptionModal';
import styles from './InactiveSubscriptionPageBlur.module.scss';

type StateProps = {
    subscriptionPlan?: SubscriptionPlan;
    dateFormat: keyof typeof dateFormats;
};

type ParentProps = {
    displayTabs: boolean;
};

const listOfAlwaysAvailable = (
    buildingId: string | undefined,
    serialNumber: string | undefined,
    childUserGroupId: string | undefined,
    subscriptionNr: string | undefined,
    spaceId: string | undefined
): string[] => [
    '/',
    '/buildings',
    '/buildings/add-building',
    '/settings',
    '/settings/security',
    '/settings/organization',
    '/settings/user',
    '/settings/subscription',
    '/settings/subscription/start-subscription',
    `settings/subscription/add-seats/${subscriptionNr}`,
    '/integrations',
    `/buildings/${buildingId}`,
    `/buildings/${buildingId}/spaces`,
    `/buildings/${buildingId}/spaces/${spaceId}`,
    `/buildings/${buildingId}/devices`,
    `/devices/${serialNumber}/change-location`,
    `/buildings/${buildingId}/settings`,
    '/404',
    '/unsupported',
    '/forbidden',
    '/home-report',
    '/partner',
    `/partner/${childUserGroupId}`,
];

export const InactiveSubscriptionPageBlurComponent = ({
    displayTabs,
    subscriptionPlan,
    dateFormat,
}: StateProps & ParentProps): React.ReactElement => {
    const location = useLocation();
    const { t: txt } = useTranslation();
    const { locationId, buildingId, serialNumber, childUserGroupId, subscriptionNumber, spaceId } = useParams();
    const { pathname } = location;
    const pageAvailable = listOfAlwaysAvailable(
        locationId || buildingId,
        serialNumber,
        childUserGroupId,
        subscriptionNumber,
        spaceId
    ).includes(pathname);
    const limitingToggle = useSelector((store: Store) => store.toggles.enableDowngradeViewToggleOn);
    const subscriptionIsLimited = limitSubscription(subscriptionPlan);
    const willLimitWithinAWeek = !subscriptionIsLimited && limitWithinAWeek(subscriptionPlan);

    const blurPage = limitingToggle && !pageAvailable && subscriptionIsLimited;

    const userIsB2B = userIsHbs();
    const preventScroll = (): void => {
        window?.scrollTo(0, 0);
    };

    useEffect(() => {
        if (userIsB2B && limitingToggle) {
            if (blurPage) {
                analyticsLogger(SUBSCRIPTION_LIMITED, {});
            } else if (willLimitWithinAWeek) {
                analyticsLogger(SUBSCRIPTION_SOON_LIMITED, {});
            }
        }
    }, []);

    useEffect(() => {
        if (userIsB2B && blurPage) {
            window?.scrollTo(0, 0);
            window.addEventListener('scroll', preventScroll);
        }
        return (): void => window.removeEventListener('scroll', preventScroll);
    }, [blurPage]);
    if (userIsB2B && willLimitWithinAWeek && limitingToggle) {
        return (
            <div className={styles.banner}>
                {txt('Subscription.OrganizationWillBeLimitedSoon', {
                    date: dayjs(subscriptionPlan?.limitingDate).format(dateFormats[dateFormat].format),
                })}
            </div>
        );
    }

    if (!blurPage || !userIsB2B) {
        return <div />;
    }
    return <InactiveSubscriptionModal displayTabs={displayTabs} />;
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { subscriptionForGroup, dateFormat },
    } = store;
    return {
        subscriptionPlan: subscriptionForGroup,
        dateFormat,
    };
};

export default connect(mapStateToProps)(InactiveSubscriptionPageBlurComponent);
