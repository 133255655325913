import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmModal from '../../../components/modals/DeleteConfirmModal';
import { paths } from '../../../constants';
import { ErrorType } from '../../../models/commonTypeScript';

import { Store } from '../../../reducers';
import { CommonRequestType } from '../../../reducers/requestReducer';

type ParentProps = {
    segmentName: string;
    onDeleteDevice: () => void;
    isModalOpen: boolean;
    onModalClose: () => void;
    isEndedSegment: boolean;
    isB2B: boolean;
    locationId?: string;
};

type StateProps = {
    loading: boolean;
    error?: ErrorType;
};

type Props = ParentProps & StateProps;

const DeleteModal = (props: Props): React.ReactElement => {
    const {
        onDeleteDevice,
        loading,
        isModalOpen,
        onModalClose,
        segmentName,
        isEndedSegment,
        error,
        locationId,
        isB2B,
    } = props;
    const { t: txt } = useTranslation();
    const navigate = useNavigate();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const deviceName = segmentName || 'UnknownDevice';
    const errorText = (error && error.error) || '';
    const redirectToMainPage = (): void => {
        if (isB2B) {
            if (locationId) {
                navigate(`/${paths.buildings}/${locationId}/devices`);
            } else {
                navigate(`/${paths.buildings}`);
            }
        } else navigate(`/${paths.devices}`);
    };

    useEffect(() => {
        if (deleteLoading && !loading && !error) {
            redirectToMainPage();
        }
        if (deleteLoading && !loading && error) {
            setDeleteLoading(false);
        }
        if (!deleteLoading && loading) {
            setDeleteLoading(true);
        }
    }, [loading, error]);

    const description = (
        <p className="modal__content__description">
            {isEndedSegment
                ? txt('DeleteEndedSegmentDescription', { name: deviceName })
                : txt('DeleteDeviceDescription', { name: deviceName })}
        </p>
    );
    if (!isModalOpen) {
        return <div />;
    }

    return (
        <DeleteConfirmModal
            title={isEndedSegment ? 'DeleteEndedSegment' : 'DeleteDevice'}
            errorText={errorText}
            error={!!error}
            description={description}
            onSubmit={onDeleteDevice}
            onCancel={onModalClose}
            onSubmitText={isEndedSegment ? 'Delete' : 'Unpair'}
            onCancelText="Cancel"
            loading={loading}
        />
    );
};

const mapStateToProps = (state: Store): StateProps => {
    const {
        commonRequests: {
            [CommonRequestType.DeleteDevice]: { loading, error },
            [CommonRequestType.DeleteEndedSegment]: {
                loading: loadingDeleteEndedMeasurement,
                error: errorDeleteEndedMeasurement,
            },
        },
    } = state;
    return {
        loading: loadingDeleteEndedMeasurement || loading,
        error: errorDeleteEndedMeasurement || error,
    };
};

export default connect(mapStateToProps)(DeleteModal);
