import React, { SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { EditDeviceSave, editDeviceSave } from 'commons/src/actions/DeviceActions';
import { analyticsLogger, PageType } from 'commons/src/analytics';
import { DEVICE_SAVED_SETTINGS } from 'commons/src/analytics/AnalyticsEvents';
import PrimaryButton from 'commons/src/components/buttons/PrimaryButton';
import ResponseBox from 'commons/src/components/responseMessages/ResponseBox';
import { DeviceType, LocationChangePayload } from 'commons/src/models/commonTypeScript';
import { CommonRequestType } from 'commons/src/reducers/requestReducer';
import { AddZoneGroupPayload } from '../../../models/common';
import { Store } from '../../../reducers';
import { BusinessRequestType as RequestType } from '../../../reducers/BusinessRequestType';
import AddGroupForm from '../../buildings/deviceGroup/AddGroupForm';

type StateProps = {
    device: DeviceType;
    loading: boolean;
    error?: string;
    rfRegionValidationLoading: boolean;
};

type ActionProps = {
    onChangeLocation: (payload: LocationChangePayload) => void;
};

export type PassedProps = {
    serialNumber: string;
    onClose: () => void;
    closeOnSave?: boolean;
};

export type Props = PassedProps & ActionProps & StateProps;

export const DeviceDetailsComponent = (props: Props): React.ReactElement => {
    const { device, error, onClose, onChangeLocation, loading, serialNumber, rfRegionValidationLoading, closeOnSave } =
        props;
    const { locationName: propLocationName, locationId: propLocationId } = device;

    const [isLoading, setIsLoading] = useState(false);
    const [triggerSubmitVirtualDevices, setTriggerSubmitVirtualDevices] = useState(false);

    useEffect((): void => {
        if (error || !loading) {
            setIsLoading(false);
            if (closeOnSave) {
                onClose();
            }
        }
    }, [error, loading]);

    const submit = (e: SyntheticEvent<HTMLElement>): void => {
        e.preventDefault();
        setTriggerSubmitVirtualDevices(true);
    };

    const submitVirtualDevice = (payload: AddZoneGroupPayload): void => {
        if (triggerSubmitVirtualDevices) {
            const virtualDevicePayload = {
                locationName: propLocationName,
                name: payload.segmentName,
                metaData: { ...payload.deviceMetaData, members: payload.members },
                serialNumber,
                segmentId: device.segmentId,
            };
            onChangeLocation(virtualDevicePayload);
            analyticsLogger(DEVICE_SAVED_SETTINGS, {
                pageType: PageType.Device,
                deviceSegmentProps: virtualDevicePayload,
            });
        }
    };

    return (
        <div className="page-wrapper__inner page-wrapper__inner--slim">
            <form className="change-location__form">
                <AddGroupForm
                    locationId={propLocationId}
                    selectedDevice={device}
                    triggerSubmit={triggerSubmitVirtualDevices}
                    setPayload={submitVirtualDevice}
                    setTriggerSubmit={setTriggerSubmitVirtualDevices}
                    slimView
                />
                {error && <ResponseBox text={`ErrorCodes.${error}`} />}
                <div className="change-location__form__buttons">
                    <PrimaryButton id="close" title="Close" loading={false} onClick={onClose} />
                    <PrimaryButton
                        id="submit"
                        type="submit"
                        title="SaveChanges"
                        filled
                        loading={isLoading}
                        onClick={submit}
                        disabled={rfRegionValidationLoading}
                    />
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: Store, passedProps: PassedProps): StateProps => {
    const {
        devicePage: { updateSegmentDetailsError, loading },
        devices: { devices },
        requests: {
            [RequestType.UpdateSegmentProperties]: { error },
        },
        commonRequests: {
            [CommonRequestType.ValidateLocationRfRegion]: { loading: rfRegionValidationLoading },
        },
    } = state;

    const device = devices[passedProps.serialNumber];

    return {
        device,
        error: (error && error.error) || updateSegmentDetailsError,
        loading,
        rfRegionValidationLoading,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => ({
    onChangeLocation: (payload: LocationChangePayload): EditDeviceSave => dispatch(editDeviceSave(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsComponent);
