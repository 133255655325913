import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Store } from 'business-dashboard/src/reducers';
import { ButtonColor, FeatureToggleEnum, GroupType, Role } from '../../models/commonEnums';
import { ActionButton, OptionButtonType } from '../../models/menuModels';
import OptionButton from '../buttons/OptionButton';
import PrimaryButton from '../buttons/PrimaryButton';
import SearchComponent from '../input/SearchComponent';
import MaterialIcon from '../MaterialIcon';
import filterMenuElements from '../menu/FilterMenuElements';

type StateProps = {
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
};

type SearchElementParams = {
    onSearchUpdate?: (text: string) => void;
    disabled?: boolean;
};

type ParentProps = {
    actionButtons: ActionButton[];
    optionButton?: OptionButtonType;
    onGoBack?: () => void;
    backTitle?: string;
    small?: boolean;
} & SearchElementParams;

export const renderActionButtons = ({
    actionButtons,
    userRole,
    featureToggles,
    groupType,
    small,
}: {
    actionButtons: ActionButton[];
    userRole?: Role;
    featureToggles?: FeatureToggleEnum[];
    groupType?: GroupType;
    small?: boolean;
}): React.ReactElement[] =>
    filterMenuElements(actionButtons, groupType, userRole, undefined, featureToggles).map(button => (
        <div className="sub-header__element" key={button.id}>
            <PrimaryButton
                onClick={button.onClick}
                title={button.title}
                icon={button.icon}
                filled={button.color === ButtonColor.primary}
                alert={button.color === ButtonColor.alert}
                noBackground={button.color === ButtonColor.noBackground}
                testAttr={button.testAttr}
                testId={button.testAttr}
                disabled={button.disabled}
                small={!!small}
            />
        </div>
    ));

export type Props = StateProps & ParentProps;

const SubHeader = (props: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const {
        onSearchUpdate,
        actionButtons,
        optionButton,
        onGoBack,
        backTitle,
        userRole,
        featureToggles,
        groupType,
        disabled,
        small,
    } = props;

    return (
        <div
            className={classNames('sub-header', {
                'sub-header--only-buttons': !onSearchUpdate && !onGoBack,
            })}
        >
            {onGoBack && (
                <button
                    type="button"
                    className="page-header__back-link page-header__back-link--config-view"
                    onClick={onGoBack}
                >
                    <MaterialIcon name="chevron_left" />
                    {txt(backTitle || 'back')}
                </button>
            )}
            {onSearchUpdate && (
                <SearchComponent onChange={onSearchUpdate} disabled={disabled} size={small ? 'sm' : 'lg'} expandable />
            )}
            <div className="sub-header__button-container">
                {renderActionButtons({ actionButtons, userRole, featureToggles, groupType, small })}
                {optionButton && (
                    <div className="sub-header__element">
                        <OptionButton
                            id={optionButton.id}
                            title={optionButton.title}
                            testAttr={optionButton.testAttr}
                            options={optionButton.options}
                            onClick={optionButton.onClick}
                            requiredRoleLevel={optionButton.requiredRoleLevel}
                            accessWithResourceFilter={optionButton.accessWithResourceFilter}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (store: Store): StateProps => {
    const {
        userSettings: { selectedGroup, featureToggles },
    } = store;
    return {
        userRole: selectedGroup && selectedGroup.role,
        groupType: selectedGroup?.groupType,
        featureToggles,
    };
};

export default connect(mapStateToProps)(SubHeader);
