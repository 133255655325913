import React, { SyntheticEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import spinner from '../../img/spinner';
import MaterialIcon from '../MaterialIcon';
import styles from './CircleButton.module.scss';

type CircleButtonSize = 'medium' | 'small';

export type CircleButtonColor = 'primary' | 'secondary' | 'tertiary' | 'confirmed' | 'alert';

export type Props = {
    onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
    testAttr?: string;
    iconName?: string | React.ReactElement | null;
    hoverText?: string;
    id?: string;
    color: CircleButtonColor;
    size?: CircleButtonSize;
    loading?: boolean;
    disabled?: boolean;
};
const CircleButton = ({
    onClick,
    testAttr,
    iconName,
    loading = false,
    size = 'medium',
    color,
    id,
    hoverText,
    disabled,
}: Props): React.ReactElement => {
    const { t: txt } = useTranslation();
    const onClickHandler = (e: SyntheticEvent<HTMLButtonElement>): void => {
        if (!disabled) onClick(e);
    };
    return (
        <button
            id={id}
            title={hoverText ? txt(hoverText) : undefined}
            type="button"
            className={classNames(styles.button, styles[color], styles[size], {
                [styles.disabled]: disabled,
            })}
            onClick={onClickHandler}
            {...{ [`data-${testAttr}`]: true }}
            data-testid={`data-${testAttr}`}
        >
            {!loading && (typeof iconName === 'string' ? <MaterialIcon name={iconName} /> : iconName)}
            {loading && spinner}
        </button>
    );
};

export default CircleButton;
